import { type BudgetAlert } from "@doitintl/cmp-models";
import { Card, darken, lighten } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { ThemeModes } from "../../../muiThemeTypes";
import { formatUSDollars } from "../../../utils/common";
import { nearestValue } from "./utils";

type ChartData = {
  x?: number;
  y?: number | null;
};

type Props = {
  actual: ChartData[];
  budget: ChartData[];
  forecast: ChartData[] | null;
  alerts: BudgetAlert[];
};

const actualsColor = "#06F";

const PerformanceChart = ({ actual, budget, forecast, alerts }: Props) => {
  const theme = useTheme();

  const forecastColor =
    theme.palette.mode === ThemeModes.LIGHT
      ? lighten(theme.palette.text.disabled, 0.2)
      : darken(theme.palette.text.disabled, 0.4);

  const budgetColor = theme.palette.success.main;

  const variance = budget.map((budgetItem) => {
    const actualItem = actual.find((j) => j.x === budgetItem.x);
    return { y: budgetItem.y, x: Math.abs((budgetItem.y || 0) - (actualItem?.y || 0)) };
  });

  const alertTriggers = alerts
    .filter((i) => i.percentage !== 100)
    .map((alert) => {
      const budgetAmountForTrigger = nearestValue(
        budget.map((b) => b.y),
        alert.amount
      );
      const budgetDate = budget.find((b) => b.y === budgetAmountForTrigger)?.x;
      const budgetDateIndex = budget.findIndex((b) => b.y === budgetAmountForTrigger);
      if (budget.length - 1 === budgetDateIndex) {
        return;
      }

      return {
        labels: [
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: budgetDate,
              y: budgetAmountForTrigger,
            },
            y: -15,
            text: "Alert trigger",
          },
        ],
      };
    })
    .filter((i) => i);

  return (
    <Card sx={{ pt: 6.6, pb: 2.8, px: 2 }}>
      <HighchartsReact
        highcharts={Highcharts}
        allowChartUpdate
        options={{
          chart: {
            height: 320,
            backgroundColor: theme?.palette?.background.paper,
            style: {
              fontFamily: "Roboto",
            },
          },
          exporting: {
            enabled: false,
          },
          title: {
            text: "",
          },
          xAxis: {
            type: "datetime",
            labels: {
              style: {
                fontFamily: "Roboto",
                fontWeight: 500,
                color: theme?.palette?.text?.primary,
              },
            },
            accessibility: {
              rangeDescription: "",
            },
            tickLength: 0,
            gridLineWidth: 0,
          },
          yAxis: {
            title: {
              text: "",
            },
            left: 58,
            labels: {
              x: -10,
              valuePrefix: "$",
              formatter() {
                return formatUSDollars((this as any).value);
              },
              style: {
                fontSize: 14,
                fontWeight: 500,
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                color: theme?.palette?.text?.primary,
              },
            },
          },
          tooltip: {
            shared: true,
            xDateFormat: "",
            useHTML: true,
            formatter() {
              const self = this as any;
              const presentPoints = self.points.map((i) => i.series.name);

              const actual = self.points.find((i) => i.series.name === "Actuals")?.y;
              const forecast = self.points.find((i) => i.series.name === "Forecasted")?.y;
              const budget = self.points.find((i) => i.series.name === "Budgeted")?.y;

              const showForecast = !presentPoints.includes("Actuals");

              const currentVariance = showForecast
                ? Math.abs(self.points[0]?.y - self.points[1]?.y)
                : variance.find((varianceItem) => varianceItem.y === self.points[1]?.y)?.x || 0;

              const firstLineColor = showForecast ? forecastColor : actualsColor;

              const firstPointName = showForecast ? "Forecasted" : "Actuals";
              const firstPointValue = showForecast ? forecast : actual;

              return `
                <span style="color:${firstLineColor};">
                  <b> ${firstPointName}:</b>
                </span>
                <span style="color:rgba(0, 0, 0, 0.60)">$${Highcharts.numberFormat(firstPointValue, 0)}</span><br/>

                <span style="color:${budgetColor}">
                  <b> Budgeted:</b>
                </span>
                <span style="color:rgba(0, 0, 0, 0.60)">$${Highcharts.numberFormat(budget, 0)}</span><br/>

                <span><b> Variance:</b></span>
                <span style="color:rgba(0, 0, 0, 0.60)">$${Highcharts.numberFormat(currentVariance, 0)}</span>
              `;
            },
            valuePrefix: "$",
            valueDecimals: 0,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: actualsColor,
            backgroundColor: "rgb(247, 247, 247)",
            shadow: {
              color: "rgba(0, 0, 0, 0.15)",
              offsetX: 1,
              offsetY: 1,
              width: 0,
            },
          },
          credits: {
            enabled: false,
          },
          plotOptions: {
            series: {
              animation: false,
            },
            area: {
              fillOpacity: 0.35,
            },
          },
          annotations: alertTriggers,
          series: [
            {
              name: "Actuals",
              data: actual,
              color: actualsColor,
              type: "area",
              fillOpacity: 0.1,
            },
            {
              name: "Budgeted",
              data: budget,
              type: "line",
              dashStyle: "Dash",
              color: budgetColor,
              lineWidth: 1,
            },
            {
              name: "Forecasted",
              data: forecast,
              type: "line",
              color: forecastColor,
              dashStyle: "ShortDash",
              marker: {
                symbol: "circle",
              },
            },
          ],
          legend: {
            layout: "horizontal",
            enabled: true,
            itemStyle: {
              color: theme.palette.text.secondary,
              fontWeight: 400,
            },
          },
        }}
      />
    </Card>
  );
};

export default PerformanceChart;
