import {
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeGSuite,
  AssetTypeMicrosoftAzure,
  type PerkModel,
  type PerkType,
  PerkTypes,
} from "@doitintl/cmp-models";
import capitalize from "lodash/capitalize";

import AWSLogo from "../../assets/aws-logo.svg";
import AzureLogo from "../../assets/azure-logo.svg";
import GSuiteLogo from "../../assets/g-suite.png";
import GCPLogo from "../../assets/gcp-logo.svg";
import { platformValue } from "../../Support/utils";
import { type PerksWData } from "../../types";
import { type GroupedPerks } from "./types";
import type { PerkPageOverviewId } from "../../Navigation/config/pages";

export const toBeautifiedName = (key: string, numPerks?: number): string => {
  const keyNameMap: Record<string, string> = {
    "amazon-web-services": "AWS",
    "g-suite": "Google Workspace",
    "google-cloud": "GCP",
    "microsoft-azure": "Azure",
    analytics: "Analytics",
    "big-data": "Big Data",
    "developer-tools": "Developer Tools",
    "security-and-monitoring": "Security & Monitoring",
    networking: "Networking",
    "ml-and-ai": "ML & AI",
    database: "Databases",
    compute: "Compute",
    "blog-and-cms": "Blog & CMS",
    "data-sources": "Data Sources",
    "operating-systems": "Operating Systems",
    storage: "Storage",
    maps: "Maps",
    "advertising-and-gtm": "Advertising & GTM",
    other: "Other",
  };

  return `${keyNameMap[key] ?? capitalize(key)} ${numPerks !== undefined ? `(${numPerks})` : ""}`;
};

export const groupPerks = (perks: PerksWData[]): GroupedPerks => {
  const grouped = perks.reduce<GroupedPerks>(
    (group, product) => {
      const { solutionType, platform, type } = product.data.fields;

      platform.forEach((p) => {
        group.platform[p] = group.platform[p] ?? [];
        group.platform[p].push(product);
      });

      solutionType?.forEach((s) => {
        group.solution[s] = group.solution[s] ?? [];
        group.solution[s].push(product);
      });

      group.type[type] = group.type[type] ?? [];
      group.type[type].push(product);

      return group;
    },
    { platform: {}, type: {}, solution: {} } as GroupedPerks
  );

  // Re-order the type attribute
  const orderedType = {
    workshop: grouped.type.workshop ?? [],
    training: grouped.type.training ?? [],
    accelerator: grouped.type.accelerator ?? [],
    "isv-solution": grouped.type["isv-solution"] ?? [],
    "pro-serv": grouped.type["pro-serv"] ?? [],
    promotion: grouped.type.promotion ?? [],
  };

  const filteredType = Object.fromEntries(Object.entries(orderedType).filter(([_, value]) => !!value.length));

  return {
    ...grouped,
    type: filteredType as GroupedPerks["type"],
  };
};

// for listing the relevant cloud platform of the service provided.
export const getPlatformNonPromotion = (perk: PerkModel | undefined) => {
  if (!perk) {
    return "";
  }

  if (
    [PerkTypes.Accelerator, PerkTypes.Training, PerkTypes.Workshop].some(
      (perkType) => perk?.fields?.type === perkType && perk?.fields?.platform?.length === 1
    )
  ) {
    return platformValue(perk.fields.platform[0]);
  }
  return "";
};

export const perkPathByType: Record<PerkType, string> = {
  [PerkTypes.Training]: "training",
  [PerkTypes.Accelerator]: "accelerator",
  [PerkTypes.Workshop]: "workshop",
  // eslint-disable-next-line deprecation/deprecation
  [PerkTypes.Promotion]: "promotion",
  // eslint-disable-next-line deprecation/deprecation
  [PerkTypes.ProServ]: "professional-services",
  [PerkTypes.IsvSolution]: "isv-solutions",
};

export const perkCreateTitleByPageId: Record<PerkPageOverviewId, string> = {
  "isv-solutions": "ISV solution",
  training: "training",
  "professional-services": "professional service",
};

export const perkTitleByType: Record<PerkType, string> = {
  [PerkTypes.IsvSolution]: "ISV solution",
  [PerkTypes.Accelerator]: "accelerator",
  [PerkTypes.Training]: "training",
  // eslint-disable-next-line deprecation/deprecation
  [PerkTypes.ProServ]: "professional service",
  [PerkTypes.Workshop]: "workshop",
  // eslint-disable-next-line deprecation/deprecation
  [PerkTypes.Promotion]: "promotion",
};

export const marketplaceMap = {
  [AssetTypeAmazonWebServices]: {
    title: "AWS",
    logo: AWSLogo,
  },
  [AssetTypeGoogleCloud]: { title: "GCP", logo: GCPLogo },
  [AssetTypeGSuite]: { title: "Google Workspace", logo: GSuiteLogo },
  [AssetTypeMicrosoftAzure]: { title: "Microsoft Azure", logo: AzureLogo },
};

/**
 * Index of capitalised English-friendly names for each perk type
 */
export const perkTypeNames: Record<PerkType, string> = {
  [PerkTypes.IsvSolution]: "ISV solution",
  [PerkTypes.Accelerator]: "Accelerator",
  [PerkTypes.Training]: "Training",
  // eslint-disable-next-line deprecation/deprecation
  [PerkTypes.ProServ]: "Professional service",
  [PerkTypes.Workshop]: "Workshop",
  // eslint-disable-next-line deprecation/deprecation
  [PerkTypes.Promotion]: "Promotion",
};

export const getSingularTypeText = (type: PerkType): string =>
  ["a", "e", "i", "o", "u"].includes(perkTypeNames[type][0]) ? `an ${perkTypeNames[type]}` : `a ${perkTypeNames[type]}`;
