import noop from "lodash/noop";

import { type RFNode } from "../../types";
import { SourceHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: RFNode;
  selected?: boolean;
};

export const TriggerNode = ({ id, data, selected }: Props) => (
  <>
    <NodeWrapper
      title={data.nodeData.name || ""}
      status={data.nodeData.status || "validated"}
      onEdit={data.onEditNode}
      isActive={!!selected}
      onClick={noop}
    />
    <SourceHandle id={id} />
  </>
);
