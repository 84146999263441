import { useEffect, useMemo, useState } from "react";

import { useParams } from "react-router";
import {
  CloudFlowNodeType,
  type NodeConfigApiParameters,
  type ReferencedNodeValue,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { Stack } from "@mui/material";

import { type NodeConfigs } from "../../../../../Pages/Cloudflow/types";
import { type NodeWitOutputModel } from "../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { getOutputModelByOperationPointer } from "../../Common/hooks/useUnwrappedApiActionModel";
import ConditionForm from "../../FilterAction/ConditionForm";
import FieldReferenceForm from "../../FilterAction/FieldReferenceForm";
import SwitchDataSourceDialog from "../../FilterAction/SwitchDataSourceDialog";
import { type FilterGroup } from "../../FilterAction/types";
import { useCloudflowNodes } from "../../hooks/useCloudflowNodes";
import { type CloudFlowNode } from "../../utils/nodeTransformUtils";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

const FilterParametersTab = () => {
  const [filterGroups, setFilterGroups] = useState<FilterGroup[]>([]);
  const [outputModel, setOutputModel] = useState<UnwrappedApiServiceModelDescriptor>();
  const [selectedNode, setSelectedNode] = useState<CloudFlowNode<CloudFlowNodeType.ACTION> | undefined>();
  const [switchDataSourceOpen, setSwitchDataSourceOpen] = useState(false);
  const { nodeConfig }: { nodeConfig: NodeConfigs<CloudFlowNodeType.ACTION> } = useNodeConfigurationContext();
  const { flowId } = useParams<{ customerId: string; flowId: string }>();
  const { getPreviousNodes } = useCloudflowNodes(flowId);
  const [handleConfirm, setHandleConfirm] = useState<(() => Promise<any>) | undefined>();
  const [handleClose, setHandleClose] = useState<(() => Promise<any>) | undefined>();
  const [fieldReference, setFieldReference] = useState<ReferencedNodeValue | undefined>();
  const [referenceableNodes, setReferenceableNodes] = useState<NodeWitOutputModel[]>([]);

  const actionNodes = useMemo(() => {
    const previousNodes = getPreviousNodes(nodeConfig.id);
    return previousNodes.filter(
      (node) =>
        node.data.type === CloudFlowNodeType.ACTION && (node.data.parameters as NodeConfigApiParameters)?.operation
    ) as CloudFlowNode<CloudFlowNodeType.ACTION>[];
  }, [getPreviousNodes, nodeConfig.id]);

  useEffect(() => {
    const fetchReferenceableNodes = async () => {
      const nodes = await Promise.all(
        actionNodes.map(async (node) => {
          const model = await getOutputModelByOperationPointer(node.data.parameters.operation);
          const nodeReference: NodeWitOutputModel = {
            id: node.id,
            name: node.data.name,
            outputModel: model,
          };
          return nodeReference;
        })
      );
      setReferenceableNodes(nodes);
    };

    fetchReferenceableNodes();
  }, [actionNodes]);

  const confirmSwitchDataSource = (
    onConfirm: (() => Promise<void>) | undefined,
    onClose: (() => Promise<void>) | undefined
  ): Promise<void> =>
    new Promise((resolve) => {
      setSwitchDataSourceOpen(true);
      const handleConfirmWrapper = async () => {
        setSwitchDataSourceOpen(false);
        const result = onConfirm ? await onConfirm() : undefined;

        resolve(result);
      };

      const handleCloseWrapper = async () => {
        setSwitchDataSourceOpen(false);
        const result = onClose ? await onClose() : undefined;
        resolve(result);
      };

      setHandleConfirm(() => handleConfirmWrapper);
      setHandleClose(() => handleCloseWrapper);
    });

  return (
    <Stack px={2} justifyContent="center" gap={2} mt={1}>
      <FieldReferenceForm
        selectedNode={selectedNode}
        filterGroups={filterGroups}
        confirmSwitchDataSource={confirmSwitchDataSource}
        setOutputModel={setOutputModel}
        setSelectedNode={setSelectedNode}
        setFieldReference={setFieldReference}
        actionNodes={actionNodes}
        setFilterGroups={setFilterGroups}
        outputModel={outputModel}
        fieldReference={fieldReference}
      />
      <ConditionForm
        selectedNode={selectedNode}
        referenceableNodes={referenceableNodes}
        outputModel={outputModel}
        filterGroups={filterGroups}
        setFilterGroups={setFilterGroups}
      />
      <SwitchDataSourceDialog
        isDialogOpened={switchDataSourceOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </Stack>
  );
};

export default FilterParametersTab;
