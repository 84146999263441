import { useMemo, useState } from "react";

import { Feature, type Key, Metric, Renderer } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import ChartsRenderer from "../../../Pages/CloudAnalytics/renderers/ChartsRenderer";
import TableRenderer from "../../../Pages/CloudAnalytics/renderers/TableRenderer";
import TreemapRenderer from "../../../Pages/CloudAnalytics/renderers/TreemapRenderer";
import { type ColKeySort } from "../../../Pages/CloudAnalytics/ReportData";
import { updateColKeySort } from "../../../Pages/CloudAnalytics/utilities";
import { useFullScreen } from "../../../utils/dialog";
import { useMetricFormatter } from "../../hooks/cloudAnalytics/useMetricFormatter";
import useFormatter from "../../hooks/useFormatter";
import { CloudReportCardError } from "./CloudReportCardError";
import { useCreateWidgetReportData } from "./hooks";

type Props = {
  hasAccessToReport: boolean;
  trackFullReport: () => void;
  rawReport: any;
  forceRender: boolean;
  reportPath: string;
  selectedFilter: string;
  cloudAnalyticsKey?: Key;
  enableWidgetLegend?: boolean;
};

export const CloudReportCardContent = ({
  hasAccessToReport,
  trackFullReport,
  rawReport,
  forceRender,
  reportPath,
  selectedFilter,
  cloudAnalyticsKey,
  enableWidgetLegend = false,
}: Props) => {
  const { config, name } = useMemo(() => rawReport ?? { config: {} }, [rawReport]);

  const [colKeySort, setColKeySort] = useState<ColKeySort>();
  const reportData = useCreateWidgetReportData(rawReport, colKeySort, selectedFilter, cloudAnalyticsKey);

  const isTableViewThreshold = useMemo(() => {
    const rowKeys = reportData?.getRowKeys() ?? [];
    const colKeys = reportData?.getColKeys() ?? [];
    return rowKeys.length > 100 || colKeys.length > 100;
  }, [reportData]);

  const {
    comparative,
    logScale,
    metric,
    renderer,
    features,
    aggregator,
    calculatedMetric,
    currency,
    extendedMetricType,
    includeSubtotals,
    forecastSettings,
  } = config ?? {};

  const baseFormatter = useFormatter(aggregator, currency, metric, undefined, extendedMetricType);
  const calculatedMetricFormatter = useMetricFormatter(aggregator, currency, calculatedMetric);
  const formatter = calculatedMetric ? calculatedMetricFormatter : baseFormatter;
  const { isMobile: smDown } = useFullScreen();
  const showForecast = useMemo(
    () => features?.includes(Feature.FORECAST) || !!forecastSettings,
    [features, forecastSettings]
  );
  const heatmapReverseColor = metric === Metric.SAVINGS || metric === Metric.MARGIN;
  if (reportData) {
    const isTable = [Renderer.TABLE, Renderer.HEATMAP, Renderer.COL_HEATMAP, Renderer.ROW_HEATMAP].includes(renderer);
    if (isTable && isTableViewThreshold) {
      return (
        <CloudReportCardError
          hasAccessToReport={hasAccessToReport}
          trackFullReport={trackFullReport}
          reportPath={reportPath}
        />
      );
    }

    let plot;
    switch (renderer) {
      case Renderer.TABLE:
      case Renderer.HEATMAP:
      case Renderer.ROW_HEATMAP:
      case Renderer.COL_HEATMAP: {
        const forecastStart = reportData?.getForecastStartIndex();
        const heatmapPropMap = {
          [Renderer.HEATMAP]: "full",
          [Renderer.ROW_HEATMAP]: "row",
          [Renderer.COL_HEATMAP]: "col",
        };
        plot = (
          <TableRenderer
            data={reportData}
            isForecast={showForecast}
            forecastStart={forecastStart}
            formatter={formatter}
            height="100%"
            smDown={smDown}
            heatmap={heatmapPropMap[renderer]}
            reverseColors={renderer !== Renderer.TABLE && heatmapReverseColor}
            sortByCol={(colKey) => updateColKeySort(setColKeySort, colKey)}
            colKeySort={colKeySort}
            showSubtotals={includeSubtotals}
          />
        );
        break;
      }
      case Renderer.TREEMAP: {
        plot = (
          <TreemapRenderer
            data={reportData}
            height="100%"
            formatter={formatter}
            isExact={true}
            isWidget={true}
            forceRender={forceRender}
            widgetsList={[]}
          />
        );
        break;
      }
      default:
        plot = (
          <ChartsRenderer
            title={name}
            data={reportData}
            height="100%"
            type={renderer}
            showForecast={showForecast}
            formatter={formatter}
            logScale={logScale}
            comparative={comparative}
            forceRender={forceRender}
            isWidget={!cloudAnalyticsKey}
            enableWidgetLegend={enableWidgetLegend}
            hideExportingMenu={Boolean(cloudAnalyticsKey)}
          />
        );
    }

    return <Box sx={{ height: "100%", margin: "0 6px 0px 6px" }}>{plot}</Box>;
  }
  return null;
};
