import { useMemo } from "react";

import {
  type BQLensBillingMode,
  BQLensBillingModes,
  BQLensMeasurements,
  BQLensMetrics,
  BQLensOptimizerModes,
  BQLensTimeFrames,
  SuperQueryModel,
} from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import keys from "lodash/keys";

import { useCustomerContext } from "../../../Context/CustomerContext";

export type DataExistsCheck = {
  exists: boolean;
  loading: boolean;
};

export type RollsUpDefault = {
  defaultMetric: BQLensMetrics | undefined;
  availableMetrics: BQLensMetrics[];
  loading: boolean;
};

interface MetricConfig {
  measurement: BQLensMeasurements;
  mode: BQLensOptimizerModes;
}

const useEditionRollsUpsData = (
  usageType: BQLensBillingModes,
  timeFrame: BQLensTimeFrames,
  subCollection: "billingProject" | "user"
) => {
  const { customer } = useCustomerContext();

  const ref = useMemo(
    () =>
      getCollection(SuperQueryModel)
        .doc("simulation-recommender")
        .collection(usageType)
        .doc(customer.id)
        .collection("rollUps")
        .doc(timeFrame)
        .collection(subCollection)
        .doc("slots"),
    [usageType, timeFrame, subCollection, customer]
  );

  const [data, loading, error] = useDocumentDataOnce(ref);

  return { data, loading, error };
};

const useEditionRecommendationsData = (usageType: BQLensBillingModes, timeFrame: BQLensTimeFrames) => {
  const { customer } = useCustomerContext();

  const ref = useMemo(
    () =>
      getCollection(SuperQueryModel)
        .doc("simulation-recommender")
        .collection(usageType)
        .doc(customer.id)
        .collection("recommendations")
        .doc(timeFrame),
    [usageType, timeFrame, customer]
  );

  const [data, loading, error] = useDocumentDataOnce(ref);

  return { data, loading, error };
};

const useRollsUpsData = (
  mode: BQLensOptimizerModes,
  timeFrame: BQLensTimeFrames,
  metric: BQLensMetrics,
  scanType: BQLensMeasurements
) => {
  const { customer } = useCustomerContext();

  const ref = useMemo(
    () =>
      getCollection(SuperQueryModel)
        .doc("simulation-recommender")
        .collection(mode)
        .doc(customer.id)
        .collection("rollUps")
        .doc(timeFrame)
        .collection(metric)
        .doc(scanType),
    [mode, timeFrame, metric, customer, scanType]
  );

  const [data, loading, error] = useDocumentDataOnce(ref);

  return { data, loading, error };
};

const useEditionExplorerData = (usageType: BQLensBillingModes, timeFrame: BQLensTimeFrames) => {
  const { customer } = useCustomerContext();

  const ref = useMemo(
    () =>
      getCollection(SuperQueryModel)
        .doc("simulation-recommender")
        .collection(usageType)
        .doc(customer.id)
        .collection("explorer")
        .doc(timeFrame),
    [usageType, timeFrame, customer]
  );

  const [data, loading, error] = useDocumentDataOnce(ref);

  return { data, loading, error };
};

export const useEditionRollsUpsBillingProjectData = (usageType: BQLensBillingModes, timeFrame: BQLensTimeFrames) =>
  useEditionRollsUpsData(usageType, timeFrame, "billingProject");

export const useEditionRollsUpsUserData = (usageType: BQLensBillingModes, timeFrame: BQLensTimeFrames) =>
  useEditionRollsUpsData(usageType, timeFrame, "user");

export const useCombinedDataCheck = (usageType: BQLensBillingModes, timeFrame: BQLensTimeFrames): DataExistsCheck[] => {
  const billingProject = useEditionRollsUpsBillingProjectData(usageType, timeFrame);
  const user = useEditionRollsUpsUserData(usageType, timeFrame);
  const recommendations = useEditionRecommendationsData(usageType, timeFrame);
  const explorer = useEditionExplorerData(usageType, timeFrame);

  return [
    { exists: !!billingProject.data, loading: billingProject.loading },
    { exists: !!user.data, loading: user.loading },
    { exists: !!recommendations.data, loading: recommendations.loading },
    { exists: !!explorer.data, loading: explorer.loading },
  ];
};

export const useCheckEditionDataExists = (usageType: BQLensBillingModes): DataExistsCheck => {
  const allResults: DataExistsCheck[] = [];

  const thirtyDaysData = useCombinedDataCheck(usageType, BQLensTimeFrames.pastThirtyDays);
  const sevenDaysData = useCombinedDataCheck(usageType, BQLensTimeFrames.pastSevenDays);
  const oneDayData = useCombinedDataCheck(usageType, BQLensTimeFrames.pastOneDay);
  allResults.push(...thirtyDaysData, ...sevenDaysData, ...oneDayData);

  const loading = allResults.some((check) => check.loading);
  const exists = allResults.some((check) => check.exists);

  return { loading, exists };
};

export const useRollsUpDefaultData = (metricMap: Record<BQLensMetrics, MetricConfig>): RollsUpDefault => {
  const billingProject = useRollsUpsData(
    metricMap[BQLensMetrics.billingProject].mode,
    BQLensTimeFrames.pastThirtyDays,
    BQLensMetrics.billingProject,
    metricMap[BQLensMetrics.billingProject].measurement
  );

  const dataset = useRollsUpsData(
    metricMap[BQLensMetrics.dataset].mode,
    BQLensTimeFrames.pastThirtyDays,
    BQLensMetrics.dataset,
    metricMap[BQLensMetrics.dataset].measurement
  );

  const project = useRollsUpsData(
    metricMap[BQLensMetrics.project].mode,
    BQLensTimeFrames.pastThirtyDays,
    BQLensMetrics.project,
    metricMap[BQLensMetrics.project].measurement
  );

  const table = useRollsUpsData(
    metricMap[BQLensMetrics.table].mode,
    BQLensTimeFrames.pastThirtyDays,
    BQLensMetrics.table,
    metricMap[BQLensMetrics.table].measurement
  );

  const user = useRollsUpsData(
    metricMap[BQLensMetrics.user].mode,
    BQLensTimeFrames.pastThirtyDays,
    BQLensMetrics.user,
    metricMap[BQLensMetrics.user].measurement
  );

  const loading = billingProject.loading || dataset.loading || project.loading || table.loading || user.loading;

  const availableMetrics = useMemo(() => {
    const metrics: BQLensMetrics[] = [];

    const hasValidData = (data: any) => data && keys(data).length > 0;

    if (hasValidData(billingProject.data)) {
      metrics.push(BQLensMetrics.billingProject);
    }
    if (hasValidData(dataset.data)) {
      metrics.push(BQLensMetrics.dataset);
    }
    if (hasValidData(project.data)) {
      metrics.push(BQLensMetrics.project);
    }
    if (hasValidData(table.data)) {
      metrics.push(BQLensMetrics.table);
    }
    if (hasValidData(user.data)) {
      metrics.push(BQLensMetrics.user);
    }

    return metrics;
  }, [billingProject.data, dataset.data, project.data, table.data, user.data]);

  const defaultMetric = useMemo(
    () => (availableMetrics.length > 0 ? availableMetrics[0] : undefined),
    [availableMetrics]
  );

  return { defaultMetric, availableMetrics, loading };
};

export const useRollsUpData = (selectedBillingMode: BQLensBillingMode): RollsUpDefault => {
  const onDemandData = useRollsUpDefaultData({
    [BQLensMetrics.billingProject]: { measurement: BQLensMeasurements.scanPrice, mode: BQLensOptimizerModes.onDemand },
    [BQLensMetrics.dataset]: { measurement: BQLensMeasurements.scanPrice, mode: BQLensOptimizerModes.onDemand },
    [BQLensMetrics.project]: { measurement: BQLensMeasurements.scanPrice, mode: BQLensOptimizerModes.onDemand },
    [BQLensMetrics.table]: { measurement: BQLensMeasurements.scanPrice, mode: BQLensOptimizerModes.onDemand },
    [BQLensMetrics.user]: { measurement: BQLensMeasurements.scanPrice, mode: BQLensOptimizerModes.onDemand },
  });

  const flatRateData = useRollsUpDefaultData({
    [BQLensMetrics.billingProject]: { measurement: BQLensMeasurements.slots, mode: BQLensOptimizerModes.flatRate },
    [BQLensMetrics.dataset]: { measurement: BQLensMeasurements.storagePrice, mode: BQLensOptimizerModes.output },
    [BQLensMetrics.project]: { measurement: BQLensMeasurements.storagePrice, mode: BQLensOptimizerModes.output },
    [BQLensMetrics.table]: { measurement: BQLensMeasurements.storagePrice, mode: BQLensOptimizerModes.output },
    [BQLensMetrics.user]: { measurement: BQLensMeasurements.slots, mode: BQLensOptimizerModes.flatRate },
  });

  if (selectedBillingMode === BQLensBillingModes.onDemand) {
    return onDemandData;
  }

  return flatRateData;
};
