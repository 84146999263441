import { EarlyAccessFeature } from "@doitintl/cmp-models";

export const text = {
  CLOUD_PROVIDER_SELECT_LABEL: "Cloud provider on Get Started page",
  CLOUD_PROVIDER_AWS_LABEL: "Amazon Web Services",
  CLOUD_PROVIDER_GCP_LABEL: "Google Cloud Platform",
  CLOUD_PROVIDER_OTHER_LABEL: "Other",
  CUSTOMER_CLASSIFICATION_SELECT_LABEL: "Customer classification",
  CUSTOMER_CLASSIFICATION_BUSINESS_LABEL: "Business",
  CUSTOMER_CLASSIFICATION_TERMINATED_LABEL: "Terminated",
  CUSTOMER_CLASSIFICATION_SUSPENDED_FOR_NON_PAYMENT_LABEL: "Suspended for non-payment",
  CUSTOMER_CLASSIFICATION_INACTIVE_LABEL: "Inactive",
  CUSTOMER_NAME_LABEL: "Customer name",
  DOMAINS_HELPER_TEXT: "Enter naked domain name e.g. example.com",
  EARLY_ACCESS_FEATURES_LABEL: "Early access features",
  LINES_PER_INVOICE_LABEL: "Lines per invoice",
  NO_PERMISSION_TOOLTIP: "You don't have permission to edit this field",
  PRIMARY_DOMAIN_CHANGED_ALERT: "Ensure the change to the primary domain is correct before saving.",
  PRESENTATION_MODE_BTN_TURN_ON: "Turn presentation mode on",
  PRESENTATION_MODE_BTN_TURN_OFF: "Turn presentation mode off",
  THIS_OPTION_NOT_FOR_TRIAL_TOOLTIP: "This option is not available to a trial customers",
  CONVERT_TO_SAAS_CUSTOMER_BTN_TITLE: "Convert to Product Only",
  NAVIGATOR_TRIAL_SETTINGS_LABEL: "DoiT Cloud Navigator Trial",
  SOLVE_TRIAL_SETTINGS_LABEL: "DoiT Cloud Solve Trial",
  EDIT_INTERESTED_PACKAGES_BTN_TITLE: "Edit interested packages",
  TIER_MANAGEMENT_LABEL: "DoiT Cloud tier management",
  TIER_MANAGEMENT_NAVIGATOR_LABEL: "Navigator Tier",
  TIER_MANAGEMEMT_SOLVE_LABEL: "Solve Tier",
};

export const featureFlagsDescriptions = {
  [EarlyAccessFeature.AWS_TCO]: "Show AWS TCO Analysis page",
  [EarlyAccessFeature.FSGCP_DISABLED]: "Disable Flexsave GCP",
  [EarlyAccessFeature.FSAWS_DISABLED]: "Disable Flexsave AWS",
  [EarlyAccessFeature.FSGCP_MARKETPLACE_DISABLED]: "Disable Flexsave GCP Marketplace",
  [EarlyAccessFeature.FLEXSAVE_AWS_STANDALONE]:
    "Enable SaaS Flexsave AWS onboarding for a customer that is GCP resold only",
  [EarlyAccessFeature.GCP_CLOUD_CONNECT_V2]: "Allow customer to upload a Service Account on a project level",
  [EarlyAccessFeature.COUNT_AGGREGATION]: "Allow to use 'count' aggregation in analytics reports",
  [EarlyAccessFeature.DISABLE_CREDIT_CARD_FEES]: "Disable processing fee surcharge when paying with credit card",
  [EarlyAccessFeature.ACCELERATOR_PROGRAM]: "Enable a specific Professional Services perk type",
};

const featureFlagDisplayName = {
  [EarlyAccessFeature.FSGCP_DISABLED]: "FSGCP Disabled",
  [EarlyAccessFeature.EKS_ONBOARDING]: "EKS Onboarding",
  [EarlyAccessFeature.FLEXSAVE_AWS_STANDALONE]: "Flexsave AWS (SaaS)",
  [EarlyAccessFeature.FLEXSAVE_GCP_STANDALONE]: "Flexsave GCP (SaaS) [Deprecated]",
};

export function getFeatureFlagOptionDisplayName(option: EarlyAccessFeature) {
  return featureFlagDisplayName[option] ?? option;
}

export function getFeatureFlagOptionDescription(option: EarlyAccessFeature) {
  return featureFlagsDescriptions[option] ?? option;
}
