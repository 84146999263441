import noop from "lodash/noop";

import { type RFNode } from "../../types";
import { SourceHandle, TargetHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: RFNode;
  selected?: boolean;
};

export const FilterNode = ({ id, data, selected }: Props) => (
  <>
    <NodeWrapper
      title={data.nodeData.name || ""}
      status={data.nodeData.status || "error"}
      onEdit={data.onEditNode}
      onDelete={data.onDeleteNode}
      isActive={!!selected}
      onClick={noop}
    />
    <SourceHandle id={id} />
    <TargetHandle id={id} />
  </>
);
