import { useMemo } from "react";

import { EarlyAccessFeature } from "@doitintl/cmp-models";

import { useFeatureFlag } from "../../../Components/hooks/useFeatureFlag";
import { Budget } from "./Budget";
import CreateBudgetStepper from "./CreateBudgetSteper/CreateBudgetStepper";
import { useNewBudgetData } from "./useNewBudgetData";

export const CreateBudgetWrapper = () => {
  const newBudgetData = useNewBudgetData();
  const isNewBudgetDesign = useFeatureFlag(EarlyAccessFeature.BUDGET_NEW_DESIGN);

  const budget = useMemo(
    () => ({
      data: newBudgetData,
    }),
    [newBudgetData]
  );
  if (isNewBudgetDesign) {
    return <CreateBudgetStepper budget={budget} />;
  }
  return <Budget budget={budget} isNewBudget={true} />;
};
