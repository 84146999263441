import { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { type SlackChannel } from "@doitintl/cmp-models";
import { Box } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import { DateTime } from "luxon";

import { budgetTxt } from "../../../../assets/texts/CloudAnalytics/budget";
import { type Step, Stepper, type StepState } from "../../../../Components/Stepper";
import { useCloudAnalyticsContext } from "../../../../Context/AnalyticsContext";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { isEditor, TypeAndFrequencyOptions } from "../../../../Pages/CloudAnalytics/utilities";
import { type BudgetInfo, type DraftBudget } from "../../../../types";
import { sanitizeDate } from "../../../../utils/common";
import { type CloudAnalyticsHistoryState } from "../../types";
import { getAlertAmountFromPercentage } from "../shared";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

type Props = {
  budget: DraftBudget;
};
const CreateBudgetStepper = ({ budget }: Props) => {
  const [stepperState] = useState<StepState[]>(["complete", "complete", "incomplete"]);
  const [, getCurrentStep] = useState(1);
  const [overrideStep, setOverrideStep] = useState<number>(-1);
  const [loading] = useState(false);
  const [budgetInfo, setBudgetInfo] = useState<BudgetInfo>({
    currentTypeAndFrequency: TypeAndFrequencyOptions[0],
    filters: [],
    dimensionOptions: [],
    startPeriod: budget.data.config.startPeriod
      ? DateTime.fromMillis(budget.data.config.startPeriod.seconds * 1000).toUTC()
      : sanitizeDate(DateTime.utc()),
    endPeriod: budget.data.config.endPeriod
      ? DateTime.fromMillis(budget.data.config.endPeriod.seconds * 1000).toUTC()
      : sanitizeDate(DateTime.utc()),
    currency: budget.data.config.currency,
    budgetAmount: budget.data.config.amount || 1000,
    alerts: budget.data.config.alerts.map((a) => ({
      ...a,
      amount: getAlertAmountFromPercentage(a.percentage, budget.data.config.amount || 1000),
    })),
  });
  const [recipients, setRecipients] = useState<string[]>(cloneDeep(budget.data.recipients));
  const [recipientsSlackChannels, setRecipientsSlackChannels] = useState<SlackChannel[]>(
    cloneDeep(budget.data.recipientsSlackChannels) || []
  );

  const { fetchMetadata } = useCloudAnalyticsContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const isCurrentUserEditor = isEditor(currentUser.email, budget.data);

  const { customer: genuineCustomer } = useCustomerContext();
  const history = useHistory<CloudAnalyticsHistoryState>();
  const handleClose = useCallback(() => {
    history.push(history.location?.state?.prevPage ?? `/customers/${genuineCustomer.id}/analytics/budgets`);
  }, [history, genuineCustomer.id]);

  const stepComponents = [
    <Step1
      key="budget-scope"
      budget={budget}
      budgetInfo={budgetInfo}
      setBudgetInfo={setBudgetInfo}
      isCurrentUserEditor={isCurrentUserEditor}
    />,
    <Step2
      key="budget-configuration"
      isCurrentUserEditor={isCurrentUserEditor}
      setOverrideStep={setOverrideStep}
      dimensionOptions={[]}
      budgetInfo={budgetInfo}
      setBudgetInfo={setBudgetInfo}
      lastPeriodCost={budget.data.utilization?.lastPeriod || 0}
    />,
    <Step3
      key="budget-notification"
      budgetInfo={budgetInfo}
      budget={budget}
      setBudgetInfo={setBudgetInfo}
      isCurrentUserEditor={isCurrentUserEditor}
      shouldRefreshData={false}
      setRecipients={setRecipients}
      setRecipientsSlackChannels={setRecipientsSlackChannels}
      recipients={recipients}
      recipientsSlackChannels={recipientsSlackChannels}
    />,
  ];

  const steps: Step[] = [
    {
      children: stepComponents[0],
      label: budgetTxt.CREATE_BUDGET.STEP_1.TITLE,
      order: 0,
      required: true,
      state: stepperState[0],
    },
    {
      children: stepComponents[1],
      label: budgetTxt.CREATE_BUDGET.STEP_2.TITLE,
      order: 1,
      required: true,
      state: stepperState[1],
    },
    {
      children: stepComponents[2],
      label: budgetTxt.CREATE_BUDGET.STEP_3.TITLE,
      order: 2,
      required: true,
      state: stepperState[2],
    },
  ];

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  const onSubmit = async () => {};

  return (
    <Box sx={{ pt: 3, pb: 7 }}>
      <Stepper
        maxWidth={600}
        loading={loading}
        onCancel={handleClose}
        onSubmit={onSubmit}
        steps={steps}
        footerMaxWidth={800}
        contentSx={{
          px: 3,
          mb: 3,
          pt: 5,
        }}
        submitButtonLabel={budgetTxt.CREATE_BUDGET.SUBMIT_BTN}
        getCurrentStep={getCurrentStep}
        overrideStep={overrideStep}
        // disableSubmit={errors.length > 0}
      />
    </Box>
  );
};

export default CreateBudgetStepper;
