export const budgetTxt = {
  ALL_CHANGES_ARE_SAVED: "All changes are saved",
  BUDGET_NAME: "Budget name",
  SAVE_NEW: "Save new budget as",
  SUCCESSFULLY_SAVED: "Budget saved successfully",
  SUCCESSFULLY_CLONED: "Budget cloned successfully",
  DUPLICATE_SUCCESSFUL: "Budget duplicated successfully",
  FAILED_TO_CLONE: "Failed cloning budget",
  FAILED_TO_UPDATE: "Failed saving budget",
  SAVE_AS_LABEL: "Name",
  SAVE_AS_HELPER_TEXT: "This will create a new budget with you as the owner",
  NAME_BEFORE_SAVING: "Name before saving",
  BUDGET_IS_INVALID:
    "A budget must contain a name, a scope, an amount, an alert threshold and a collaborator (or a Slack channel)",
  CREATE_BUDGET: {
    SUBMIT_BTN: "Create budget",
    STEP_1: {
      TITLE: "Budget scope",
    },
    STEP_2: {
      TITLE: "Configuration",
    },
    STEP_3: {
      TITLE: "Notification thresholds",
      PERCENTAGE: "Percentage",
      BUDGET_AMOUNT: "Budget amount",
      NOTIFICATION_SETTINGS: "Notification settings",
      EMAIL_INPUT_LABEL: "Send email alerts to the following accounts when thresholds are met",
    },
    INFO_PANEL: {
      TYPE_AND_FREQUENCY: "Type and frequency",
      DIMENSIONS: "Dimensions",
      START_DATE: "Start date",
      END_DATE: "End date",
      EDIT_BUDGET_SCOPE: "Edit budget scope",
    },
    BUDGET_AMOUNT: "Budget amount",
    LAST_PERIOD_ACTUAL_COST: "Last period actual cost",
    BUDGET_CONFIGURATION: "Budget configuration",
    BUDGET_CONFIGURATION_SINGLE_PERIOD: "Allow the budget to track against a single period budgeted amount",
    BUDGET_CONFIGURATION_DYNAMIC: "Allow dynamic adjustments of your budgeted amount",
    BUDGET_CONFIGURATION_SPECIFY: "Specify your budgeted amount for each budget period",
    BUDGET_CONFIGURATION_DYNAMIC_LAST_PERIOD: "Budgeted amount is based on the last period amount",
    BUDGET_CONFIGURATION_DYNAMIC_PERCENTAGE_GROWTH: "Allow % growth in recurring period budgeted amounts",
    BUDGET_CONFIGURATION_DYNAMIC_NEXT_PERIOD_ROLLOVER: "Allow for excess to rollover to the next period",
    USE_AMORTIZED_COST: "Use amortized cost",
    BUDGET_PREVIEW: "Budget preview",
    GROWTH: "Growth %",
    ADVANCED_SETTINGS: "Advanced settings",
  },
};
