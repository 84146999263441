import { useEffect, useRef, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { lighten } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { DateTime } from "luxon";

import { DefinitionList, DefinitionListDesc, DefinitionListTerm } from "../../../../../Components/DefinitionList";
import { ThemeModes } from "../../../../../muiThemeTypes";
import { formatUSDollars } from "../../../../../utils/common";
import { type FirestoreTimestamp } from "../../../../../utils/firebase";
import mixpanel from "../../../../../utils/mixpanel";
import { useToggle } from "../../../../../utils/useToggle";
import { type CommitmentPeriod } from "../../../types";
import { commitmentPeriodToRampPlanCommitmentPeriods, timestampToFormat } from "../../../utils";
import EditPlannedSpendDialog from "../../EditPlannedSpendDialog";
import PeriodSpendBreakdown from "../../SpendBreakdown";
import { RampCellDialog } from "../CellDialog/RampCellDialog";
import ActualsRow from "./ActualsRow";
import { type UpdatePlanDataType } from "./PeriodList";
import PlannedRow from "./PlannedRow";

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: "fixed",
    width: "auto",
  },
  scrollBox: {
    overflowX: "auto",
    border: `1px solid ${theme.palette.general.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  headerCell: {
    fontWeight: 500,
    fontSize: 14,
    width: 118,
  },
  menuCell: {
    fontWeight: 500,
    width: 148,
    minWidth: 148,
    padding: 12,
    fontSize: 14,
    position: "sticky",
    left: 0,
    zIndex: 1,
    backgroundColor: theme.palette.general.backgroundDefault,
    color: theme.palette.text.primary,
  },
  rowCellInput: {
    padding: 0,
    fontSize: 14,
    minWidth: 92,
  },
  borderedCell: {
    borderBottom: `1px solid ${theme.palette.general.divider}`,
    borderCollapse: "collapse",
  },
  monthDataCell: {
    width: "12vw",
  },
  bgColor: {
    backgroundColor: theme.palette.action.focus,
  },
  arrowIcon: {
    margin: "-10px 0",
    padding: 0,
    float: "right",
  },
  dialogRoot: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor:
        theme.palette.mode === ThemeModes.LIGHT
          ? lighten(theme.palette.text.disabled, 0.45)
          : lighten(theme.palette.background.default, 0.25),
      opacity: 0.5,
    },
  },
  closeBtn: {
    float: "right",
    "& .MuiSvgIcon-root": {
      fontSize: 16,
    },
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 14,
    marginBottom: 10,
  },
  cellDialogFooter: {
    justifyContent: "flex-end",
  },
  footerCell: {
    border: 0,
    backgroundColor: theme.palette.general.backgroundDark,
  },
}));

type PeriodTableProps = {
  hasEditPermission: boolean;
  isLastTableOfPeriod: boolean;
  periodActualData: number[];
  periodMonthsArr: Array<Array<number>>;
  periodPlanData: number[];
  periodStart: FirestoreTimestamp;
  planStart: FirestoreTimestamp;
  tableEndPos: number;
  tableNum: number;
  tableStartPos: number;
  totalCommitment: number;
  updatePlanData: UpdatePlanDataType;
  periodNum: number;
  rollover: number;
  commitmentPeriod: CommitmentPeriod;

  commitmentPeriodsArray: CommitmentPeriod[];
  showEditPlannedSpendButton: boolean;
  showRollover: boolean;
};

export const PeriodTable = (props: PeriodTableProps) => {
  const {
    hasEditPermission,
    isLastTableOfPeriod,
    periodActualData,
    periodMonthsArr,
    periodPlanData,
    periodStart,
    planStart,
    tableNum,
    tableEndPos,
    tableStartPos,
    updatePlanData,
    periodNum,
    rollover,
    commitmentPeriod,

    commitmentPeriodsArray,
    showEditPlannedSpendButton,
    showRollover,
  } = props;

  const classes = useStyles();
  const [totalPlanned, setTotalPlanned] = useState<number>(periodPlanData.reduce((sum, x) => sum + x, 0));
  const [totalActuals, setTotalActuals] = useState<number>(0);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isBreakdownDialogOpen, openBreakdownDialog, closeBreakdownDialog] = useToggle(false);
  const [isEditSpendDialogOpen, openEditSpendDialog, closeEditSpendDialog] = useToggle(false);
  const [cellGrowthPerMonth, setCellGrowthPerMonth] = useState<number | null>(null);
  const [cellBaselineCheckbox, setCellBaselineCheckbox] = useState<boolean>(false);
  const [inputError, setInputError] = useState<null | "DIGITS" | "RANGE">(null);
  const inputEl = useRef<HTMLTableCellElement | null>(null);
  const [editPlannedSpendButtonVisible, setEditPlannedSpendButtonVisible] = useState<boolean>(true);

  useEffect(() => {
    periodActualData && setTotalActuals(periodActualData.reduce((sum, x) => sum + x, 0));
  }, [periodActualData]);

  useEffect(() => {
    if (periodPlanData.length) {
      setTotalPlanned(periodPlanData.reduce((sum, x) => sum + x, 0));
    }
  }, [periodPlanData]);

  useEffect(() => {
    if (showEditPlannedSpendButton) {
      setEditPlannedSpendButtonVisible(true);
    }
  }, [showEditPlannedSpendButton]);

  const onOpenCellDialog = () => {
    setIsDialogOpen(true);
  };
  const updatePlannedByMonthlyGrowth = () => {
    if (
      (inputEl.current !== null && typeof inputEl.current !== "undefined" && cellGrowthPerMonth !== null) ||
      (cellBaselineCheckbox && inputError === null)
    ) {
      const inputIndex = inputEl.current?.getAttribute("index");
      if (typeof inputIndex !== "undefined" && inputIndex !== null) {
        const i: number = parseInt(inputIndex);
        updatePlanData(periodNum - 1, cellGrowthPerMonth, i, cellBaselineCheckbox, true);
      }
    }
    setCellGrowthPerMonth(null);
    setInputError(null);
    setCellBaselineCheckbox(false);
  };

  const closeCellDialog = () => {
    setIsDialogOpen(false);
    updatePlannedByMonthlyGrowth();
  };

  const closeWithoutSaving = () => {
    setIsDialogOpen(false);
    setCellGrowthPerMonth(null);
    setInputError(null);
    setCellBaselineCheckbox(false);
  };

  const onGrowthPerMonthChange = (percent: number) => {
    if (percent) {
      setInputError(null);
      !isNaN(percent) && percent <= 100 && setCellGrowthPerMonth(percent);
      isNaN(percent) && setInputError("DIGITS");
      (percent < 0 || percent > 100) && setInputError("RANGE");
    } else {
      setCellGrowthPerMonth(null);
    }
  };

  const tableDateHeaders = periodMonthsArr[periodNum - 1]
    .slice(tableStartPos, tableEndPos)
    .map((_item, index: number) => {
      const monthNum = index + tableStartPos;
      const colHeader = DateTime.fromSeconds(periodStart?.seconds)
        .toUTC()
        .plus({ months: monthNum })
        .toFormat("MMM yy");
      return (
        <TableCell
          padding="none"
          size="small"
          key={index + 1}
          className={clsx(classes.borderedCell, classes.headerCell)}
          data-testid={`table-headers-index-${index}`}
        >
          {colHeader}
        </TableCell>
      );
    });

  const fillerLengthBase = periodPlanData.length - tableStartPos === 13 ? 13 : 12;
  const tableDateHeadersFiller = new Array(fillerLengthBase - tableDateHeaders.length).fill(
    <TableCell padding="none" size="small" className={clsx(classes.borderedCell, classes.headerCell)} />
  );
  tableDateHeaders.push(...tableDateHeadersFiller);

  const formatPeriodStart = timestampToFormat(periodStart, "MMM yy");
  const formatPeriodEnd = DateTime.fromSeconds(periodStart.seconds)
    .toUTC()
    .plus({ months: periodPlanData.length - 1 })
    .toFormat("MMM yy");
  const fullDateLabel = `${formatPeriodStart} - ${formatPeriodEnd}`;

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontWeight={500}>
          Period {periodNum} {tableNum === 1 ? `(${fullDateLabel})` : " - Continued"}
        </Typography>
        <Box>
          {commitmentPeriod.endDate &&
            tableStartPos === 0 &&
            (commitmentPeriod.actualsBreakdown || commitmentPeriod.periodActualsBreakdown) && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    mixpanel.track("customers.ramps.ramp-plan-view.view-total-spend-breakdown");
                    openBreakdownDialog();
                  }}
                >
                  View total spend breakdown
                </Button>
                <PeriodSpendBreakdown
                  open={isBreakdownDialogOpen}
                  onClose={closeBreakdownDialog}
                  actualBreakdowns={commitmentPeriod.actualsBreakdown ?? []}
                  periodBreakdown={commitmentPeriod.periodActualsBreakdown ?? {}}
                  startDate={commitmentPeriod.startDate}
                  endDate={commitmentPeriod.endDate}
                />
              </>
            )}
          {editPlannedSpendButtonVisible && tableStartPos === 0 && (
            <>
              <Button
                onClick={() => {
                  mixpanel.track("customers.ramps.ramp-plan-view.edit-planned-spend");
                  openEditSpendDialog();
                }}
                sx={{ ml: 2 }}
              >
                <EditIcon sx={{ mr: 1 }} />
                Edit planned spend
              </Button>
              <EditPlannedSpendDialog
                open={isEditSpendDialogOpen}
                onClose={closeEditSpendDialog}
                planStart={DateTime.fromSeconds(periodStart.seconds).toUTC()}
                planDateLabel={fullDateLabel}
                origCommitmentPeriods={commitmentPeriodsArray.map((v) =>
                  commitmentPeriodToRampPlanCommitmentPeriods(v)
                )}
                commitmentPeriodIndex={periodNum - 1}
              />
            </>
          )}
        </Box>
      </Stack>
      <Box className={clsx(classes.scrollBox)}>
        <Table className={classes.table} data-testid="periodTable">
          <TableHead>
            <TableRow>
              <TableCell padding="none" size="small" className={clsx(classes.borderedCell, classes.menuCell)}>
                Month
              </TableCell>
              {tableDateHeaders}
            </TableRow>
          </TableHead>
          <TableBody>
            <PlannedRow
              classes={classes}
              hasEditPermission={hasEditPermission}
              inputEl={inputEl}
              onOpenCellDialog={onOpenCellDialog}
              periodNum={periodNum}
              periodPlanData={periodPlanData}
              periodStart={periodStart}
              tableEndPos={tableEndPos}
              tableStartPos={tableStartPos}
              updatePlanData={updatePlanData}
              periodMonthsArr={periodMonthsArr}
              fillerLength={fillerLengthBase}
            />
            <ActualsRow
              planStart={planStart}
              periodActualData={periodActualData}
              periodMonthsArr={periodMonthsArr}
              tableStartPos={tableStartPos}
              periodStart={periodStart}
              periodNum={periodNum}
              tableEndPos={tableEndPos}
              classes={classes}
              fillerLength={fillerLengthBase}
            />
          </TableBody>
          {isLastTableOfPeriod && (
            <TableFooter>
              <TableRow>
                <TableCell
                  padding="none"
                  size="small"
                  className={clsx(classes.borderedCell, classes.menuCell, classes.footerCell)}
                >
                  Totals
                </TableCell>
                <TableCell
                  padding="none"
                  size="small"
                  colSpan={fillerLengthBase}
                  className={clsx(classes.borderedCell, classes.footerCell)}
                >
                  <DefinitionList variant="horizontal">
                    <DefinitionListTerm>Planned spend:</DefinitionListTerm>
                    <DefinitionListDesc>{formatUSDollars(totalPlanned)}</DefinitionListDesc>
                    <DefinitionListTerm>Total spend:</DefinitionListTerm>
                    <DefinitionListDesc>{formatUSDollars(totalActuals)}</DefinitionListDesc>
                    {showRollover && (
                      <>
                        <DefinitionListTerm>New rollover:</DefinitionListTerm>
                        <DefinitionListDesc>{formatUSDollars(rollover)}</DefinitionListDesc>
                      </>
                    )}
                  </DefinitionList>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Box>
      {hasEditPermission && (
        <RampCellDialog
          isDialogOpen={isDialogOpen}
          closeWithoutSaving={closeWithoutSaving}
          onClose={closeCellDialog}
          classes={classes}
          onCheckboxClick={(e) => {
            setCellBaselineCheckbox(e.target.checked);
            setCellGrowthPerMonth(null);
          }}
          onGrowthPerMonthChange={onGrowthPerMonthChange}
          inputError={inputError}
        />
      )}
    </Stack>
  );
};
