import React from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { WidgetContentWrapper } from "./WidgetContentWrapper";

function always() {
  return true;
}

export function SortableWidget({ id, width, height, activeIndex, ...props }: any) {
  const { attributes, listeners, index, isDragging, isSorting, over, setNodeRef, transform, transition } = useSortable({
    id,
    animateLayoutChanges: always,
  });

  return (
    <WidgetContentWrapper
      ref={setNodeRef}
      id={id}
      active={isDragging}
      style={{
        width,
        height,
        transition,
        transform: isSorting ? undefined : CSS.Translate.toString(transform),
      }}
      insertPosition={over?.id === id ? (index > activeIndex ? "after" : "before") : undefined}
      {...props}
      {...attributes}
      listeners={listeners}
    />
  );
}
