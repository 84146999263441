import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import debounce from "lodash/debounce";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import CopyIcon from "@mui/icons-material/FileCopyRounded";
import BarChartIcon from "@mui/icons-material/BarChart";
import TableIcon from "@mui/icons-material/TableChart";
import { Aggregator, AnalyticsDataSource, CurrencyCodes, Metadata, Metric, Renderer, Sort } from "@doitintl/cmp-models";
import ShareIcon from "@mui/icons-material/ShareRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import SettingsIcon from "@mui/icons-material/SettingsRounded";
import isEqual from "lodash/isEqual";

import { useCustomerContext } from "../../../Context/CustomerContext";

import mixpanel from "../../../utils/mixpanel";
import useUpdateEffect from "../../../Components/hooks/useUpdateEffect";
import useFormatter from "../../../Components/hooks/useFormatter";
import { useBackUrl } from "../../../Components/hooks/useBackUrl";

import { attributionText, globalText } from "../../../assets/texts";

import { handleResponseError } from "../../../api/axiosClient";
import Title from "../../../Components/Title/Title";
import ChartsRenderer from "../renderers/ChartsRenderer";
import { getReportRowSort, isEditor, isOwner, isSharedWith } from "../utilities";
import TableRenderer from "../renderers/TableRenderer";
import { useAnalyticsContext } from "../CloudAnalyticsContext";
import { useAuthContext } from "../../../Context/AuthContext";
import { useFullScreen } from "../../../utils/dialog";
import { consoleErrorWithSentry } from "../../../utils";
import SaveDialog from "../../../Components/SaveComponents/SaveDialog";
import DeleteDialog from "../../../Components/DeleteDialog";
import { useUnsavedChanges } from "../../../Context/UnsavedChangesContext";
import { addAnalyticsWindowFunction } from "../../../utils/windowInit";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import SaveAsComponent from "../../../Components/SaveComponents/SaveAsComponent";
import SaveComponent from "../../../Components/SaveComponents/SaveComponent";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAnalyticsDimensions } from "../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions";
import { attributionTxt } from "../../../assets/texts/CloudAnalytics";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { useApiContext } from "../../../api/context";
import AttributionOwnerBanner from "./AttributionOwnerBanner";
import AttributionShareDialog from "./AttributionShareDialog";
import { AttributionSettingsModal } from "./AttributionSettingsModal";
import { useDeleteAttribution } from "./DeleteAttributionValidation";
import { isValidFiltersAndFormula } from "./utils";
import useAttributionCreateFormula from "./AttributionCreate/useAttributionCreateFormula";
import useAttributionCreateSelector from "./AttributionCreate/useAttributionCreateSelector";
import { createAttribution, updateAttribution } from "./db";
import { DoitConsoleTitle } from "../../../Components/DoitConsoleTitle";
import { useGotoParentUrl } from "../../../Components/hooks/useGotoParentUrl";

const ATTRIBUTION_EVENT_SOURCE = "attributions";

const ATTRIBUTIONS_LIST_UPDATE_DELAY = 500;

const LOGIC_PART_WIDTH = 600;
const NOT_ALLOWED_CHARS_REGEX = /[^a-zA-Z0-9-_.,:()[\]%&\s]/g;
const styles = {
  cardHeader: {
    flexWrap: { xs: "wrap", sm: "nowrap" },
    "& .MuiCardHeader-content": {
      minWidth: 0,
    },
    "& .MuiCardHeader-action": {
      width: { xs: "100%", sm: "unset" },
      mt: { xs: 1, sm: "auto" },
      mb: { sm: "auto" },
    },
  },
  cardHeaderAction: {
    mr: { xs: 0.5, sm: 1.0, lg: 3.0 },
  },
};

export const Attribution = ({ attribution = { data: {} }, metadataSnapshots, excludeSelectMetadataIds, currency }) => {
  const isEntitledAttributions = useIsFeatureEntitled("analytics:attributions");
  const api = useApiContext();
  const attributionId = attribution.ref?.id;
  const { handleDeleteValidationResponse, hasDataHub } = useAnalyticsContext();

  const { isMobile: smDown } = useFullScreen();
  const { customer } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const [saved, setSaved] = useState(false);
  const [queryRunning, setQueryRunning] = useState(false);
  const [reportError, setReportError] = useState(null);
  const [renderer, setRenderer] = useState(attribution.data.config?.renderer ?? Renderer.STACKED_COLUMN_CHART);
  const [isInit, setIsInit] = useState(false);
  const [bannerHidden, setBannerHidden] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [saveAsModalOpen, setSaveAsModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveButtonState, setSaveButtonState] = useState({
    tooltip: !attributionId ? attributionTxt.ADD_CONDITION : attributionText.CHANGES_SAVED,
    disabled: true,
  });
  const [openNameDialog, setOpenNameDialog] = useState(false);
  const [name, setName] = useState(attribution.data.name);
  const [description, setDescription] = useState(attribution.data.description);
  const [rowOrder, setRowOrder] = useState(getReportRowSort(renderer));
  const reportData = useRef(null);
  const contentRef = useRef(null);
  const isSharedWithUser = !!currentUser && isSharedWith(currentUser.email, attribution.data);
  const isUserEditor = (!!currentUser && isEditor(currentUser.email, attribution.data)) || !attributionId;
  const { collaborators } = attribution.data;
  const { activatePendingPrompt, clearPendingPrompt } = useUnsavedChanges();
  const attributionsBrowserUrl = useBackUrl();
  const { dimensions, handleDimensionLabelsChange } = useAnalyticsDimensions({
    metadataSnapshots,
    defaultDataSource: hasDataHub ? AnalyticsDataSource.BILLING_DATAHUB : AnalyticsDataSource.BILLING,
  });

  const filteredDimensions = useMemo(() => {
    const filtered = dimensions?.slice();
    filtered?.forEach((d) => {
      if (d.data.type === Metadata.DATETIME) {
        d._visible = false;
      } else if (d.data.type === Metadata.ATTRIBUTION) {
        const index = filtered.indexOf(d);
        if (index !== -1) {
          filtered.splice(index, 1);
        }
      }
    });
    return filtered;
  }, [dimensions]);

  const { AttributionCreateSelector, filters } = useAttributionCreateSelector({
    initialFilters: attribution?.data?.filters,
    dimensions: filteredDimensions,
    handleDimensionLabelsChange,
    excludeSelectMetadataIds,
  });

  const { AttributionCreateFormula, formulaState, attributionQueryRun } = useAttributionCreateFormula({
    formula: attribution.data.formula,
    filters,
  });

  const userIsOwner = useMemo(
    () => isOwner(currentUser.email, attribution.data),
    [currentUser.email, attribution.data]
  );

  const hasValidFiltersAndFormula = useMemo(
    () => isValidFiltersAndFormula(filters, formulaState.valid),
    [filters, formulaState.valid]
  );

  const isValidAttribution = useMemo(() => !!(name && hasValidFiltersAndFormula), [name, hasValidFiltersAndFormula]);

  const saveAsMode = !isUserEditor;
  const attributionCurrency = useMemo(() => currency ?? CurrencyCodes.USD, [currency]);

  const formatter = useFormatter(Aggregator.TOTAL, attributionCurrency, Metric.COST);

  const routeMatchURL = useRouteMatchURL();
  const history = useHistory();

  const handleRun = useCallback(async () => {
    setQueryRunning(true);
    setReportError(null);
    try {
      reportData.current = await attributionQueryRun(name, currency, filteredDimensions, attributionId, rowOrder);
    } catch (error) {
      handleResponseError(
        error,
        (errorCode) => {
          const reportErr = {
            code: errorCode,
          };
          reportData.current = null;
          setReportError(reportErr);
        },
        [404, 413]
      );
    }
    setQueryRunning(false);
  }, [attributionId, attributionQueryRun, currency, filteredDimensions, name, rowOrder]);

  const handleRunDebounced = useMemo(() => debounce(handleRun, 5000, { leading: true }), [handleRun]);

  // change of title or description
  const handleChange = (values) => {
    if (values.title !== undefined) {
      setName(values.title);
    } else if (values.description !== undefined) {
      setDescription(values.description);
    }
  };

  const createNewAttribution = useCallback(
    async (fields) => {
      const newAttribution = await createAttribution(api, customer.id, fields, undefined, {
        from: ATTRIBUTION_EVENT_SOURCE,
      });
      setTimeout(() => {
        history.push(routeMatchURL.replace("create", newAttribution.data.id));
      }, ATTRIBUTIONS_LIST_UPDATE_DELAY);
    },
    [api, customer, history, routeMatchURL]
  );

  const saveChanges = useCallback(
    async (fields) => {
      await updateAttribution(api, customer.id, attributionId, fields, { from: ATTRIBUTION_EVENT_SOURCE });
    },
    [api, customer.id, attributionId]
  );

  // Set as initialized once metadata is ready
  useEffect(() => {
    if (!isInit && filteredDimensions !== null) {
      mixpanel.track("analytics.attributions.open", { attributionId });
      addAnalyticsWindowFunction({ setTitle: setName });
      setIsInit(true);
    }
  }, [isInit, filteredDimensions, attributionId]);

  // Run report if initialized and has a saved config
  useEffect(() => {
    if (isInit) {
      if (isValidAttribution) {
        handleRunDebounced();
      }
    }
  }, [isInit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasUnsavedChanges && !isDeleting && !isGenerating) {
      activatePendingPrompt({
        exceptionsPath: [new RegExp(`^${routeMatchURL.replace("create", "")}(\\w+)$`)],
      });
    } else {
      clearPendingPrompt();
    }
  }, [activatePendingPrompt, clearPendingPrompt, hasUnsavedChanges, isDeleting, isGenerating, routeMatchURL]);

  const isFormulaIdentical = useMemo(
    () =>
      (attribution.data.formula || "").replace(/\s+/g, " ").trim() ===
      (formulaState.value || "").replace(/\s+/g, " ").trim(),
    [attribution.data.formula, formulaState.value]
  );
  const isFiltersIdentical = useMemo(
    () => isEqual(attribution?.data?.filters, filters),
    [attribution.data.filters, filters]
  );

  useUpdateEffect(() => {
    setHasUnsavedChanges(
      name !== attribution.data.name ||
        description !== attribution.data.description ||
        !isFormulaIdentical ||
        !isFiltersIdentical
    );
  }, [attribution.data.description, attribution.data.name, description, isFiltersIdentical, isFormulaIdentical, name]);

  useUpdateEffect(() => {
    if (!hasUnsavedChanges || saveAsMode) {
      return;
    }
    if (hasValidFiltersAndFormula) {
      setSaveButtonState({ disabled: false, tooltip: "" });
    } else if (!filters.length) {
      setSaveButtonState({ disabled: true, tooltip: attributionTxt.ADD_CONDITION });
    } else if (!formulaState.valid) {
      setSaveButtonState({ disabled: true, tooltip: attributionText.INVALID_FORMULA });
    } else {
      setSaveButtonState({ disabled: true, tooltip: "" });
    }
  }, [isValidAttribution, hasUnsavedChanges, saveAsMode, name, filters.length, formulaState.valid]);

  useUpdateEffect(() => {
    const formulaHasTrailingSpaces = formulaState.value.length !== formulaState.value.trim().length;

    if (formulaHasTrailingSpaces) {
      return;
    }

    if (formulaState.shouldRun && hasValidFiltersAndFormula) {
      handleRunDebounced();
    }
  }, [formulaState.shouldRun, hasValidFiltersAndFormula, handleRunDebounced, formulaState.value]);

  useUpdateEffect(() => {
    setSaveButtonState({ disabled: true, tooltip: attributionText.CHANGES_SAVED });
    setHasUnsavedChanges(false);
    setIsGenerating(false);
  }, [history.location.pathname, saved]);

  const handleSave = useCallback(
    async (newName) => {
      if (!newName) {
        if (!name) {
          setOpenNameDialog(true);
        }

        if (!isValidAttribution) {
          return;
        }
      }

      const fields = {
        filters,
        formula: formulaState.value,
        draft: false,
        name: (newName || name)?.trim(),
        description,
      };
      try {
        if (!attributionId) {
          await createNewAttribution(fields);
          successSnackbar(attributionText.SUCCESSFULLY_CREATED);
        } else {
          await saveChanges(fields);
          successSnackbar(attributionText.SUCCESSFULLY_UPDATED);
        }

        if ((attribution.data.formula ?? "") !== formulaState.value) {
          mixpanel.track("analytics.attributions.formula-edit", { formula: attribution.data.formula });
        }

        setSaveButtonState({ disabled: true, tooltip: attributionText.CHANGES_SAVED });
        setSaved(true);
        setHasUnsavedChanges(false);
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar(attributionTxt.FAILED_TO_SAVE);
      }
    },
    [
      attribution.data.formula,
      attributionId,
      createNewAttribution,
      description,
      errorSnackbar,
      filters,
      formulaState.value,
      isValidAttribution,
      name,
      saveChanges,
      successSnackbar,
    ]
  );

  const handleGenerateAttribution = useCallback(
    async (name, copy) => {
      setIsGenerating(true);
      const newData = {
        name: copy ? `Copy of ${name}` : name,
        description,
        filters,
        formula: formulaState.value,
      };

      const eventType = copy ? "duplicate" : "save-as";

      const newAttribution = await createAttribution(api, customer.id, newData, undefined, {
        type: eventType,
        from: ATTRIBUTION_EVENT_SOURCE,
      });
      if (newAttribution.data?.id) {
        setName(copy ? `${attributionText.COPY_OF} ${name}` : name);
        setTimeout(() => {
          history.push(`${routeMatchURL.replace(attributionId, newAttribution.data.id)}`);
        }, ATTRIBUTIONS_LIST_UPDATE_DELAY);
      } else {
        setIsGenerating(false);
      }
    },
    [
      setIsGenerating,
      api,
      customer.id,
      setName,
      history,
      routeMatchURL,
      attributionId,
      description,
      filters,
      formulaState.value,
    ]
  );

  const handleSaveAs = useCallback(
    async (name) => {
      if (!name) {
        return;
      }
      await handleGenerateAttribution(name, false);
      setSaveAsModalOpen(false);
      setHasUnsavedChanges(false);
    },
    [handleGenerateAttribution]
  );

  const handleCopyAttribution = useCallback(async () => {
    await handleGenerateAttribution(name, true);
    successSnackbar(attributionText.SUCCESSFULLY_CREATED);
  }, [name, handleGenerateAttribution, successSnackbar]);

  const deleteAttribution = useDeleteAttribution();

  const handleDeleteAttribution = useCallback(async () => {
    setIsDeleting(true);
    const ids = [attributionId];

    if (
      !isOwner(currentUser.email, {
        collaborators,
      })
    ) {
      errorSnackbar(attributionText.CANNOT_DELETE);
      return;
    }

    await deleteAttribution({
      ids,
      onResponsePopulated: (data) => handleDeleteValidationResponse(data),
      onResponseEmpty: () => {
        history.push(attributionsBrowserUrl);
        successSnackbar(attributionText.DELETE_ATTR_SUCCESS);
      },
      onError: () => {
        errorSnackbar(attributionText.DELETE_ATTR_ERROR);
      },
    });
    setIsDeleting(false);
  }, [
    attributionId,
    attributionsBrowserUrl,
    collaborators,
    currentUser.email,
    deleteAttribution,
    errorSnackbar,
    handleDeleteValidationResponse,
    history,
    successSnackbar,
  ]);

  const handleOpenDeleteDialog = useCallback(() => setDeleteDialogOpen(true), []);
  const handleCloseDeleteDialog = useCallback(() => setDeleteDialogOpen(false), []);

  const handleRendererChange = useCallback((e, newRenderer) => {
    if (!newRenderer) {
      return;
    }
    const newSort = getReportRowSort(newRenderer);
    reportData.current.setSort(newSort, Sort.A_TO_Z);
    setRenderer(newRenderer);
    setRowOrder(newSort);
  }, []);

  const parentUrl = useMemo(() => `/customers/${customer.id}/analytics/attributions`, [customer.id]);
  const handleBack = useGotoParentUrl(parentUrl);

  const plot = useMemo(() => {
    const previewHeight = smDown ? "300px" : "500px";
    if (queryRunning) {
      return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 2 }}>
          <Typography variant="h6" sx={{ position: "absolute" }} color="textSecondary">
            Fetching Preview...
          </Typography>
          <Skeleton animation="pulse" variant="rectangular" height={previewHeight} width="100%" />
        </Box>
      );
    }
    if (reportError) {
      switch (reportError?.code) {
        case "result_empty":
          return (
            <CardContent>
              <Alert variant="standard" severity="error" sx={{ width: { md: LOGIC_PART_WIDTH } }}>
                <AlertTitle>Error</AlertTitle>
                This attribution didn't produce any meaningful results, please try changing some of the conditions
              </Alert>
            </CardContent>
          );
        default:
          return (
            <CardContent>
              <Alert variant="standard" severity="error" sx={{ width: { md: LOGIC_PART_WIDTH } }}>
                <AlertTitle>Error</AlertTitle>
                We could not preview your attribution results due to a problem with our infrastructure. We are sorry for
                the inconvenience!
              </Alert>
            </CardContent>
          );
      }
    }
    if (!reportData.current) {
      return null;
    }

    return (
      <>
        <CardContent sx={{ pt: 6, pb: 1 }}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h4">{attributionText.PREVIEW}</Typography>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end">
              <ToggleButtonGroup size="small" value={renderer} exclusive onChange={handleRendererChange}>
                <ToggleButton value={Renderer.STACKED_COLUMN_CHART} sx={{ color: "action.disabled" }}>
                  <BarChartIcon />
                </ToggleButton>
                <ToggleButton value={Renderer.TABLE} sx={{ color: "action.disabled" }}>
                  <TableIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </CardContent>
        {renderer === Renderer.TABLE ? (
          <CardContent sx={{ pt: 0, pb: 1 }}>
            <TableRenderer data={reportData.current} formatter={formatter} smDown={smDown} />
          </CardContent>
        ) : (
          <CardContent sx={{ py: 0, px: 1 }}>
            <ChartsRenderer
              title="attribution"
              data={reportData.current}
              type={renderer}
              height={previewHeight}
              formatter={formatter}
            />
          </CardContent>
        )}
        <div />
      </>
    );
  }, [queryRunning, reportError, formatter, renderer, smDown, handleRendererChange]);

  const titleTransformation = (title) => title.replace(NOT_ALLOWED_CHARS_REGEX, "");
  const closeShareDialog = useCallback(() => {
    setShareDialogOpen(false);
  }, [setShareDialogOpen]);

  if (!filteredDimensions?.length) {
    return null;
  }

  return (
    <>
      <DoitConsoleTitle pageName="Attributions" pageLevel1={name || attributionText.NEW_ATTRIBUTION} />
      <Card sx={{ border: "none", mb: 1 }}>
        <CardHeader
          sx={styles.cardHeader}
          disableTypography={true}
          title={
            <Title
              values={{
                title: name,
                description,
              }}
              onChange={handleChange}
              handleBack={handleBack}
              placeholder={attributionText.UNTITLED_ATTRIBUTION}
              disabled={saveAsMode}
              titleTransformation={titleTransformation}
            />
          }
          action={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {userIsOwner && attributionId && (
                <Tooltip title="Share Attribution" sx={styles.cardHeaderAction}>
                  <IconButton size="small" onClick={() => setShareDialogOpen(true)}>
                    <ShareIcon />
                  </IconButton>
                </Tooltip>
              )}
              {attributionId && isEntitledAttributions && (
                <Tooltip title="Clone Attribution" sx={styles.cardHeaderAction}>
                  <Box>
                    <IconButton
                      data-testid="attributionClone"
                      onClick={handleCopyAttribution}
                      size="small"
                      disabled={!hasValidFiltersAndFormula}
                    >
                      <CopyIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              )}
              {userIsOwner && attributionId && (
                <Tooltip title="Settings" sx={styles.cardHeaderAction}>
                  <IconButton size="small" onClick={() => setSettingsModalOpen(true)}>
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              )}
              {attributionId && (
                <Tooltip title="Delete Attribution" sx={styles.cardHeaderAction}>
                  <Box>
                    <IconButton size="small" onClick={handleOpenDeleteDialog} disabled={!userIsOwner}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              )}
              <Button variant="text" color="primary" onClick={handleBack} sx={styles.cardHeaderAction}>
                {globalText.CLOSE}
              </Button>
              {saveAsMode ? (
                <SaveAsComponent
                  disabled={!isValidAttribution || !isEntitledAttributions}
                  onSaveAs={handleSaveAs}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  saveAsTitle={attributionText.SAVE_AS_TITLE}
                  textFieldProps={{
                    helperText: attributionText.SAVE_AS_HELPER_TEXT,
                  }}
                  successMessage={attributionText.SUCCESSFULLY_CREATED}
                />
              ) : (
                <SaveComponent
                  disabled={saveButtonState.disabled || !isEntitledAttributions}
                  tooltip={saveButtonState.tooltip}
                  onSave={handleSave}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              )}
            </Box>
          }
        />
        <CardContent sx={{ "&:last-child": { pb: 0 }, pt: 0 }}>
          <Grid container spacing={1}>
            {!isSharedWithUser && attributionId && (
              <Collapse in={!bannerHidden}>
                <AttributionOwnerBanner setHidden={setBannerHidden} sx={{ p: 1 }} />
              </Collapse>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ border: "none" }}>
        <CardContent ref={contentRef} sx={{ pb: 0 }}>
          {AttributionCreateSelector}
          {AttributionCreateFormula}
        </CardContent>
        {plot}
      </Card>
      {shareDialogOpen && (
        <AttributionShareDialog
          title={attributionText.SHARE_ATTRIBUTION}
          shareDialogOpen={shareDialogOpen}
          closeDialog={closeShareDialog}
          attributions={[
            {
              collaborators,
              public: attribution.data.public,
              id: attributionId,
              name: attribution.data.name,
            },
          ]}
        />
      )}
      {settingsModalOpen && (
        <AttributionSettingsModal onClose={() => setSettingsModalOpen(false)} attributionId={attributionId} />
      )}
      <SaveDialog
        title={attributionText.SAVE_AS_TITLE}
        textFieldProps={{
          helperText: attributionText.SAVE_AS_HELPER_TEXT,
        }}
        open={saveAsModalOpen}
        onClose={() => setSaveAsModalOpen(false)}
        onSave={(name) => handleSaveAs(name)}
      />
      {deleteDialogOpen && (
        <DeleteDialog
          open={deleteDialogOpen}
          title={attributionText.DELETE_SELECTED}
          message={attributionText.DELETE_MESSAGE}
          onDelete={handleDeleteAttribution}
          onClose={handleCloseDeleteDialog}
        />
      )}
      {openNameDialog && (
        <SaveDialog
          title={attributionTxt.NAME_BEFORE_SAVING}
          open={openNameDialog}
          onClose={() => setOpenNameDialog(false)}
          onSave={async (title) => {
            handleChange({ title });
            await handleSave(title);
            setOpenNameDialog(false);
          }}
          textFieldProps={{ label: attributionText.ATTRIBUTION_NAME }}
        />
      )}
    </>
  );
};

export default Attribution;
