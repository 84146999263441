import { createContext, type ReactNode, useContext, useEffect, useMemo, useState } from "react";

import { type CloudFlowNodeType } from "@doitintl/cmp-models";

import { type ConfigurationTab, type NodeConfigs } from "../../types";
import { useTabs } from "./Tabs/hooks";

type NodeConfigurationContextType<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> = {
  activeTab: ConfigurationTab;
  setActiveTab: (tab: ConfigurationTab) => void;
  nodeConfig: NodeConfigs<TNodeType>;
  onChangeTriggerType: () => void;
  updateNode: (nodeConfig: any) => void;
};

type NodeConfigurationProviderType<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> = {
  nodeConfig: NodeConfigs<TNodeType>;
  updateNode: (nodeConfig: any) => void;
  children: ReactNode;
  onChangeTriggerType: (triggerType: CloudFlowNodeType) => void;
};

const NodeConfigurationContext = createContext<NodeConfigurationContextType<any>>({
  activeTab: "Parameters",
  setActiveTab: () => {},
  nodeConfig: {} as unknown as NodeConfigs<any>,
  onChangeTriggerType: () => {},
  updateNode: () => {},
});

export const NodeConfigurationProvider = <TNodeType extends CloudFlowNodeType = CloudFlowNodeType>({
  nodeConfig,
  updateNode,
  children,
}: NodeConfigurationProviderType<TNodeType>) => {
  const [activeTab, setActiveTab] = useState<ConfigurationTab>("Parameters");
  const tabs = useTabs(nodeConfig);

  useEffect(() => {
    setActiveTab(tabs[0] || "Parameters");
  }, [tabs]);

  const value = useMemo(
    () => ({
      activeTab,
      setActiveTab,
      nodeConfig,
      updateNode,
      onChangeTriggerType: () => {},
    }),
    [activeTab, nodeConfig, updateNode]
  );

  return <NodeConfigurationContext.Provider value={value}>{children}</NodeConfigurationContext.Provider>;
};

export const useNodeConfigurationContext = <TNodeType extends CloudFlowNodeType = CloudFlowNodeType>() =>
  useContext<NodeConfigurationContextType<TNodeType>>(NodeConfigurationContext);
