import {
  APP_KEY,
  CloudFlowNodeType,
  CustomFrequencies,
  Frequencies,
  NODE_STATUS,
  type NodeConfigScheduleTriggerParameters,
  type NodeModel,
} from "@doitintl/cmp-models";
import { type Node } from "@xyflow/react";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";

import { type RFNode } from "../../types";

const position = { x: 0, y: 0 };

// TODO: Implement the getNode function correctly as per the node types
export const initializeNode: (nodeType: CloudFlowNodeType, nodeId: string) => Node<RFNode> = (
  nodeType: CloudFlowNodeType,
  nodeId: string
) => {
  switch (nodeType) {
    case CloudFlowNodeType.TRIGGER:
      return {
        id: nodeId,
        type: CloudFlowNodeType.TRIGGER,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Schedule",
            status: NODE_STATUS.VALIDATED,
            display: {
              position,
            },
            type: CloudFlowNodeType.TRIGGER,
            parameters: {
              startDate: DateTime.now().toUTC().toISO(),
              timeZone: DateTime.local().zoneName,
              time: DateTime.local().startOf("hour").plus({ hours: 1 }).toUTC().toISO(),
              frequency: Frequencies.Daily,
              customFrequency: CustomFrequencies.Month,
              customFrequencyAmount: 1,
            } as unknown as NodeConfigScheduleTriggerParameters,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.MANUAL_TRIGGER:
      return {
        id: nodeId,
        type: CloudFlowNodeType.MANUAL_TRIGGER,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Manually Start",
            status: NODE_STATUS.VALIDATED,
            display: {
              position,
            },
            type: CloudFlowNodeType.MANUAL_TRIGGER,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.FILTER:
      return {
        id: nodeId,
        type: CloudFlowNodeType.FILTER,
        position: position ?? { x: 0, y: 300 },
        data: {
          touched: false,
          nodeData: {
            name: "Filter",
            status: NODE_STATUS.VALIDATED,
            display: {
              position,
            },
            type: CloudFlowNodeType.FILTER,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.ACTION:
      return {
        id: nodeId,
        type: CloudFlowNodeType.ACTION,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Perform action",
            status: NODE_STATUS.ERROR,
            display: {
              position,
            },
            type: CloudFlowNodeType.ACTION,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
            statusMessage: "Additional permissions required",
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.APPROVAL:
      return {
        id: nodeId,
        type: CloudFlowNodeType.APPROVAL,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Send for approval",
            status: NODE_STATUS.VALIDATED,
            display: {
              position,
            },
            type: CloudFlowNodeType.APPROVAL,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.CONDITION:
      return {
        id: nodeId,
        type: CloudFlowNodeType.CONDITION,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Condition",
            status: NODE_STATUS.VALIDATED,
            display: {
              position,
            },
            type: CloudFlowNodeType.CONDITION,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
          requiresValidation: true,
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.LOOP:
      return {
        id: nodeId,
        type: CloudFlowNodeType.LOOP,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Loop",
            status: NODE_STATUS.VALIDATED,
            display: {
              position,
            },
            type: CloudFlowNodeType.LOOP,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.START_STEP:
      return {
        id: nodeId,
        type: CloudFlowNodeType.START_STEP,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Start flow",
            status: NODE_STATUS.PENDING,
            display: {
              position,
            },
            type: CloudFlowNodeType.START_STEP,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.ACTION_STEP:
      return {
        id: nodeId,
        type: CloudFlowNodeType.ACTION_STEP,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "What do you want to do?",
            display: {
              position,
            },
            type: CloudFlowNodeType.ACTION_STEP,
            status: NODE_STATUS.PENDING,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
        },
      } satisfies Node<RFNode>;
    default:
      return {
        id: nodeId,
        type: CloudFlowNodeType.ACTION,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Perform action",
            status: NODE_STATUS.VALIDATED,
            display: {
              position,
            },
            type: CloudFlowNodeType.ACTION,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
        },
      } satisfies Node<RFNode>;
  }
};

export const createConditionNodes = (
  newNode: Node,
  handleAddNode: (nodeType: CloudFlowNodeType, id: string) => void,
  handleDeleteNode: (id: string) => void
) => {
  const trueNodeId = uuidv4();
  const trueNodeGhostId = uuidv4();
  const falseNodeId = uuidv4();
  const falseNodeGhostId = uuidv4();

  const trueNodeTree = [
    {
      id: trueNodeId,
      type: CloudFlowNodeType.ACTION_STEP,
      data: {
        touched: false,
        nodeData: {
          name: "What do you want to do?",
          status: NODE_STATUS.PENDING,
          display: {
            position,
          },
          type: CloudFlowNodeType.ACTION_STEP,
          transitions: null,
          appKey: APP_KEY.INTERNAL,
        },
        onAddNode: handleAddNode,
        onDeleteNode: () => handleDeleteNode(trueNodeId),
      },
      position,
    },
    {
      id: trueNodeGhostId,
      type: CloudFlowNodeType.GHOST,
      position: { x: newNode.position.x, y: newNode.position.y },
      data: {
        touched: false,
        nodeData: {
          name: "Ghost node",
          status: NODE_STATUS.VALIDATED,
          display: {
            position,
          },
          type: CloudFlowNodeType.GHOST,
          transitions: null,
          appKey: APP_KEY.INTERNAL,
        },
      },
    },
  ] satisfies Node<RFNode>[];

  const falseNodeTree = [
    {
      id: falseNodeId,
      type: CloudFlowNodeType.ACTION_STEP,
      data: {
        touched: false,
        nodeData: {
          name: "What do you want to do?",
          status: NODE_STATUS.PENDING,
          display: {
            position,
          },
          type: CloudFlowNodeType.ACTION_STEP,
          transitions: null,
          appKey: APP_KEY.INTERNAL,
        },
        onAddNode: handleAddNode,
        onDeleteNode: () => handleDeleteNode(falseNodeId),
      },
      position,
    },
    {
      id: falseNodeGhostId,
      type: CloudFlowNodeType.GHOST,
      position,
      data: {
        touched: false,
        nodeData: {
          name: "Ghost node",
          status: NODE_STATUS.VALIDATED,
          display: {
            position,
          },
          type: CloudFlowNodeType.GHOST,
          transitions: null,
          appKey: APP_KEY.INTERNAL,
        },
      },
    },
  ] satisfies Node<RFNode>[];

  return { trueNodeTree, falseNodeTree, trueNodeId, falseNodeId, trueNodeGhostId, falseNodeGhostId };
};

export const isGhostNode = (node: Node | NodeModel<CloudFlowNodeType>) => node.type === CloudFlowNodeType.GHOST;
