import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AnalyticsResourceType, DashboardModel, type DashboardModelAttributionModel } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  type QueryDocumentSnapshotModel,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import intersection from "lodash/intersection";
import noop from "lodash/noop";

import { formatFirestoreDate } from "../Pages/CloudAnalytics/utilities";
import { type AttributionWRef, type Customer } from "../types";
import { getCustomerRefList } from "../utils/customers";
import { useIsFeatureEntitled, useTier } from "./TierProvider";

export const PresetAllGcpResources = "7mJDP8HtXXlpFTb2pVz7";
export const PresetAllAwsResources = "PvqyGcdFcTHh7aLUdGdf";
export const PresetAllAzureResources = "nXetZ5usYAdALHkt4wiE";

const attributionsTransform = (
  data: WithFirebaseModel<DashboardModelAttributionModel>,
  snapshot:
    | QueryDocumentSnapshotModel<DashboardModelAttributionModel>
    | DocumentSnapshotModel<DashboardModelAttributionModel>
): AttributionWRef => ({
  data,
  ref: snapshot.ref,
  transform: {
    timeModified: formatFirestoreDate(data.timeModified),
  },
});

export type AttributionsContextType = {
  filteredAttributions: AttributionWRef[];
  attributions: AttributionWRef[];
  attributionsLoading: boolean;
  getAttributions: () => void;
};

const attributionsContextDefaultValue: AttributionsContextType = {
  filteredAttributions: [],
  attributions: [],
  attributionsLoading: false,
  getAttributions: noop,
};

const AttributionsContext = createContext({} as AttributionsContextType);

export const useAttributionsContext = () => {
  const { filteredAttributions, attributions, attributionsLoading, getAttributions } = useContext(AttributionsContext);

  useEffect(() => {
    if (!attributions?.length) {
      getAttributions();
    }
  }, [attributions?.length, getAttributions]);

  return {
    filteredAttributions,
    attributions,
    attributionsLoading,
  };
};

export const AttributionsContextProvider = ({
  children,
  customer,
  customerOrPresentationModeCustomer,
}: {
  children?: ReactNode;
  customer: Customer | undefined | null;
  customerOrPresentationModeCustomer: Customer | undefined | null;
}) => {
  const { getFeatureKey } = useTier();
  const isEntitledAttributionsPresets = useIsFeatureEntitled("analytics:attributions:presets");
  const isEntitledPdiEks = useIsFeatureEntitled("pdi:eks");
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    if (!customer?.id || !isEntitledAttributionsPresets) {
      setShouldFetch(false);
    }
  }, [isEntitledAttributionsPresets, customer?.id]);

  const getAttributions = useCallback(() => setShouldFetch(true), []);

  const queries = useMemo(() => {
    if (!shouldFetch || !customer?.ref) {
      return;
    }

    const collectionRef = getCollection(DashboardModel).doc("google-cloud-reports").collection("attributions");

    const customerRefs = getCustomerRefList(customer, customerOrPresentationModeCustomer);

    const customerAttributions = collectionRef
      .where("type", "in", [AnalyticsResourceType.CUSTOM, AnalyticsResourceType.MANAGED])
      .where("customer", "in", customerRefs);

    const presetAttributions = collectionRef
      .where("type", "==", AnalyticsResourceType.PRESET)
      .where("customer", "==", null);

    return {
      customerAttributions,
      presetAttributions,
    };
  }, [shouldFetch, customer, customerOrPresentationModeCustomer]);

  const [presetAttributions, presetAttributionsLoading] = useCollectionData(queries?.presetAttributions, {
    transform: attributionsTransform,
  });

  const [customerAttributions, customerAttributionsLoading] = useCollectionData(queries?.customerAttributions, {
    transform: attributionsTransform,
  });

  const attributionsLoading = presetAttributionsLoading || customerAttributionsLoading;

  // List of all attributions, where the preset attributions are filtered
  // by the available providers the customer uses.
  const attributions = useMemo<AttributionWRef[]>(() => {
    if (attributionsLoading) {
      return [];
    }

    const customerAssets = customerOrPresentationModeCustomer?.assets ?? [];
    const presetAttributionsFiltered = (presetAttributions ?? []).filter((attr) => {
      const entitlementsKeys = attr.data.entitlements?.map((entitlement) => getFeatureKey(entitlement)) ?? [];
      const isEntitlementAttribution = attr.data.entitlements && attr.data.entitlements.length > 0;
      const isEKSAttribution = isEntitledPdiEks && entitlementsKeys.includes("pdi:eks");
      const hasCloudAsset = attr.data.cloud?.length ? intersection(customerAssets, attr.data.cloud).length > 0 : true;
      return (!isEntitlementAttribution || isEKSAttribution) && hasCloudAsset;
    });

    return [...(presetAttributionsFiltered ?? []), ...(customerAttributions ?? [])].sort((a, b) =>
      a.data.name.localeCompare(b.data.name)
    );
  }, [
    attributionsLoading,
    customerOrPresentationModeCustomer?.assets,
    presetAttributions,
    customerAttributions,
    isEntitledPdiEks,
    getFeatureKey,
  ]);

  // List of all non-draft attributions
  // TODO: With the new save button, there should not be a "draft" state anymore, because
  // only the first save should create the attribution.
  // This needs to be removed once we remove the draft state.
  const filteredAttributions = useMemo<AttributionWRef[]>(
    () => attributions.filter((attr) => !attr.data.draft),
    [attributions]
  );

  const value = useMemo(
    () => ({
      filteredAttributions,
      attributions,
      attributionsLoading,
      getAttributions,
    }),
    [attributionsLoading, attributions, filteredAttributions, getAttributions]
  );

  return <AttributionsContext.Provider value={value}>{children}</AttributionsContext.Provider>;
};

export const AttributionsContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<AttributionsContextType>;
}) => {
  const actualValue = value ? value : attributionsContextDefaultValue;

  return (
    <AttributionsContext.Provider value={actualValue as AttributionsContextType}>
      {children}
    </AttributionsContext.Provider>
  );
};
