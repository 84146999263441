import { useState } from "react";

import {
  CLOUD_FLOW_CONDITION,
  type CloudflowCondition,
  type CloudFlowNodeType,
  type ConditionParameter,
  LOGICAL_OPERATORS,
  type LogicalOperators,
} from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";

import { useNodeConfigurationContext } from "../NodeConfigurationContext";

// eslint-disable-next-line deprecation/deprecation
export const initialConditions: ConditionParameter[] = [
  // eslint-disable-next-line deprecation/deprecation
  { field: "", operator: CLOUD_FLOW_CONDITION.EQUALS, value: "" },
  // eslint-disable-next-line deprecation/deprecation
  { field: "", operator: CLOUD_FLOW_CONDITION.EQUALS, value: "", logicalOperator: LOGICAL_OPERATORS.AND },
];

export const ConditionParametersTab = () => {
  const { nodeConfig, updateNode } = useNodeConfigurationContext<CloudFlowNodeType.CONDITION>();

  const nodeConditions = (nodeConfig.parameters as any)?.conditions || [];
  const initConditions =
    nodeConditions && Array.isArray(nodeConditions) && nodeConditions.length ? nodeConditions : initialConditions;
  // eslint-disable-next-line deprecation/deprecation
  const [conditions, setConditions] = useState<ConditionParameter[]>(cloneDeep(initConditions));

  // eslint-disable-next-line deprecation/deprecation
  const updateConditions = (updatedConditions: ConditionParameter[]) => {
    setConditions(updatedConditions);

    // TODO fix these updates
    updateNode({ ...nodeConfig, parameters: { ...nodeConfig.parameters, ...updatedConditions } });
  };

  // eslint-disable-next-line deprecation/deprecation
  const handleChange = <K extends keyof ConditionParameter>(index: number, key: K, value: ConditionParameter[K]) => {
    const updatedConditions = [...conditions];
    updatedConditions[index][key] = value;
    updateConditions(updatedConditions);
  };

  const handleAddCondition = () => {
    const newCondition = {
      field: "",
      // eslint-disable-next-line deprecation/deprecation
      operator: CLOUD_FLOW_CONDITION.EQUALS,
      value: "",
      // eslint-disable-next-line deprecation/deprecation
      logicalOperator: LOGICAL_OPERATORS.AND,
    };
    updateConditions([...conditions, newCondition]);
  };

  const handleRemoveCondition = (index: number) => {
    updateConditions(conditions.filter((_, i) => i !== index));
  };

  return (
    <Box width="100%" p={4} display="flex" flexDirection="column" justifyContent="center" gap={2}>
      {conditions.map((condition, index) => (
        <Stack spacing={2} key={index}>
          <Stack direction="row" justifyContent="space-between" pt={0}>
            <Typography
              variant="subtitle1"
              color="text.primary"
              fontWeight={500}
            >{`Condition ${index + 1}`}</Typography>
            {index > 0 && (
              <Button variant="text" color="error" onClick={() => handleRemoveCondition(index)} endIcon={<CloseIcon />}>
                Remove
              </Button>
            )}
          </Stack>
          {index > 0 && (
            <FormControl required>
              <InputLabel>Operator</InputLabel>
              <Select
                // eslint-disable-next-line deprecation/deprecation
                value={condition.logicalOperator || LOGICAL_OPERATORS.AND}
                onChange={(e) => {
                  // eslint-disable-next-line deprecation/deprecation
                  handleChange(index, "logicalOperator", e.target.value as LogicalOperators);
                }}
                label="Operator"
                sx={{ height: 40, width: "50%" }}
              >
                {
                  // eslint-disable-next-line deprecation/deprecation
                  Object.entries(LOGICAL_OPERATORS).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          )}
          <TextField
            label="Field"
            value={condition.field}
            onChange={(e) => handleChange(index, "field", e.target.value)}
            fullWidth
            required
          />
          <FormControl required>
            <InputLabel>Condition</InputLabel>
            <Select
              value={condition.operator}
              onChange={(e) => {
                // eslint-disable-next-line deprecation/deprecation
                handleChange(index, "operator", e.target.value as CloudflowCondition);
              }}
              label="Condition"
              sx={{ height: 40 }}
            >
              {
                // eslint-disable-next-line deprecation/deprecation
                Object.entries(CLOUD_FLOW_CONDITION).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <TextField
            label="Value"
            value={condition.value}
            onChange={(e) => handleChange(index, "value", e.target.value)}
            fullWidth
            required
            sx={{ pb: 2 }}
          />
        </Stack>
      ))}
      <Button variant="text" color="primary" onClick={handleAddCondition}>
        Add Additional Condition
      </Button>
    </Box>
  );
};
