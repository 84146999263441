import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";

import { SideDrawer } from "../../../../Components/SideDrawer";
import { type NodeConfigs, type TestNodeResponse } from "../../types";
import { ActionBar } from "./ActionBar";
import { Header } from "./Header";
import { NodeConfigurationProvider } from "./NodeConfigurationContext";
import { useTabs } from "./Tabs/hooks";
import { TabContent } from "./Tabs/TabContent";
import { TabNavigation } from "./Tabs/TabNavigation";

export type TestProps = {
  onTestNode: (nodeConfig: NodeConfigs) => Promise<TestNodeResponse>;
  testNodeLoading?: boolean;
};

type Props = {
  open: boolean;
  onClose: () => void;
  nodeConfig: NodeConfigs;
  onUpdateNode: (nodeConfig: Partial<NodeConfigs>) => void;
  onChangeTriggerType: (triggerType: CloudFlowNodeType) => void;
} & TestProps;

const NodeConfigurationPanel = ({
  open,
  onClose,
  nodeConfig,
  onUpdateNode,
  onChangeTriggerType,
  onTestNode,
  testNodeLoading,
}: Props) => {
  const tabs = useTabs(nodeConfig);
  return (
    <NodeConfigurationProvider
      nodeConfig={nodeConfig}
      updateNode={onUpdateNode}
      onChangeTriggerType={onChangeTriggerType}
    >
      <SideDrawer
        open={open}
        title=""
        onClose={onClose}
        variant="persistent"
        sideDrawerSx={{
          my: 7.1,
          borderRadius: 0,
          mx: 0,
          "& .MuiStack-root:nth-of-type(1) > .MuiStack-root:nth-of-type(1)": {
            py: 0.5,
          },
          "& .MuiDivider-root": {
            marginBottom: 0,
          },
        }}
        disableGutters={true}
        headerElement={<Header />}
      >
        <Stack height="100%">
          {tabs.length > 1 && <TabNavigation tabs={tabs} />}
          <Stack sx={{ flexGrow: 1, overflowY: "auto" }}>
            <TabContent onTestNode={onTestNode} testNodeLoading={testNodeLoading} />
          </Stack>
          <Stack sx={{ p: 1, borderTop: 1, borderColor: "divider", bgcolor: "background.paper", mb: 5.5 }}>
            <ActionBar />
          </Stack>
        </Stack>
      </SideDrawer>
    </NodeConfigurationProvider>
  );
};

export default NodeConfigurationPanel;
