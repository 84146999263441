import { useHistory } from "react-router-dom";
import { Card, CardContent, TableCell, TablePagination, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import { useCustomerContext } from "../../Context/CustomerContext";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { getProducts } from "../../Pages/KnownIssues/utils";
import { truncate } from "../../utils/common";
import Hide from "../HideChildren/Hide";
import { CARD_HEADER_HEIGHT } from "./Analytics/cloudCardStyle";
import { useGetActiveCloudIncidents } from "./hooks/useGetActiveCloudIncidents";
import { useRowPerPage } from "./hooks/useRowPerPage";
import { SkeletonCard } from "./SkeletonCard";
import type { WidgetItemProps } from "./types";

const useStyles = makeStyles((theme) => ({
  table: {
    whiteSpace: "nowrap",
  },
  tableHeader: {
    height: 32,
  },
  tableRow: {
    height: 32,
  },
  tablePagination: {
    borderBottom: "none",
    padding: theme.spacing(0),
  },
  companyLogoWrapper: {
    verticalAlign: "bottom",
  },
  companyLogo: {
    margin: theme.spacing(0.5, 0.5, 0, 0.5),
    height: 24,
  },
}));

export default function CloudIncidentsCard({ raised, fallbackComponent, widgetHeight = 200 }: WidgetItemProps) {
  const classes = useStyles();
  const { loading, cloudIncidents } = useGetActiveCloudIncidents();
  const { customer } = useCustomerContext();
  const history = useHistory();
  const { emptyRows, page, rowsPerPage, handleChangePage } = useRowPerPage({
    totalRows: cloudIncidents?.length ?? 0,
    widgetHeight,
  });

  if (loading) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  if (!loading && cloudIncidents.length === 0) {
    return fallbackComponent;
  }

  return (
    <Card raised={raised}>
      <WidgetCardHeader title="Active cloud incidents" subheader="List of active cloud incidents" />
      <CardContent sx={{ height: widgetHeight - CARD_HEADER_HEIGHT, p: 0 }}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell padding="none" size="small" sx={{ width: "40%", paddingRight: "5px" }}>
                Title
              </TableCell>
              <Hide xlDown>
                <TableCell padding="none" size="small" sx={{ width: "20%", paddingRight: "5px" }}>
                  Product
                </TableCell>
              </Hide>
              <Hide smDown>
                <TableCell padding="none" size="small">
                  Date
                </TableCell>
              </Hide>
            </TableRow>
          </TableHead>
          <TableBody>
            {cloudIncidents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow className={classes.tableRow} key={row.id}>
                <TableCell padding="none" size="small">
                  <Link
                    onClick={() => {
                      history.push(`/customers/${customer.id}/known-issues/${row.id}`);
                    }}
                    sx={{ cursor: "pointer" }}
                    color="inherit"
                    noWrap
                  >
                    <Typography noWrap sx={{ fontSize: "14px", pr: 1 }}>
                      {truncate(row.title, 32, false)}
                    </Typography>
                  </Link>
                </TableCell>
                <Hide xlDown>
                  <TableCell padding="none" size="small" sx={{ minWidth: 100 }}>
                    <Typography noWrap sx={{ fontSize: "14px", pr: 1 }}>
                      {truncate(getProducts(row), 24, false)}
                    </Typography>
                  </TableCell>
                </Hide>
                <Hide smDown>
                  <TableCell padding="none" size="small" sx={{ fontSize: "14px" }}>
                    {DateTime.fromJSDate(row.dateTime.toDate()).toLocaleString(DateTime.DATE_MED)}
                  </TableCell>
                </Hide>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 32 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                className={classes.tablePagination}
                rowsPerPageOptions={[rowsPerPage]}
                count={cloudIncidents.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                  size: "small",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                  size: "small",
                }}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  );
}
