import { useEffect, useState } from "react";

import { isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import { ModelType, type ReferencedNodeValue, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";

import { useReferencedFieldContext } from "./useReferencedFieldContext";

type ReferenceFieldMenuItemData = {
  name: string;
  caption?: string;
  hasContinuation: boolean;
  disabled?: boolean;
};

type NodeMenuItemData = ReferenceFieldMenuItemData & {
  type: "node";
  nodeId: string;
  disabled: boolean;
};

type TokenMenuItemData = ReferenceFieldMenuItemData & {
  type: "token";
};

export type MenuItemData = NodeMenuItemData | TokenMenuItemData;

export function hasOtherReferencedFields(values: unknown, fieldPath: string, currentFieldPath = ""): [boolean, string] {
  let referencedNodeId = "";
  let foundOther = false;
  switch (true) {
    case isArray(values): {
      values.some((paramValue, idx) => {
        [foundOther, referencedNodeId] = hasOtherReferencedFields(paramValue, fieldPath, `${currentFieldPath}[${idx}]`);
        return foundOther;
      });
      return [foundOther, referencedNodeId];
    }

    case isObject(values): {
      if (isReferencedNodeValue(values)) {
        return [currentFieldPath !== fieldPath, values.referencedNodeId];
      }
      Object.entries(values).some(([paramName, paramValue]) => {
        [foundOther, referencedNodeId] = hasOtherReferencedFields(
          paramValue,
          fieldPath,
          `${currentFieldPath}.${paramName}`
        );
        return foundOther;
      });
      return [foundOther, referencedNodeId];
    }
  }

  return [foundOther, referencedNodeId];
}

export function useReferencedFieldMenuItems(
  fieldValue: ReferencedNodeValue | unknown,
  fieldPath: string
): MenuItemData[] {
  const { referenceableNodes, values } = useReferencedFieldContext();
  const [menuItems, setMenuItems] = useState<MenuItemData[]>([]);

  useEffect(() => {
    let menuItems: MenuItemData[] = [];
    if (!isReferencedNodeValue(fieldValue)) {
      const [thereAreOthers, otherReferencedNodeId] = hasOtherReferencedFields(values, fieldPath);

      menuItems = referenceableNodes.map(({ id, name }) => ({
        name,
        type: "node",
        nodeId: id,
        hasContinuation: true,
        disabled: thereAreOthers && id !== otherReferencedNodeId,
      }));
    } else {
      const referencedNode = referenceableNodes.find(({ id }) => id === fieldValue.referencedNodeId);
      if (referencedNode) {
        menuItems = getNextTokens(referencedNode.outputModel, fieldValue.referencedField);
      }
    }
    setMenuItems(menuItems);
  }, [fieldPath, fieldValue, referenceableNodes, values]);

  return menuItems;
}

function getNextTokens(model: UnwrappedApiServiceModelDescriptor, referencedField: string[]): TokenMenuItemData[] {
  switch (model.type) {
    case ModelType.LIST:
      return getNextTokens(model.member.model, referencedField);

    case ModelType.STRUCTURE: {
      const currentTokens = Array.from(referencedField);
      const current = currentTokens.shift();

      if (current) {
        return getNextTokens(model.members[current].model, currentTokens);
      }
      return Object.entries(model.members)
        .map<TokenMenuItemData>(([label, { model }]) => ({
          name: label,
          caption: model.type,
          type: "token",
          hasContinuation: doesModelHasContinuation(model),
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    default:
      return [];
  }
}

function doesModelHasContinuation(model: UnwrappedApiServiceModelDescriptor) {
  return (
    model.type === ModelType.STRUCTURE ||
    (model.type === ModelType.LIST && [ModelType.LIST, ModelType.STRUCTURE].includes(model.member.model.type))
  );
}

function isObject(value: unknown): value is Record<string | number, object> {
  return value !== null && typeof value === "object";
}
function isArray<T>(value: T | T[]): value is T[] {
  return Array.isArray(value);
}
