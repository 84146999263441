import { type MutableRefObject } from "react";

import { CloudFlowNodeType } from "@doitintl/cmp-models";
import {
  type DeleteElementsOptions,
  type Edge,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  type Node,
} from "@xyflow/react";

import { EDGE_TYPE, type RFNode } from "../../types";
import { createEdge, createFalsePathEdge, createTruePathEdge } from "./edgeUtils";
import { createConditionNodes, initializeNode, isGhostNode } from "./nodeUtils";

type NodeEdgeProps = {
  nodes: Node[];
  edges: Edge[];
};

type NodeHandlers = {
  handleEditNode: (node: Node) => void;
  handleAddNode: (nodeType: CloudFlowNodeType, nodeId: string) => void;
  handleDeleteNode: (nodeId: string) => void;
};

type BaseActionProps = NodeEdgeProps & NodeHandlers;

type handleDeleteActionsProps = BaseActionProps & {
  manageIfActionsId: string;
  getTreeOfOutgoers: (nodeId: string) => { nodes: Node[]; edges: Edge[] };
};

type replaceWithIfNodeProps = NodeEdgeProps &
  Omit<NodeHandlers, "handleAddNode"> & {
    nodeId: string;
  };

type handleMoveActionsProps = BaseActionProps & {
  manageIfActionsId: string;
  moveToTrue: boolean;
};

type handleDeleteIfNodeParentProps = NodeEdgeProps & {
  incomer: Node;
  currentNode: Node;
  handleAddRef: MutableRefObject<((nodeType: CloudFlowNodeType, nodeId: string) => void) | undefined>;
  handleDeleteRef: MutableRefObject<((nodeId: string, forceDelete?: boolean) => Promise<void>) | undefined>;
};

type setNodesOnDeleteIfNodeProps = NodeEdgeProps & {
  nodeId: string;
  deleteElements: (params: DeleteElementsOptions) => Promise<{ deletedNodes: Node[]; deletedEdges: Edge[] }>;
  handleAddRef: MutableRefObject<((nodeType: CloudFlowNodeType, nodeId: string) => void) | undefined>;
  handleDeleteRef: MutableRefObject<((nodeId: string, forceDelete?: boolean) => Promise<void>) | undefined>;
};

type handleAddIfNodeProps = BaseActionProps & {
  newNode: Node;
};

const doesNodeHaveChildren = (nodes: Node[], edges: Edge[], nodeId: string): boolean => {
  const outgoers = getOutgoers(
    {
      id: nodeId,
    },
    nodes,
    edges
  );
  if (outgoers.length === 0) return false;
  return outgoers.some((outgoer) => !isGhostNode(outgoer));
};

const findCurrentNode = (nodes: Node[], nodeId: string) => nodes.find((node) => node.id === nodeId);

const replaceWithIfNode = ({ nodes, edges, nodeId, handleEditNode, handleDeleteNode }: replaceWithIfNodeProps) => {
  const currentNode = findCurrentNode(nodes, nodeId);
  const currentNodeId = currentNode!.id;
  let newNodes = [...nodes];
  const newEdges = [...edges];

  const newNode = initializeNode(CloudFlowNodeType.CONDITION, currentNodeId);

  newNodes = newNodes.map((node) => {
    if (node.id === currentNodeId) {
      return {
        ...newNode,
        data: {
          ...newNode.data,
          isActive: true,
          onEditNode: () => handleEditNode(newNode),
          onDeleteNode: () => handleDeleteNode(currentNodeId),
        },
      };
    }
    return node;
  });

  return { newNodes, newEdges, newNode };
};

const createConditionNodesAndPaths = (
  currentNode: Node,
  handleAddNode: (nodeType: CloudFlowNodeType, nodeId: string) => void,
  handleDeleteNode: (nodeId: string) => void
) => {
  const { trueNodeId, falseNodeId, trueNodeTree, falseNodeTree, trueNodeGhostId, falseNodeGhostId } =
    createConditionNodes(currentNode, handleAddNode, handleDeleteNode);

  const newEdges = [
    createTruePathEdge(currentNode.id, trueNodeId),
    createFalsePathEdge(currentNode.id, falseNodeId),
    createEdge(trueNodeId, trueNodeGhostId, EDGE_TYPE.GHOST),
    createEdge(falseNodeId, falseNodeGhostId, EDGE_TYPE.GHOST),
  ];

  return { trueNodeTree, falseNodeTree, newEdges };
};

const handleDeleteActions: ({
  nodes,
  edges,
  handleEditNode,
  handleAddNode,
  handleDeleteNode,
  manageIfActionsId,
  getTreeOfOutgoers,
}: handleDeleteActionsProps) => {
  newNodes: Node[];
  newEdges: Edge[];
  newNode: Node<RFNode>;
} = ({ nodes, edges, handleEditNode, handleAddNode, handleDeleteNode, manageIfActionsId, getTreeOfOutgoers }) => {
  const currentNode = findCurrentNode(nodes, manageIfActionsId);
  const currentNodeId = currentNode!.id;

  const { newNodes, newEdges, newNode } = replaceWithIfNode({
    nodes,
    edges,
    nodeId: manageIfActionsId,
    handleEditNode,
    handleDeleteNode,
  });

  const { nodes: outnodes, edges: outEdges } = getTreeOfOutgoers(currentNodeId);
  const filteredNodes = newNodes.filter((node) => !outnodes.some((outNode) => outNode.id === node.id));

  // Remove edges from `newEdges` that are part of the outgoers
  const filteredEdges = newEdges.filter((edge) => !outEdges.some((outEdge) => outEdge.id === edge.id));
  const {
    trueNodeTree,
    falseNodeTree,
    newEdges: conditionEdges,
  } = createConditionNodesAndPaths(currentNode!, handleAddNode, handleDeleteNode);
  return {
    newNodes: [...filteredNodes, ...trueNodeTree, ...falseNodeTree],
    newEdges: [...filteredEdges, ...conditionEdges],
    newNode,
  };
};

const handleMoveActions = ({
  nodes,
  edges,
  handleEditNode,
  handleAddNode,
  handleDeleteNode,
  manageIfActionsId,
  moveToTrue,
}: handleMoveActionsProps) => {
  const currentNode = findCurrentNode(nodes, manageIfActionsId);
  const currentNodeId = currentNode!.id;
  const { newNodes, newEdges, newNode } = replaceWithIfNode({
    nodes,
    edges,
    nodeId: manageIfActionsId,
    handleEditNode,
    handleDeleteNode,
  });

  const { trueNodeId, falseNodeId, trueNodeTree, falseNodeTree, trueNodeGhostId, falseNodeGhostId } =
    createConditionNodes(currentNode!, handleAddNode, handleDeleteNode);

  const edgeFromCurrent = edges.findIndex((edge) => edge.source === currentNodeId);
  const realTrueNodeId = moveToTrue ? newEdges[edgeFromCurrent].target : trueNodeId;
  const realFalseNodeId = moveToTrue ? falseNodeId : newEdges[edgeFromCurrent].target;
  newEdges.splice(edgeFromCurrent, 1);

  if (moveToTrue) {
    newNodes.push(...falseNodeTree);
    newEdges.push(createEdge(realFalseNodeId, falseNodeGhostId, EDGE_TYPE.GHOST));
  } else {
    newNodes.push(...trueNodeTree);
    newEdges.push(createEdge(realTrueNodeId, trueNodeGhostId, EDGE_TYPE.GHOST));
  }

  newEdges.push(createTruePathEdge(currentNodeId, realTrueNodeId));
  newEdges.push(createFalsePathEdge(currentNodeId, realFalseNodeId));

  return { newNodes, newEdges, newNode };
};

const handleDeleteIfNodeParent = ({
  incomer,
  currentNode,
  nodes,
  edges,
  handleAddRef,
  handleDeleteRef,
}: handleDeleteIfNodeParentProps) => {
  if (!handleAddRef.current || !handleDeleteRef.current) return { newEdges: edges, newNodes: nodes };

  const connectedEdges = getConnectedEdges([incomer], edges);
  const outgoers = getOutgoers(incomer, nodes, edges);

  const newNode = initializeNode(CloudFlowNodeType.CONDITION, incomer.id);

  const { trueNodeId, falseNodeId, trueNodeTree, falseNodeTree, trueNodeGhostId, falseNodeGhostId } =
    createConditionNodes(newNode, handleAddRef.current, handleDeleteRef.current);

  const otherEdge = connectedEdges.find((edge) => edge.source === incomer.id && edge.target !== currentNode.id);

  const label = otherEdge?.data!.label === "True" ? "False" : "True";

  let newEdges: Edge[];
  let newNodes: Node[];

  if (label === "True") {
    newEdges = [createTruePathEdge(incomer.id, trueNodeId), createEdge(trueNodeId, trueNodeGhostId, EDGE_TYPE.GHOST)];

    newNodes = [...(outgoers.length ? trueNodeTree : [])];
  } else {
    newEdges = [
      createFalsePathEdge(incomer.id, falseNodeId),
      createEdge(falseNodeId, falseNodeGhostId, EDGE_TYPE.GHOST),
    ];

    newNodes = [...(outgoers.length ? falseNodeTree : ([] as Node[]))];
  }

  return { newEdges, newNodes };
};

const getNodeDescendants = (nodes: Node[], edges: Edge[], nodeId: string): Node[] => {
  const childEdges = edges.filter((edge) => edge.source === nodeId);

  if (childEdges.length === 0) {
    return [] as Node[];
  }

  return childEdges.flatMap((edge) => {
    const childId = edge.target;
    const childNode = findCurrentNode(nodes, childId);
    return childNode ? [childNode, ...getNodeDescendants(nodes, edges, childId)] : [];
  });
};

const rearrangeFlowOnDeleteIfNode: ({
  nodeId,
  nodes,
  edges,
  deleteElements,
  handleDeleteRef,
  handleAddRef,
}: setNodesOnDeleteIfNodeProps) => Promise<{
  newEdges: Edge[];
  newNodes: Node[];
}> = async ({ nodeId, nodes, edges, deleteElements, handleDeleteRef, handleAddRef }: setNodesOnDeleteIfNodeProps) => {
  const nodeToDelete = findCurrentNode(nodes, nodeId);
  if (!nodeToDelete) {
    return { newNodes: nodes, newEdges: edges };
  }
  const [incomer] = getIncomers(nodeToDelete, nodes, edges);

  const outgoers = getOutgoers({ id: nodeToDelete.id } as Node, nodes, edges);
  const connectedEdges = getConnectedEdges([nodeToDelete], edges);
  await deleteElements({ nodes: [nodeToDelete], edges: connectedEdges });

  const { newNodes, newEdges } = handleDeleteIfNodeParent({
    currentNode: nodeToDelete,
    incomer,
    nodes,
    edges,
    handleDeleteRef,
    handleAddRef,
  });

  const ghostIndex = newNodes.findIndex((node) => isGhostNode(node));
  const stepNodeIndex = newEdges.findIndex((edge) => edge.source === incomer.id);
  const stepNodeId = newEdges.find((edge) => edge.source === incomer.id)?.target;
  const ghostNode = newNodes[ghostIndex];

  if (isGhostNode(outgoers[0])) {
    newNodes.splice(ghostIndex, 1);
    newEdges.splice(
      newEdges.findIndex((edge) => edge.target === ghostNode.id),
      1
    );
    outgoers.forEach((outgoer) => {
      if (stepNodeId) {
        newEdges.push(createEdge(stepNodeId, outgoer.id, isGhostNode(outgoer) ? EDGE_TYPE.GHOST : EDGE_TYPE.CUSTOM));
      }
    });

    return { newNodes, newEdges };
  } else {
    newEdges.splice(
      newEdges.findIndex((edge) => edge.target === ghostNode.id),
      1
    );

    newEdges[stepNodeIndex] =
      newEdges[stepNodeIndex].data?.label === "True"
        ? createTruePathEdge(incomer.id, outgoers[0].id)
        : createFalsePathEdge(incomer.id, outgoers[0].id);

    return { newNodes: [] as Node[], newEdges };
  }
};

const filterDeletedNodeDescendants = (
  nodes: Node[],
  edges: Edge[],
  nodesToExclude: Node[],
  edgesToExclude: Edge[],
  nodeIdToDelete: string
) => ({
  filteredNodes: nodes.filter((n) => !nodesToExclude.includes(n) && n.id !== nodeIdToDelete),
  filteredEdges: edges
    .filter((edge) => !edgesToExclude.includes(edge))
    .filter((edge) => !nodesToExclude.some((n) => n.id === edge.source) && edge.source !== nodeIdToDelete),
});

const sortEdgesByHandle = (edges: Edge[]): Edge[] => {
  const getHandleSuffix = (handle?: string | null) => {
    if (!handle) return "";
    if (handle.endsWith("-true")) return "true";
    if (handle.endsWith("-false")) return "false";
    return "none";
  };

  const order = { true: 1, false: 2, none: 3 };

  return edges.sort((a, b) => {
    const suffixA = getHandleSuffix(a.sourceHandle);
    const suffixB = getHandleSuffix(b.sourceHandle);

    return (order[suffixA] || 0) - (order[suffixB] || 0);
  });
};

const handleAddIfNode = ({
  nodes,
  edges,
  newNode,
  handleAddNode,
  handleDeleteNode,
  handleEditNode,
}: handleAddIfNodeProps) => {
  const nodeId = newNode.id;
  const outgoers = getOutgoers({ id: nodeId }, nodes, edges);
  const existingGhostNode = outgoers.find((outgoer) => isGhostNode(outgoer));
  const {
    trueNodeTree,
    falseNodeTree,
    newEdges: conditionEdges,
  } = createConditionNodesAndPaths(newNode, handleAddNode, handleDeleteNode);

  const filteredNodes = nodes.filter((node) => node.id !== existingGhostNode?.id);
  const updatedNodes = [...filteredNodes, ...trueNodeTree, ...falseNodeTree];

  const newNodes = updatedNodes.map((node: Node) => {
    if (node.id === nodeId) {
      return {
        ...newNode,
        data: {
          ...newNode.data,
          isActive: true,
          onEditNode: () => handleEditNode(newNode),
          onDeleteNode: () => handleDeleteNode(nodeId),
        },
      };
    }
    return node;
  });

  const filteredEdges = edges.filter((edge) => edge.source !== nodeId);
  const newEdges = [...filteredEdges, ...conditionEdges];

  return { newNodes, newEdges, newNode };
};

const utils = {
  doesNodeHaveChildren,
  handleAddIfNode,
  handleDeleteActions,
  handleMoveActions,
  handleDeleteIfNodeParent,
  filterDeletedNodeDescendants,
  getNodeDescendants,
  rearrangeFlowOnDeleteIfNode,
  sortEdgesByHandle,
};

export default utils;
