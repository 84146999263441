import { createRef, type ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { type GSuiteAssetModel, type GSuiteAssetModelProperties } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/PlusOneRounded";
import ResetIcon from "@mui/icons-material/RefreshRounded";
// TODO MUI5 Vlad get new Icon for -1 Rounded
import RemoveIcon from "@mui/icons-material/RemoveRounded";
import { Card } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import { green, grey, red } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { type PopoverProps } from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles, withStyles } from "@mui/styles";
import classNames from "classnames";
import get from "lodash/get";
import { DateTime } from "luxon";

import { useApiContext } from "../../../api/context";
import GoogleWorkspaceIcon from "../../../assets/g-suite.png";
import GoogleDriveIcon from "../../../assets/google-drive.png";
import { CatalogHooks, type LegacyDataFormat } from "../../../Components/Catalog/Catalog.context";
import { CARD_HEADER_HEIGHT } from "../../../Components/Dashboard/Analytics/cloudCardStyle";
import { SkeletonCard } from "../../../Components/Dashboard/SkeletonCard";
import { type WidgetItemProps, type WidgetItemWithWidgetIdProps } from "../../../Components/Dashboard/types";
import LoadingButton from "../../../Components/LoadingButton";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useUserContext } from "../../../Context/UserContext";
import { ThemeModes } from "../../../muiThemeTypes";
import { type AnyAsset, type Asset, type Contract } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { formatCurrency } from "../../../utils/common";
import sku, { GetResellerLabel, transformWorkspacePlanName } from "../../../utils/gsuite";
import mixpanel from "../../../utils/mixpanel";
import { WidgetCardHeader } from "../../Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import AssetSettingsDialog from "../Dialogs/AssetSettingsDialog";
import AutomationDialog from "../Dialogs/AutomationDialog";
import ChangeGVoiceDialog from "../Dialogs/ChangeGVoiceDialog";
import { getTotal } from "../Tabs/components/gsuite/gSuiteAssetUtils";
import { useAssetDataProps } from "./assetCardUtils";
import GSuiteLicenseChangeConfirm from "./components/GSuiteLicenseChangeConfirm";
import { RemoveDialog } from "./components/RemoveDialog";

const useStyles = makeStyles((theme) => ({
  card: {
    // height: "100%",
  },
  highlight: {
    backgroundColor: theme.palette.mode === ThemeModes.DARK ? grey[600] : grey[300],
  },
  actions: {
    height: 64,
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  listRoot: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  infoDiv: {
    height: 72,
  },
  typography: {
    display: "inline",
  },
  overrode: {
    color: red[500],
  },
  listItemIcon: {
    margin: 0,
  },
  increase: {
    color: green[500],
  },
  decrease: {
    color: red[500],
  },
  startAction: {
    marginRight: theme.spacing(1),
  },
  endAction: {
    marginLeft: theme.spacing(1),
  },
}));

const BorderLinearProgress = withStyles({
  root: {},
  bar: {
    borderRadius: 12,
  },
})(LinearProgress);

const getRenewalTypeLabel = (renewalType: string | undefined) => {
  switch (renewalType) {
    case "SWITCH_TO_PAY_AS_YOU_GO":
      return "Switch to Flexible";
    case "AUTO_RENEW":
      return "Auto Renew";
    case "AUTO_RENEW_MONTHLY_PAY":
      return "Auto Renew";
    case "AUTO_RENEW_YEARLY_PAY":
      return "Auto Renew";
    case "CANCEL":
      return "Cancel Subscription";
    case "RENEW_CURRENT_USERS":
      return "Auto Renew";
    case "RENEW_CURRENT_USERS_MONTHLY_PAY":
      return "Auto Renew";
    case "RENEW_CURRENT_USERS_YEARLY_PAY":
      return "Auto Renew";
    default:
      return renewalType;
  }
};

type Props = WidgetItemProps & {
  highlight?: boolean;
  widget: boolean;
  entity: Entity | undefined;
  onRemoveAsset?: () => void;
  onAssignToEntity?: () => void;
  unassignedAction?: ReactNode;
  onAddTag?: () => void;
  asset: Asset<GSuiteAssetModel>;
  contract?: Contract;
};

const GSuiteAssetCardContent = ({
  asset,
  contract,
  entity,
  widget,
  highlight,
  onAssignToEntity,
  onRemoveAsset,
  onAddTag,
  unassignedAction,
  widgetHeight = 200,
}: Props) => {
  const { onOpen: showSnackbar, onClose: hideSnackbar } = useSnackbar();
  const { isDoitEmployee, isDoitOwner } = useAuthContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const api = useApiContext();
  const classes = useStyles();
  const domRef = createRef<HTMLInputElement>();
  const [catalog, catalogLoading] = CatalogHooks.useGSuiteCatalogContext();
  const [anchorEl, setAnchorEl] = useState<PopoverProps["anchorEl"] | null>(null);
  const [subscription, setSubscription] = useState<GSuiteAssetModelProperties["subscription"]>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showChangeSeats, setShowChangeSeats] = useState(false);
  const [settingsDialog, setSettingsDialog] = useState(false);
  const [changeGVoiceDialog, setChangeGVoiceDialog] = useState(false);
  const [automationDialog, setAutomationDialog] = useState(false);
  const [ackSubscriptionChangeSeatsDialog, setAckSubscriptionChangeSeatsDialog] = useState(false);
  const [ackAssetRemoveDialog, setAckAssetRemoveDialog] = useState(false);
  const [catalogItem, setCatalogItem] = useState<LegacyDataFormat>();

  useEffect(() => {
    if (highlight && domRef.current) {
      window.scrollTo({
        top: domRef.current.offsetTop - 14,
        behavior: "smooth",
      });
    }
  }, [domRef, highlight]);

  const planName = useMemo<"ANNUAL" | "FLEXIBLE">(() => {
    const { settings, subscription } = asset.data.properties;
    return (settings?.plan?.planName ?? subscription.plan.isCommitmentPlan) ? "ANNUAL" : "FLEXIBLE";
  }, [asset.data.properties]);

  const payment = useMemo<"MONTHLY" | "YEARLY">(() => {
    const { settings } = asset.data.properties;
    return (settings?.payment ?? planName === "FLEXIBLE") ? "MONTHLY" : "YEARLY";
  }, [asset.data.properties, planName]);

  useEffect(() => {
    if (!catalog) {
      return;
    }

    const { subscription } = asset.data.properties;

    const currentCatalogItem = catalog.find(
      (item) => item.data.skuId === subscription.skuId && item.data.plan === planName && item.data.payment === payment
    );

    if (!currentCatalogItem) {
      throw new Error(`${subscription.skuId} Item not found in catalog.`);
    }

    const seatBasedSKU = ![sku.GoogleMeetGlobalDialing, sku.CloudIdentityFree].includes(
      asset.data.properties.subscription.skuId
    );

    setSubscription(asset.data.properties.subscription);
    setShowChangeSeats(asset.data.properties.subscription.billingMethod === "ONLINE" && seatBasedSKU);
    setCatalogItem(currentCatalogItem);
  }, [planName, payment, asset.data.properties, catalog, catalogItem]);

  const handleRemoveDialogOK = useCallback(() => {
    if (!onRemoveAsset) {
      return;
    }

    onRemoveAsset();
    setAckAssetRemoveDialog(false);
  }, [onRemoveAsset]);

  const handleResetChanges = useCallback(() => {
    setSubscription(asset.data.properties.subscription);
  }, [asset.data.properties.subscription]);

  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleModifyAssetSeats = useCallback(
    (val: number) => () => {
      if (!subscription) {
        return;
      }

      if (!subscription.seats) {
        return;
      }

      let { maximumNumberOfSeats, numberOfSeats } = subscription.seats;
      const { licensedNumberOfSeats } = subscription.seats;
      if (asset.data.properties.subscription.plan.isCommitmentPlan && asset.data.properties.subscription.seats) {
        numberOfSeats += val;
        if (numberOfSeats < asset.data.properties.subscription.seats.numberOfSeats) {
          numberOfSeats = asset.data.properties.subscription.seats.numberOfSeats;
        }
        setSubscription((prevSubscription) => {
          if (!prevSubscription) {
            return prevSubscription;
          }
          return {
            ...prevSubscription,
            seats: {
              maximumNumberOfSeats,
              licensedNumberOfSeats,
              numberOfSeats,
            },
          };
        });

        return;
      }

      maximumNumberOfSeats += val;
      if (maximumNumberOfSeats < licensedNumberOfSeats) {
        maximumNumberOfSeats = licensedNumberOfSeats;
      }

      if (maximumNumberOfSeats <= 0) {
        maximumNumberOfSeats = 0;
      }

      setSubscription((prevSubscription) => {
        if (!prevSubscription) {
          return prevSubscription;
        }

        return {
          ...prevSubscription,
          seats: {
            licensedNumberOfSeats,
            numberOfSeats,
            maximumNumberOfSeats,
          },
        };
      });
    },
    [asset.data.properties.subscription.plan.isCommitmentPlan, asset.data.properties.subscription.seats, subscription]
  );

  const handleSubscriptionChangeSeats = useCallback(
    (currentMaxSeats, maxSeats) => () => {
      if (!catalogItem || !subscription || !payment) {
        return;
      }
      /*
      PLAN: 13/12/2022 | https://doitintl.atlassian.net/browse/CMP-6685
        Move this to a utils file and make it a prop for LicenseChangeDialog when the table is ready and
        makes use of LicenseChangeDialog.
     */
      setAckSubscriptionChangeSeatsDialog(false);
      setIsSubmitting(true);

      const quantity = maxSeats - currentMaxSeats;
      const { amount, currency } = getTotal({
        asset,
        quantity,
        planName,
        payment,
        catalogItem,
        contract,
      });
      const total = formatCurrency(amount, currency);

      api
        .post(
          `/licenses/gsuite` +
            `/customers/${asset.data.properties.customerId}` +
            `/subscriptions/${asset.data.properties.subscription.subscriptionId}/changeSeats`,
          {
            reseller: asset.data.properties.reseller,
            seats: subscription.seats,
            total,
            payment: catalogItem.data.payment,
          }
        )
        .then(() => {
          mixpanel.track(`assets.gsuite.${quantity > 0 ? "increase" : "decrease"}`, {
            reseller: asset.data.properties.reseller,
            quantity,
            sku: catalogItem.data.skuId,
            domain: asset.data.properties.customerDomain,
            subscriptionId: asset.data.properties.subscription.subscriptionId,
          });

          showSnackbar({
            message: "Order completed successfully",
            variant: "success",
            autoHideDuration: 20000,
            action: [
              <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
                <CloseIcon />
              </IconButton>,
            ],
          });
        })
        .catch((error) => {
          consoleErrorWithSentry(error);
          showSnackbar({
            message: "Order failed",
            variant: "error",
            autoHideDuration: 20000,
            action: [
              <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
                <CloseIcon />
              </IconButton>,
            ],
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [payment, planName, api, asset, catalogItem, contract, hideSnackbar, showSnackbar, subscription]
  );

  const getSubscriptionTitle = (asset: AnyAsset<GSuiteAssetModel>) => {
    const suspendedText = asset.data.properties.subscription.status === "SUSPENDED" ? "(SUSPENDED) " : "";

    return `${suspendedText}${asset.data.properties.subscription.skuName}`;
  };

  let usedSeats = 0;
  let maxSeats = 0;
  let currentMaxSeats = 0;

  if (!subscription) {
    return null;
  }

  if (asset.data.properties.subscription.plan.isCommitmentPlan) {
    usedSeats = asset.data.properties.subscription.seats?.licensedNumberOfSeats ?? 0;
    currentMaxSeats = asset.data.properties.subscription.seats?.numberOfSeats ?? 0;
    maxSeats = subscription.seats?.numberOfSeats ?? 0;
  } else {
    usedSeats = asset.data.properties.subscription.seats?.licensedNumberOfSeats ?? 0;
    currentMaxSeats = asset.data.properties.subscription.seats?.maximumNumberOfSeats ?? 0;
    maxSeats = subscription.seats?.maximumNumberOfSeats ?? 0;
  }

  const hasPendingChanges = maxSeats !== currentMaxSeats;
  let removeTooltip = "Remove license";
  let removeDisabled = false;
  if (
    (asset.data.properties.subscription.plan.isCommitmentPlan ||
      get(asset.data, "properties.settings.plan.isCommitmentPlan", false)) &&
    maxSeats <= currentMaxSeats
  ) {
    removeDisabled = true;
    removeTooltip = "Cannot remove seats on a commitment plan";
  } else if (maxSeats <= usedSeats) {
    removeDisabled = true;
    removeTooltip = "Cannot remove used seats";
  } else if (maxSeats <= 0) {
    removeDisabled = true;
    removeTooltip = "Cannot remove seats below 0";
  }

  const planNameText = transformWorkspacePlanName(asset.data.properties.subscription.plan.planName);
  let expiration: string | undefined;
  let renewal: string | undefined;
  if (
    asset.data.properties.subscription.plan.isCommitmentPlan &&
    asset.data.properties.subscription.plan.commitmentInterval
  ) {
    expiration = DateTime.fromMillis(asset.data.properties.subscription.plan.commitmentInterval.endTime, {
      zone: "utc",
    })
      .startOf("day")
      .toLocaleString(DateTime.DATE_FULL);
    renewal = getRenewalTypeLabel(asset.data.properties.subscription.renewalSettings?.renewalType);
  } else {
    expiration = "Never";
    renewal = "Auto Renew";
  }

  let expirationNode = (
    <Typography variant="subtitle2" className={classes.typography}>
      {expiration}
    </Typography>
  );

  let title = (
    <Typography variant="button" className={classes.typography}>
      {getSubscriptionTitle(asset)} {planNameText}
    </Typography>
  );

  if (asset.data.properties.settings?.plan) {
    if (isDoitEmployee) {
      title = (
        <>
          <Typography variant="button" className={classes.typography}>
            {getSubscriptionTitle(asset)}
          </Typography>{" "}
          <Typography
            variant="button"
            className={classNames(classes.typography, {
              [classes.overrode]: planName !== asset.data.properties.settings.plan.planName,
            })}
          >
            {asset.data.properties.settings.plan.planName}
          </Typography>
        </>
      );

      expirationNode = (
        <Typography variant="subtitle2" className={classNames(classes.typography, classes.overrode)}>
          {asset.data.properties.settings.plan.isCommitmentPlan
            ? DateTime.fromMillis(asset.data.properties.settings.plan.commitmentInterval.endTime)
                .toUTC()
                .startOf("day")
                .toLocaleString(DateTime.DATE_FULL)
            : "Never"}
        </Typography>
      );
    } else {
      title = (
        <>
          <Typography variant="button" className={classes.typography}>
            {getSubscriptionTitle(asset)}
          </Typography>{" "}
          <Typography variant="button" className={classes.typography}>
            {asset.data.properties.settings.plan.planName}
          </Typography>
        </>
      );

      if (asset.data.properties.settings.plan.isCommitmentPlan) {
        expirationNode = (
          <Typography variant="subtitle2" className={classes.typography}>
            {DateTime.fromMillis(asset.data.properties.settings.plan.commitmentInterval.endTime)
              .toUTC()
              .startOf("day")
              .toLocaleString(DateTime.DATE_FULL)}
          </Typography>
        );
      } else {
        expirationNode = (
          <Typography variant="subtitle2" className={classes.typography}>
            Never
          </Typography>
        );
      }
    }
  }

  const skuId = asset.data.properties.subscription.skuId;
  const isGVoice = sku.isVoice(skuId);
  const isChromeEnt = skuId === sku.GoogleChromeEnterprise;
  const isSeatBasedSKU = ![sku.CloudIdentityFree, sku.GoogleMeetGlobalDialing].includes(skuId);
  const AssetLogo = sku.isDrive(skuId) ? GoogleDriveIcon : GoogleWorkspaceIcon;

  const menuItems = [
    isDoitEmployee && (
      <MenuItem key="reseller" disabled divider>
        {GetResellerLabel(asset.data.properties.reseller)}
      </MenuItem>
    ),
    (isDoitEmployee || userRoles.billingProfilesAdmin) && !widget && (
      <MenuItem
        key="assign"
        onClick={onAssignToEntity}
        data-cy={`assign-entity-g-suite-${asset.data.properties.subscription.subscriptionId}`}
      >
        Billing Profile Assignment
      </MenuItem>
    ),
    userRoles.invoicesViewer && onAddTag && (
      <MenuItem
        key="tag"
        onClick={onAddTag}
        data-cy={`add-tag-g-suite-${asset.data.properties.subscription.subscriptionId}`}
      >
        Tag Asset
      </MenuItem>
    ),
    userRoles.assetsManager && showChangeSeats && !isChromeEnt && (
      <MenuItem
        key="auto-increase"
        onClick={() => {
          setAutomationDialog(true);
        }}
      >
        Configure Auto-Increase
      </MenuItem>
    ),
    isGVoice && userRoles.assetsManager && !widget && (
      <MenuItem
        key="change-g-voice"
        onClick={() => {
          setChangeGVoiceDialog(true);
        }}
      >
        Change Google Voice Edition
      </MenuItem>
    ),
    isDoitEmployee && <Divider key="divider" />,
    isDoitEmployee && (
      <MenuItem
        key="settings"
        onClick={() => {
          setSettingsDialog(true);
        }}
      >
        Asset Settings
      </MenuItem>
    ),
    isDoitOwner && !widget && (
      <MenuItem
        key="remove"
        onClick={() => {
          setAckAssetRemoveDialog(true);
        }}
      >
        Remove Asset
      </MenuItem>
    ),
  ];

  if (catalogLoading) {
    return null;
  }

  return (
    <>
      <div ref={domRef}>
        <Card
          className={classNames(classes.card, { [classes.highlight]: highlight })}
          data-cy={`card-g-suite-${asset.data.properties.subscription.subscriptionId}`}
        >
          <WidgetCardHeader
            avatar={<Avatar alt="Google Workspace" src={AssetLogo} />}
            action={
              menuItems.length > 0 &&
              userRoles.assetsManager && (
                <>
                  <IconButton
                    aria-owns={anchorEl ? "settings-menu" : undefined}
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    size="large"
                    data-cy={`menu-g-suite-${asset.data.properties.subscription.subscriptionId}`}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="settings-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    role="button"
                    tabIndex={0}
                    onClose={handleMenuClose}
                    onClick={handleMenuClose}
                  >
                    {menuItems}
                  </Menu>
                </>
              )
            }
            title={title}
            subheader={
              <div style={{ height: 25 }}>
                {asset.data.properties.customerDomain}
                {asset.data.tags?.map((tag) => (
                  <Chip style={{ marginRight: 4, marginLeft: 4 }} size="small" key={tag} label={tag} />
                ))}
              </div>
            }
          />
          <CardContent sx={{ height: widgetHeight - CARD_HEADER_HEIGHT - 64, pb: 0 }}>
            <div className={classes.infoDiv}>
              {isSeatBasedSKU && (!widget || !usedSeats) && (
                <div>
                  <Typography variant="body2" className={classes.typography}>
                    Quantity:{" "}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.typography}>
                    {!isChromeEnt && (
                      <>
                        {usedSeats}
                        {" / "}
                      </>
                    )}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classNames(classes.typography, {
                      [classes.increase]: currentMaxSeats < maxSeats,
                      [classes.decrease]: currentMaxSeats > maxSeats,
                    })}
                  >
                    {maxSeats}
                  </Typography>
                  {hasPendingChanges && (
                    <Typography variant="subtitle2" className={classes.typography}>
                      {` (${currentMaxSeats < maxSeats ? "+" : ""}${maxSeats - currentMaxSeats})`}
                    </Typography>
                  )}
                </div>
              )}
              <div>
                <Typography variant="body2" className={classes.typography}>
                  Expiration:{" "}
                </Typography>
                {expirationNode}
              </div>
              <div>
                <Typography variant="body2" className={classes.typography}>
                  Renewal:{" "}
                </Typography>
                <Typography variant="subtitle2" className={classes.typography}>
                  {renewal}
                </Typography>
              </div>
            </div>
            {isSeatBasedSKU && widget && usedSeats !== 0 && (
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Typography variant="body2" className={classes.typography}>
                      Used Seats:{" "}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.typography}>
                      {!isChromeEnt && <>{usedSeats}</>}
                    </Typography>
                  </div>
                  <div style={{ justifyContent: "flex-end" }}>
                    <Typography variant="body2" className={classes.typography}>
                      Total Seats:{" "}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classNames(classes.typography, {
                        [classes.increase]: currentMaxSeats < maxSeats,
                        [classes.decrease]: currentMaxSeats > maxSeats,
                      })}
                    >
                      {maxSeats}
                    </Typography>
                    {hasPendingChanges && (
                      <Typography variant="subtitle2" className={classes.typography}>
                        {` (${currentMaxSeats < maxSeats ? "+" : ""}${maxSeats - currentMaxSeats})`}
                      </Typography>
                    )}
                  </div>
                </div>

                <BorderLinearProgress variant="determinate" color="primary" value={(usedSeats * 100) / maxSeats} />
              </div>
            )}
          </CardContent>

          <CardActions className={classes.actions} disableSpacing>
            {userRoles.assetsManager && (
              <Grid container justifyContent="space-between" wrap="nowrap">
                {showChangeSeats && (
                  <Grid container item>
                    <Tooltip title={removeTooltip}>
                      <Grid item>
                        <IconButton
                          onMouseDown={handleModifyAssetSeats(-1)}
                          disabled={removeDisabled || isSubmitting}
                          className={classes.startAction}
                          size="large"
                        >
                          <RemoveIcon
                            color="inherit"
                            className={classNames({
                              [classes.decrease]: !(removeDisabled || isSubmitting),
                            })}
                            fontSize="small"
                          />
                        </IconButton>
                      </Grid>
                    </Tooltip>
                    <Tooltip title="Add license">
                      <Grid item>
                        <IconButton
                          onMouseDown={handleModifyAssetSeats(1)}
                          className={classes.startAction}
                          disabled={isSubmitting}
                          size="large"
                        >
                          <AddIcon
                            color="inherit"
                            className={classNames({
                              [classes.increase]: !isSubmitting,
                            })}
                            fontSize="small"
                          />
                        </IconButton>
                      </Grid>
                    </Tooltip>
                  </Grid>
                )}

                <Grid container item justifyContent="flex-end" alignItems="center">
                  {showChangeSeats && hasPendingChanges && (
                    <Fade in={hasPendingChanges}>
                      <Grid container justifyContent="flex-end" alignItems="center">
                        <Tooltip title="Reset changes">
                          <Grid item>
                            <IconButton
                              onClick={handleResetChanges}
                              disabled={isSubmitting}
                              className={classes.endAction}
                              size="large"
                            >
                              <ResetIcon color="inherit" fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Tooltip>

                        <Grid item>
                          <LoadingButton
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setAckSubscriptionChangeSeatsDialog(true);
                            }}
                            disabled={!hasPendingChanges || isSubmitting}
                            loading={isSubmitting}
                            className={classes.endAction}
                            mixpanelEventId="assets.gsuite-asset-card.apply"
                          >
                            APPLY
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Fade>
                  )}
                  <Grid item>{unassignedAction}</Grid>
                </Grid>
              </Grid>
            )}
          </CardActions>
        </Card>
      </div>

      {settingsDialog && (
        <AssetSettingsDialog
          onClose={() => {
            setSettingsDialog(false);
          }}
          entity={entity}
          asset={asset}
          catalogItem={catalogItem}
        />
      )}

      {isGVoice && changeGVoiceDialog && catalogItem && (
        <ChangeGVoiceDialog
          onClose={() => {
            setChangeGVoiceDialog(false);
          }}
          asset={asset}
          contract={contract}
          catalogItem={catalogItem}
        />
      )}

      {userRoles.assetsManager && showChangeSeats && !isChromeEnt && automationDialog && (
        <AutomationDialog
          onClose={() => {
            setAutomationDialog(false);
          }}
          asset={asset}
        />
      )}

      {ackSubscriptionChangeSeatsDialog && catalogItem && (
        <GSuiteLicenseChangeConfirm
          open={ackSubscriptionChangeSeatsDialog}
          onCancel={() => {
            setAckSubscriptionChangeSeatsDialog(false);
          }}
          onAccept={handleSubscriptionChangeSeats(currentMaxSeats, maxSeats)}
          currentMaxSeats={currentMaxSeats}
          maxSeats={maxSeats}
          asset={asset}
          contract={contract}
          catalogItem={catalogItem}
        />
      )}

      {isDoitOwner && ackAssetRemoveDialog && onRemoveAsset && (
        <RemoveDialog
          open={ackAssetRemoveDialog}
          onCancel={() => {
            setAckAssetRemoveDialog(false);
          }}
          onAccept={handleRemoveDialogOK}
          name={asset.data.properties.subscription.skuName}
        />
      )}
    </>
  );
};

export default GSuiteAssetCardContent;

export const GSuiteAssetCard = ({
  widgetId,
  fallbackComponent,
  isCustomizeMode,
  widgetHeight = 200,
}: WidgetItemWithWidgetIdProps) => {
  const data = useAssetDataProps(widgetId);

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  if (data === null) {
    return fallbackComponent;
  }

  return (
    <GSuiteAssetCardContent
      asset={data.asset as Asset<GSuiteAssetModel>}
      entity={data.entity}
      contract={data.contract}
      widget={true}
      isCustomizeMode={isCustomizeMode}
      widgetHeight={widgetHeight}
      fallbackComponent={fallbackComponent}
    />
  );
};
