import { TextField } from "@mui/material";

import { CustomerPicker } from "../../../Components/CustomerPicker/CustomerPicker";

const styles = {
  input: {
    maxWidth: 500,
  },
};

export type DomainAndLogoInputProps = {
  isDoitCustomer: boolean;
  isvDomainCustomerId: string;
  title: string;
  handleDomainChange: (value: string) => void;
  logoURL: string;
  handleLogoURLChange: (value: string) => void;
};

export const DomainInput = ({
  isDoitCustomer,
  isvDomainCustomerId,
  title,
  handleDomainChange,
  handleLogoURLChange,
}: DomainAndLogoInputProps) => {
  if (isDoitCustomer) {
    return (
      <CustomerPicker
        value={isvDomainCustomerId}
        onChange={(_, value) => {
          handleDomainChange(value?.primaryDomain || "");
          handleLogoURLChange(value?.logo || "");
        }}
        getOptionLabel={({ primaryDomain }) => primaryDomain}
        data-cy="isv-domain-autocomplete-textfield"
        TextFieldProps={{
          sx: styles.input,
          label: "ISV Domain",
          size: "small",
          required: true,
          variant: "outlined",
          InputLabelProps: { shrink: true },
        }}
        doOnChangeForDefaultValue={true}
      />
    );
  } else {
    return (
      <TextField
        label="ISV Domain"
        sx={styles.input}
        value={title}
        onChange={(e) => handleDomainChange(e.target.value)}
        data-cy="isv-non-customer-domain-textfield"
        required
      />
    );
  }
};
