import { type ForecastMode } from "@doitintl/cmp-models";
import { type Theme } from "@mui/material";

import { type ForecastItem } from "../../../ReportData";
import { type ChartSeriesRecord } from "../../useHighchartsRenderer";
import { analyticsToEChartsTypesMapping, type EChartType } from "../ECharts";
import getErrorBarSeries from "./getErrorBarSeries";

type Props = {
  type: EChartType;
  forecasts: ForecastItem[];
  series: ChartSeriesRecord[];
  forecastMode?: ForecastMode;
  chartSettings: Record<string, string | number | undefined | boolean | number[]>;
  theme: Theme;
};

const getTotalForecastBarStyle = (theme: Theme) =>
  theme.palette.mode === "light"
    ? { color: "rgba(0, 0, 0, 0.07)", borderColor: "rgba(0, 0, 0, 0.38)" }
    : { color: "#999", borderColor: "rgba(255, 255, 255)" };

export const getForecastSeries = ({ type, forecasts, forecastMode, chartSettings, series, theme }: Props) => {
  if (!forecasts?.length || type === "tree-map") {
    return [];
  }

  const chartType = analyticsToEChartsTypesMapping[type];

  if (forecastMode === "grouping") {
    const forecastByIdMap: Record<string, (number | null)[]> = {};

    forecasts?.forEach(({ value, id }) => {
      if (!id) {
        return;
      }
      if (!forecastByIdMap[id]) {
        forecastByIdMap[id] = [value];
      } else {
        forecastByIdMap[id].push(value);
      }
    });

    const forecastSeries: any[] = [];
    const idOrder = [...new Set(series.map((s) => s.name))];

    idOrder?.forEach((id) => {
      if (forecastByIdMap[id]) {
        forecastSeries.push({
          type: chartType,
          ...chartSettings,
          itemStyle: {
            opacity: 0.5,
            emphasis: {
              borderWidth: 4,
            },
          },
          data: forecastByIdMap[id],
          name: id,
        });
      }
    });
    if (type === "stacked-column" || type === "stacked-bar" || type === "stacked-area") {
      forecastSeries.reverse();
    }
    return forecastSeries;
  }

  if (!forecastMode || forecastMode === "totals") {
    const errorBarSeries = getErrorBarSeries(theme);
    const errorBarData: number[][] = [];
    const values: number[] = [];

    let forecastStartIndex: number | null = null;

    forecasts?.forEach((forecastRow, index) => {
      let value = forecastRow?.value;
      const { yhatLower, yhatUpper } = forecastRow || {};
      // in case of overlapping current period in stacked type of charts subtract value of actual data from forecast
      if (
        forecastStartIndex === null &&
        value !== null &&
        ["stacked-column", "stacked-bar", "stacked-area"].includes(type)
      ) {
        forecastStartIndex = index;
        // check for overlapped period of actual data and forecasting
        const currentPeriodActualData = series.find((s) => s.data[index] !== null && s.data[index] !== 0);
        if (currentPeriodActualData) {
          const currentPeriodSum = series.reduce((a, b) => a + b.data[index], 0);
          value -= currentPeriodSum;
        }
      }

      values.push(value);

      if (value) {
        errorBarData.push([index, yhatLower ?? 0, yhatUpper ?? 0]);
      }
    });

    const forecastSeries = [
      {
        type: chartType,
        ...chartSettings,
        itemStyle: getTotalForecastBarStyle(theme),
        data: values,
        name: "Total Forecast",
      },
    ] as any;

    if (type === "stacked-column" && errorBarData.length) {
      forecastSeries.push({ ...errorBarSeries, data: errorBarData } as any);
    }

    return forecastSeries;
  }

  return [];
};
