import { useCallback, useEffect, useMemo, useState } from "react";

import { Aggregator, type ForecastMode, Metric } from "@doitintl/cmp-models";
import { Alert, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

import { googleSheetsExportDialogText } from "../../../assets/texts";
import { reportExportTxt } from "../../../assets/texts/CloudAnalytics";
import useMountEffect from "../../../Components/hooks/useMountEffect";
import { CircularProgressLoader } from "../../../Components/Loader";
import LoadingButton from "../../../Components/LoadingButton";
import { Scopes, useGapiContext } from "../../../Context/GapiContext";
import { consoleErrorWithSentry } from "../../../utils";
import { convertSavingsTotalOverTotalValue, Labels, roundNumber } from "../utilities";
import type ReportData from "../ReportData";

const borderStyle = {
  style: "DASHED",
};

const forecastCellFormat: gapi.client.sheets.CellFormat = {
  borders: {
    left: borderStyle,
    right: borderStyle,
    top: borderStyle,
    bottom: borderStyle,
  },
};

type Props = {
  onClose?: () => void;
  isDialogOpen?: boolean;
  isDialog?: boolean;
  aggregator?: Aggregator;
  metric?: Metric;
  data: ReportData;
};

const GoogleSheetsDialog = ({
  data,
  isDialog,
  isDialogOpen,
  aggregator = Aggregator.TOTAL,
  metric = Metric.COST,
  onClose,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [lastExportFailedReason, setLastExportFailedReason] = useState<string>();
  const { getToken, isAuthenticated } = useGapiContext();

  useMountEffect(() => {
    getToken(Scopes.DriveFile);
  });

  useEffect(() => {
    setLoading(false);
  }, [isDialogOpen]);

  const setHeader = useCallback(
    (result: gapi.client.sheets.RowData[], rowKeys: string[][], colKeys: string[][]) => {
      const header: gapi.client.sheets.CellData[] = [
        {
          userEnteredValue: {
            stringValue: reportExportTxt.VALUE,
          },
        },
      ];

      for (const row of data.rows) {
        header.push({
          userEnteredValue: {
            stringValue: row.label,
          },
        });
      }

      if (colKeys.length === 1 && colKeys[0].length === 0) {
        header.push({
          userEnteredValue: {
            stringValue: "Total",
          },
        });
      } else {
        for (const colKey of colKeys) {
          header.push({
            userEnteredValue: {
              stringValue: colKey.join("-"),
            },
          });
        }
      }

      // When no grouping or filters are selected
      result.push({
        values: header,
      });
    },
    [data.rows]
  );

  const setRows = useCallback(
    (result: gapi.client.sheets.RowData[], rowKeys: string[][], colKeys: string[][], forecastMode?: ForecastMode) => {
      for (const rowKey of rowKeys) {
        const row: gapi.client.sheets.CellData[] = [
          {
            userEnteredValue: {
              stringValue: reportExportTxt.ACTUAL,
            },
          },
        ];
        for (const k of rowKey) {
          row.push({
            userEnteredValue: {
              stringValue: k,
            },
          });
        }
        for (const colKey of colKeys) {
          let forecastValue: number | null = null;
          if (forecastMode === "grouping" && !!data.forecasts) {
            const id = rowKey.join(";");
            const date = colKey.join("-");
            forecastValue = data.forecasts.find((f) => f.id === id && f.date === date)?.value ?? null;
          }

          let value = data.getAggregator(rowKey, colKey).value();
          if (forecastValue !== null) {
            row.push({
              userEnteredValue: {
                numberValue: forecastValue,
              },
              userEnteredFormat: forecastCellFormat,
            });
          } else if (value !== null) {
            if (aggregator === Aggregator.TOTAL_OVER_TOTAL && metric === Metric.SAVINGS) {
              value = convertSavingsTotalOverTotalValue(value);
            }
            row.push({
              userEnteredValue: {
                numberValue: roundNumber(value),
              },
            });
          } else {
            row.push({
              userEnteredValue: {
                stringValue: "",
              },
            });
          }
        }
        result.push({
          values: row,
        });
      }
    },
    [aggregator, data, metric]
  );

  const setTotalModeForecastRow = useCallback(
    (result: gapi.client.sheets.RowData[]) => {
      if (data?.forecasts) {
        const forecastRow: gapi.client.sheets.CellData[] = [
          {
            userEnteredValue: {
              stringValue: Labels.FORECAST,
            },
          },
        ];
        if (data.rows.length > 0) {
          for (let i = 0; i < data.rows.length; i++) {
            forecastRow.push({
              userEnteredValue: {
                stringValue: "",
              },
            });
          }
        }
        data.forecasts.forEach((forecast) => {
          forecastRow.push({
            userEnteredValue: {
              numberValue: forecast.value,
            },
            userEnteredFormat: forecast.value !== null ? forecastCellFormat : undefined,
          });
        });
        result.push({
          values: forecastRow,
        });
      }
    },
    [data.forecasts, data.rows.length]
  );

  const getDataValues = useCallback((): gapi.client.sheets.RowData[] => {
    if (!data) {
      return [];
    }

    const result: gapi.client.sheets.RowData[] = [];
    const rowKeys = data.getRowKeys();
    const colKeys = data.getColKeys();

    if (rowKeys.length === 0) {
      rowKeys.push([]);
    }
    if (colKeys.length === 0) {
      colKeys.push([]);
    }

    const forecastMode = data.forecastSettings?.mode;

    setHeader(result, rowKeys, colKeys);
    setRows(result, rowKeys, colKeys, forecastMode);
    if (forecastMode === "totals") {
      setTotalModeForecastRow(result);
    }

    // Create Row label and values for forecast
    return result;
  }, [data, setTotalModeForecastRow, setHeader, setRows]);

  const handleExport = useCallback(async () => {
    setLoading(true);

    await getToken(Scopes.DriveFile);
    const value = getDataValues();

    const timestamp = DateTime.local().toISO({ includeOffset: false });
    const spreadsheetBody: gapi.client.sheets.Spreadsheet = {
      properties: {
        title: `DoiT Cloud Navigator report-${timestamp}`,
      },
      sheets: [
        {
          data: [
            {
              startRow: 0,
              startColumn: 0,
              rowData: value,
            },
          ],
        },
      ],
    };

    try {
      const response = await gapi.client.sheets.spreadsheets.create({}, spreadsheetBody);
      if (response.result.spreadsheetUrl) {
        setLastExportFailedReason(undefined);
        const win = window.open(response.result.spreadsheetUrl, "_blank");
        if (win !== null) {
          win.focus();
        }
      }
      onClose?.();
    } catch (err: any) {
      setLastExportFailedReason(err.result.error.message);
      consoleErrorWithSentry(err.result.error);
    } finally {
      setLoading(false);
    }
  }, [getDataValues, getToken, onClose]);

  const dialogData = useMemo(() => {
    if (isAuthenticated(Scopes.DriveFile)) {
      const saveContent = googleSheetsExportDialogText.DIALOG_SAVE;
      return {
        content: saveContent,
        actionButtonText: googleSheetsExportDialogText.DIALOG_EXPORT,
        actionCallback: handleExport,
      };
    }

    return {
      content: googleSheetsExportDialogText.SELECT_ACCOUNT,
      actionButtonText: googleSheetsExportDialogText.DIALOG_ALLOW,
      actionCallback: getToken,
    };
  }, [getToken, handleExport, isAuthenticated]);

  const alertUI = useCallback(
    ({ content, actionButtonText, actionCallback }) => {
      const getSeverity = () => {
        if (lastExportFailedReason === undefined) {
          return "info";
        }

        return lastExportFailedReason ? "success" : "error";
      };
      return (
        <Alert
          variant="standard"
          severity={getSeverity()}
          action={
            <>
              <LoadingButton
                color="inherit"
                size="small"
                variant="outlined"
                onClick={actionCallback}
                loading={loading}
                disabled={loading}
                mixpanelEventId={`analytics.renderer.google-sheets.${actionButtonText}`}
              >
                {actionButtonText}
              </LoadingButton>
            </>
          }
        >
          {content}
        </Alert>
      );
    },
    [lastExportFailedReason, loading]
  );

  const dialogUI = useCallback(
    ({ content, actionButtonText, actionCallback }) => (
      <Dialog open={isDialogOpen ?? false} onClose={onClose}>
        <DialogTitle>{googleSheetsExportDialogText.DIALOG_TITLE}</DialogTitle>
        <DialogContent>
          {!!lastExportFailedReason && (
            <Alert variant="standard" severity="error">
              {lastExportFailedReason}
            </Alert>
          )}
          <DialogContentText>
            <Typography component="span">{content}</Typography>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose} variant="text">
            {googleSheetsExportDialogText.DIALOG_CANCEL}
          </Button>
          <LoadingButton
            color="primary"
            onClick={actionCallback}
            disabled={loading}
            loading={loading}
            variant="contained"
            mixpanelEventId={`analytics.renderer.google-sheets.${actionButtonText}`}
          >
            {actionButtonText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    ),
    [isDialogOpen, lastExportFailedReason, loading, onClose]
  );

  if (!data) {
    return null;
  }

  if (!isAuthenticated(Scopes.DriveFile)) {
    return <CircularProgressLoader />;
  }

  return isDialog ? dialogUI(dialogData) : alertUI(dialogData);
};

export default GoogleSheetsDialog;
