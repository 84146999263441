import { useLocation } from "react-router-dom";
import { Button, Link, TableCell } from "@mui/material";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import { ThreeDotsMenu } from "../../../Components/ThreeDotsMenu";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { useEKSApi } from "../hooks";
import { type ClusterConfig } from "../types";
import { StatusBox } from "./StatusBox";

type SetOpenAwsSplitDialog = ({ region, open }: { region: string | null | undefined; open: boolean }) => void;

type SetOpenTierDialog = ({ open }: { open: boolean }) => void;

type Props = {
  row: ClusterConfig;
  onRemoveClusterClicked: (row: ClusterConfig) => void;
  onEditClusterClicked: (row: ClusterConfig) => void;
  awsOpenSplitDialog: SetOpenAwsSplitDialog;
  tierOpenDialog: SetOpenTierDialog;
};

export const ClusterRow = ({ row, onRemoveClusterClicked, awsOpenSplitDialog, tierOpenDialog }: Props) => {
  const location = useLocation();
  const eksApi = useEKSApi();
  const isEntitled = useIsFeatureEntitled("lens:eks-new");

  const operationLabel = () => {
    if (row.status === "pending-update") {
      return "Update";
    } else if (row.status === "deleted") {
      return "Fix";
    } else if (row.awsSplitEnabled || row.isEc2Cluster) {
      return "Connect";
    } else {
      return "";
    }
  };

  const handleOpenAwsAwsSplitDialog = () => {
    awsOpenSplitDialog({ open: true, region: row.region });
  };

  const handleOpenTierDialog = () => {
    tierOpenDialog({ open: true });
  };

  const awsSplitStatus = row.awsSplitEnabled ? (
    "Enabled"
  ) : row.isEc2Cluster ? (
    "N/A"
  ) : (
    <span>
      Disabled
      <Link target="_blank" underline="none" paddingLeft="10px">
        <Button
          variant="text"
          color="primary"
          size="small"
          style={{ marginBottom: "3px" }}
          onClick={handleOpenAwsAwsSplitDialog}
        >
          How to enable
        </Button>
      </Link>
    </span>
  );

  const optionsList = [
    row.status !== "not-connected" && {
      key: "3",
      label: "Download yaml",
      action: () => eksApi.downloadDeploymentFile(row),
    },
    row.isManual && {
      key: "2",
      label: "Remove cluster",
      action: () => Promise.resolve(onRemoveClusterClicked(row)),
    },
  ].filter(Boolean) as { key: string; label: string; action: () => void }[];

  const { clusterConfigRef: _clusterConfigRef, deploymentRef: _deploymentRef } = row;

  return (
    <CellsWrapper>
      <TableCell>{row.clusterName}</TableCell>
      <TableCell>{row.region}</TableCell>
      <TableCell>{row.accountId}</TableCell>
      <TableCell>{awsSplitStatus}</TableCell>
      <TableCell>
        <StatusBox
          cloudFormationStatus={row.status}
          bucketStatus={row?.bucketStatus}
          bucketLastMetricsData={row?.bucketLastMetricsData}
        />
        {row.status !== "active" && isEntitled && (
          <Link style={{ textDecoration: "none" }} href={`${location.pathname}/${row.id}`}>
            <Button variant="text" color="primary" size="small" style={{ marginLeft: "8px" }}>
              {operationLabel()}
            </Button>
          </Link>
        )}
        {row.status !== "active" && !isEntitled && (
          <Link style={{ textDecoration: "none" }} onClick={handleOpenTierDialog}>
            <Button variant="text" color="primary" size="small">
              Upgrade tier
            </Button>
          </Link>
        )}
      </TableCell>
      <TableCell padding="none">
        <ThreeDotsMenu closeAfterSelect options={optionsList} disabled={optionsList.length === 0} />
      </TableCell>
    </CellsWrapper>
  );
};
