import { useEffect, useState } from "react";

import { unwrapModel } from "@doitintl/cloudflow-commons";
import {
  type ApiServiceModelDescriptor,
  CloudflowEngine,
  type OperationPointer,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { getCollection, type WithFirebaseModel } from "@doitintl/models-firestore";

import { getOperationByPointer } from "./useGetOperationById";
import { usePromiseStateSetter } from "./usePromiseStateSetter";

export async function getModelById(
  vendor: string,
  service: string,
  version: string,
  modelId: string
): Promise<WithFirebaseModel<ApiServiceModelDescriptor>> {
  const modelURI = `cloudflowEngine/integrations/apis/${vendor}/services/${service}/versions/${version}/models/${modelId}`;

  const cachedModelData = sessionStorage.getItem(modelURI);
  if (cachedModelData !== null) {
    return JSON.parse(cachedModelData);
  }

  const modelData = (
    await getCollection(CloudflowEngine)
      .doc("integrations")
      .collection("apis")
      .doc(vendor)
      .collection("services")
      .doc(service)
      .collection("versions")
      .doc(version)
      .collection("models")
      .doc(modelId)
      .get()
  ).asModelData();
  if (modelData === undefined) {
    throw new Error(`Unable to fetch model ${modelURI}`);
  }
  sessionStorage.setItem(modelURI, JSON.stringify(modelData));
  return modelData;
}

export type ModelData = {
  model: WithFirebaseModel<UnwrappedApiServiceModelDescriptor> | null;
  modelId: string | null;
};

export function useUnwrappedApiActionModel(
  operationPointer: OperationPointer | null,
  modelId: string | undefined
): ModelData {
  const [modelData, setModelData] = useState<ModelData>({ model: null, modelId: null });
  const setPromiseState = usePromiseStateSetter();

  useEffect(() => {
    if (!modelId || !operationPointer) {
      return setModelData({
        model: null,
        modelId: null,
      });
    }
    const getReferencedModelById = (modelId: string) =>
      getModelById.bind(null, operationPointer.provider, operationPointer.service, operationPointer.version)(modelId);
    return setPromiseState(
      getReferencedModelById(modelId).then((model) => unwrapModel(getReferencedModelById, model)),
      (model) => setModelData({ model, modelId })
    );
  }, [modelId, operationPointer, setPromiseState]);

  return modelData;
}

export const getOutputModelByOperationPointer = async (operationPointer: OperationPointer) => {
  const operationData = await getOperationByPointer(operationPointer);

  if (!operationData.outputModel) {
    throw new Error(`Output model not defined for operation with ID: ${operationPointer.id}`);
  }

  const model = await getModelById(
    operationPointer.provider,
    operationPointer.service,
    operationPointer.version,
    operationData.outputModel
  );

  return unwrapModel(
    async (modelId: string) =>
      getModelById(operationPointer.provider, operationPointer.service, operationPointer.version, modelId),
    model
  );
};
