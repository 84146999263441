import {
  Aggregator,
  type DatahubMetrics,
  type ExtendedMetric,
  type ForecastSettings,
  Renderer,
  type ReportConfig,
  Sort,
  TimeInterval,
} from "@doitintl/cmp-models";
import isEqual from "lodash/isEqual";

import { type CustomTimeRange, type MetricWSnap } from "../../../types";
import { showValuesPersistKey } from "../Context";
import { getDefaultForecastSettings } from "../report/config/ForecastSettings/utils";
import { type TimeRangeOption } from "../utilities";

export type ReportConfigStoreType = Omit<ReportConfig, "calculatedMetric" | "customTimeRange"> & {
  name: string;
  description: string;
  calculatedMetric: MetricWSnap | null;
  calcMetrics: MetricWSnap[];
  extendedMetrics: ExtendedMetric[];
  forecastSettings?: ForecastSettings;
  datahubMetrics: DatahubMetrics[];
  customTimeRange: CustomTimeRange | null;
  excludeSelectMetadataIds: Set<string>;
  timeRangeOption: TimeRangeOption;
};

export enum ReportConfigKind {
  UPDATE_TIME_INTERVAL = "update_time_interval",
  REMOVE_FEATURES_FORECAST = "remove_features_forecast",
  ON_COMPARATIVE_MODE = "on_comparative_mode",
  TOGGLE_LOG_SCALE = "toggle_log_scale",
  SET_SHOW_VALUES_IN_FILTER_RESULTS = "set_values_in_filter_results",
}

export type ReportConfigAction = {
  type?: ReportConfigKind;
  payload?: Partial<ReportConfigStoreType>;
};

export const reportConfigReducer = (
  state: ReportConfigStoreType,
  action: ReportConfigAction
): ReportConfigStoreType => {
  const { payload } = action;

  switch (action.type) {
    case ReportConfigKind.UPDATE_TIME_INTERVAL: {
      if (payload?.timeInterval === TimeInterval.YEAR) {
        return { ...state, ...payload, forecastSettings: undefined };
      }

      if (state.forecastSettings && payload?.timeInterval) {
        const forecastSettings = getDefaultForecastSettings(
          state?.timeRangeOption,
          payload.timeInterval,
          state.forecastSettings.mode
        );
        return { ...state, ...payload, forecastSettings };
      }

      return { ...state, ...payload };
    }
    case ReportConfigKind.REMOVE_FEATURES_FORECAST: {
      return { ...state, forecastSettings: undefined };
    }
    case ReportConfigKind.ON_COMPARATIVE_MODE: {
      let newState: Partial<ReportConfigStoreType> = { aggregator: Aggregator.TOTAL };
      if (state.renderer === Renderer.TREEMAP) {
        newState = { renderer: Renderer.TABLE };
      }

      newState.colOrder = Sort.A_TO_Z;
      return { ...state, ...newState };
    }
    case ReportConfigKind.TOGGLE_LOG_SCALE:
      return { ...state, logScale: !state.logScale };
    case ReportConfigKind.SET_SHOW_VALUES_IN_FILTER_RESULTS: {
      const checked = payload?.showValuesInFilterSearchResults ?? false;
      localStorage.setItem(showValuesPersistKey, checked.toString());
      return { ...state, ...payload };
    }
    default: {
      if (payload?.timeRangeOption && state.forecastSettings) {
        const forecastSettings = getDefaultForecastSettings(
          payload?.timeRangeOption,
          state.timeInterval,
          state.forecastSettings.mode
        );
        return { ...state, ...payload, forecastSettings };
      }

      const newVal = { ...state, ...payload };
      if (isEqual(newVal, state)) {
        return state;
      }
      return newVal;
    }
  }
};
