import {
  type CloudFlowNodeType,
  type ReferencedNodeValue,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";

import { cloudflowTexts } from "../../../../assets/texts";
import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";
import { PlaceholderParam } from "../ApiActionParametersForm/parameters/PlaceholderParam";
import { FieldChip } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldChip";
import { ReferencedFieldStandalone } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldStandalone";
import { getOutputModelByOperationPointer } from "../Common/hooks/useUnwrappedApiActionModel";
import { type CloudFlowNode } from "../utils/nodeTransformUtils";
import { type FilterGroup } from "./types";

type FieldReferenceFormProps = {
  fieldReference?: ReferencedNodeValue;
  selectedNode: CloudFlowNode<CloudFlowNodeType.ACTION> | undefined;
  actionNodes: CloudFlowNode<CloudFlowNodeType.ACTION>[];
  setOutputModel: (model: UnwrappedApiServiceModelDescriptor) => void;
  setSelectedNode: (node: CloudFlowNode<CloudFlowNodeType.ACTION>) => void;
  setFieldReference: (field: ReferencedNodeValue | undefined) => void;
  setFilterGroups: (groups: FilterGroup[] | ((prevGroups: FilterGroup[]) => FilterGroup[])) => void;
  outputModel?: UnwrappedApiServiceModelDescriptor;
  filterGroups: FilterGroup[];
  confirmSwitchDataSource: (
    onConfirm: (() => Promise<void>) | undefined,
    onClose: (() => Promise<void>) | undefined
  ) => Promise<void>;
};

const FieldReferenceForm = ({
  fieldReference,
  setFieldReference,
  setFilterGroups,
  selectedNode,
  setSelectedNode,
  outputModel,
  actionNodes,
  setOutputModel,
  confirmSwitchDataSource,
  filterGroups,
}: FieldReferenceFormProps) => {
  const showErrorSnackbar = useErrorSnackbar();

  const handleDataSourceChange = async (event: SelectChangeEvent<string>) => {
    const nodeId = event.target.value;
    const node = actionNodes.find((node) => node.id === nodeId);
    if (!node) return;

    const onConfirm = async () => {
      setFieldReference({ referencedField: [], referencedNodeId: node.id });
      setSelectedNode(node);
      setFilterGroups([]);

      try {
        const model = await getOutputModelByOperationPointer(node.data.parameters.operation);
        setOutputModel(model);
      } catch (error) {
        consoleErrorWithSentry(error);
        const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
        showErrorSnackbar(`Error: ${errorMessage}`);
      }
    };

    if (!selectedNode || (fieldReference?.referencedField?.length === 0 && filterGroups.length === 0)) {
      await onConfirm();
    } else {
      await confirmSwitchDataSource(onConfirm, undefined);
    }
  };
  return (
    <Stack mb={1}>
      <Stack mb={2}>
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
          {cloudflowTexts.SELECT_DATA_SOURCE}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {cloudflowTexts.PICK_STEP_TO_FILTER}
        </Typography>
      </Stack>
      <Stack mb={3}>
        <FormControl sx={{ minWidth: 300 }} required>
          <InputLabel size="small" id="dataSourceLabel">
            {cloudflowTexts.DATA_SOURCES}
          </InputLabel>

          <Select
            input={<OutlinedInput size={"small"} label={cloudflowTexts.DATA_SOURCES} />}
            labelId="dataSourceLabel"
            required
            value={selectedNode?.data.name ?? ""}
            onChange={handleDataSourceChange}
            renderValue={() => <>{selectedNode && <FieldChip tokens={[selectedNode.data.name]} />}</>}
          >
            {actionNodes.map((node) => (
              <MenuItem key={node.id} value={node.id}>
                {node.data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack mb={2}>
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
          {cloudflowTexts.SELECT_FIELD_TO_FILTER}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {cloudflowTexts.FROM_CHOSEN_STEP}
        </Typography>
      </Stack>
      {!selectedNode || !outputModel ? (
        <PlaceholderParam
          label={cloudflowTexts.REFERENCED_FIELD}
          required
          placeholderTooltip={cloudflowTexts.SELECT_VALUE_FROM_DATA_SOURCE}
        />
      ) : (
        <ReferencedFieldStandalone
          value={fieldReference}
          name="fieldReference"
          label={cloudflowTexts.REFERENCED_FIELD}
          onChange={(event: { target: { name: string; value: any } }) => {
            setFieldReference(event.target.value);
          }}
          onBlur={() => {}}
          required={true}
          helperText={""}
          error={false}
          referenceableNodes={[{ id: selectedNode.id, name: selectedNode.data.name, outputModel }]}
        />
      )}
    </Stack>
  );
};

export default FieldReferenceForm;
