import { useEffect, useMemo, useState } from "react";

import { type PerkPlatform, type PerkSolutionType, type PerkType } from "@doitintl/cmp-models";
import { Box, FormControlLabel, FormLabel, Stack, Switch } from "@mui/material";

import { useAuthContext } from "../../../Context/AuthContext";
import { type PerksWData } from "../../../types";
import { useFullScreen } from "../../../utils/dialog";
import { groupPerks } from "../helpers";
import { useBuildMobileFilters, useHandleCheckboxChange } from "../hooks";
import { perkTypeDefaultFilter, platformDefaultFilter, solutionDefaultFilter, styles } from "./consts";
import FilterCheckboxes from "./FilterCheckboxes";
import { PerkFilterMobile } from "./PerkFilterMobile";

type PerkFilterProps = {
  pageId: string;
  perks: PerksWData[];
  setFilteredPerks: (perks: PerksWData[]) => void;
};

export const PerkFilter = ({ pageId, perks, setFilteredPerks }: PerkFilterProps) => {
  const { fullScreen: isViewLessThanLg } = useFullScreen("lg");
  const { isDoitEmployee } = useAuthContext();
  const [platformFilter, setPlatformFilter] = useState<Record<PerkPlatform, boolean>>(platformDefaultFilter);
  const [solutionFilter, setSolutionFilter] = useState<Record<PerkSolutionType, boolean>>(solutionDefaultFilter);
  const [perkTypeFilter, setPerkTypeFilter] = useState<Record<PerkType, boolean>>(perkTypeDefaultFilter);
  const [hideDrafts, setHideDrafts] = useState<boolean>(true);

  const groupedPerks = useMemo(() => groupPerks(perks), [perks]);

  const buildMobileFilters = useBuildMobileFilters(groupedPerks, pageId);

  useEffect(() => {
    // reset filters when pageId changes
    // REMOVE this when persistence is introduced
    setPlatformFilter(platformDefaultFilter);
    setSolutionFilter(solutionDefaultFilter);
    setPerkTypeFilter(perkTypeDefaultFilter);
  }, [pageId]);

  useEffect(() => {
    // if no filters selected, return everything
    if (!Object.keys(perkTypeFilter).length && !Object.keys(platformFilter).length) {
      setFilteredPerks(perks);
      return;
    }

    const noTypesSelected = Object.keys(perkTypeFilter).every((key) => !perkTypeFilter[key]);
    const noCloudsSelected = Object.keys(platformFilter).every((key) => !platformFilter[key]);
    const noSolutionsSelected = Object.keys(solutionFilter).every((key) => !solutionFilter[key]);

    const foundPerks = perks.filter((p) => {
      if (hideDrafts && !p.data.fields.active) {
        return false;
      }

      const cloudSelected = p.data.fields.platform.some((elem) => platformFilter[elem]);
      const typeSelected = perkTypeFilter[p.data.fields.type];
      const solutionSelected = p.data.fields.solutionType?.some((elem) => solutionFilter[elem]);

      return (
        (typeSelected || noTypesSelected) &&
        (cloudSelected || noCloudsSelected) &&
        (noSolutionsSelected || solutionSelected)
      );
    });

    setFilteredPerks(foundPerks);
  }, [hideDrafts, perkTypeFilter, platformFilter, perks, setFilteredPerks, solutionFilter]);

  const handleCheckboxChange = useHandleCheckboxChange({
    platformFilter,
    setPlatformFilter,
    solutionFilter,
    setSolutionFilter,
    perkTypeFilter,
    setPerkTypeFilter,
  });

  if (isViewLessThanLg) {
    // render mobile filters
    const filterOptions = buildMobileFilters();

    /*
     * The `Box` wrapping the filters keeps things vertically aligned when in mobile mode.
     * DO NOT REMOVE IT UNLESS MISALIGNMENT IS YOUR INTENTION.
     */
    return (
      <Box mb={3}>
        <PerkFilterMobile
          filterOptions={filterOptions}
          setFilteredPerks={setFilteredPerks}
          perks={perks}
          handleCheckboxChange={handleCheckboxChange}
          handleHideDraftsChange={setHideDrafts}
          filterSettings={{ setPlatformFilter, setSolutionFilter, setPerkTypeFilter }}
          textFieldSx={{ width: "100%" }}
        />
      </Box>
    );
  }

  return (
    <Stack direction="column" gap={4} data-testid="perk-filter-container">
      {pageId === "professional-services" && (
        <>
          <FilterCheckboxes<PerkType>
            entries={groupedPerks.type}
            filter={perkTypeFilter}
            filterTitle="Type"
            handleCheckboxChange={handleCheckboxChange}
          />
          <FilterCheckboxes<PerkPlatform>
            entries={groupedPerks.platform}
            filter={platformFilter}
            filterTitle="Cloud Marketplace"
            handleCheckboxChange={handleCheckboxChange}
          />
        </>
      )}
      {pageId === "isv-solutions" && (
        <>
          <FilterCheckboxes<PerkPlatform>
            entries={groupedPerks.platform}
            filter={platformFilter}
            filterTitle="Cloud Marketplace"
            handleCheckboxChange={handleCheckboxChange}
          />
          <FilterCheckboxes<PerkSolutionType>
            entries={groupedPerks.solution}
            filter={solutionFilter}
            filterTitle="Solution types"
            handleCheckboxChange={handleCheckboxChange}
          />
        </>
      )}
      {isDoitEmployee && (
        <>
          <FormLabel component="legend" sx={styles.filterTitle}>
            Draft services
            <FormControlLabel
              control={
                <Switch
                  checked={hideDrafts}
                  onChange={(e) => setHideDrafts(e.target.checked)}
                  data-cy="toggle-drafts"
                />
              }
              label="Hide drafts"
              sx={{ mt: 1 }}
            />
          </FormLabel>
        </>
      )}
    </Stack>
  );
};
