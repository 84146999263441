import {
  type AttributionFilter,
  CalcMetricFormat,
  Metadata,
  type Metric,
  type MetricVariable,
  Positions,
  type ReportFilter,
  TimeInterval,
} from "@doitintl/cmp-models";
import intersection from "lodash/intersection";
import { DateTime } from "luxon";

import { type Split } from "../../../Components/SplitCostModal/types";
import { type AttributionWRef, type MetadataOption } from "../../../types";
import { type AttributionsPayload } from "../previewReport/utils";
import { type DataRecord } from "../ReportData";
import { FixedFilters, parseMetricSplitsForReport } from "../utilities";
import { dimensionProvidersValues } from "../utils/cloudProviders";
import { type CalculatedMetricPayload } from "./ReportQuery";

export const accountsParam = (metadata: MetadataOption[], cloudProviders?: string[] | null) => {
  const accountsMetadata = metadata?.find((md) => md.id === `${Metadata.FIXED}:${FixedFilters.ACCOUNT}`);
  let accounts: string[] = [];

  if (!cloudProviders?.length) {
    accounts = !accountsMetadata?._filter?.length ? (accountsMetadata?.data?.values ?? []) : accountsMetadata._filter;
  } else if (accountsMetadata) {
    accounts = dimensionProvidersValues(accountsMetadata, cloudProviders);
  }

  // If filtered for specific accounts, filter the provider accounts results
  if (accountsMetadata?._filter?.length) {
    accounts = intersection(accounts, accountsMetadata._filter);
  }

  return accounts;
};

export const extractFieldsFromMetadata = (
  metadata: MetadataOption[],
  position: Positions,
  isAttribution?: boolean,
  filters?: any[],
  isGuidedExperience?: boolean
): DataRecord[] => {
  const filterIDs = filters?.map((filter) => filter.id) ?? [];
  const reportFilter = (metadata: MetadataOption[]) =>
    metadata.filter((md) => md._visible && md._position === position);
  const attributionRowFilter = (metadata: MetadataOption[]) =>
    metadata
      .filter((md) => filterIDs.includes(md.id))
      .sort((a, b) => filterIDs.indexOf(a.id) - filterIDs.indexOf(b.id));
  const attributionColFilter = (metadata: MetadataOption[]) => {
    const timeIntervals = isGuidedExperience
      ? [TimeInterval.YEAR, TimeInterval.MONTH]
      : [TimeInterval.YEAR, TimeInterval.MONTH, TimeInterval.DAY];
    return metadata.filter(
      (md) => md.data.type === Metadata.DATETIME && timeIntervals.includes(md.data.key as TimeInterval)
    );
  };

  let filteredMetadata = reportFilter(metadata);
  if (isAttribution) {
    if (position === Positions.ROW) {
      filteredMetadata = attributionRowFilter(metadata);
    }

    if (position === Positions.COL) {
      filteredMetadata = attributionColFilter(metadata);
    }
  }

  return filteredMetadata.map((md) => ({
    id: md.id,
    type: md.data.type,
    field: md.data.field,
    key: md.data.key,
    position: md._position,
    label: md.data.label,
    nullFallback: md.data.nullFallback ?? "[N/A]",
  }));
};

export const positionsParams = (
  metadata: MetadataOption[],
  isAttribution?: boolean,
  filters?: any[],
  isGuidedExperience?: boolean
) => {
  const rows = extractFieldsFromMetadata(metadata, Positions.ROW, isAttribution, filters, isGuidedExperience);
  const cols = extractFieldsFromMetadata(metadata, Positions.COL, isAttribution, filters, isGuidedExperience);
  let count: any = null;
  if (!isAttribution) {
    const counts = extractFieldsFromMetadata(metadata, Positions.COUNT);
    count = counts[0]
      ? {
          field: counts[0].field,
          type: counts[0].type,
          key: counts[0].key,
        }
      : null;
  }
  return { rows, cols, count };
};

export const attrModelToQueryRequest = (
  attr: { id: string; name: string; filters: AttributionFilter[] | null; formula: string },
  includeInFilter: boolean
): AttributionsPayload => ({
  id: `${Metadata.ATTRIBUTION}:${attr.id}`,
  type: Metadata.ATTRIBUTION,
  key: attr.name,
  includeInFilter,
  composite: attr.filters || [],
  formula: attr.formula,
});

export const attributionParam = (
  metadata: MetadataOption[],
  attributions: AttributionWRef[]
): AttributionsPayload[] | undefined => {
  const mdAttribution = metadata.find((md) => md.data.type === Metadata.ATTRIBUTION);
  if (mdAttribution) {
    if (mdAttribution._filter && mdAttribution._filter?.length > 0) {
      // If report filter by attribution
      const filterValues = (mdAttribution._filter ?? []).slice();
      let allowNull = false;
      if (mdAttribution.data.nullFallback) {
        const nullIndex = mdAttribution._filter.findIndex((v) => v === mdAttribution.data.nullFallback);
        allowNull = nullIndex !== -1;
        if (allowNull) {
          filterValues.splice(nullIndex, 1);
        }
      }
      const usedAttributions =
        attributions?.filter((attr) => !!attr.data.filters?.length && filterValues.includes(attr.ref.id)) || [];
      return usedAttributions.map(({ data: { name, filters, formula }, ref: { id } }) =>
        attrModelToQueryRequest({ name, id, filters, formula: formula ?? "" }, !allowNull)
      );
    } else if (mdAttribution._position !== Positions.UNUSED) {
      // If attribution chip has no filters but used in rows or cols
      const usedAttributions = attributions.filter((attr) => !!attr.data.filters?.length);
      return usedAttributions.map(({ data: { name, filters, formula }, ref: { id } }) =>
        attrModelToQueryRequest({ name, id, filters, formula: formula ?? "" }, false)
      );
    }
  }
};

export const defaultTimeSettings = () => ({
  interval: TimeInterval.DAY,
  from: DateTime.utc().minus({ days: 30 }).startOf("day"),
  to: DateTime.utc().minus({ days: 1 }).startOf("day"),
});

export const splitsParam = (metricSplits: { [key: string]: Split }, rows: DataRecord[], cols: DataRecord[]) => {
  const splits = parseMetricSplitsForReport(metricSplits);
  const splitsWithoutDuplicates = splits.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
  const rowsIds = rows.map((r) => r.id);
  splits.sort((a, b) => rowsIds.indexOf(a.id) - rowsIds.indexOf(b.id));
  splitsWithoutDuplicates.forEach((split) => {
    if (split.includeOrigin) {
      const rowIndex = rows.findIndex((row) => row.id === split.id);
      const colIndex = cols.findIndex((col) => col.id === split.id);
      if (rowIndex !== -1 && rows[rowIndex]) {
        const originRow = { ...rows[rowIndex] };
        if (rows[rowIndex].label) {
          rows[rowIndex].label += " - origin";
        }
        rows.splice(rowIndex, 0, originRow);
      }
      if (colIndex !== -1 && cols[colIndex]) {
        const originCol = { ...cols[colIndex] };
        if (cols[colIndex].label) {
          cols[colIndex].label += " - origin";
        }
        cols.splice(colIndex, 0, originCol);
      }
    }
  });
  return splits;
};

const calcMetricVariables = (
  variables: MetricVariable[],
  attributions: AttributionWRef[]
): {
  metric: Metric;
  attribution: AttributionsPayload;
}[] =>
  variables.map((v) => {
    const attr = attributions.find((a) => a.ref.id === v.attribution?.id);
    return {
      metric: v.metric,
      extended_metric: v.extendedMetric ?? "",
      attribution: {
        id: `${Metadata.ATTRIBUTION}:${attr?.ref?.id}`,
        type: Metadata.ATTRIBUTION,
        key: attr?.data.name || "",
        includeInFilter: true,
        composite: attr?.data?.filters || [],
        formula: attr?.data?.formula,
      },
    };
  });

export const calculatedMetricParam = (
  variables: MetricVariable[],
  attributions: AttributionWRef[],
  formula: string,
  format?: CalcMetricFormat,
  percentage?: boolean
): CalculatedMetricPayload => ({
  formula,
  variables: calcMetricVariables(variables, attributions),
  format,
  percentage,
});

export const calculatedMetricParamForReport = (attributions: AttributionWRef[], data?: any) => {
  if (data) {
    const { variables, formula, format } = data;
    const percentage = !!(format && format === CalcMetricFormat.PRECENTAGE);
    return calculatedMetricParam(variables, attributions, formula, undefined, percentage);
  }
};

// TODO: filter by type
export const parseFilters = (dimensions: MetadataOption[]): ReportFilter[] =>
  dimensions.flatMap((md) => {
    if (!(md._filter?.length || md._regexp?.length || md._limit)) {
      return [];
    }
    const filterValues = (md._filter ?? []).slice();
    let allowNull = false;
    if (md.data.nullFallback) {
      const nullIndex = filterValues.findIndex((v) => v === md.data.nullFallback);
      allowNull = nullIndex !== -1;
      if (allowNull) {
        filterValues.splice(nullIndex, 1);
      }
    }
    if (!allowNull && filterValues.length === 0 && md._regexp === null && md._limit === null) {
      return [];
    }
    return {
      id: md.id,
      type: md.data.type,
      field: md.data.field,
      key: md.data.key,
      position: md._position,
      // Filter options
      allowNull,
      values: filterValues,
      regexp: md._regexp ?? null,
      inverse: md._inverse,
      limit: md._limit ?? 0,
      limitOrder: md._limitOrder,
      limitMetric: md._limitMetric ?? 0,
      includeInFilter: true,
      composite: [],
    };
  }) || [];
