import { doc, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type MSTeamsChannel } from "./App";
import { type SlackChannel } from "./CloudAnalytics";
import { type CustomerModel, type CustomerModelOrganizationModel } from "./Customer";
import { type DashboardModelAttributionModel, type DashboardModelSavedReportsModel } from "./Dashboard";
import { type EntityModel } from "./Entity";
import { type PermissionModel } from "./Permission";
import { type UserNotification } from "./User";

export enum NotificationCommonFilterType {
  ATTRIBUTIONS = "ATTRIBUTIONS",
}

export enum NotificationAudienceType {
  USER = "user",
  COMPANY = "company",
}

export enum NotificationProviderType {
  EMAIL = "EMAIL",
  SLACK = "SLACK",
  MSTEAMS = "MSTEAMS",
}

export type NotificationCommonFilterDescriptor = {
  type: NotificationCommonFilterType;
  required: boolean;
};

export type NotificationDescriptor = {
  audience: NotificationAudienceType[];
  description: string;
  name: string;
  group?: string;
  order: number;
  value: UserNotification;
  supportedProviders: NotificationProviderType[];
  commonFilters?: NotificationCommonFilterDescriptor[];
};

export type NotificationGroupDescriptor = {
  name: string;
  title: string;
  description?: string;
};

export type NotificationPermission = {
  [NotificationAudienceType.USER]: Reference<PermissionModel>[];
  [NotificationAudienceType.COMPANY]: Reference<PermissionModel>[];
};

@doc("notifications")
export class NotificationsDescriptorDoc {
  notifications!: NotificationDescriptor[];

  notificationGroups!: NotificationGroupDescriptor[];

  notificationPermissions!: NotificationPermission[];
}

export type NotificationWithAttributionsSettings = {
  attributions?: Reference<DashboardModelAttributionModel>[];
};

export type InvoiceNotificationSettings = {
  entities?: Reference<EntityModel>[];
};

export type CostAnomaliesNotificationSettings = NotificationWithAttributionsSettings &
  Partial<{
    anomalyAbsolute: number;
    anomalyAlerts: number;
  }>;

export type DeliveryFrequency = "Daily" | "Weekly" | "Monthly" | "Annually" | "Custom";

export const deliveryFrequencies: DeliveryFrequency[] = ["Daily", "Weekly", "Monthly", "Annually", "Custom"] as const;

export type CustomPeriodUnit = "Day" | "Week" | "Month" | "Year";

export const customPeriodUnits: CustomPeriodUnit[] = ["Day", "Week", "Month", "Year"] as const;

export type CommonSubscriptionNotificationSettings = {
  orgRef: Reference<CustomerModelOrganizationModel>;
  frequency: DeliveryFrequency;
  timeZone: string;
  time: Timestamp; // represents time of day
  startDate: Timestamp;
  nextAt: Timestamp;

  // exists only when deliveryFrequency is "Custom"
  customPeriodAmount: number | null;
  customPeriodUnit: CustomPeriodUnit | null;
};
export type DashboardSubscriptionNotificationSettings = CommonSubscriptionNotificationSettings & {
  dashboardPath: string;
};

export type ReportSubscriptionNotificationSettings = CommonSubscriptionNotificationSettings & {
  reportRef: Reference<DashboardModelSavedReportsModel>;
};

// prettier-ignore
export type NotificationSettingsByType<T extends UserNotification> =
  T extends UserNotification.DailyDigest | UserNotification.WeeklyDigest ? NotificationWithAttributionsSettings :
  T extends UserNotification.CostAnomalies ? CostAnomaliesNotificationSettings :
  T extends UserNotification.NewInvoice | UserNotification.PaymentOverdue | UserNotification.NewProformaInvoice ? InvoiceNotificationSettings :
  T extends UserNotification.DashboardSubscription ? DashboardSubscriptionNotificationSettings :
  T extends UserNotification.ReportSubscription ? ReportSubscriptionNotificationSettings :
  object;

export type NotificationSettings = {
  [Key in UserNotification]?: NotificationSettingsByType<Key>;
};

export type MSTeamsTarget = {
  channelRef: Reference<MSTeamsChannel>;

  channel?: {
    conversation_id: string;
    tenant_id: string;
    service_url: string;
  };
};

export type ProviderTarget = {
  [NotificationProviderType.SLACK]?: SlackChannel[];
  [NotificationProviderType.EMAIL]?: string[];
  [NotificationProviderType.MSTEAMS]?: MSTeamsTarget[];
};

@subCollection("notifications")
export class NotificationModel {
  name!: string;

  selectedNotifications!: NotificationSettings;

  selectedNotificationsKeys!: UserNotification[];

  providerTarget?: ProviderTarget;

  createdAt!: Timestamp;

  createdBy!: string;

  customerRef?: Reference<CustomerModel>;

  disableTargetUpdate?: boolean;
}

export const getProviderTypes = (notification: NotificationModel): NotificationProviderType[] => {
  const providerTypes = Object.values(NotificationProviderType);
  return providerTypes.filter((providerType) => notification.providerTarget?.[providerType]);
};

@subCollection("userPersonalNotifications")
export class UserPersonalNotificationModel extends NotificationModel {
  declare customerRef: Reference<CustomerModel>;
}
