import { budgetText } from "../../../assets/texts";
import { type FilterTableItemDescriptor } from "../../../Components/FilterTable/filterTableUtils";
import { type Budget } from "../../../types";

export const itemsDescriptions: FilterTableItemDescriptor<Budget>[] = [
  {
    value: "data.name",
    headerText: budgetText.TABLE_HEADERS.NAME,
    tooltip: budgetText.TABLE_HEADERS.NAME,
    headerStyle: { align: "left", padding: "normal" },
    filterType: "string",
  },
  {
    value: "data.config.timeInterval",
    headerText: budgetText.TABLE_HEADERS.PERIOD,
    tooltip: budgetText.TABLE_HEADERS.PERIOD,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    disableSorting: true,
  },
  {
    value: "data.config.amount",
    headerText: budgetText.TABLE_HEADERS.BUDGET_FOR_CURRENT_PERIOD,
    tooltip: budgetText.TABLE_HEADERS.BUDGET_FOR_CURRENT_PERIOD,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { xsDown: true },
    filterType: "number",
  },
  {
    value: "data.utilization.current",
    headerText: budgetText.TABLE_HEADERS.ACTUALS_FOR_CURRENT_PERIOD,
    tooltip: budgetText.TABLE_HEADERS.ACTUALS_FOR_CURRENT_PERIOD,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { xsDown: true },
    filterType: "number",
  },
  {
    value: "data.utilization.forecasted",
    headerText: budgetText.TABLE_HEADERS.FORECAST_FOR_CURRENT_PERIOD,
    tooltip: budgetText.TABLE_HEADERS.FORECAST_FOR_CURRENT_PERIOD,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    filterType: "number",
  },
  {
    value: "data.varianceSpend",
    headerText: budgetText.TABLE_HEADERS.VARIANCE_FOR_CURRENT_PERIOD,
    tooltip: budgetText.TABLE_HEADERS.VARIANCE_FOR_CURRENT_PERIOD,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    filterType: "number",
  },
  {
    value: "data.currentPercentage",
    headerText: budgetText.TABLE_HEADERS.CURRENT_PCT,
    tooltip: budgetText.TABLE_HEADERS.CURRENT_PCT,
    headerStyle: { align: "left", padding: "normal" },
    disableSorting: true,
    hidden: { xsDown: true },
  },
  {
    value: "@",
    headerText: "",
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    disableSorting: true,
  },
];
