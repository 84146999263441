import { Link } from "react-router-dom";
import { Skeleton, TableCell } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import Hide from "../../../Components/HideChildren/Hide";
import ThreadThreeDotsMenu from "../ThreeDotsMenu/ThreadThreeDotsMenu";
import { type ThreadRowData } from "./ThreadsOverview";

type Props = {
  row: ThreadRowData;
};

export const ThreadsOverviewRow = ({ row }: Props) => {
  const theme = useTheme();

  return (
    <CellsWrapper>
      <TableCell>
        {row.showSkeleton ? (
          <Skeleton animation="pulse" variant="text" width="280px" sx={{ fontSize: 12 }} />
        ) : (
          <Link
            to={row.targetLink}
            style={{ color: row.errorLoadingJiraIssue ? theme.palette.text.disabled : "inherit" }}
          >
            {row.errorLoadingJiraIssue ? "Thread not available" : row.name}
          </Link>
        )}
      </TableCell>

      <Hide mdDown>
        <TableCell>
          <Link to={row.sourceLink} style={{ color: "inherit" }}>
            {row.source.charAt(0).toUpperCase() + row.source.slice(1)}
          </Link>
        </TableCell>
      </Hide>

      <Hide mdDown>
        <TableCell>{row.impactFormatter(row.impact)}</TableCell>
      </Hide>

      <Hide mdDown>
        <TableCell>
          {row.showSkeleton ? (
            <Skeleton animation="pulse" variant="text" width="280px" sx={{ fontSize: 12 }} />
          ) : row.errorLoadingJiraIssue ? (
            ""
          ) : (
            row.assignee
          )}
        </TableCell>
      </Hide>

      <Hide mdDown>
        <TableCell>
          {row.showSkeleton ? (
            <Skeleton animation="pulse" variant="text" width="280px" sx={{ fontSize: 12 }} />
          ) : row.errorLoadingJiraIssue ? (
            ""
          ) : (
            row.priority
          )}
        </TableCell>
      </Hide>

      <Hide mdDown>
        <TableCell>
          {row.showSkeleton ? (
            <Skeleton animation="pulse" variant="text" width="280px" sx={{ fontSize: 12 }} />
          ) : row.errorLoadingJiraIssue ? (
            ""
          ) : (
            row.status
          )}
        </TableCell>
      </Hide>

      <TableCell padding="none">
        <ThreadThreeDotsMenu threadId={row.id} targetUrl={row.jiraLink} />
      </TableCell>
    </CellsWrapper>
  );
};
