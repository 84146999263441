import { useCallback, useState } from "react";

import { isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import { type CloudFlowNodeType, ModelType, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";

import { cmpBaseColors } from "../../../../cmpBaseColors";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { ReferencedFieldChip } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldChip";
import { ReferencedFieldContextProvider } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldContextProvider";
import { type NodeWitOutputModel } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { type CloudFlowNode } from "../utils/nodeTransformUtils";
import { FilterDialog } from "./FilterDialog";
import { type EditActionParams, type FilterGroup, type FilterGroupCondition } from "./types";
import { getModelByFieldReference } from "./utils";

type FilterConditionProps = {
  condition: FilterGroupCondition;
  groupId: string;
  selectedNode: CloudFlowNode<CloudFlowNodeType.ACTION> | undefined;
  referenceableNodes: NodeWitOutputModel[];
  outputModel?: UnwrappedApiServiceModelDescriptor;
  setFilterGroups: (groups: FilterGroup[] | ((prevGroups: FilterGroup[]) => FilterGroup[])) => void;
};

const FilterCondition = ({
  condition,
  setFilterGroups,
  groupId,
  selectedNode,
  outputModel,
  referenceableNodes,
}: FilterConditionProps) => {
  const [editConditionOpen, setEditConditionOpen] = useState(false);

  const handleEditFilter = async ({
    groupId,
    operator,
    conditionType,
    value,
    conditionId,
    fieldReference,
  }: EditActionParams) => {
    setFilterGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              conditions: group.conditions.map((condition) =>
                condition.id === conditionId
                  ? {
                      ...condition,
                      field: fieldReference,
                      comparisonOperator: operator,
                      type: conditionType,
                      value,
                    }
                  : condition
              ),
            }
          : group
      )
    );
    setEditConditionOpen(false);
  };

  const handleDeleteFilter = (groupId: string, conditionId: string) => {
    setFilterGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id === groupId
          ? { ...group, conditions: group.conditions.filter((condition) => condition.id !== conditionId) }
          : group
      )
    );
  };

  const isDarkTheme = useDarkThemeCheck();
  const theme = useTheme();

  const ConditionValue = useCallback(() => {
    const value = condition.value;

    if (!isReferencedNodeValue(value)) {
      const model = outputModel ? getModelByFieldReference(outputModel, condition.field.referencedField) : undefined;

      if (model && model.type === ModelType.TIMESTAMP) {
        const timestampFormat = model.timestampFormat ?? "X";
        let dateTime: DateTime;

        if (typeof value === "number" || typeof value === "string") {
          if (timestampFormat === "X") {
            dateTime =
              typeof value === "number" ? DateTime.fromSeconds(value) : DateTime.fromSeconds(parseFloat(value));
          } else {
            dateTime = DateTime.fromFormat(String(value), timestampFormat);
          }

          if (dateTime.isValid) {
            return (
              <Typography variant="body2" noWrap>
                {dateTime.toLocaleString(DateTime.DATETIME_MED)}
              </Typography>
            );
          } else {
            return (
              <Typography noWrap variant="body2">
                {String(value)}
              </Typography>
            );
          }
        } else {
          return (
            <Typography noWrap variant="body2">
              {JSON.stringify(value)}
            </Typography>
          );
        }
      }

      return (
        <Typography noWrap variant="body2">
          {JSON.stringify(value)}
        </Typography>
      );
    }

    return (
      <Box sx={{ overflow: "hidden" }}>
        <ReferencedFieldContextProvider referenceableNodes={referenceableNodes}>
          <ReferencedFieldChip value={value} />
        </ReferencedFieldContextProvider>
      </Box>
    );
  }, [condition.field.referencedField, condition.value, outputModel, referenceableNodes]);
  return (
    <>
      <FilterDialog
        open={editConditionOpen}
        groupId={groupId}
        referenceableNodes={referenceableNodes}
        mode="edit"
        selectedNode={selectedNode}
        initialValue={condition.value}
        initialFieldReference={condition.field}
        initialOperator={condition.comparisonOperator}
        outputModel={outputModel}
        conditionId={condition.id}
        handleClose={() => setEditConditionOpen(false)}
        handleAction={handleEditFilter}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          border: "1px solid",
          borderColor: theme.palette.general.outlineBorder,
          borderRadius: 1,
          width: "100%",
          backgroundColor: isDarkTheme ? cmpBaseColors.backgroundDark : cmpBaseColors.backgroundLight,
          minHeight: "47px",
          px: 2,
        }}
      >
        <Stack direction={"row"} gap={0.5} sx={{ overflow: "hidden" }}>
          <Typography noWrap variant="body2" sx={{ flexShrink: "0.5" }}>
            {`${condition.field.referencedField.join(".")} ${condition.comparisonOperator} `}
          </Typography>
          <ConditionValue />
        </Stack>
        <Stack direction={"row"} gap={0.5} p={0}>
          <IconButton onClick={() => setEditConditionOpen(true)} sx={{ p: 0.5 }}>
            <EditIcon sx={{ fontSize: "16px" }} />
          </IconButton>
          <IconButton onClick={() => handleDeleteFilter(groupId, condition.id)} sx={{ p: 0.5 }}>
            <CloseIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </Stack>
      </Stack>
    </>
  );
};

export default FilterCondition;
