import {
  type ApiServiceModelDescriptor,
  type CloudFlowNodeType,
  type Member,
  type MemberReference,
  ModelType,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-admin";

import { isActionNode, isConditionNode, isFilterNode } from "./pattern-matchers";
import { type NodeModelWithId } from "./types";

export async function getNodeOutputModel(
  getOutputModelForActionNode: (
    referencedNode: NodeModelWithId<CloudFlowNodeType.ACTION>
  ) => Promise<UnwrappedApiServiceModelDescriptor | null>,
  nodes: NodeModelWithId[],
  nodeId: string
): Promise<UnwrappedApiServiceModelDescriptor | null> {
  const referencedNode = nodes.find(({ id }) => nodeId === id);
  if (referencedNode === undefined) {
    throw new Error(`Could not find referenced node id: ${nodeId}`);
  }

  switch (true) {
    case isActionNode(referencedNode):
      return getOutputModelForActionNode(referencedNode);
    case isConditionNode(referencedNode):
    case isFilterNode(referencedNode): {
      // FIXME: adjust FilterNode type to declare parameters as undefined or ensure they are always set
      if (!referencedNode.parameters?.referencedNodeId) {
        return null;
      }
      const referencedNodeOutputModel = await getNodeOutputModel(
        getOutputModelForActionNode,
        nodes,
        referencedNode.parameters.referencedNodeId
      );
      if (referencedNodeOutputModel === null) {
        return null;
      }
      return wrapModelWithListModel(
        getModelByPath(referencedNodeOutputModel, referencedNode.parameters.referencedField)
      );
    }
  }

  throw new Error(`Unable to provide an output model for node ${referencedNode.id} with type ${referencedNode.type}`);
}

export function getModelByPath(model: UnwrappedApiServiceModelDescriptor, path: string[]) {
  if (path.length === 0) {
    return model;
  }

  switch (model.type) {
    case ModelType.LIST:
      return getModelByPath(model.member.model, path);
    case ModelType.STRUCTURE: {
      const [memberName, ...pathRest] = path;
      const memberModel = model.members[memberName];
      if (memberModel === undefined) {
        throw new Error(`Could not get model for path token ${memberName}`);
      }

      return getModelByPath(memberModel.model, pathRest);
    }
    default:
      throw new Error("Model path out of bounds!");
  }
}

export function wrapModelWithListModel(model: UnwrappedApiServiceModelDescriptor): UnwrappedApiServiceModelDescriptor {
  if (model.type === ModelType.LIST) {
    return model;
  }
  return {
    type: ModelType.LIST,
    member: {
      model,
    },
  };
}

export function isUnwrappedMember(member: Member | MemberReference): member is Member {
  return Object.hasOwn(member, "model");
}

export async function unwrapModel(
  getReferencedModelById: (modelId: string) => Promise<WithFirebaseModel<ApiServiceModelDescriptor>>,
  modelToUnwrap: ApiServiceModelDescriptor
): Promise<WithFirebaseModel<UnwrappedApiServiceModelDescriptor>> {
  switch (modelToUnwrap.type) {
    case ModelType.LIST: {
      const memberModel = isUnwrappedMember(modelToUnwrap.member)
        ? modelToUnwrap.member.model
        : await getReferencedModelById(modelToUnwrap.member.modelId);
      return {
        ...modelToUnwrap,
        member: {
          documentation: modelToUnwrap.member.documentation,
          model: await unwrapModel(getReferencedModelById, memberModel),
        },
      };
    }
    case ModelType.STRUCTURE: {
      const membersEntries = await Promise.all(
        Object.entries(modelToUnwrap.members).map(async ([memberName, member]) => {
          const memberModel = isUnwrappedMember(member) ? member.model : await getReferencedModelById(member.modelId);
          return [
            memberName,
            {
              model: await unwrapModel(getReferencedModelById, memberModel),
              documentation: member.documentation,
            },
          ] as const;
        })
      );
      return {
        ...modelToUnwrap,
        members: Object.fromEntries(membersEntries),
      };
    }
    default:
      return modelToUnwrap as WithFirebaseModel<UnwrappedApiServiceModelDescriptor>;
  }
}
