import { type ReactNode } from "react";

import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Chip, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";

import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { dateFormatDayMonthYear } from "../../../utils/dateTimeFormats";
import { useThreads } from "../../Threads/api";
import { useInsightsContext } from "../context";
import { type Insight } from "../types";
import { isOptimized, totalCustomProblems, totalPotentialDailySavings } from "../utils";
import { CloudProvidersIcons } from "./CloudProvidersIcons";
import { CustomProblemsPreview } from "./CustomProblemsPreview";
import { PotentialSavingsPreview } from "./PotentialSavingsPreview";

export const Preview = ({ insight, isListView }: { insight: Insight; isListView: boolean }) => {
  const { savingsPeriod, calculateSavingsForSelectedPeriod } = useInsightsContext();
  const { customer: originalCustomer } = useCustomerContext();
  const { threads } = useThreads();
  const theme = useTheme();
  const routeMatchURL = useRouteMatchURL();

  const lastUpdatedFormatted = DateTime.fromJSDate(new Date(insight.lastUpdated)).toFormat(dateFormatDayMonthYear);
  let component: ReactNode;

  if (insight.status === "error") {
    component = <Chip label="Something went wrong" variant="filled" size="small" color="error" />;
  } else if (!insight.results?.isRelevant) {
    component = <Chip label="No spend" variant="filled" size="small" color="info" />;
  } else if (isOptimized(insight)) {
    component = <Chip label="Optimized" variant="filled" size="small" color="success" />;
  } else {
    const potentialDailySavings = totalPotentialDailySavings(insight);
    const customProblems = totalCustomProblems(insight);

    const resultPreviews: ReactNode[] = [];

    if (potentialDailySavings !== null) {
      resultPreviews.push(
        <PotentialSavingsPreview
          savings={calculateSavingsForSelectedPeriod(potentialDailySavings)}
          savingsPeriod={savingsPeriod}
        />
      );
    }

    if (customProblems !== null) {
      resultPreviews.push(
        <CustomProblemsPreview
          value={customProblems}
          unitLongSingular={insight?.results?.customProblems?.unitLongSingular || ""}
          unitLongPlural={insight?.results?.customProblems?.unitLongPlural || ""}
        />
      );
    }

    component = (
      <Stack direction="row" spacing={2} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
        {resultPreviews.map((preview, i) => (
          <Stack key={i}>{preview}</Stack>
        ))}
      </Stack>
    );
  }

  const matchingThreads =
    threads?.filter(
      (thread) =>
        thread.source === "insight" &&
        thread.sourceData.insightKey === insight.key &&
        thread.sourceData.providerID === insight.providerId
    ) || [];

  const mainBlock = (
    <>
      <Typography variant={isListView ? "h3" : "h1"}>
        {insight.isInternal ? <Chip label="DoiT internal" color="primary" size="small" /> : null} {insight.title}
      </Typography>

      <Typography sx={{ mt: 1.5 }} color="text.secondary" variant="body1">
        {insight.shortDescription}
      </Typography>

      <Box sx={{ my: isListView ? 2.25 : 3.5 }}>{component}</Box>
    </>
  );

  return (
    <>
      {isListView ? (
        <Link
          to={`${routeMatchURL}/${insight.providerId}/${insight.key}`}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          {mainBlock}
        </Link>
      ) : (
        mainBlock
      )}

      <Stack
        direction="row"
        spacing={1.5}
        alignItems="center"
        divider={<Divider orientation="vertical" sx={{ height: "22px" }} />}
        sx={{ color: "text.secondary" }}
        height="28px"
      >
        <Stack direction="row" spacing={0.75} alignItems="center">
          <Typography variant="body2">Last checked on {lastUpdatedFormatted}</Typography>
          <Tooltip title="Based on recent billing data, excluding the last three days" arrow>
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Stack>

        <Tooltip title="Insight type" arrow>
          <Typography variant="body2" sx={{ borderBottom: "1px dotted", cursor: "pointer" }}>
            {insight.otherTags.join(", ")}
          </Typography>
        </Tooltip>

        <CloudProvidersIcons cloudTags={insight.cloudTags} />

        {isListView && matchingThreads?.length > 0 ? (
          <Link
            to={`/customers/${originalCustomer.id}/insights/${insight.providerId}/${insight.key}`}
            style={{ color: theme.palette.primary.main, fontSize: "14px" }}
          >
            Tracked by {matchingThreads.length} {matchingThreads.length === 1 ? "thread" : "threads"}
          </Link>
        ) : null}
      </Stack>
    </>
  );
};
