import React, { useState } from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import { InputAdornment, Stack, TextField } from "@mui/material";
import { DateTime } from "luxon";

import { getCurrencySymbol, onKeyPressPreventNonNumeric } from "../../../../utils/common";

interface DaysNextPeriodPullOverProps {
  currency: CurrencyCode;
  disabled: boolean;
}

const DaysNextPeriodPullOver: React.FC<DaysNextPeriodPullOverProps> = ({ currency, disabled }) => {
  const weekStart = DateTime.now().set({ weekday: 1 });
  const initialDays = Array.from({ length: 7 }, (_, i) => {
    const dayName = weekStart.plus({ days: i }).toFormat("EEEE");
    return { [dayName]: dayName };
  }).reduce((acc, day) => ({ ...acc, ...day }), {});

  const [days, setDays] = useState<{ [key: string]: string }>(initialDays);

  const handleDayChange = (dayKey: string, value: string) => {
    setDays((prev) => ({ ...prev, [dayKey]: value }));
  };

  return (
    <Stack gap={3}>
      {Object.keys(days).map((dayKey, index) => (
        <TextField
          key={`DaysNextPeriodPullOver${index}`}
          variant="outlined"
          type="number"
          fullWidth
          required
          disabled={disabled}
          label={dayKey}
          value={days[dayKey]}
          onChange={(e) => handleDayChange(dayKey, e.target.value)}
          onKeyUp={onKeyPressPreventNonNumeric}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
          }}
        />
      ))}
    </Stack>
  );
};

export default DaysNextPeriodPullOver;
