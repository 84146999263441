import { useState, useEffect, useMemo, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Link from "@mui/material/Link";
import WarningIcon from "@mui/icons-material/ReportProblem";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { DateTime } from "luxon";
import Tooltip from "@mui/material/Tooltip";
import find from "lodash/find";
import { orange as Orange } from "@mui/material/colors";
import { AssetTypeAmazonWebServices, AssetTypeGoogleCloud, AssetModelBillingAnomalyModel } from "@doitintl/cmp-models";
import { getCollectionGroup } from "@doitintl/models-firestore";
import SparkLineTable from "../../Pages/AnomalyDetection/SparkLine/SparkLineTable";
import { assetTypeName, clouds } from "../../utils/common";
import { getGraphLength } from "../../Pages/AnomalyDetection/AlertTools";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useFullScreen } from "../../utils/dialog";
import { SkeletonCard } from "./SkeletonCard";
import { CARD_HEADER_HEIGHT } from "./Analytics/cloudCardStyle";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";

const useStyles = makeStyles((theme) => ({
  table: {
    whiteSpace: "nowrap",
  },
  tableHeader: {
    height: 32,
  },
  tableRow: {
    height: 32,
  },
  tablePagination: {
    borderBottom: "none",
    padding: theme.spacing(0),
  },
  link: {
    textDecoration: "underline",
    color: "inherit",
    cursor: "pointer",
  },
  companyLogo: {
    margin: theme.spacing(0.5, 0.5, 0, 0.5),
    height: 24,
  },
}));

const levelText = ["No alert", "Information", "Warning", "Critical"];
const levelIcons = [
  <CheckIcon key={0} color="primary" style={{ marginLeft: 10 }} />,
  <InfoIcon key={1} color="primary" style={{ marginLeft: 10 }} />,
  <WarningIcon key={2} style={{ color: Orange[300], marginLeft: 10 }} />,
  <ErrorIcon key={3} color="error" style={{ marginLeft: 10 }} />,
];

export default function CostAnomaliesWidgetCard({ fallbackComponent, raised, widgetHeight = 200 }) {
  const classes = useStyles();
  const { customer } = useCustomerContext();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [data, setData] = useState();

  useEffect(() => {
    getCollectionGroup(AssetModelBillingAnomalyModel)
      .where("customer", "==", customer.ref)
      .where("metadata.severity", "in", [2, 3])
      .orderBy("metadata.timestamp", "desc")
      .limit(18)
      .onSnapshot(async (docSnap) => {
        if (docSnap.empty) {
          setData(null);
          return;
        }

        setData(
          docSnap.docs.flatMap((item) => {
            if (!item.exists()) {
              return [];
            }

            return [
              {
                data: item.asModelData(),
                id: item.id,
              },
            ];
          })
        );
      });
  }, [customer.ref]);

  useMemo(() => {
    const timeFrame = [
      { id: "RISING_DAILY_COSTS", title: "Daily" },
      { id: "MULTIPLE_ALERTS_IN_A_DAY", title: "Hourly" },
    ];
    if (data) {
      data.forEach((element) => {
        const miniGraph = [];
        const gLength = getGraphLength(element.data.chart_data);
        Object.keys(element.data.chart_data)
          .sort()
          .forEach((key, i) => {
            if (i > gLength - 30) {
              const d = new Date(key.replace(/-/g, "/")).getTime();
              miniGraph.push({ y: element.data.chart_data[key].snapshot_value, x: d });
            }
          });

        element.data.metadata.miniGraph = miniGraph;
        element.data.metadata._ts = DateTime.fromSQL(element.data.metadata.timestamp);
        if (!element.data.metadata.context) {
          element.data.metadata.context = timeFrame[1].id;
        }
      });
    }
  }, [data]);

  const { isMobile } = useFullScreen();

  const getLinkUrl = (row) => {
    if (row.data.metadata.platform.includes(AssetTypeAmazonWebServices)) {
      return `${AssetTypeAmazonWebServices}-${row.data.metadata.project_id}`;
    }
    return `${AssetTypeGoogleCloud}-${row.data.metadata.billing_account_id}`;
  };

  const getDate = useCallback(
    (data) =>
      isMobile
        ? DateTime.fromJSDate(data.metadata.timestamp.toDate()).toFormat("yyyy-MM-dd")
        : DateTime.fromJSDate(data.metadata.timestamp.toDate()).toFormat("MMM dd, yyyy"),
    [isMobile]
  );

  useEffect(() => {
    switch (widgetHeight) {
      case 2: {
        setRowsPerPage(9);
        break;
      }
      case 1.5: {
        setRowsPerPage(6);
        break;
      }
      case 1:
      default: {
        setRowsPerPage(3);
      }
    }
  }, [widgetHeight]);

  const emptyRows = useMemo(
    () => (data ? rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) : 0),
    [data, page, rowsPerPage]
  );

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  if (data === null) {
    return fallbackComponent;
  }

  return (
    <Grid>
      <Card raised={raised}>
        <WidgetCardHeader
          title="Cost Anomalies"
          subheader="Sudden spikes in your Google Cloud or Amazon Web Services usage"
        />
        <CardContent style={{ height: widgetHeight - CARD_HEADER_HEIGHT }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell padding="none" size="small">
                  Date
                </TableCell>
                <TableCell padding="none" size="small">
                  Cloud
                </TableCell>
                <TableCell padding="none" size="small">
                  Anomaly
                </TableCell>
                <TableCell padding="none" size="small">
                  Severity
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow className={classes.tableRow} key={row.id}>
                  <TableCell padding="none" size="small">
                    <Link
                      component={RouterLink}
                      className={classes.link}
                      to={`/customers/${customer.id}/anomaly/${getLinkUrl(row)}/${row.id}`}
                    >
                      {getDate(row.data)}
                    </Link>
                  </TableCell>
                  <TableCell padding="none" size="small">
                    {isMobile ? (
                      <img
                        className={classes.companyLogo}
                        alt={row.data.metadata.platform}
                        src={find(clouds, { hyphenName: row.data.metadata.platform }).icon}
                      />
                    ) : (
                      assetTypeName(row.data.metadata.platform)
                    )}
                  </TableCell>
                  <TableCell padding="none" size="small">
                    <SparkLineTable>
                      <td style={{ overflow: "visible" }} data-sparkline={row.data.metadata.miniGraph} />
                    </SparkLineTable>
                  </TableCell>
                  <TableCell padding="none" size="small">
                    <Tooltip title={levelText[parseInt(row.data.metadata.severity)]}>
                      {levelIcons[parseInt(row.data.metadata.severity)]}
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 32 * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  className={classes.tablePagination}
                  rowsPerPageOptions={[rowsPerPage]}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                    size: "small",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                    size: "small",
                  }}
                  onPageChange={(_event, page) => setPage(page)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </CardContent>
        <div />
      </Card>
    </Grid>
  );
}
