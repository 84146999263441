import { Link as InternalLink } from "react-router-dom";
import { Box, Button, Divider, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import threadsLogoDark from "../../assets/threads-logo-dark.svg";
import threadsLogoLight from "../../assets/threads-logo-light.svg";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { useCustomerContext } from "../../Context/CustomerContext";
import mixpanel from "../../utils/mixpanel";

type Props = {
  isModal: boolean;
  onClose?: () => void;
  fullContent?: boolean;
};

export const IntroducingThreadsContent = ({ isModal, onClose, fullContent = true }: Props) => {
  const isDarkMode = useDarkThemeCheck();
  const theme = useTheme();

  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const jiraSetupUrl = `/customers/${customer.id}/integrations/jira`;

  const noJiraLink = (
    <Link
      href="https://forms.gle/A65CPwjs9QLnt3dF9"
      target="_blank"
      rel="noopener noreferrer"
      sx={{ fontSize: "12px", color: theme.palette.text.secondary, textDecorationColor: theme.palette.text.secondary }}
      onClick={() => mixpanel.track("threads.no-jira.click")}
    >
      Not using Jira?
    </Link>
  );

  return (
    <>
      <Box m={3} maxWidth="sm">
        <Typography variant="h2" color="inherit" mb={4}>
          Integrate your insights into your Jira workflow
        </Typography>
        {fullContent ? (
          <>
            <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
              <img
                src={isDarkMode ? threadsLogoDark : threadsLogoLight}
                alt="threads-logo"
                style={{ maxWidth: "50%" }}
              />
            </Box>
            <Typography variant="body2" mb={2}>
              A <strong>DoiT Thread</strong> is a powerful new way to drive action and accountability into your
              organization. Threads drive action to optimize, reduce waste, and enable organizational adoption of FinOps
              and provide easier reporting and tracking of improvements and cost optimization efforts.
            </Typography>
          </>
        ) : null}

        <Typography
          variant="body2"
          color={fullContent ? theme.palette.text.primary : theme.palette.text.secondary}
          mb={1}
        >
          {fullContent
            ? `DoiT Threads connects DoiT Cloud Navigator with your Jira instance to generate and track actionable insights directly assigned to engineering teams or other accountable team members.`
            : `DoiT Threads connects DoiT Cloud Navigator with your Jira instance to generate and track actionable insights directly assigned to engineering teams. Threads drive action to optimize, reduce waste, and enable organizational adoption of FinOps and provide easier reporting and tracking of cost optimization efforts.`}
        </Typography>
        {isModal ? noJiraLink : null}
      </Box>
      <Divider />
      <Box
        display="flex"
        alignItems={isModal ? "flex-end" : "center"}
        justifyContent="flex-end"
        p={1}
        flexDirection={isModal ? "row" : "column"}
        gap={isModal ? 1 : 2}
      >
        {isModal && (
          <Button variant="text" onClick={onClose}>
            Not now
          </Button>
        )}
        <Button variant="contained" component={InternalLink} to={jiraSetupUrl}>
          Set up Jira integration
        </Button>

        {!isModal ? noJiraLink : null}
      </Box>
    </>
  );
};
