import { useState } from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { type AxiosError } from "axios";

import { useApiContext } from "../../../api/context";
import LoadingButton from "../../../Components/LoadingButton";
import { useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useFullScreen } from "../../../utils/dialog";
import { deleteThread } from "../firestore";

type Props = {
  threadId: string;
  onClose: () => void;
};

export const DeleteThreadModal = ({ threadId, onClose }: Props) => {
  const { isMobile: isSmallScreen } = useFullScreen("sm");

  const [isDeleting, setIsDeleting] = useState(false);
  const [deletionOption, setDeletionOption] = useState("");
  const [isDeleteIssueError, setIsDeleteIssueError] = useState(false);
  const [isJiraAppOutOfDate, setIsJiraAppOutOfDate] = useState(false);

  const { isDoitEmployee } = useAuthContext();
  const { customer } = useCustomerContext();
  const successSnackbar = useSuccessSnackbar();

  const api = useApiContext();

  const handleDeleteThread = async () => {
    setIsDeleting(true);

    let deleteIssueError = false;
    let jiraAppPossiblyOutOfDate = false;

    // If the user has selected to delete the thread and the associated Jira issue, try and delete the issue in Jira, first
    if (deletionOption === "thread-and-issue") {
      try {
        const cacheUrl = `threads/jira/issue?customerID=${customer.id}&threadID=${threadId}`;
        await api.delete(cacheUrl);
      } catch (e) {
        deleteIssueError = true;

        const axiosError = e as AxiosError;

        // If the app in the customer's instance can't handle DELETE requests, it's probably because the app hasn't been updated
        if (axiosError.response?.status === 405) {
          jiraAppPossiblyOutOfDate = true;
        }
      }
    }

    // Regardless of the user's choice we can now 'delete' the thread in Firestore
    await deleteThread(threadId);

    setIsDeleting(false);

    if (!deleteIssueError) {
      successSnackbar(
        deletionOption === "thread-and-issue"
          ? "Thread and Jira issue deleted successfully!"
          : "Thread deleted successfully!"
      );
      onClose();
    } else {
      setIsDeleteIssueError(deleteIssueError);
      setIsJiraAppOutOfDate(jiraAppPossiblyOutOfDate);
    }
  };

  return (
    <Dialog open={true} fullScreen={isSmallScreen} fullWidth maxWidth="xs" onClose={onClose}>
      <DialogTitle>Delete thread</DialogTitle>
      <DialogContent>
        {isDoitEmployee ? (
          <Alert severity="warning" sx={{ mb: 2 }}>
            <strong>You're logged in as a DoiT employee</strong> - you cannot delete a customer thread.
          </Alert>
        ) : null}
        <Typography sx={{ mb: 2 }}>Choose an option to proceed:</Typography>
        <RadioGroup
          sx={{ pl: 2, mb: 2 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeletionOption(e.target.value)}
        >
          <FormControlLabel
            disabled={isDeleteIssueError}
            value="thread-only"
            control={<Radio />}
            label="Delete thread only"
          />
          <FormControlLabel
            disabled={isDeleteIssueError}
            value="thread-and-issue"
            control={<Radio />}
            label="Delete thread and its associated Jira issue"
          />
        </RadioGroup>
        <Typography>This action cannot be undone.</Typography>
        {isDeleteIssueError ? (
          <Alert severity="error" sx={{ my: 2 }}>
            {isJiraAppOutOfDate
              ? "This thread has been deleted but we weren't able to delete the Jira issue associated with it. This could be because the DoiT Cloud Navigator app in your Jira instance is not on the latest version."
              : "This thread has been deleted but we weren't able to delete the Jira issue associated with it. This could be due to permissions or settings in your Jira Project, or because the Jira issue has one or more sub-tasks."}
          </Alert>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={onClose} sx={{ mr: 1 }}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          loading={isDeleting}
          variant="contained"
          disabled={isDoitEmployee || deletionOption === "" || isDeleteIssueError}
          onClick={handleDeleteThread}
          mixpanelEventId="threads.delete"
        >
          Delete thread
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
