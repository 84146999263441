import { useMemo } from "react";

import { DateTime } from "luxon";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useTier } from "../../Context/TierProvider";
import { useUserContext } from "../../Context/UserContext";
import { isAllowedToOnboardStandaloneAsset } from "../../Navigation/config/utils";

export default function useIsAllowedToOnboardAzure() {
  const { customer, isProductOnlyCustomer } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { userRoles } = useUserContext();
  const { isFeatureEntitled } = useTier();

  return useMemo(
    () =>
      isAllowedToOnboardStandaloneAsset(
        { doitEmployee: isDoitEmployee, billingProfilesAdmin: !!userRoles?.billingProfilesAdmin },
        {
          startTrialInitiated: customer?.onboarding?.startTrialInitiated,
          isStandalone: isProductOnlyCustomer,
          navigatorTierTrialStartDate: customer?.tiers?.navigator.trialStartDate
            ? DateTime.fromJSDate(customer?.tiers?.navigator.trialStartDate.toDate())
            : undefined,
          isFeatureEntitled,
        },
        "pdi:azure"
      ),
    [customer, isDoitEmployee, userRoles?.billingProfilesAdmin, isProductOnlyCustomer, isFeatureEntitled]
  );
}
