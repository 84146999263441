import { useCallback, useState } from "react";

import { type PerkPlatform, type PerkSolutionType, type PerkType } from "@doitintl/cmp-models";
import { FormControlLabel, Switch, type SxProps, Typography } from "@mui/material";
import isEqual from "lodash/isEqual";

import MultiselectGroupedCheckboxes from "../../../Components/MultiSelectAutocompleteWithCheckboxes/MultiselectCheckboxes";
import { useAuthContext } from "../../../Context/AuthContext";
import { type PerksWData } from "../../../types";
import { type FilterablePerkType } from "../types";
import { perkTypeDefaultFilter, platformDefaultFilter, solutionDefaultFilter } from "./consts";

export type PerkFilterMobileBasic = {
  filterGroupDisplayName: string; // type, platform, solution
  filterValue: FilterablePerkType; // actual filter-by value
  filterNiceName: string;
};

export type MobileFiltersProps = {
  filterOptions: PerkFilterMobileBasic[];
  handleCheckboxChange: (checked: boolean, key: FilterablePerkType) => void;
  handleHideDraftsChange: (checked: boolean) => void;
  perks: PerksWData[];
  setFilteredPerks: (perks: PerksWData[]) => void;
  isOptionEqualToValue?: (option: PerkFilterMobileBasic, value: PerkFilterMobileBasic) => boolean;
  filterSettings: {
    setPlatformFilter: (filters: Record<PerkPlatform, boolean>) => void;
    setSolutionFilter: (filters: Record<PerkSolutionType, boolean>) => void;
    setPerkTypeFilter: (filters: Record<PerkType, boolean>) => void;
  };
  textFieldSx?: SxProps;
};

export const PerkFilterMobile = ({
  filterOptions,
  filterSettings,
  handleCheckboxChange,
  handleHideDraftsChange,
  textFieldSx,
}: MobileFiltersProps) => {
  const { isDoitEmployee } = useAuthContext();
  const optionDisplayLabel = useCallback((option: PerkFilterMobileBasic) => option.filterNiceName, []);
  const [hideDrafts, setHideDrafts] = useState<boolean>(true);

  const handleToggleHideDrafts = useCallback(
    (hide: boolean) => {
      handleHideDraftsChange(hide);
      setHideDrafts(hide);
    },
    [handleHideDraftsChange]
  );

  const renderTags = (selectedValues: PerkFilterMobileBasic[]) => {
    let output = "";
    if (selectedValues.length >= 1) {
      output += selectedValues[0].filterNiceName;
    }
    if (selectedValues.length > 1) {
      output += ` +${selectedValues.length - 1}`;
    }
    return <Typography paddingLeft={1}>{output}</Typography>;
  };

  return (
    <>
      {isDoitEmployee && (
        <FormControlLabel
          control={
            <Switch
              checked={hideDrafts}
              onChange={(e) => handleToggleHideDrafts(e.target.checked)}
              data-cy="toggle-drafts"
            />
          }
          label="Hide drafts"
        />
      )}
      <MultiselectGroupedCheckboxes<PerkFilterMobileBasic>
        options={filterOptions}
        optionDisplayLabel={optionDisplayLabel}
        optionGroupBy={(option) => option.filterGroupDisplayName}
        isOptionEqualToValue={(option, value) => isEqual(option.filterValue, value.filterValue)}
        onChange={(v: PerkFilterMobileBasic[]) => {
          const actualFilterValues = v.map((filter: PerkFilterMobileBasic) => filter.filterValue);

          // reset everything
          const { setPlatformFilter, setSolutionFilter, setPerkTypeFilter } = filterSettings;
          setPlatformFilter(platformDefaultFilter);
          setSolutionFilter(solutionDefaultFilter);
          setPerkTypeFilter(perkTypeDefaultFilter);

          // only reapply selected filters
          actualFilterValues.forEach((filter) => {
            handleCheckboxChange(true, filter);
          });
        }}
        fieldPlaceholder="Filter"
        renderTags={renderTags}
        defaultSelectAll={false}
        maxWidth
        textFieldSx={textFieldSx}
      />
    </>
  );
};
