import { type MouseEvent, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import MenuIcon from "@mui/icons-material/Settings";
import { IconButton, Menu as MUIMenu, MenuItem } from "@mui/material";

import { useApiContext } from "../../api/context";
import { useCustomerId } from "../../Components/hooks/useCustomerId";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useAvatarLink } from "../../Navigation/Components/Header/AvatarMenu/useAvatarLink";
import { type Organization } from "../types";

type Props = {
  toggleOrganizationSharing: (organization: Organization) => void;
  organization: Organization;
  downloadTickets: () => void;
  setIsDefaultEmailsDialogOpen: (value: boolean) => void;
  isCurrentUserUserManager: boolean;
  loadTickets: ({ isDoitEmployee, userId }: { isDoitEmployee: boolean; userId: string }) => void;
};

export const SettingsButton = ({
  toggleOrganizationSharing,
  organization,
  downloadTickets,
  setIsDefaultEmailsDialogOpen,
  isCurrentUserUserManager,
  loadTickets,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const customerId = useCustomerId();

  const { customer } = useCustomerContext({ allowNull: true });
  const { isDoitEmployee, userId } = useAuthContext({ mustHaveUser: true });

  const avatarLink = useAvatarLink({ isDoitEmployee, customerId: customer?.id, userId });
  const { t } = useTranslation("services");

  const snackbar = useSnackbar();
  const history = useHistory();

  const api = useApiContext();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const baseUrl = "/support";

  const handleDisableConcedefy = useCallback(async () => {
    handleClose();
    const concedefyState = organization?.organization_fields?.concedefy_disabled;

    await api.request({
      method: "put",
      url: `${baseUrl}/customers/${customerId}/concedefy-enabled`,
      data: { concedefyDisabled: !concedefyState },
    });

    loadTickets({ isDoitEmployee, userId });
    snackbar.onOpen({
      message: `Grant access command is ${concedefyState ? "enabled" : "disabled"}`,
      variant: "info",
      autoHideDuration: 6000,
      withClose: true,
    });
  }, [
    organization?.organization_fields?.concedefy_disabled,
    api,
    customerId,
    loadTickets,
    isDoitEmployee,
    userId,
    snackbar,
  ]);

  return (
    <>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <MUIMenu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            toggleOrganizationSharing(organization);
          }}
        >
          {organization.shared_tickets
            ? t("SettingsButton.disableRequestSharing")
            : t("SettingsButton.enableRequestSharing")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            downloadTickets();
          }}
        >
          {t("SettingsButton.downloadRequests")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsDefaultEmailsDialogOpen(true);
            handleClose();
          }}
        >
          {t("SettingsButton.manageEmails")}
        </MenuItem>
        {isCurrentUserUserManager && (
          <MenuItem onClick={handleDisableConcedefy}>
            {organization?.organization_fields?.concedefy_disabled
              ? t("SettingsButton.enableAccess")
              : t("SettingsButton.disableAccess")}
          </MenuItem>
        )}
        <MenuItem onClick={() => history.push(avatarLink)}>{t("SettingsButton.changeLanguage")}</MenuItem>
      </MUIMenu>
    </>
  );
};
