import { useState } from "react";

import { type CloudFlowNodeType, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { v4 as uuid } from "uuid";

import { cloudflowTexts } from "../../../../assets/texts";
import { type NodeWitOutputModel } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { type CloudFlowNode } from "../utils/nodeTransformUtils";
import FilterCondition from "./FilterCondition";
import { FilterDialog } from "./FilterDialog";
import { type AddActionParams, type FilterGroup, type FilterGroupCondition } from "./types";

type FilterGroupProps = {
  groupId: string;
  selectedNode: CloudFlowNode<CloudFlowNodeType.ACTION> | undefined;
  groupIndex: number;
  outputModel?: UnwrappedApiServiceModelDescriptor;
  conditions: FilterGroupCondition[];
  referenceableNodes: NodeWitOutputModel[];
  setFilterGroups: (groups: FilterGroup[] | ((prevGroups: FilterGroup[]) => FilterGroup[])) => void;
  onDeleteConditionGroup: (groupId: string) => void;
};

const FilterGroupComp = ({
  groupId,
  conditions,
  outputModel,
  groupIndex,
  selectedNode,
  onDeleteConditionGroup,
  setFilterGroups,
  referenceableNodes,
}: FilterGroupProps) => {
  const [addConditionOpen, setAddConditionOpen] = useState(false);

  const onConfirmAddCondition = async ({
    groupId,
    operator,
    conditionType,
    value,
    fieldReference,
  }: AddActionParams) => {
    setFilterGroups((prevGroups: FilterGroup[]) =>
      prevGroups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              conditions: [
                ...group.conditions,
                {
                  id: uuid(),
                  field: fieldReference,
                  comparisonOperator: operator,
                  type: conditionType,
                  value,
                },
              ],
            }
          : group
      )
    );
    setAddConditionOpen(false);
  };
  const theme = useTheme();
  return (
    <>
      <FilterDialog
        outputModel={outputModel}
        open={addConditionOpen}
        selectedNode={selectedNode}
        mode="add"
        handleClose={() => {
          setAddConditionOpen(false);
        }}
        handleAction={onConfirmAddCondition}
        referenceableNodes={referenceableNodes}
        groupId={groupId}
      />
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ pb: 0.25, width: "100%" }}>
        <Typography variant="body2">
          {cloudflowTexts.FILTER_GROUP} {groupIndex + 1}
        </Typography>
        <IconButton
          onClick={() => {
            onDeleteConditionGroup(groupId);
          }}
          sx={{ p: 0.5 }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Stack
        alignItems={"flex-start"}
        gap={1}
        sx={{
          border: "1px solid",
          borderColor: theme.palette.general.outlineBorder,
          borderRadius: 1,
          padding: 1,
          width: "100%",
        }}
      >
        {conditions.map((condition, index) => (
          <>
            <FilterCondition
              outputModel={outputModel}
              key={condition.id}
              selectedNode={selectedNode}
              condition={condition}
              referenceableNodes={referenceableNodes}
              groupId={groupId}
              setFilterGroups={setFilterGroups}
            />
            {index + 1 !== conditions.length && <Typography variant="caption">{cloudflowTexts.AND}</Typography>}
          </>
        ))}
        <Button
          startIcon={<AddIcon fontSize="small" />}
          onClick={() => {
            setAddConditionOpen(true);
          }}
          color="primary"
          sx={{ textAlign: "left" }}
        >
          {cloudflowTexts.ADD_NEW_FILTER}
        </Button>
      </Stack>
    </>
  );
};

export default FilterGroupComp;
