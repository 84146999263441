import { createContext, type ReactNode, useCallback, useContext, useMemo, useState } from "react";

import {
  type BQLensBillingMode,
  type BQLensMeasurement,
  type BQLensMetric,
  type BQLensTimeFrame,
  BQLensTimeFrames,
  CustomerModel,
  DashboardModel,
  type DashboardType,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { arrayMoveImmutable } from "array-move";
import findIndex from "lodash/findIndex";
import noop from "lodash/noop";

import { defaultMeasurement, defaultMetricType } from "../Components/Dashboard/BigQueryLens/constants";
import { BillingMode } from "../Pages/Customer/constants";
import { GlobalDashboardId } from "../utils/common";
import { filterExcludeWidgetsIfStandaloneCustomer } from "../utils/widgets";
import { useAuthContext } from "./AuthContext";
import { useCustomerContext } from "./CustomerContext";
import { type DashboardsContextType, useDashboardsContext } from "./DashboardContext";
import { useInitGCPLensOrAWSLens } from "./Dashboards/InitGCPOrAWSLens";
import { useInitPulse } from "./Dashboards/InitPulse";
import { useIsFeatureEntitled } from "./TierProvider";

type DashboardValues = {
  selectedMetric: BQLensMetric;
  selectedBillingMode: BQLensBillingMode;
  selectedMeasurement: BQLensMeasurement;
  availableUnitOptions: string[];
  selectedTimeframe: BQLensTimeFrame;
};

const defaultDashboardValues: DashboardValues = {
  selectedMetric: defaultMetricType,
  selectedBillingMode: BillingMode.onDemand,
  selectedMeasurement: defaultMeasurement,
  availableUnitOptions: [],
  selectedTimeframe: BQLensTimeFrames.pastThirtyDays,
};

export type CurrentDashboardContextType = DashboardValues & {
  dashboardType?: DashboardType | null;
  dashboardIndex: number;
  currentDashboard?: DashboardsContextType["dashboards"][number];
  widgets: NonNullable<DashboardsContextType["dashboards"][number]["widgets"]>;
  clearDashboardWidgets: () => Promise<void>;
  moveWidget: (activeName: string, overName: string) => Promise<void>;
  removeWidgetFromDashboard: (widgetId: string) => Promise<void>;
  addWidgetToDashboard: (widgetId: string) => Promise<void>;
  changeWidgetWidth: (widgetId: string, width: number) => Promise<void>;
  setDashboardWidgetContextValue: <TKey extends keyof DashboardValues>(key: TKey, value: DashboardValues[TKey]) => void;
  setDashboardWidgetContextMultipleValues: (payload: Partial<DashboardValues>) => void;
  changeDashboardByName: (name: string) => Promise<void>;
  isAllowToEditDashboard: boolean;
  updateWidgets(widgets: Widget[]): Promise<void>;
};

export type Widget = DashboardsContextType["dashboards"][number]["widgets"][number];

const currentDashboardContext = createContext<CurrentDashboardContextType | undefined>(undefined);

export const CurrentDashboardContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<CurrentDashboardContextType>;
}) => {
  // make sure that
  const actualValue = useMemo(
    (): Partial<CurrentDashboardContextType> => ({
      ...defaultDashboardValues,
      widgets: [],
      dashboardIndex: -1,
      setDashboardWidgetContextValue: noop,
      setDashboardWidgetContextMultipleValues: noop,
      clearDashboardWidgets: noop as any,
      moveWidget: noop as any,
      updateWidgets: noop as any,
      removeWidgetFromDashboard: noop as any,
      addWidgetToDashboard: noop as any,
      changeWidgetWidth: noop as any,
      changeDashboardByName: noop as any,
      isAllowToEditDashboard: false,
      ...value,
    }),
    [value]
  );

  return (
    <currentDashboardContext.Provider value={actualValue as CurrentDashboardContextType}>
      {children}
    </currentDashboardContext.Provider>
  );
};

export const CurrentDashboardContextProvider = ({ children }: { children?: ReactNode }) => {
  const [dashboardValues, setDashboardValues] = useState<DashboardValues>({ ...defaultDashboardValues });
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { customer, isProductOnlyCustomer } = useCustomerContext();
  const {
    dashboards,
    attachDashboard,
    defaultDashboard,
    removeWidgetFromDashboard: removeWidgetFromDashboardById,
    addWidgetToDashboard: addWidgetToDashboardById,
    clearDashboardWidgets: clearDashboardWidgetsById,
  } = useDashboardsContext();
  const [dashboardIndex, setDashboardIndex] = useState(-1);
  const isEntitledDashboardCustom = !!useIsFeatureEntitled("dashboard:custom");

  const currentDashboard = useMemo(
    () => (dashboardIndex >= 0 && dashboardIndex < dashboards.length ? dashboards[dashboardIndex] : undefined),
    [dashboardIndex, dashboards]
  );

  useInitGCPLensOrAWSLens(currentDashboard);
  useInitPulse(currentDashboard);

  const widgets = useMemo(
    () =>
      currentDashboard?.widgets?.filter((widget) =>
        filterExcludeWidgetsIfStandaloneCustomer(widget.name, isProductOnlyCustomer)
      ) ?? [],
    [currentDashboard?.widgets, isProductOnlyCustomer]
  );

  const dashboardId = useMemo(() => currentDashboard?.id, [currentDashboard?.id]);

  const newDashboardDocRef = useMemo(
    () =>
      dashboardId
        ? getCollection(DashboardModel)
            .doc("customization")
            .collection("users")
            .doc(currentUser.uid)
            .collection("duc")
            .doc(customer.id)
            .collection("dashboards")
            .doc(dashboardId)
        : undefined,
    [currentUser.uid, dashboardId, customer.id]
  );

  const publicDashboardsDocRef = useMemo(() => {
    if (!dashboardId) {
      return;
    }
    return getCollection(CustomerModel).doc(customer.id).collection("publicDashboards").doc(dashboardId);
  }, [dashboardId, customer.id]);

  const findDashboard = useCallback(
    (nameOrId: string) => findIndex(dashboards, (d) => d.name === nameOrId || d.id === nameOrId),
    [dashboards]
  );

  const changeDashboardByName = useCallback(
    async (name: string) => {
      if (dashboards.length === 0) {
        return;
      }

      const dashboardName =
        name.localeCompare("default", undefined, { sensitivity: "base" }) === 0 ? defaultDashboard : name;

      const selectedDashboardIndex = dashboardName ? findDashboard(dashboardName) : 0;

      if (selectedDashboardIndex === -1) {
        const successAttached = await attachDashboard(name);
        if (successAttached) {
          return;
        }
      }

      setDashboardIndex(selectedDashboardIndex >= 0 ? selectedDashboardIndex : 0);
    },
    [dashboards.length, defaultDashboard, findDashboard, attachDashboard]
  );

  const moveWidget = useCallback(
    async (activeName: string, overName: string) => {
      const oldIndex = findIndex(widgets, { name: activeName });
      const newIndex = findIndex(widgets, { name: overName });
      const newWidgetsOrder = arrayMoveImmutable(widgets, oldIndex, newIndex);
      if (currentDashboard?.isPublic) {
        await publicDashboardsDocRef?.update({
          widgets: newWidgetsOrder,
        });

        return;
      }

      await newDashboardDocRef?.update({
        widgets: newWidgetsOrder,
      });
    },
    [currentDashboard, newDashboardDocRef, publicDashboardsDocRef, widgets]
  );

  const updateWidgets = useCallback(
    async (widgets: Widget[]) => {
      if (currentDashboard?.isPublic) {
        await publicDashboardsDocRef?.update({
          widgets,
        });
        return;
      }

      await newDashboardDocRef?.update({
        widgets,
      });
    },
    [currentDashboard, newDashboardDocRef, publicDashboardsDocRef]
  );

  const changeWidgetWidth = useCallback(
    async (widgetId: string, width: number) => {
      const updateWidgets = () =>
        widgets.map((widget) => ({
          ...widget,
          cardWidth: widgetId === widget.name ? width : widget.cardWidth,
        }));

      if (currentDashboard?.isPublic) {
        await publicDashboardsDocRef?.update({
          widgets: updateWidgets(),
        });

        return;
      }

      await newDashboardDocRef?.update({
        widgets: updateWidgets(),
      });
    },
    [currentDashboard?.isPublic, newDashboardDocRef, publicDashboardsDocRef, widgets]
  );

  const dashboardType = currentDashboard?.dashboardType;

  const setDashboardWidgetContextValue = useCallback(
    <TKey extends keyof DashboardValues>(key: TKey, value: DashboardValues[TKey]) => {
      setDashboardValues((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const isAllowToEditDashboard = useMemo(
    () =>
      !(
        currentDashboard &&
        !currentDashboard.allowToEdit &&
        currentDashboard.isPublic &&
        currentDashboard.ownerId !== currentUser.uid
      ) &&
      (currentDashboard?.id === GlobalDashboardId.Home || isEntitledDashboardCustom),
    [currentDashboard, currentUser.uid, isEntitledDashboardCustom]
  );

  const setDashboardWidgetContextMultipleValues = useCallback((payload: Partial<DashboardValues>) => {
    setDashboardValues((prev) => ({ ...prev, ...payload }));
  }, []);

  const removeWidgetFromDashboard = useCallback(
    async (name: string) => {
      if (!currentDashboard) {
        return;
      }
      await removeWidgetFromDashboardById(currentDashboard?.id, name);
    },
    [currentDashboard, removeWidgetFromDashboardById]
  );

  const addWidgetToDashboard = useCallback(
    async (name: string) => {
      if (!currentDashboard) {
        return;
      }
      await addWidgetToDashboardById(currentDashboard?.id, name);
    },
    [currentDashboard, addWidgetToDashboardById]
  );

  const clearDashboardWidgets = useCallback(async () => {
    if (!currentDashboard) {
      return;
    }
    await clearDashboardWidgetsById(currentDashboard?.id);
  }, [currentDashboard, clearDashboardWidgetsById]);

  const value = useMemo(
    () => ({
      ...dashboardValues,
      dashboardType,
      widgets,
      moveWidget,
      updateWidgets,
      currentDashboard,
      dashboardIndex,
      changeWidgetWidth,
      isAllowToEditDashboard,
      setDashboardWidgetContextValue,
      setDashboardWidgetContextMultipleValues,
      removeWidgetFromDashboard,
      addWidgetToDashboard,
      changeDashboardByName,
      clearDashboardWidgets,
    }),
    [
      addWidgetToDashboard,
      changeDashboardByName,
      changeWidgetWidth,
      clearDashboardWidgets,
      currentDashboard,
      dashboardIndex,
      dashboardType,
      dashboardValues,
      isAllowToEditDashboard,
      moveWidget,
      updateWidgets,
      removeWidgetFromDashboard,
      setDashboardWidgetContextMultipleValues,
      setDashboardWidgetContextValue,
      widgets,
    ]
  );

  return <currentDashboardContext.Provider value={value}>{children}</currentDashboardContext.Provider>;
};

export const useCurrentDashboardContext = () => {
  const context = useContext(currentDashboardContext);

  if (!context) {
    throw new Error("useCurrentDashboardContext must be used within a CurrentDashboardContextProvider");
  }

  return context;
};
