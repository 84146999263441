import { useCallback, useEffect, useMemo, useState } from "react";

import {
  CloudflowEngineModel,
  type CloudflowEntityModel,
  type CloudflowExecutionModel,
  FeatureDemandModel,
  type INodeModel,
  type NodeModel,
} from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  type QueryDocumentSnapshotModel,
  useCollectionData,
  useDocumentData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import { type AxiosResponse, default as axios } from "axios";
import { DateTime } from "luxon";

import { useApiContext } from "../../api/context";
import { useRoles } from "../../Components/hooks/IAM/useRoles";
import { useUsers } from "../../Components/hooks/IAM/useUsersOrInvites";
import { useErrorSnackbar, useSuccessSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { FeatureDemands } from "../../constants/featureDemands";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { type CloudflowExecutionSnap, type CloudflowWSnap, type NodeModelWSnap } from "../../types/Cloudflow";
import { consoleErrorWithSentry } from "../../utils";
import {
  createCloudflow,
  createNode,
  deleteCloudflow,
  deleteNode,
  getAwsRegions,
  testNode,
  triggerCloudflow,
  updateCloudflow,
  updateCloudflowNodes,
} from "./api";
import { isArrayOfObjects, parseResponse } from "./CloudflowBuilder/utils/testUtils";
import {
  type Cloud,
  type CloudflowDTO,
  type CloudflowTemplate,
  type CreateCloudflowInput,
  type CreateOrUpdateNode,
  type DeleteCloudflowInput,
  type TestNodeRequest,
  type TestNodeResponse,
  type TriggerCloudflowResponce,
  type UpdateCloudflowInput,
  type UpdateCloudflowNodes,
} from "./types";

export const useRegisteredInterest = () => {
  const { customer } = useCustomerContext();
  const { userModel } = useUserContext();
  const featureDemandCollection = getCollection(FeatureDemandModel);

  const [demands, demandsLoading] = useCollectionData(
    featureDemandCollection.where("feature", "==", FeatureDemands.CLOUDFLOW).where("customer", "==", customer.ref)
  );

  const onRegisterInterest = useCallback(async () => {
    if (!userModel) {
      throw new Error("User not found");
    }
    await featureDemandCollection.add({
      feature: FeatureDemands.CLOUDFLOW,
      customer: customer.ref,
      data: {
        user: userModel.ref,
        time: DateTime.now().toString(),
      },
    });
  }, [customer.ref, featureDemandCollection, userModel]);

  const isRegistered = !!demands?.length;
  const tenantHasAlreadyRegisteredInterest =
    isRegistered && !demands?.find((demand) => demand.data?.user.id === userModel?.id);

  return {
    onRegisterInterest,
    isRegistered: { customer: isRegistered, user: !tenantHasAlreadyRegisteredInterest },
    isRegisteredLoading: demandsLoading,
  };
};

const findWorkflowOwner = (collaborators?: { role: string; email: string }[]) =>
  collaborators?.find((collaborator) => collaborator.role === "owner")?.email || "";

export const useCloudflows = () => {
  const { customer } = useCustomerContext();

  const cloudflowTransform = useCallback(
    (
      data: WithFirebaseModel<CloudflowEntityModel>,
      snapshot: QueryDocumentSnapshotModel<CloudflowEntityModel> | DocumentSnapshotModel<CloudflowEntityModel>
    ): CloudflowWSnap => {
      const owner = findWorkflowOwner(data.collaborators);
      return {
        data: { ...data, owner, customer: customer.ref },
        ref: snapshot.ref,
      };
    },
    [customer.ref]
  );

  const cloudflowsRef = useMemo(
    () =>
      getCollection(CloudflowEngineModel)
        .doc("cloudflows")
        .collection("cloudflowEntities")
        .where("customer", "==", customer.ref),
    [customer.ref]
  );

  const [cloudflows, cloudflowsLoading] = useCollectionData(customer ? cloudflowsRef : undefined, {
    transform: cloudflowTransform,
  });

  return { cloudflows, cloudflowsLoading };
};

export const useCloudflow = (flowId: string) => {
  const { customer } = useCustomerContext();
  const { roles } = useRoles();
  const { users } = useUsers(roles);

  const cloudflowTransform = useCallback(
    (
      data: WithFirebaseModel<CloudflowEntityModel>,
      snapshot: QueryDocumentSnapshotModel<CloudflowEntityModel> | DocumentSnapshotModel<CloudflowEntityModel>
    ): CloudflowWSnap => {
      const owner = users?.find((user) => user.id === data.createdBy.id)?.email || "";
      return {
        data: { ...data, owner, customer: customer.ref },
        ref: snapshot.ref,
      };
    },
    [customer.ref, users]
  );

  const cloudflowDocRef = useMemo(
    () => getCollection(CloudflowEngineModel).doc("cloudflows").collection("cloudflowEntities").doc(flowId),
    [flowId]
  );

  const [cloudflow, cloudflowLoading] = useDocumentData(customer ? cloudflowDocRef : undefined, {
    transform: cloudflowTransform,
  });

  return { cloudflow, cloudflowLoading };
};

export const useCloudflowTemplates = () => {
  const templateTransform = useCallback(
    (
      data: WithFirebaseModel<CloudflowEntityModel>,
      snapshot: QueryDocumentSnapshotModel<CloudflowEntityModel> | DocumentSnapshotModel<CloudflowEntityModel>
    ): CloudflowTemplate => ({
      id: snapshot.id,
      name: data.name,
      description: data.description,
      integrations: [],
      ref: snapshot.ref,
    }),
    []
  );

  const templatesRef = useMemo(
    () =>
      getCollection(CloudflowEngineModel)
        .doc("cloudflows")
        .collection("cloudflowEntities")
        .where("type", "==", "preset"),
    []
  );

  const [templates, templatesLoading] = useCollectionData(templatesRef, {
    transform: templateTransform,
  });

  return { templates, templatesLoading };
};

export const useNodes = () => {
  const { cloudflows } = useCloudflows();
  const [nodesLoading, setNodesLoading] = useState(true);
  const [nodes, setNodes] = useState<NodeModelWSnap[]>([]);

  const nodesTransform = useCallback(
    (
      data: WithFirebaseModel<NodeModel>,
      snapshot: QueryDocumentSnapshotModel<NodeModel> | DocumentSnapshotModel<NodeModel>,
      cloudflowId: string
    ): NodeModelWSnap => ({
      cloudflowId,
      data,
      ref: snapshot.ref,
    }),
    []
  );

  useEffect(() => {
    const newNodes: NodeModelWSnap[] = [];
    cloudflows?.forEach((cloudflow, i) => {
      const nodesRef = cloudflow.ref?.collection("nodes");
      nodesRef?.get().then((snapshot) => {
        snapshot.docs.map((doc) => {
          newNodes.push(nodesTransform(doc.data(), doc, cloudflow.ref.id));
        });
        if (i === cloudflows.length - 1) {
          setNodesLoading(false);
          setNodes(newNodes);
        }
      });
    });
  }, [cloudflows, nodesTransform]);

  return { nodes, nodesLoading };
};

export const useTemplateNodes = () => {
  const { templates } = useCloudflowTemplates();
  const [templateNodesLoading, setTemplateNodesLoading] = useState(true);
  const [templateNodes, setTemplateNodes] = useState<NodeModelWSnap[]>([]);

  const nodesTransform = useCallback(
    (
      data: WithFirebaseModel<NodeModel>,
      snapshot: QueryDocumentSnapshotModel<NodeModel> | DocumentSnapshotModel<NodeModel>,
      cloudflowId: string
    ): NodeModelWSnap => ({
      cloudflowId,
      data,
      ref: snapshot.ref,
    }),
    []
  );

  useEffect(() => {
    const newNodes: NodeModelWSnap[] = [];
    templates?.forEach((cloudflow, i) => {
      const nodesRef = cloudflow.ref?.collection("nodes");
      nodesRef?.get().then((snapshot) => {
        snapshot.docs.map((doc) => {
          newNodes.push(nodesTransform(doc.data(), doc, cloudflow.ref.id));
        });

        if (i === templates.length - 1) {
          setTemplateNodesLoading(false);
          setTemplateNodes(newNodes);
        }
      });
    });
  }, [templates, nodesTransform]);

  return { templateNodes, templateNodesLoading };
};

export const useClouds = () => {
  const { templateNodes } = useTemplateNodes();

  return templateNodes.reduce((accumulator, node) => {
    accumulator.set(node.cloudflowId, [node.data.appKey]);
    return accumulator;
  }, new Map<string, Cloud[]>());
};

export const useDeleteCloudflow = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const deleteCloudFlow = useCallback(
    async (customerId: string, deleteCloudflowInput: DeleteCloudflowInput) => {
      let res: AxiosResponse<CloudflowDTO> | undefined;
      setLoading(true);
      try {
        res = await deleteCloudflow(api, customerId, deleteCloudflowInput);
        successSnackbar("CloudFlow successfully deleted");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to delete CloudFlow");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar]
  );

  return [deleteCloudFlow, loading] as const;
};

export const useCreateNode = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const create = useCallback(
    async (customerId: string, flowId: string, data: CreateOrUpdateNode) => {
      let res: AxiosResponse<CloudflowDTO> | undefined;
      try {
        setLoading(true);
        res = await createNode(api, customerId, flowId, data);
        successSnackbar("Step successfully added");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to add Step");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar]
  );

  return [create, loading] as const;
};

export const useDeleteNode = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const remove = useCallback(
    async (customerId: string, flowId: string, nodeId: string) => {
      let res: AxiosResponse<CloudflowDTO> | undefined;
      try {
        setLoading(true);
        res = await deleteNode(api, customerId, flowId, nodeId);
        successSnackbar("Step successfully deleted");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to delete step");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar]
  );

  return [remove, loading] as const;
};

export const useCreateCloudflow = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const create = useCallback(
    async (customerId: string, createCloudflowInput: CreateCloudflowInput) => {
      let res: AxiosResponse<CloudflowDTO> | undefined;
      try {
        setLoading(true);
        res = await createCloudflow(api, customerId, createCloudflowInput);
        successSnackbar("CloudFlow successfully created");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to create CloudFlow");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar]
  );

  return [create, loading] as const;
};

export const useUpdateCloudflow = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const create = useCallback(
    async (customerId: string, flowId: string, updateCloudflowInput: UpdateCloudflowInput) => {
      let res: AxiosResponse<CloudflowDTO> | undefined;
      try {
        setLoading(true);
        res = await updateCloudflow(api, customerId, flowId, updateCloudflowInput);
        successSnackbar("CloudFlow successfully updated");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to update CloudFlow");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar]
  );

  return [create, loading] as const;
};

export const useUpdateCloudflowNodes = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const create = useCallback(
    async (customerId: string, nodes: UpdateCloudflowNodes) => {
      let res: AxiosResponse<INodeModel> | undefined;
      try {
        setLoading(true);
        res = await updateCloudflowNodes(api, customerId, nodes);
        successSnackbar("CloudFlow nodes successfully updated");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to update CloudFlow");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar]
  );

  return [create, loading] as const;
};

export const useTestNode = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const run = useCallback(
    async (customerId: string, data: TestNodeRequest) => {
      if (!isArrayOfObjects(data.input)) {
        errorSnackbar("Failed to test node");
        return Promise.resolve({
          status: 500,
          data: { error: "Invalid input", status: 500 },
        }) as unknown as Promise<TestNodeResponse>;
      }

      try {
        setLoading(true);
        const res = await testNode(api, customerId, data);
        const { data: response } = res;
        const { isError } = parseResponse({ status: res.status, data: response });

        if (isError) {
          throw res.data;
        }

        successSnackbar("Node successfully tested");
        return res;
      } catch (e) {
        let error = e;

        // when worker is down, axios error is thrown, and the error object is wrapped in another object
        // when worker is up, the error object is returned directly

        if (axios.isAxiosError(e)) {
          error = (e as { response: { data: string; status: number } }).response.data;
        }

        consoleErrorWithSentry(error);
        errorSnackbar("Failed to test node");
        return error as TestNodeResponse;
      } finally {
        setLoading(false);
      }
    },
    [api, errorSnackbar, successSnackbar]
  );

  return [run, loading] as const;
};
export const useTriggerCloudflow = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const run = useCallback(
    async (customerId: string, flowId: string) => {
      let res: AxiosResponse<TriggerCloudflowResponce> | undefined;
      try {
        setLoading(true);
        res = await triggerCloudflow(api, customerId, flowId);
        successSnackbar("CloudFlow successfully triggered");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to trigger CloudFlow");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar]
  );

  return [run, loading] as const;
};

export const useCloudflowExecutions = () => {
  const { cloudflows } = useCloudflows();
  const { customer } = useCustomerContext();

  const [cloudflowExecutionsLoading, setCloudflowExecutionsLoading] = useState(true);
  const [cloudflowExecutions, setCloudflowExecutions] = useState<CloudflowExecutionSnap[]>([]);

  const cloudflowExecutionTransform = useCallback(
    (
      data: WithFirebaseModel<CloudflowExecutionModel>,
      snapshot: QueryDocumentSnapshotModel<CloudflowExecutionModel> | DocumentSnapshotModel<CloudflowExecutionModel>,
      cloudflow: CloudflowWSnap | undefined
    ): CloudflowExecutionSnap => ({
      data: {
        ...data,
        customer: customer.ref,
        cloudflow,
      },
      ref: snapshot.ref,
    }),
    [customer.ref]
  );

  useEffect(() => {
    if (customer && cloudflows) {
      const newExecutions: CloudflowExecutionSnap[] = [];
      const cloudflowExecutionRefs = getCollection(CloudflowEngineModel)
        .doc("cloudflows")
        .collection("cloudflowExecutions")
        .where("customer", "==", customer.ref);

      cloudflowExecutionRefs.get().then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          const data = doc.data();

          const cloudflow = cloudflows.find((cloudFlow) => cloudFlow.ref.id === data.cloudflowId);

          newExecutions.push(cloudflowExecutionTransform(data, doc, cloudflow));
        });

        setCloudflowExecutions(newExecutions);
        setCloudflowExecutionsLoading(false);
      });
    }
  }, [cloudflows, customer, cloudflowExecutionTransform]);

  return { cloudflowExecutions, cloudflowExecutionsLoading };
};

export const useAwsData = (accountId: string, shouldLoadRegions: boolean) => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const errorSnackbar = useErrorSnackbar();
  const [regions, setRegions] = useState<string[]>([]);
  useEffect(() => {
    if (!customer?.id || !accountId) {
      return;
    }

    async function fetch() {
      if (!shouldLoadRegions) {
        return;
      }
      try {
        const response = await getAwsRegions(api, customer?.id, accountId);
        const data = response.data.map((region) => region.RegionName);
        setRegions(data);
      } catch (e) {
        errorSnackbar("Failed to fetch regions");
        setRegions([]);
      }
    }

    fetch();
  }, [accountId, api, customer?.id, errorSnackbar, shouldLoadRegions]);

  return { regions };
};
