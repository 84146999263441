import { type Dispatch, type SetStateAction, useCallback, useState } from "react";

import { type CurrencyCodes, Renderer, type ReportFilter, TimeInterval } from "@doitintl/cmp-models";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { analyticsAlertText, globalText } from "../../../../assets/texts";
import { budgetTxt } from "../../../../assets/texts/CloudAnalytics/budget";
import { useAnalyticsDimensions } from "../../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions";
import { useCloudAnalyticsContext } from "../../../../Context/AnalyticsContext";
import { useAttributionsContext } from "../../../../Context/AttributionsContext";
import PreviewReport, { type PreviewData } from "../../../../Pages/CloudAnalytics/previewReport/PreviewReport";
import { getCols, getRows } from "../../../../Pages/CloudAnalytics/previewReport/utils";
import {
  budgetConfigurationDynamicOptions,
  BudgetConfigurationOptions,
  BudgetConfigurations,
  BudgetDynamicConfigurations,
  BudgetTypes,
  CurrencyOptions,
  getCurrencyOption,
} from "../../../../Pages/CloudAnalytics/utilities";
import { type BudgetInfo, type MetadataOption } from "../../../../types";
import { getCurrencySymbol, onKeyPressPreventNonNumeric } from "../../../../utils/common";
import { getFixedTimeInterval, getTimeRangeOption } from "../utils";
import BudgetInfoPanel from "./BudgetInfoPanel";
import DaysNextPeriodPullOver from "./DaysNextPeriodPullOver";
import MonthsNextPeriodPullOver from "./MonthsNextPeriodPullOver";
import QuartersNextPeriodPullOver from "./QuartersNextPeriodPullOver";
import YearsNextPeriodPullOver from "./YearsNextPeriodPullOver";

type Props = {
  setOverrideStep: (step: number) => void;
  dimensionOptions: MetadataOption[];
  budgetInfo: BudgetInfo;
  setBudgetInfo: Dispatch<SetStateAction<BudgetInfo>>;
  isCurrentUserEditor: boolean;
  lastPeriodCost: number;
};

const Step2 = ({ setOverrideStep, budgetInfo, setBudgetInfo, isCurrentUserEditor, lastPeriodCost }: Props) => {
  const { filteredAttributions: attributions } = useAttributionsContext();
  const { metadata: metadataSnapshots } = useCloudAnalyticsContext();
  const { dimensions } = useAnalyticsDimensions({
    metadataSnapshots,
    attributions,
  });
  const [refreshPreview, setRefreshPreview] = useState<boolean>(true);

  const [budgetConfiguration, setBudgetConfiguration] = useState<BudgetConfigurations>(
    BudgetConfigurations.SINGLE_PERIOD
  );

  const [budgetConfigurationDynamic, setBudgetConfigurationDynamic] = useState<string>(
    budgetConfigurationDynamicOptions[0].value
  );
  const handleChangeAmount = useCallback(
    (event) => {
      const newAmount = parseFloat(event.target.value);
      setBudgetInfo((prev) => ({ ...prev, budgetAmount: isNaN(newAmount) ? 0 : newAmount }));
    },
    [setBudgetInfo]
  );

  const handleBudgetConfigurationOptionChanged = useCallback((event) => {
    setBudgetConfiguration(event.target.value);
  }, []);

  const previewData: PreviewData = {
    currency: budgetInfo.currency,
    scope: budgetInfo.filters?.map((f) => f.id) ?? [],
    attributionGroups: [],
    timeInterval:
      budgetInfo.currentTypeAndFrequency.type === BudgetTypes.RECURRING
        ? budgetInfo.currentTypeAndFrequency.period
        : getFixedTimeInterval(budgetInfo.startPeriod, budgetInfo.endPeriod),
    timeRangeOptions: getTimeRangeOption(
      budgetInfo.currentTypeAndFrequency.type,
      budgetInfo.currentTypeAndFrequency.period,
      budgetInfo.startPeriod,
      budgetInfo.endPeriod
    ),
    attributionGroupsPayload: [],
    rows: getRows(attributions?.length ?? 0, []),
    filters:
      budgetInfo.filters?.flatMap((f) => {
        const md = dimensions?.find((d) => f.id === d.id);
        const filter: ReportFilter = {
          ...f,
          type: (f.type || md?.data.type) ?? "",
          position: md?._position,
          id: f.id,
          field: (f.field || md?.data.field) ?? "",
          key: (f.key || md?.data.key) ?? "",
          includeInFilter: false,
          limit: 0,
          composite: [],
          regexp: "",
          values: f.values ?? [],
        };
        return filter;
      }) ?? [],
    cols: getCols(metadataSnapshots ?? [], budgetInfo.currentTypeAndFrequency.period),
  };

  const NextPeriodPullOverInputs = () => {
    switch (budgetInfo.currentTypeAndFrequency.period) {
      case TimeInterval.DAY:
        return <DaysNextPeriodPullOver currency={budgetInfo.currency} disabled={!isCurrentUserEditor} />;
      case TimeInterval.MONTH:
        return (
          <MonthsNextPeriodPullOver
            startDate={budgetInfo.startPeriod}
            currency={budgetInfo.currency}
            disabled={!isCurrentUserEditor}
          />
        );
      case TimeInterval.QUARTER:
        return <QuartersNextPeriodPullOver currency={budgetInfo.currency} disabled={!isCurrentUserEditor} />;
      case TimeInterval.YEAR:
        return (
          <YearsNextPeriodPullOver
            startDate={budgetInfo.startPeriod}
            currency={budgetInfo.currency}
            disabled={!isCurrentUserEditor}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Stack gap={3}>
      <BudgetInfoPanel
        typeAndFrequency={budgetInfo.currentTypeAndFrequency.label}
        startDate={budgetInfo.startPeriod.toFormat("dd LLL, yyyy")}
        endDate={
          budgetInfo.currentTypeAndFrequency.type === BudgetTypes.FIXED
            ? budgetInfo.endPeriod?.toFormat("dd LLL, yyyy")
            : undefined
        }
        setOverrideStep={setOverrideStep}
        filters={budgetInfo.filters}
        dimensionOptions={dimensions ?? []}
      />

      <Box>
        <Typography variant="subtitle1" fontWeight={500} mb={2}>
          {budgetTxt.CREATE_BUDGET.BUDGET_AMOUNT}
        </Typography>

        <Autocomplete
          sx={{
            mb: 3,
          }}
          disabled={!isCurrentUserEditor}
          onChange={(_, val) => {
            if (val) {
              setBudgetInfo((prev) => ({ ...prev, currency: val.split(" ")[0] as CurrencyCodes }));
            }
          }}
          options={CurrencyOptions.map((c) => getCurrencyOption(c))}
          value={getCurrencyOption(budgetInfo.currency)}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={analyticsAlertText.STEPPER.CURRENCY}
              placeholder={analyticsAlertText.STEPPER.SELECT_CURRENCY}
              fullWidth
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />

        <TextField
          required
          variant="outlined"
          type="number"
          label={budgetTxt.CREATE_BUDGET.BUDGET_AMOUNT}
          fullWidth
          size="small"
          inputProps={{ min: 0 }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getCurrencySymbol(budgetInfo.currency)}</InputAdornment>,
          }}
          value={Math.round(budgetInfo.budgetAmount) || ""}
          onChange={handleChangeAmount}
          onKeyUp={onKeyPressPreventNonNumeric}
          disabled={
            !isCurrentUserEditor || budgetConfigurationDynamic === BudgetDynamicConfigurations.PERCENTAGE_GROWTH
          } // !isCurrentUserEditor || usePrevSpend
        />
        <Typography variant="caption" color="text.secondary">
          {budgetTxt.CREATE_BUDGET.LAST_PERIOD_ACTUAL_COST}:{" "}
          <b>
            {lastPeriodCost
              ? `${getCurrencySymbol(budgetInfo.currency)}${(Math.round(lastPeriodCost * 100) / 100).toLocaleString()}`
              : globalText.NA}
          </b>
        </Typography>
      </Box>

      {budgetInfo.currentTypeAndFrequency.type !== BudgetTypes.FIXED && (
        <>
          <Box>
            <Typography variant="subtitle1" fontWeight={500} mb={1}>
              {budgetTxt.CREATE_BUDGET.BUDGET_CONFIGURATION}
            </Typography>

            <RadioGroup
              sx={{
                pl: 1,
              }}
              value={budgetConfiguration}
              onChange={handleBudgetConfigurationOptionChanged}
            >
              {BudgetConfigurationOptions.filter((option) => {
                if (option.value === BudgetConfigurations.SPECIFY) {
                  return budgetInfo.currentTypeAndFrequency.period !== TimeInterval.WEEK;
                }
                return true;
              }).map((option) => (
                <Box key={option.value}>
                  <FormControlLabel value={option.value} control={<Radio />} label={option.label} />
                  {option.value === BudgetConfigurations.DYNAMIC &&
                    budgetConfiguration === BudgetConfigurations.DYNAMIC && (
                      <>
                        <Select
                          fullWidth
                          value={budgetConfigurationDynamic}
                          onChange={(e) => setBudgetConfigurationDynamic(e.target.value)}
                          size="small"
                        >
                          {budgetConfigurationDynamicOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              disabled={
                                option.value === BudgetDynamicConfigurations.PERCENTAGE_GROWTH && !lastPeriodCost
                              }
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>

                        {budgetConfigurationDynamic === BudgetDynamicConfigurations.PERCENTAGE_GROWTH && (
                          <TextField
                            fullWidth
                            sx={{
                              mt: 2,
                            }}
                            required
                            label={budgetTxt.CREATE_BUDGET.GROWTH}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            disabled={!isCurrentUserEditor || !lastPeriodCost}
                          />
                        )}
                      </>
                    )}
                </Box>
              ))}
            </RadioGroup>
          </Box>

          {budgetConfiguration === BudgetConfigurations.SPECIFY && <NextPeriodPullOverInputs />}
          <Box>
            <Typography variant="subtitle1" fontWeight={500} mb={1}>
              {budgetTxt.CREATE_BUDGET.ADVANCED_SETTINGS}
            </Typography>
            <FormGroup
              sx={{
                pl: 1,
              }}
            >
              <FormControlLabel control={<Checkbox />} label={budgetTxt.CREATE_BUDGET.USE_AMORTIZED_COST} />
            </FormGroup>
          </Box>

          <Box>
            <Typography variant="subtitle1" fontWeight={500} mb={1}>
              {budgetTxt.CREATE_BUDGET.BUDGET_PREVIEW}
            </Typography>
          </Box>

          <PreviewReport
            attributions={attributions}
            displayForecast
            previewData={previewData}
            renderer={Renderer.STACKED_COLUMN_CHART}
            runQuery={refreshPreview}
            setRenderer={() => {}}
            setRunQuery={(reset) => {
              setRefreshPreview(reset);
            }}
          />
        </>
      )}
    </Stack>
  );
};

export default Step2;
