import React, { createContext, useMemo } from "react";

import { Box } from "@mui/material";
import partition from "lodash/partition";

import { CloudSpendSummaryCards } from "../../../../Components/Dashboard/CloudSpendSummaryCards";
import { useFullScreen } from "../../../../utils/dialog";
import { DraggableWidgetsGrid } from "./Grid/DraggableWidgetsGrid";
import { StaticWidgetsGrid } from "./Grid/StaticWidgetsGrid";
import { useWidth } from "./hooks/useWidth";
import type { Widget } from "../../../../Context/CurrentDashboardContext";

export const WidgetsContext = createContext<[Widget[], number, boolean]>([[], 0, false]);

type Props = {
  widgets: Widget[];
  disableEditing: boolean;
};

export function WidgetsGrid({ widgets, disableEditing }: Props) {
  const [rowsParentRef, totalWidth] = useWidth();
  const { isMobile } = useFullScreen();

  const staticGrid = totalWidth < 900;

  const [cloudSpendWidget, filteredWidgets] = useMemo(() => {
    const [{ 0: cloudSpendWidget }, otherWidgets] = partition(widgets, (widget) => widget.name === "cloudSpendSummary");

    return [cloudSpendWidget, otherWidgets];
  }, [widgets]);

  return (
    <WidgetsContext.Provider value={[filteredWidgets, totalWidth, disableEditing || staticGrid]}>
      <Box ref={rowsParentRef} width="100%" />
      <Box width="100%" mb={{ xs: 2, lg: 0 }}>
        {cloudSpendWidget && <CloudSpendSummaryCards />}
      </Box>

      {staticGrid ? <StaticWidgetsGrid singleWidgetInRow={isMobile} /> : <DraggableWidgetsGrid />}
    </WidgetsContext.Provider>
  );
}
