import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router";
import { APP_KEY, CloudFlowNodeType, NODE_STATUS, type NodeParameters } from "@doitintl/cmp-models";
import {
  addEdge,
  type Connection,
  type Edge,
  type EdgeMouseHandler,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  type Node,
  type NodeMouseHandler,
  type OnConnect,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStore,
} from "@xyflow/react";
import { v4 as uuidv4 } from "uuid";

import { useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";
import { CHOICE_OPTIONS } from "../../Dialog/ManageIfNodeDialog";
import { useCreateNode, useDeleteNode } from "../../hooks";
import { type CreateOrUpdateNode, EDGE_TYPE, type NodeEdgeManagerConfig, type RFNode } from "../../types";
import { type BaseCloudflowHit } from "../algolia/types";
import { useCloudflowNodes } from "../hooks/useCloudflowNodes";
import utils from "../utils/conditionNodeUtils";
import { createEdge } from "../utils/edgeUtils";
import { applyGraphLayout } from "../utils/layoutUtils";
import { mapToCreateNodePayload, mergeCloudflowNodes } from "../utils/nodeTransformUtils";
import { initializeNode } from "../utils/nodeUtils";

const NodeEdgeManagerContext = createContext<NodeEdgeManagerConfig>({} as NodeEdgeManagerConfig);
export const useNodeEdgeManager = () => {
  const context = useContext(NodeEdgeManagerContext);
  if (!context) {
    throw new Error("useNodeEdgeManager must be used within a NodeEdgeManagerProvider");
  }
  return context;
};
export const NodeEdgeManagerProvider = ({ children }: { children?: ReactNode }) => {
  const { flowId, customerId } = useParams<{ customerId: string; flowId: string }>();
  const [nodes, setNodes, onNodesChange] = useNodesState<Node<RFNode>>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const { cloudflowNodes, cloudflowEdges, cloudflowNodesLoading } = useCloudflowNodes(flowId);
  const [create, loading] = useCreateNode();
  const [remove] = useDeleteNode();

  const activeNode: Node<RFNode> | undefined = useStore(
    (state) => state.nodes.find((node) => node.selected) as Node<RFNode>
  );
  const { getEdges, getNodes, deleteElements } = useReactFlow<Node<RFNode>>();
  const handleAddRef = useRef<(nodeType: CloudFlowNodeType, nodeId: string) => void>();
  const handleDeleteRef = useRef<(nodeId: string) => Promise<void>>();
  const handleAddActionStepRef = useRef<(sourceNode: Node<RFNode>, targetNode: Node<RFNode>, edgeData: Edge) => void>();

  const [focusedNodeId, setFocusedNodeId] = useState<string>();
  const showSuccess = useSuccessSnackbar(3);
  const [showModal, setShowModal] = useState(false);
  const [manageIfActionsId, setManageIfActionsId] = useState<string>("");
  const [deleteIfNodeId, setDeleteIfNodeId] = useState<string>("");

  const selectNode = useCallback(
    (nodeId: string | null) => {
      setNodes((prev) =>
        prev.map((node) => ({
          ...node,
          selected: nodeId !== null && node.id === nodeId,
        }))
      );
    },
    [setNodes]
  );
  const applyLayoutAndSetState = useCallback(
    (nodes, edges) => {
      const { positionedNodes, positionedEdges } = applyGraphLayout(nodes, edges);
      setNodes(positionedNodes);
      setEdges(positionedEdges);
    },
    [setEdges, setNodes]
  );

  const getTreeOfOutgoers = useCallback(
    (id: string): { nodes: Node<RFNode>[]; edges: Edge[] } => {
      const currentNode = getNodes().find((node) => node.id === id);
      if (!currentNode) return { nodes: [], edges: [] };

      const outgoers = getOutgoers<Node<RFNode>>(currentNode, getNodes(), getEdges());
      if (!outgoers.length) return { nodes: [], edges: [] };
      const connectedEdges = getConnectedEdges<Node<RFNode>>(outgoers, getEdges());

      return outgoers.reduce(
        (acc, outgoer) => {
          const childResult = getTreeOfOutgoers(outgoer.id);
          return {
            nodes: [...acc.nodes, ...childResult.nodes],
            edges: [...acc.edges, ...childResult.edges],
          };
        },
        { nodes: outgoers, edges: connectedEdges }
      );
    },
    [getEdges, getNodes]
  );

  const handleEditNode = useCallback(
    (node) => {
      selectNode(node.id);
    },
    [selectNode]
  );

  const rearrangeFlowOnDelete = useCallback(
    async (nodeId: string) => {
      const nodeToDelete = getNodes().find((node) => node.id === nodeId);
      if (!nodeToDelete) return;

      const [incomer] = getIncomers(nodeToDelete, getNodes(), getEdges());
      const connectedEdges = getConnectedEdges([nodeToDelete], getEdges());

      // TODO: Use api for deleting if node
      if (incomer.type === CloudFlowNodeType.CONDITION) {
        const { newNodes, newEdges } = await utils.rearrangeFlowOnDeleteIfNode({
          nodeId,
          nodes: getNodes(),
          edges: getEdges(),
          handleDeleteRef,
          handleAddRef,
          deleteElements,
        });
        const updatedNodes = [...getNodes().filter((node) => node.id !== nodeId), ...newNodes];
        const filteredEdges = getEdges().filter((edge) => !connectedEdges.includes(edge));
        const updatedEdges = [...filteredEdges, ...newEdges];

        // Preserve the order of edges for If node branches
        const sortedEdges = utils.sortEdgesByHandle(updatedEdges);
        applyLayoutAndSetState(updatedNodes, sortedEdges);
      } else {
        try {
          remove(customerId, flowId, nodeId);
        } catch (error) {
          consoleErrorWithSentry(error);
        }
      }
    },
    [getNodes, getEdges, deleteElements, applyLayoutAndSetState, remove, customerId, flowId]
  );

  const handleDeleteNode = useCallback(
    async (nodeId: string) => {
      selectNode(null);

      const nodeToDelete = getNodes().find((node) => node.id === nodeId);
      if (!nodeToDelete) return;

      if (nodeToDelete.type === CloudFlowNodeType.CONDITION) {
        setDeleteIfNodeId(nodeId);
        return;
      }

      await rearrangeFlowOnDelete(nodeId);
    },
    [selectNode, getNodes, rearrangeFlowOnDelete]
  );

  const doesNodeHaveChildren = useCallback(
    (nodeId: string) => utils.doesNodeHaveChildren(getNodes(), getEdges(), nodeId),
    [getEdges, getNodes]
  );

  const handleAddNode = useCallback(
    (nodeType: CloudFlowNodeType, nodeId: string) => {
      if (nodeType === CloudFlowNodeType.ACTION) {
        setFocusedNodeId(nodeId);
        setShowModal(true);
        return;
      }
      const newNode = initializeNode(nodeType, nodeId);
      if (nodeType === CloudFlowNodeType.CONDITION) {
        if (doesNodeHaveChildren(nodeId)) {
          setManageIfActionsId(nodeId);
          return;
        }

        const { newNodes, newEdges } = utils.handleAddIfNode({
          nodes: getNodes(),
          edges: getEdges(),
          newNode,
          handleAddNode,
          handleDeleteNode,
          handleEditNode,
        });
        applyLayoutAndSetState(newNodes, newEdges);
        selectNode(newNode.id);
      } else {
        const updatedNodes = getNodes().map((node: Node<RFNode>) =>
          node.id === nodeId
            ? {
                ...newNode,
                data: {
                  ...newNode.data,
                  nodeData: {
                    ...newNode.data.nodeData,
                    transitions: node.data.nodeData.transitions,
                  },
                  isActive: true,
                  onEditNode: () => handleEditNode(newNode),
                  onDeleteNode: () => handleDeleteNode(nodeId),
                  touched: true,
                },
                name: newNode.data.name,
              }
            : node
        );
        applyLayoutAndSetState(updatedNodes, getEdges());
      }
    },
    [doesNodeHaveChildren, getNodes, getEdges, handleDeleteNode, handleEditNode, applyLayoutAndSetState, selectNode]
  );

  const restoreNodeToInitialState = useCallback(
    (nodeType: CloudFlowNodeType, nodeId: string) => {
      const reinitializedNode = initializeNode(nodeType, nodeId);
      const updatedNodes = getNodes().map((node) => {
        if (node.id === reinitializedNode.id) {
          return {
            ...reinitializedNode,
            data: {
              ...reinitializedNode.data,
              onAddNode: handleAddNode,
              onDeleteNode: () => handleDeleteNode(nodeId),
              onEditNode: () => handleEditNode(reinitializedNode),
            },
          };
        }
        return node;
      });

      applyLayoutAndSetState(updatedNodes, getEdges());
    },
    [applyLayoutAndSetState, getEdges, getNodes, handleAddNode, handleDeleteNode, handleEditNode]
  );

  const onConfirmDeleteIfNode = useCallback(async () => {
    selectNode(null);

    const nodeToDelete = getNodes().find((node) => node.id === deleteIfNodeId);
    if (!nodeToDelete) return;

    const childNodes = utils.getNodeDescendants(getNodes(), getEdges(), deleteIfNodeId);
    const connectedEdges = getConnectedEdges([nodeToDelete], getEdges());

    await handleDeleteRef.current?.(deleteIfNodeId);
    const [incomer] = getIncomers(nodeToDelete, getNodes(), getEdges());

    const { filteredNodes, filteredEdges } = utils.filterDeletedNodeDescendants(
      getNodes(),
      getEdges(),
      childNodes,
      connectedEdges,
      deleteIfNodeId
    );

    if (incomer.type === CloudFlowNodeType.CONDITION) {
      const { newNodes, newEdges } = utils.handleDeleteIfNodeParent({
        currentNode: nodeToDelete,
        incomer,
        nodes: getNodes(),
        edges: getEdges(),
        handleDeleteRef,
        handleAddRef,
      });

      const updatedNodes = [...filteredNodes, ...newNodes];
      const updatedEdges = [...filteredEdges, ...newEdges];

      // Sort edges by handle for correct rendering
      const sortedEdges = utils.sortEdgesByHandle(updatedEdges);

      applyLayoutAndSetState(updatedNodes, sortedEdges);
    } else {
      const newGhostNode = {
        id: uuidv4(),
        type: CloudFlowNodeType.GHOST,
        position: { x: nodeToDelete.position.x, y: nodeToDelete.position.y },
        data: {},
      };

      const newGhostEdge = createEdge(incomer.id, newGhostNode.id, EDGE_TYPE.GHOST);

      const updatedNodes = [...filteredNodes, newGhostNode];
      const updatedEdges = [...filteredEdges, newGhostEdge];

      applyLayoutAndSetState(updatedNodes, updatedEdges);
    }

    setDeleteIfNodeId("");
    showSuccess("Step successfully deleted");
  }, [selectNode, getNodes, getEdges, deleteIfNodeId, showSuccess, applyLayoutAndSetState]);

  // This function adds a new action step card between source and target
  const handleAddActionStepCard = useCallback(
    async (sourceNode: Node<RFNode>, targetNode: Node<RFNode>, edgeData: Edge) => {
      const newNodeId = uuidv4();
      const newNode: Node<RFNode> = {
        id: newNodeId,
        type: CloudFlowNodeType.ACTION_STEP,
        data: {
          touched: true,
          nodeData: {
            name: "What do you want to do?",
            display: {
              position: {
                x: sourceNode.position.x,
                y: targetNode.position.y,
              },
            },
            type: CloudFlowNodeType.ACTION_STEP,
            status: NODE_STATUS.PENDING,
            parameters: undefined,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
          onAddNode: handleAddNode,
          onDeleteNode: () => handleDeleteNode(newNode.id),
        },
        position: {
          x: 0,
          y: 0,
        },
      };

      setEdges((prevEdges: Edge[]) =>
        prevEdges.map((edge) => {
          if (edge.id === edgeData.id) {
            return {
              ...edge,
              data: {
                ...edge.data,
                loading: true,
              },
            };
          }
          return edge;
        })
      );

      const newNodeRequestData: CreateOrUpdateNode = {
        transition: {
          parentNodeId: sourceNode.id,
          targetNodeId: newNodeId,
        },
        node: mapToCreateNodePayload(newNode, targetNode),
      };

      try {
        create(customerId, flowId, newNodeRequestData);
      } catch (error) {
        consoleErrorWithSentry(error);
      }

      // This will trigger closing the side panel to avoid mismatched node configuration.
      // TBD with product team
      selectNode(null);
    },
    [handleAddNode, setEdges, create, customerId, flowId, handleDeleteNode, selectNode]
  );

  useEffect(() => {
    if (cloudflowNodesLoading) {
      return;
    }
    const localStateNodes = getNodes();
    const uiEnrichedNodes = mergeCloudflowNodes(
      cloudflowNodes,
      localStateNodes,
      handleAddNode,
      handleEditNode,
      handleDeleteNode
    );

    applyLayoutAndSetState(uiEnrichedNodes, cloudflowEdges);
  }, [
    cloudflowNodesLoading,
    cloudflowNodes,
    cloudflowEdges,
    handleAddNode,
    handleDeleteNode,
    setNodes,
    setEdges,
    handleEditNode,
    applyLayoutAndSetState,
    getNodes,
  ]);

  // these refs are to avoid circular dependencies, so every action is a ref ad could be accessible no matter where it is defined or called
  useEffect(() => {
    handleAddRef.current = handleAddNode;
    handleDeleteRef.current = handleDeleteNode;
    handleAddActionStepRef.current = handleAddActionStepCard;
  }, [handleAddActionStepCard, handleAddNode, handleDeleteNode]);

  const handleDeleteActions = useCallback(
    (manageIfActionsId: string) => {
      const { newNodes, newEdges, newNode } = utils.handleDeleteActions({
        nodes: getNodes(),
        edges: getEdges(),
        manageIfActionsId,
        handleAddNode,
        handleDeleteNode,
        handleEditNode,
        getTreeOfOutgoers,
      });

      applyLayoutAndSetState(newNodes, newEdges);
      selectNode(newNode.id);
    },
    [
      selectNode,
      applyLayoutAndSetState,
      getEdges,
      getNodes,
      getTreeOfOutgoers,
      handleAddNode,
      handleDeleteNode,
      handleEditNode,
    ]
  );

  const handleMoveActions = useCallback(
    (manageIfActionsId: string, moveToTrue: boolean) => {
      const { newNodes, newEdges, newNode } = utils.handleMoveActions({
        nodes: getNodes(),
        edges: getEdges(),
        manageIfActionsId,
        moveToTrue,
        handleAddNode,
        handleDeleteNode,
        handleEditNode,
      });

      applyLayoutAndSetState(newNodes, newEdges);
      selectNode(newNode.id);
    },
    [applyLayoutAndSetState, getEdges, getNodes, handleAddNode, handleDeleteNode, handleEditNode, selectNode]
  );

  const onSaveManageIfActionsDialog = useCallback(
    (choice: string) => {
      const currentNode = getNodes().find((node) => node.id === manageIfActionsId);

      if (!currentNode) {
        return;
      }

      const { type: nodeType, id: nodeId, position } = currentNode;

      if (!nodeType || !nodeId || !position) {
        return;
      }

      switch (choice) {
        case CHOICE_OPTIONS.MOVE_ACTIONS_TO_TRUE:
          handleMoveActions(manageIfActionsId, true);
          break;
        case CHOICE_OPTIONS.MOVE_ACTIONS_TO_FALSE:
          handleMoveActions(manageIfActionsId, false);
          break;
        case CHOICE_OPTIONS.DELETE_ACTIONS:
          handleDeleteActions(manageIfActionsId);
          break;
      }

      setManageIfActionsId("");
    },
    [getNodes, handleDeleteActions, handleMoveActions, manageIfActionsId]
  );

  const onEdgeClick: EdgeMouseHandler<Edge> = useCallback(
    (_event, edgeData) => {
      const sourceNode = getNodes().find((node) => node.id === edgeData.source);
      const targetNode = getNodes().find((node) => node.id === edgeData.target);
      if (
        !loading &&
        sourceNode &&
        targetNode &&
        edgeData.type !== EDGE_TYPE.CONDITION &&
        sourceNode.type !== CloudFlowNodeType.ACTION_STEP &&
        targetNode.type !== CloudFlowNodeType.ACTION_STEP
      ) {
        handleAddActionStepCard(sourceNode, targetNode, edgeData);
      }
    },
    [getNodes, handleAddActionStepCard, loading]
  );

  const handleActionNodeInit = useCallback(
    (nodeId: string, item: BaseCloudflowHit) => {
      const newNode = initializeNode(CloudFlowNodeType.ACTION, nodeId);

      const parameters = {
        provider: item.provider,
        operation: {
          id: item.operationName,
          service: item.serviceNameShort,
          version: item.versionId,
          provider: item.provider,
        },
      };

      const updatedNodes: Node<RFNode>[] = getNodes().map((node: Node<RFNode>) => {
        if (node.id !== nodeId) {
          return node;
        }

        return {
          ...newNode,
          selected: true,
          data: {
            ...newNode.data,
            nodeData: {
              ...newNode.data.nodeData,
              name: item.operationName,
              status: NODE_STATUS.ERROR,
              statusMessage: "Additional permissions required",
              parameters: parameters as NodeParameters,
              transitions: node.data.nodeData.transitions,
            },
            touched: true,
            onEditNode: () => handleEditNode(newNode),
            onDeleteNode: () => handleDeleteNode(nodeId),
          },
        };
      });

      applyLayoutAndSetState(updatedNodes, edges);
      setShowModal(false);
    },
    [applyLayoutAndSetState, edges, getNodes, handleDeleteNode, handleEditNode]
  );

  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const onConnect: OnConnect = useCallback(
    (params: Connection | Edge) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onNodeClick: NodeMouseHandler<Node<RFNode>> = useCallback(
    (_event, node) => {
      switch (node.type) {
        case CloudFlowNodeType.START_STEP:
        case CloudFlowNodeType.ACTION_STEP:
        case CloudFlowNodeType.ACTION:
          return;
        default:
          if (!activeNode || activeNode.id !== node.id) {
            selectNode(node.id);
          }
      }
    },
    [activeNode, selectNode]
  );

  const onChangeActiveNode = useCallback(
    (nodeType: CloudFlowNodeType, nodeId: string) => {
      selectNode(null);
      setFocusedNodeId(nodeId);
      if (nodeType === CloudFlowNodeType.ACTION) {
        setShowModal(true);
        return;
      }
      restoreNodeToInitialState(nodeType, nodeId);
    },
    [restoreNodeToInitialState, selectNode]
  );

  const data = useMemo(
    () => ({
      activeNode,
      nodes,
      edges,
      setEdges,
      focusedNodeId,
      onNodesChange,
      onEdgesChange,
      handleEditNode,
      handleAddActionStepCard,
      onConnect,
      onEdgeClick,
      showModal,
      closeModal,
      handleActionNodeInit,
      onConfirmDeleteIfNode,
      deleteIfNodeId,
      setDeleteIfNodeId,
      manageIfActionsId,
      setManageIfActionsId,
      onSaveManageIfActionsDialog,
      handleAddNode,
      onNodeClick,
      onChangeActiveNode,
      selectNode,
      setNodes,
    }),
    [
      activeNode,
      nodes,
      edges,
      setEdges,
      focusedNodeId,
      onNodesChange,
      onEdgesChange,
      handleEditNode,
      handleAddActionStepCard,
      onConnect,
      onEdgeClick,
      showModal,
      closeModal,
      handleActionNodeInit,
      onConfirmDeleteIfNode,
      deleteIfNodeId,
      setDeleteIfNodeId,
      manageIfActionsId,
      setManageIfActionsId,
      onSaveManageIfActionsDialog,
      handleAddNode,
      onNodeClick,
      onChangeActiveNode,
      selectNode,
      setNodes,
    ]
  );

  return <NodeEdgeManagerContext.Provider value={data}>{children}</NodeEdgeManagerContext.Provider>;
};
