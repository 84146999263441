import { createContext, type MutableRefObject, type ReactNode, useContext, useRef } from "react";

import { useClipboard } from "use-clipboard-copy";

import { reportText } from "../../../assets/texts";
import { reportTxt } from "../../../assets/texts/CloudAnalytics";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { convertToCSV, exportCSVFile } from "../../../utils/csv";
import { refactorReportDataToCsv } from "../utilities";
import { useReportConfig } from "./ReportConfigContext";
import { useReportContext } from "./ReportContext";

type ReportExportContextType = {
  chartInstanceRef: MutableRefObject<any>;
  handleDownload: (type: string) => void;
  handleCSVExport: () => void;
  handleCSVCopyToClipboard: () => void;
};

export const ReportExportContext = createContext<ReportExportContextType>({} as ReportExportContextType);

export const ReportExportContextProvider = ({ children }: { children?: ReactNode }) => {
  const { report } = useReportContext();
  const { reportConfig, reportData, reportDataWForecasts } = useReportConfig();
  const { aggregator, metric, forecastSettings } = reportConfig;
  const chartInstanceRef = useRef<any>(null);
  const errorSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();

  const forecastMode = forecastSettings?.mode;

  const clipboard = useClipboard({
    onSuccess: () => successSnackbar(reportText.CSV_COPIED_TO_CLIPBOARD_SUCCESS),
  });

  const handleDownload = (type: string) => {
    if (!chartInstanceRef.current?.chart?.exportChart) {
      errorSnackbar(reportTxt.CHART_EXPORT_FAILED);
    } else {
      const filename = report.data.name || reportText.EXPORTED_REPORT;
      chartInstanceRef.current.chart.exportChart({ type, filename });
    }
  };

  const handleCSVExport = () => {
    const dataProp =
      !!reportConfig.forecastSettings && reportDataWForecasts.current
        ? reportDataWForecasts.current
        : reportData.current;
    const csvData = refactorReportDataToCsv(dataProp, aggregator, metric, forecastMode);
    const reportName = report.data.name || reportText.EXPORTED_REPORT;
    if (csvData?.headers && csvData?.data) {
      exportCSVFile(csvData.headers, csvData.data, reportName.replace(/\./g, "_"));
    }
  };

  const handleCSVCopyToClipboard = () => {
    const dataProp =
      !!reportConfig.forecastSettings && reportDataWForecasts.current
        ? reportDataWForecasts.current
        : reportData.current;
    const csvData = refactorReportDataToCsv(dataProp, aggregator, metric, forecastMode);
    try {
      if (csvData?.headers && csvData?.data) {
        csvData?.data.unshift(csvData?.headers);
        const jsonObject = JSON.stringify(csvData.data);
        const csv = convertToCSV(jsonObject);
        clipboard.copy(csv);
      } else {
        throw new Error();
      }
    } catch (e) {
      errorSnackbar(reportText.CSV_COPIED_TO_CLIPBOARD_FAIL);
    }
  };

  return (
    <ReportExportContext.Provider
      value={{
        chartInstanceRef,
        handleDownload,
        handleCSVExport,
        handleCSVCopyToClipboard,
      }}
    >
      {children}
    </ReportExportContext.Provider>
  );
};

export const useReportExportContext = (): ReportExportContextType => useContext(ReportExportContext);
