import { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { TimeInterval, TimeSettingsMode } from "@doitintl/cmp-models";
import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import { DefinitionList, DefinitionListDesc, DefinitionListTerm } from "../../../Components/DefinitionList";
import { useBudgets } from "../../../Components/hooks/cloudAnalytics/budgets/useBudgets";
import { MetadataCard } from "../../../Components/MetadataCard";
import { useCloudAnalyticsContext } from "../../../Context/AnalyticsContext";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { formatUSDollars, sanitizeDate } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import { useQueryRun } from "../previewReport/useQueryRun";
import { getCols, getFilters } from "../previewReport/utils";
import useHighchartsRenderer from "../renderers/useHighchartsRenderer";
import { BudgetTypes, type TimeRangeOption } from "../utilities";
import { getAttributionScope } from "../utils/getScope";
import PerformanceChart from "./PerformanceChart";
import { getAlertAmountFromPercentage } from "./shared";
import {
  convertToUTCTimestamp,
  daysElapsedSinceStartOfMonth,
  daysElapsedSinceStartOfWeek,
  daysInCurrentMonth,
  filterByCurrentDay,
  filterByCurrentMonth,
  filterByCurrentQuarter,
  filterByCurrentWeek,
  filterByCurrentYear,
  getStartAndEndOfMonthUTC,
  getStartAndEndOfQuarterUTC,
  getStartAndEndOfWeekUTC,
  getStartAndEndOfYearUTC,
  hoursElapsedSinceStartOfDay,
  makeDataCumulative,
  monthsElapsedSinceStartOfYear,
  weeksElapsedSinceStartOfQuarter,
  weeksInCurrentQuarter,
} from "./utils";
import { ViewBudgetHeader } from "./ViewBudgetHeader";
import type ReportData from "../ReportData";

const previewTimeByInterval = {
  [TimeInterval.DAY]: TimeInterval.HOUR,
  [TimeInterval.WEEK]: TimeInterval.DAY,
  [TimeInterval.MONTH]: TimeInterval.DAY,
  [TimeInterval.QUARTER]: TimeInterval.WEEK,
  [TimeInterval.YEAR]: TimeInterval.MONTH,
};

const fullPreviewAmountByInterval = {
  [TimeInterval.DAY]: 24,
  [TimeInterval.WEEK]: 7,
  [TimeInterval.MONTH]: daysInCurrentMonth(),
  [TimeInterval.QUARTER]: weeksInCurrentQuarter(),
  [TimeInterval.YEAR]: 12,
};

const previewAmountByInterval = {
  [TimeInterval.DAY]: hoursElapsedSinceStartOfDay(),
  [TimeInterval.WEEK]: daysElapsedSinceStartOfWeek(),
  [TimeInterval.MONTH]: daysElapsedSinceStartOfMonth(),
  [TimeInterval.QUARTER]: weeksElapsedSinceStartOfQuarter(),
  [TimeInterval.YEAR]: monthsElapsedSinceStartOfYear(),
};

const rangeByInterval = {
  [TimeInterval.WEEK]: getStartAndEndOfWeekUTC(),
  [TimeInterval.MONTH]: getStartAndEndOfMonthUTC(),
  [TimeInterval.QUARTER]: getStartAndEndOfQuarterUTC(),
  [TimeInterval.YEAR]: getStartAndEndOfYearUTC(),
};

const filterForecastedByInterval = {
  [TimeInterval.DAY]: filterByCurrentDay,
  [TimeInterval.WEEK]: filterByCurrentWeek,
  [TimeInterval.MONTH]: filterByCurrentMonth,
  [TimeInterval.QUARTER]: filterByCurrentQuarter,
  [TimeInterval.YEAR]: filterByCurrentYear,
};

export const ViewBudget = () => {
  const { metadata: metadataSnapshots } = useCloudAnalyticsContext();
  const { filteredAttributions: attributions } = useAttributionsContext();
  const [, budgets] = useBudgets();

  const { budgetId } = useParams<{ budgetId: string }>();

  const budget = budgets.find((b) => b.snapshot.id === budgetId);

  const amount = budget?.data.config.amount || 1000;
  const alerts = budget?.data.config.alerts.map((a) => ({
    ...a,
    amount: getAlertAmountFromPercentage(a.percentage, amount),
  }));
  const scope = budget && getAttributionScope(budget.data.config.scope, attributions);
  const timeInterval = budget?.data.config.timeInterval;

  const type = budget?.data.config.type;
  const currency = budget?.data.config.currency || "USD";
  const startPeriod = budget?.data.config.startPeriod
    ? DateTime.fromMillis(budget?.data.config.startPeriod.seconds * 1000).toUTC()
    : sanitizeDate(DateTime.utc());
  const endPeriod = budget?.data.config.endPeriod
    ? DateTime.fromMillis(budget?.data.config.endPeriod.seconds * 1000).toUTC()
    : sanitizeDate(DateTime.utc());
  const actual = budget?.data.utilization?.current || 0;
  const forecasted = budget?.data.utilization?.forecasted;
  const forecastedDateSeconds = budget?.data.utilization?.forecastedTotalAmountDate?.seconds;
  const variance = Math.abs(actual - amount);
  const isInit = useRef(false);
  const [runQuery, setRunQuery] = useState(false);

  const previewTime = timeInterval && previewTimeByInterval[timeInterval];

  const actualDataPreviewAmount = timeInterval && previewAmountByInterval[timeInterval];

  const fullPreviewAmount = timeInterval && fullPreviewAmountByInterval[timeInterval];

  const newTimeRangeOptions: TimeRangeOption = {
    mode:
      timeInterval === TimeInterval.WEEK ||
      timeInterval === TimeInterval.MONTH ||
      timeInterval === TimeInterval.QUARTER ||
      timeInterval === TimeInterval.YEAR
        ? TimeSettingsMode.Fixed
        : TimeSettingsMode.Last,
    time: previewTime,
    amount: actualDataPreviewAmount,
    includeCurrent: true,
  };

  const range = timeInterval && rangeByInterval[timeInterval];
  if (range) {
    newTimeRangeOptions.range = range;
  }

  const previewData = {
    currency,
    scope: scope?.map((s) => s.ref.id) || [],
    attributionGroups: [],
    timeInterval: previewTime,
    timeRangeOptions: newTimeRangeOptions,
    attributionGroupsPayload: [],
    rows: [],
    filters: getFilters(scope?.map((s) => s.ref.id) || []),
    cols: getCols(metadataSnapshots ?? [], previewTime),
  };

  const { isMobile } = useFullScreen("lg");

  const reportDataWForecasts = useRef<ReportData | null>(null);
  const reportData = reportDataWForecasts.current;
  const [queryRunning, setQueryRunning] = useState(false);
  const [reportError, setReportError] = useState<string | null>(null);
  const { categories, series } = useHighchartsRenderer({
    data: reportData,
    isComparative: false,
  });

  const categoriesByAmount = categories.slice(0, fullPreviewAmount);
  const timestamps = categoriesByAmount.map(convertToUTCTimestamp);

  const data = series?.[0]?.data;
  const actualData = makeDataCumulative(
    timestamps.slice(0, actualDataPreviewAmount).map((timestamp, index) => ({ x: timestamp, y: data?.[index] || 0 }))
  );
  const forecastData = reportData?.forecasts
    ?.filter((i) => i.value !== null)
    ?.map((forecastItem) => ({
      x: convertToUTCTimestamp(forecastItem.date),
      y: forecastItem.value,
    }))
    .map((forecastItem) => ({ ...forecastItem, y: forecastItem.y || 0 }));

  const actualDataDates = actualData.map((i) => i.x);

  const lastActualData = actualData[actualData.length - 1];
  const cumulativeForecast = makeDataCumulative(
    forecastData?.filter((forecastItem) => !actualDataDates.includes(forecastItem.x)),
    lastActualData?.y
  );

  const forecastForTimeInterval = timeInterval && filterForecastedByInterval[timeInterval](cumulativeForecast);

  const forecastWithoutDuplicates = forecastForTimeInterval?.filter(
    (forecastItem) => !actualDataDates.includes(forecastItem.x)
  );

  const lastAlert = alerts?.[alerts.length - 1];
  const maxBudget = lastAlert?.amount || 0;
  const maxBudgetByInterval = maxBudget / categoriesByAmount.length;

  const budgetData = timestamps.map((i, index) => ({
    x: i,
    y: maxBudgetByInterval * (index + 1),
  }));

  // logic to connect two lines on chart
  const lastDataPoint = actualData[actualData.length - 1];

  if (lastDataPoint) {
    forecastWithoutDuplicates?.splice(0, 0, lastDataPoint);
  }
  const { fetchMetadata } = useCloudAnalyticsContext();

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  useEffect(() => {
    if (!isInit.current && !queryRunning && metadataSnapshots.length !== 0) {
      setRunQuery(true);
      isInit.current = true;
    }
  }, [metadataSnapshots.length, queryRunning]);

  useQueryRun({
    attributions,
    previewData,
    queryRunning,
    setQueryRunning,
    reportDataWForecasts,
    runQuery,
    setRunQuery,
    setReportError,
    forecastMode: true,
  });

  const frequency = type === BudgetTypes.RECURRING ? "recurring" : "one-time";

  const showSkeleton = !data && !reportError;

  const showEmptyInfo = actualData.length === 0 || reportError === "ERR_BAD_REQUEST";

  return (
    <>
      <ViewBudgetHeader name={budget?.data.name || ""} />
      <Stack data-testid="ramp-page" flexDirection="column" flexWrap="nowrap" gap={2.4}>
        {showSkeleton && (
          <Skeleton animation="wave" variant="rectangular" width="100%" height="397px" sx={{ borderRadius: 0.5 }} />
        )}
        {showEmptyInfo && !showSkeleton && (
          <Stack
            component={Paper}
            variant="outlined"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={414}
          >
            <Typography sx={{ fontWeight: 500, fontSize: 18, mb: 1.5 }}>Data available soon</Typography>
            <Box sx={{ width: "397px", textAlign: "center" }}>
              {capitalize(timeInterval)}ly budgets are displayed at a {previewTime}ly granularity. Data will start being
              displayed after one {previewTime}.
            </Box>
          </Stack>
        )}
        {!showSkeleton && !showEmptyInfo && (
          <PerformanceChart
            actual={actualData}
            budget={budgetData}
            forecast={forecastWithoutDuplicates}
            alerts={alerts || []}
          />
        )}
        <Stack direction={isMobile ? "column" : "row"} justifyContent="stretch" spacing={2}>
          <MetadataCard title="Budget details">
            <DefinitionList>
              <DefinitionListTerm>Type and frequency:</DefinitionListTerm>
              <DefinitionListDesc>{`${capitalize(timeInterval)}ly ${frequency}`}</DefinitionListDesc>
              <DefinitionListTerm>Start and end date:</DefinitionListTerm>
              <DefinitionListDesc>
                {`${startPeriod.toFormat("d MMM yyyy")} - ${endPeriod.toFormat("d MMM yyyy")}`}
              </DefinitionListDesc>
              <DefinitionListTerm>Dimension:</DefinitionListTerm>
              <DefinitionListDesc>{scope?.map((scopeItem) => scopeItem?.data?.name).join(", ")}</DefinitionListDesc>
              {/* <DefinitionListTerm>Budget configuration:</DefinitionListTerm>
              <DefinitionListDesc>Budget tracks against a single period budgeted amount</DefinitionListDesc>
              <DefinitionListTerm>Amortized cost:</DefinitionListTerm>
              <DefinitionListDesc>No</DefinitionListDesc> */}
            </DefinitionList>
          </MetadataCard>
          <MetadataCard title="Current period details">
            <DefinitionList>
              <DefinitionListTerm>Budgeted amount:</DefinitionListTerm>
              <DefinitionListDesc>{formatUSDollars(amount)}</DefinitionListDesc>
              <DefinitionListTerm>Actuals:</DefinitionListTerm>
              <DefinitionListDesc>{formatUSDollars(actual)}</DefinitionListDesc>
              <DefinitionListTerm>Variance:</DefinitionListTerm>
              <DefinitionListDesc color="#2E7D32">{formatUSDollars(variance)}</DefinitionListDesc>
              <DefinitionListTerm>Forecasted amount:</DefinitionListTerm>
              <DefinitionListDesc>{formatUSDollars(forecasted || 0)}</DefinitionListDesc>
              <DefinitionListTerm>Forecasted to hit budget amount:</DefinitionListTerm>
              <DefinitionListDesc>
                {forecastedDateSeconds
                  ? DateTime.fromSeconds(forecastedDateSeconds).toFormat("d MMMM yyyy")
                  : "No forecasted date"}
              </DefinitionListDesc>
            </DefinitionList>
          </MetadataCard>
        </Stack>
      </Stack>
    </>
  );
};
