import { CloudFlowNodeType } from "@doitintl/cmp-models";
import { Button, Stack } from "@mui/material";
import noop from "lodash/noop";

import { cloudflowTexts } from "../../../../assets/texts";
import { type RFNode } from "../../types";
import { SourceHandle, TargetHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: RFNode;
  selected?: boolean;
};

const buttons = [
  { label: cloudflowTexts.PERFORM_ACTION, nodeType: CloudFlowNodeType.ACTION },
  { label: cloudflowTexts.CHECK_CONDITION, nodeType: CloudFlowNodeType.CONDITION },
  { label: cloudflowTexts.RUN_LOOP, nodeType: CloudFlowNodeType.LOOP },
  { label: cloudflowTexts.FILTER_DATA, nodeType: CloudFlowNodeType.FILTER },
];

export const ActionStepConfigurator = ({ id, data }: Props) => (
  <NodeWrapper
    title={data.nodeData.name || ""}
    status={data.nodeData.status || "validated"}
    isActive={false}
    onClick={noop}
    onDelete={data.onDeleteNode}
    disableHover={true}
  >
    <Stack gap={1} mb={-1} pl={1}>
      {buttons.map((button) => (
        <Button
          key={button.label}
          variant="outlined"
          onClick={() => data.onAddNode && data.onAddNode(button.nodeType, id, data.nodeData.display?.position)}
        >
          {button.label}
        </Button>
      ))}
    </Stack>
    <SourceHandle id={id} />
    <TargetHandle id={id} />
  </NodeWrapper>
);
