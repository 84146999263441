import { getProviderTypes, NotificationProviderType } from "@doitintl/cmp-models";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Tooltip } from "@mui/material";

import SlackIcon from "../../../assets/slack-mark.png";
import { never } from "../../../utils";
import { type NotificationModelWithId, type TableNotification } from "./types";

export const providerIconCyIds: Record<NotificationProviderType, string> = {
  [NotificationProviderType.EMAIL]: "provider-icon-email",
  [NotificationProviderType.SLACK]: "provider-icon-slack",
  [NotificationProviderType.MSTEAMS]: "provider-icon-msteams",
};

export const getProviderIcon = (providerType: NotificationProviderType) => {
  switch (providerType) {
    case NotificationProviderType.EMAIL:
      return (
        <Tooltip title="Email">
          <Box data-cy={providerIconCyIds[providerType]}>
            <EmailIcon fontSize="small" sx={{ color: "action.active" }} />
          </Box>
        </Tooltip>
      );

    case NotificationProviderType.SLACK:
      return (
        <Tooltip title="Slack">
          <Box data-cy={providerIconCyIds[providerType]}>
            <img alt="slack" src={SlackIcon} width="20" height="20" />
          </Box>
        </Tooltip>
      );

    case NotificationProviderType.MSTEAMS:
      return (
        <Tooltip title="MSTeams">
          <Box data-cy={providerIconCyIds[providerType]}>
            {/* <img alt="msteams" src={MSTeamsIcon} width="20" height="20" /> */}
          </Box>
        </Tooltip>
      );

    default:
      return never(providerType, "");
  }
};

const channelsToString = (notification: NotificationModelWithId, providerType: NotificationProviderType) => {
  const { providerTarget } = notification;
  const channels = providerTarget?.[providerType];
  if (providerType === NotificationProviderType.EMAIL) {
    return channels?.join(", ");
  } else {
    return channels?.map((channel) => channel.name).join(", ");
  }
};

export const parseNotifications = (
  notifications: NotificationModelWithId[] = [],
  notificationsData?: { name: string; value: number }[]
): TableNotification[] => {
  const singleProviderNotifications = notifications.filter(
    (notification) => getProviderTypes(notification).length === 1
  );
  return singleProviderNotifications?.map((notification) => {
    const selectedNotifications = Object.keys(notification.selectedNotifications)
      .map((key) => notificationsData?.find((n) => n.value.toString() === key)?.name)
      .filter((name): name is string => name !== undefined);

    const providerType = getProviderTypes(notification)[0];
    return {
      ...notification,
      selectedNotifications: selectedNotifications.join(","),
      providerTarget: channelsToString(notification, providerType),
      providerType,
    };
  });
};

export const editCustomerNotification = (customerId: string, notificationId: string): string =>
  `/customers/${customerId}/company/notifications/edit/${notificationId}`;

export const editUserNotification = (customerId: string, notificationId: string): string =>
  `/customers/${customerId}/user/notifications/edit/${notificationId}/2`;
