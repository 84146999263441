import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Typography } from "@mui/material";

import { Alert } from "../../Components/Alerts";
import { useLocalStorage } from "../../Components/FilterTable/hook";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { browserLanguage } from "../../locales/utils";
import { useAvatarLink } from "../../Navigation/Components/Header/AvatarMenu/useAvatarLink";

export const LanguageAlert = () => {
  const [alreadyShown, setAlreadyShown] = useLocalStorage("languageAlert", false);

  const { customer } = useCustomerContext({ allowNull: true });
  const history = useHistory();
  const { isDoitEmployee, userId } = useAuthContext({ mustHaveUser: true });

  const avatarLink = useAvatarLink({ isDoitEmployee, customerId: customer?.id, userId });
  const { i18n } = useTranslation();

  if (!alreadyShown && browserLanguage === "ja") {
    return (
      <Alert
        buttonProps={{
          text: "Change to English",
          onClick: () => {
            setAlreadyShown(true);
            i18n.changeLanguage("en");
            localStorage.setItem("i18nLng", "en");
            history.push(avatarLink);
          },
        }}
        severity="info"
        onClose={() => setAlreadyShown(true)}
      >
        <Typography>Your console is set to Japanese</Typography>
      </Alert>
    );
  }
};
