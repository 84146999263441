import { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { CloudAnalyticsModel, type CloudAnalyticsModelBudgetModel, Roles } from "@doitintl/cmp-models";
import { getCollection, type WithFirebaseModel } from "@doitintl/models-firestore";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import uniqBy from "lodash/uniqBy";

import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import BudgetUtilizationProgressBar from "../../../Pages/CloudAnalytics/budgets/BudgetUtilizationProgressBar";
import { getUtilizationProgressBarColor } from "../../../Pages/CloudAnalytics/budgets/shared";
import { BudgetTypes } from "../../../Pages/CloudAnalytics/utilities";
import { WidgetCardHeader } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { getDateTimeFromFirestoreTimestamp } from "../../../utils/common";
import { TimestampNow } from "../../../utils/firebase";
import { budgetStyles, CARD_HEADER_HEIGHT } from "./cloudCardStyle";
import type { WidgetItemProps } from "../types";

export default function BudgetsCard({ widgetHeight = 1 }: WidgetItemProps) {
  const classes = budgetStyles();
  const history = useHistory();
  const { isDoitEmployee, currentUser } = useAuthContext({ mustHaveUser: true });
  const { customerOrPresentationModeCustomer: customer, customer: genuineCustomer } = useCustomerContext();
  const [page, setPage] = useState(0);
  const rowsPerPage = 6;
  const [data, setData] = useState<
    {
      data: WithFirebaseModel<CloudAnalyticsModelBudgetModel>;
      id: string;
    }[]
  >();

  useEffect(() => {
    const collectionRef = getCollection(CloudAnalyticsModel).doc("budgets").collection("cloudAnalyticsBudgets");
    const getQuery = () => {
      if (isDoitEmployee) {
        return collectionRef.where("customer", "==", customer.ref).get();
      }
      return collectionRef
        .where("customer", "==", customer.ref)
        .where("public", "in", [Roles.VIEWER, Roles.EDITOR])
        .get();
    };

    const getShardQuery = () =>
      collectionRef
        .where("customer", "==", customer.ref)
        .where("public", "==", null)
        .where("collaborators", "array-contains-any", [
          { email: currentUser.email, role: Roles.OWNER },
          { email: currentUser.email, role: Roles.VIEWER },
          { email: currentUser.email, role: Roles.EDITOR },
        ])
        .get();

    Promise.all([getQuery(), getShardQuery()]).then((res) => {
      const budgetData = res.flatMap((queryResult) =>
        queryResult.docs.map((doc) => ({ data: doc.asModelData(), id: doc.id }))
      );

      const now = TimestampNow();
      const filteredBudgets = uniqBy(budgetData, "id").filter(
        (b) =>
          b.data.config.type !== BudgetTypes.FIXED ||
          (!!b.data.config?.endPeriod && b.data.config.startPeriod <= now && b.data.config.endPeriod >= now)
      );

      setData(filteredBudgets);
    });
  }, [currentUser.email, customer.ref, isDoitEmployee]);

  const getBudgetProjectedDate = useCallback((budget) => {
    if (budget?.data?.utilization?.forecastedTotalAmountDate) {
      return getDateTimeFromFirestoreTimestamp(budget?.data?.utilization?.forecastedTotalAmountDate).toFormat(
        "LLL dd, yyyy"
      );
    }
    return "N/A";
  }, []);

  if (!data) {
    return null;
  }
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClick = (budget) => () => {
    history.push(`/customers/${genuineCustomer.id}/analytics/budgets/${budget.id}`);
  };

  return (
    <Card>
      <WidgetCardHeader title="Budget Utilization" subheader="List of active budgets and their utilization" />
      <CardContent className={classes.cardContent} sx={{ height: widgetHeight - CARD_HEADER_HEIGHT }}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell padding="none" size="small">
                Budget Name
              </TableCell>
              <TableCell padding="none" size="small">
                Budget Status
              </TableCell>
              <TableCell padding="none" size="small" align="right">
                Max Utilization
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((budget) => (
              <TableRow key={budget.id} className={classes.tableRow} hover onClick={handleClick(budget)}>
                <TableCell
                  padding="none"
                  size="small"
                  style={{
                    textOverflow: "ellipsis",
                    maxWidth: "130px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    paddingRight: "10px",
                  }}
                >
                  {budget.data.name}
                </TableCell>
                <TableCell padding="none" size="small">
                  <BudgetUtilizationProgressBar
                    value={
                      budget.data.config.amount && budget.data.utilization?.current
                        ? (budget.data.utilization.current / budget.data.config.amount) * 100
                        : 0
                    }
                    color={getUtilizationProgressBarColor(budget.data)}
                  />
                </TableCell>
                <TableCell padding="none" size="small" align="right" style={{ width: "25%" }}>
                  {getBudgetProjectedDate(budget)}
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 32 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                className={classes.tablePagination}
                rowsPerPageOptions={[rowsPerPage]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  );
}
