import { type FC, type ReactNode, useEffect, useState } from "react";

import {
  type NodeWitOutputModel,
  ReferencedFieldContext,
  type ReferencedFieldContextValue,
} from "./useReferencedFieldContext";

export const ReferencedFieldContextProvider: FC<{
  children: ReactNode;
  referenceableNodes: NodeWitOutputModel[];
  values?: unknown;
}> = ({ children, referenceableNodes, values }) => {
  const [contextValue, setContextValue] = useState<ReferencedFieldContextValue>({
    referenceableNodes,
    values,
  });

  useEffect(() => setContextValue({ referenceableNodes, values }), [referenceableNodes, values]);

  return <ReferencedFieldContext.Provider value={contextValue}>{children}</ReferencedFieldContext.Provider>;
};
