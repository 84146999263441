import { type FC, Fragment } from "react";

import { type CloudFlowNodeType, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/Add";
import { Button, Stack, Typography } from "@mui/material";
import { v4 as uuid } from "uuid";

import { cloudflowTexts } from "../../../../assets/texts";
import { type NodeWitOutputModel } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { type CloudFlowNode } from "../utils/nodeTransformUtils";
import FilterGroupComp from "./FilterGroup";
import { type FilterGroup } from "./types";

type ConditionFormProps = {
  outputModel?: UnwrappedApiServiceModelDescriptor;
  filterGroups: FilterGroup[];
  selectedNode: CloudFlowNode<CloudFlowNodeType.ACTION> | undefined;
  referenceableNodes: NodeWitOutputModel[];
  setFilterGroups: (groups: FilterGroup[] | ((prevGroups: FilterGroup[]) => FilterGroup[])) => void;
};

const ConditionForm: FC<ConditionFormProps> = ({
  outputModel,
  filterGroups,
  selectedNode,
  setFilterGroups,
  referenceableNodes,
}) => {
  const handleAddGroup = () => {
    const newGroup: FilterGroup = {
      id: uuid(),
      conditions: [],
    };
    setFilterGroups([...filterGroups, newGroup]);
  };

  const handleDeleteConditionGroup = (groupId: string) => {
    setFilterGroups((prevGroups) => prevGroups.filter((group) => group.id !== groupId));
  };

  return (
    <>
      <Stack>
        <Stack mb={1.5}>
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            {cloudflowTexts.DEFINE_FILTER_CRITERIA}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {cloudflowTexts.FROM_CHOOSEN_FIELD_AND_FILTER}
          </Typography>
        </Stack>
        <Stack alignItems={"flex-start"}>
          {filterGroups.map((group, index) => (
            <Fragment key={group.id}>
              <FilterGroupComp
                groupId={group.id}
                groupIndex={index}
                outputModel={outputModel}
                selectedNode={selectedNode}
                referenceableNodes={referenceableNodes}
                setFilterGroups={setFilterGroups}
                conditions={group.conditions}
                onDeleteConditionGroup={() => {
                  handleDeleteConditionGroup(group.id);
                }}
              />

              {index + 1 !== filterGroups.length && (
                <Typography mt={1} mb={0.5} variant="caption">
                  {cloudflowTexts.OR}
                </Typography>
              )}
            </Fragment>
          ))}
          <Button
            startIcon={<AddIcon fontSize="small" />}
            onClick={handleAddGroup}
            color="primary"
            disabled={!selectedNode}
            sx={{ textAlign: "left", my: 0.8 }}
          >
            {cloudflowTexts.ADD_NEW_FILTER_GROUP}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default ConditionForm;
