import { type SyntheticEvent, useEffect, useState } from "react";

import { CustomerModel, EntityModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  type TextFieldProps,
  type UseAutocompleteProps,
} from "@mui/material";
import identity from "lodash/identity";
import omit from "lodash/omit";

import { type TypeOrUndefined } from "../../utils/typeOrUndefined";
import { type CustomerDetails, useAlgoliaCustomerSearch } from "./useAlgoliaCustomerSearch";
import { useCustomerFetch } from "./useCustomerFetch";

export type CustomerData = CustomerDetails & {
  hasActiveBillingProfile: boolean;
};

interface CustomerPickerProps
  extends Omit<Omit<Omit<UseAutocompleteProps<CustomerData, false, false, false>, "onChange">, "value">, "options"> {
  TextFieldProps?: TextFieldProps;
  value: TypeOrUndefined<string>;
  onChange: (event: SyntheticEvent | undefined, value: CustomerData | null) => void;
  /**
   * Trigger onChange on detection of a default value?
   */
  doOnChangeForDefaultValue?: boolean;
}

const getDefaultOptionLabel = ({ name }: CustomerData) => name;

export const CustomerPicker = (props: CustomerPickerProps) => {
  const { TextFieldProps, value, onChange, doOnChangeForDefaultValue } = props;
  const [isLoadingCustomers, getCustomers] = useAlgoliaCustomerSearch();
  const [isFetchingCustomer, fetchCustomer] = useCustomerFetch();

  const [checkedCustomersCache] = useState(new Map<string, boolean>());
  const [query, setQuery] = useState("");
  const [customers, setCustomers] = useState<CustomerData[]>([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(value);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerData | null>(null);

  const loading = isFetchingCustomer || isLoadingCustomers;

  useEffect(() => {
    (async () => {
      const customers = await getCustomers(query);
      customers
        .filter(({ objectID }) => !checkedCustomersCache.has(objectID))
        .forEach(({ objectID: customerId }) => {
          (async () => {
            const customerRef = getCollection(CustomerModel).doc(customerId);

            const activeBillingProfiles = await getCollection(EntityModel)
              .where("customer", "==", customerRef)
              .where("active", "==", true)
              .limit(1)
              .get();

            const hasActiveBillingProfile = activeBillingProfiles.size !== 0;
            checkedCustomersCache.set(customerId, hasActiveBillingProfile);
            if (!hasActiveBillingProfile) {
              setCustomers((currentCustomers) =>
                currentCustomers.map((customer) =>
                  customer.objectID === customerId ? { ...customer, hasActiveBillingProfile } : customer
                )
              );
            }
          })();
        });

      setCustomers(
        customers.map((customer) => ({
          ...customer,
          hasActiveBillingProfile: checkedCustomersCache.get(customer.objectID) ?? true,
        }))
      );
    })();
  }, [checkedCustomersCache, getCustomers, query]);

  useEffect(() => setSelectedCustomerId(value), [value]);

  useEffect(() => {
    if (!selectedCustomerId) {
      setSelectedCustomer(null);
    } else if (selectedCustomerId !== selectedCustomer?.objectID) {
      (async () => {
        const newSelectedCustomer = await fetchCustomer(selectedCustomerId);
        if (doOnChangeForDefaultValue) {
          onChange(undefined, newSelectedCustomer);
        }
        setSelectedCustomer(newSelectedCustomer);
      })();
    }
  }, [fetchCustomer, doOnChangeForDefaultValue, onChange, selectedCustomerId, selectedCustomer?.objectID]);

  return (
    <Autocomplete
      getOptionLabel={getDefaultOptionLabel}
      {...omit(props, "TextFieldProps")}
      value={selectedCustomer}
      onChange={(event, customer) => {
        setSelectedCustomerId(customer?.objectID);
        setSelectedCustomer(customer);
        onChange(event, customer);
      }}
      filterOptions={identity}
      onInputChange={(_event, query) => {
        setQuery(query);
      }}
      isOptionEqualToValue={(option, value) => option.objectID === value.objectID}
      options={customers}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          InputProps={{
            ...TextFieldProps?.InputProps,
            ...params.InputProps,
            endAdornment: (
              <>{loading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</>
            ),
          }}
        />
      )}
    />
  );
};
