import { SuperQueryModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import { useState, useEffect } from "react";
import { useCurrentDashboardContext } from "../../../Context/CurrentDashboardContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { BillingMode } from "../../../Pages/Customer/constants";
import { formatCurrency, formatDecimalNumber } from "../../../utils/common";
import Hide from "../../HideChildren/Hide";
import { useCloudConnect } from "../../hooks/useCloudConnect";
import { CARD_HEADER_HEIGHT, HEADER_PADDING } from "../Analytics/cloudCardStyle";
import { SkeletonCard } from "../SkeletonCard";
import RecommendationsDetailsView from "./RecommendationsDetailsView";
import "./codemirror.css";
import { recommendationKey, timeframeOptions } from "./constants";
import { WidgetCardHeader } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";

const useStyles = makeStyles(() => ({
  cell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: 200,
    width: "100%",
  },
  paging: {
    minHeight: 48,
  },
  chip: {
    marginTop: 8,
    marginRight: 4,
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

export default function RecommendationsCard({ raised, widgetHeight = 200, fallbackComponent }) {
  const classes = useStyles();
  const [rows, setRows] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [totalPrice, setTotalPrice] = useState(0);
  const [openDetailsView, setOpenDetailsView] = useState(false);
  const [detailsViewData, setDetailsViewData] = useState(null);
  const { selectedTimeframe, selectedBillingMode } = useCurrentDashboardContext();
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const cloudConnect = useCloudConnect(customer);
  const bigqueryFinopsPermission = cloudConnect?.some(
    (sa) => sa.cloudPlatform === "google-cloud" && sa.categoriesStatus["bigquery-finops"] === 1
  );
  const [data, setData] = useState();

  useEffect(() => {
    const promises = [
      getCollection(SuperQueryModel)
        .doc("simulation-recommender")
        .collection("output")
        .doc(customer.id)
        .collection("recommendations")
        .doc(selectedTimeframe)
        .get(),
    ];

    if (selectedBillingMode === BillingMode.flatRate) {
      promises.push(
        getCollection(SuperQueryModel)
          .doc("simulation-recommender")
          .collection("flat-rate")
          .doc(customer.id)
          .collection("recommendations")
          .doc(selectedTimeframe)
          .get()
      );
    } else if (selectedBillingMode === BillingMode.onDemand) {
      promises.push(
        getCollection(SuperQueryModel)
          .doc("simulation-recommender")
          .collection("on-demand")
          .doc(customer.id)
          .collection("recommendations")
          .doc(selectedTimeframe)
          .get()
      );
    }

    Promise.all(promises).then((values) => {
      const [timeframeData, billingData] = [values[0].asModelData(), values?.[1]?.asModelData()];

      setData({
        ...(timeframeData ?? {}),
        ...(billingData ?? {}),
      });
    });
  }, [customer.id, selectedBillingMode, selectedTimeframe]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const tmpRows = [];
    let total_price = 0;
    let total_price_storage = 0;

    Object.keys(data)
      .filter((key) => key !== "lastUpdate")
      .sort()
      .forEach((key) => {
        if (data[key] && "savingsPrice" in data[key] && data[key].savingsPrice) {
          total_price += key !== "storageSavings" ? data[key].savingsPrice : 0;
          total_price_storage += key === "storageSavings" ? data[key].savingsPrice : 0;
        }

        tmpRows.push({ ...data[key], [recommendationKey]: key });
      });

    setRows(sortBy(tmpRows, ["savingsPrice"]).reverse());
    setTotalPrice({ scan: total_price, storage: total_price_storage });
  }, [data]);

  useEffect(() => {
    if (widgetHeight > 1) {
      setRowsPerPage(6);
    }
  }, [widgetHeight]);

  const openDetailsViewDialog = (e, data) => {
    e.preventDefault();
    if (data.detailedTable) {
      setDetailsViewData(data);
      setOpenDetailsView(true);
    } else {
      setDetailsViewData({ superQuery: true, recommendation: data.recommendation });
      setOpenDetailsView(true);
    }
  };
  const closeDetailsViewDialog = () => {
    setDetailsViewData(null);
    setOpenDetailsView(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (data === null) {
    return fallbackComponent;
  }

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);
  return (
    <Grid>
      <Card raised={raised}>
        <WidgetCardHeader
          title="Recommendations"
          subheader={
            bigqueryFinopsPermission
              ? `You can save up to ${formatCurrency(totalPrice.scan + totalPrice.storage, "USD")} (${
                  find(timeframeOptions, { value: selectedTimeframe }).label
                })`
              : ""
          }
        />

        <CardContent style={{ height: widgetHeight - CARD_HEADER_HEIGHT }} className={classes.cardContent}>
          <TableContainer>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Your Recommendations</TableCell>
                  <Hide smDown>
                    <TableCell align="center">Savings(%)</TableCell>
                  </Hide>
                  <TableCell align="center">Savings($)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rows || [])
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter(Boolean)
                  .map((row) => (
                    <TableRow key={row.recommendation}>
                      <TableCell className={classes.cell}>
                        <Link
                          color="textPrimary"
                          className={classes.link}
                          onClick={(e) => openDetailsViewDialog(e, row)}
                        >
                          {row.recommendation}
                        </Link>
                      </TableCell>
                      <Hide smDown>
                        <TableCell align="center">{formatDecimalNumber(row.savingsPercentage, 2)}%</TableCell>
                      </Hide>
                      <TableCell align="center">{formatCurrency(row.savingsPrice, "USD")}</TableCell>
                    </TableRow>
                  ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            classes={{
              toolbar: classes.paging,
            }}
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            backIconButtonProps={{
              "aria-label": "Previous Page",
              size: "small",
            }}
            labelRowsPerPage=""
            nextIconButtonProps={{
              "aria-label": "Next Page",
              size: "small",
            }}
            count={rows?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>

        <RecommendationsDetailsView open={openDetailsView} onClose={closeDetailsViewDialog} data={detailsViewData} />
      </Card>
    </Grid>
  );
}
