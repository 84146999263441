import { memo, type MutableRefObject, useCallback, useEffect, useMemo, useRef } from "react";

import { ComparativeFeature, Renderer } from "@doitintl/cmp-models";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import * as Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting";
import HighchartsReact from "highcharts-react-official";

import { highchartsText } from "../../../assets/texts";
import { useChartColors } from "../../../Components/hooks/cloudAnalytics/useChartColors";
import { ThemeModes } from "../../../muiThemeTypes";
import { getAxisType, isComparative } from "../utilities";
import { getAreaChartOptions } from "./AreaChartRenderer";
import { getAreaSplineChartOptions } from "./AreaSplineChartRenderer";
import { getBarChartOptions } from "./BarChartRenderer";
import { getColumnChartOptions } from "./ColumnChartRenderer";
import { ECharts, type EChartType } from "./EChartsPOC/ECharts";
import { getLineChartOptions } from "./LineChartRenderer";
import { getSplineChartOptions } from "./SplineChartRenderer";
import { getStackedAreaChartOptions } from "./StackedAreaChartRenderer";
import { getStackedBarChartOptions } from "./StackedBarChartRenderer";
import { getStackedColumnChartOptions } from "./StackedColumnChartRenderer";
import useHighchartsRenderer from "./useHighchartsRenderer";
import { getWidgetChartOptions, getWidgetFormatter } from "./WidgetChartRenderer";
import type ReportData from "../../../Pages/CloudAnalytics/ReportData";

if (process.env.NODE_ENV !== "test") {
  HighchartsExporting(Highcharts);
  HighchartsOfflineExporting(Highcharts);
}

/**
 * function applied on Highcharts to override log functions, in order to support negative values on log scale.
 */
(function addLogScale(H) {
  H.addEvent(H.Axis, "afterInit", function supportNegativeLog(this: any) {
    const { logarithmic, options } = this;
    if (logarithmic && options.custom.allowNegativeLog) {
      // Avoid errors on negative numbers on a log axis
      this.positiveValuesOnly = false;
      // Override the converter functions
      logarithmic.log2lin = (num: number): number => {
        let absNum = Math.abs(num);
        if (absNum < 10) {
          absNum += (10 - absNum) / 10;
        }
        const result = Math.log(absNum) / Math.LN10;
        return num < 0 ? -result : result;
      };

      logarithmic.lin2log = (num: number): number => {
        let result = Math.pow(10, Math.abs(num));
        if (result < 10) {
          result = (10 * (result - 1)) / (10 - 1);
        }
        return num < 0 ? -result : result;
      };
    }
  });
})(Highcharts);

type ChartsRendererProps = {
  title: string;
  height?: string;
  type: string;
  data: ReportData | null;
  formatter: (value: any, short?: any, comparative?: ComparativeFeature) => any;
  showForecast: boolean;
  isWidget: boolean;
  forceRender: boolean;
  highchartRef?: MutableRefObject<any | null>;
  comparative?: ComparativeFeature;
  changeAxisScale?: () => void;
  logScale?: boolean;
  hideExportingMenu?: boolean;
  enableWidgetLegend?: boolean;
};

const ChartsRenderer = ({
  title,
  type,
  data,
  formatter,
  isWidget,
  forceRender,
  highchartRef,
  comparative = ComparativeFeature.NONE,
  changeAxisScale = () => null,
  logScale = false,
  hideExportingMenu,
  enableWidgetLegend = false,
}: ChartsRendererProps) => {
  const theme = useTheme();
  const localChartRef = useRef<any | null>(null);
  const isCompReport = !!comparative && isComparative(comparative);
  const { categories, series, reversed } = useHighchartsRenderer({
    data,
    isComparative: isCompReport,
  });

  const ref = highchartRef ?? localChartRef;
  const shiftKeyPressed = useRef<boolean>(false); // needs to be global for High charts events
  const colors = useChartColors(theme.palette.mode);

  const handleFilterAll = useCallback((e, currentSeries) => {
    currentSeries.forEach((s) => {
      if (s.name === e?.target?.name) {
        s.setVisible(true, false);
        return;
      }
      s.visible ? s.setVisible(false, false) : s.setVisible(true, false);
      shiftKeyPressed.current = false;
    });
  }, []);

  const exportMenuItems = useMemo(() => {
    let items = ["viewFullscreen", "separator", "logScale"];
    if (highchartRef === undefined) {
      items = ["viewFullscreen", "separator", "downloadPNG", "downloadPDF", "separator", "logScale"];
    }
    return items;
  }, [highchartRef]);

  const options = useMemo(() => {
    let options: any = null;
    switch (type) {
      case Renderer.COLUMN_CHART:
        options = getColumnChartOptions();
        break;
      case Renderer.STACKED_COLUMN_CHART:
        options = getStackedColumnChartOptions(theme, reversed, formatter, comparative);
        break;
      case Renderer.BAR_CHART:
        options = getBarChartOptions();
        break;
      case Renderer.STACKED_BAR_CHART:
        options = getStackedBarChartOptions(theme, reversed, formatter);
        break;
      case Renderer.LINE_CHART:
        options = getLineChartOptions();
        break;
      case Renderer.SPLINE_CHART:
        options = getSplineChartOptions();
        break;
      case Renderer.AREA_CHART:
        options = getAreaChartOptions();
        break;
      case Renderer.AREA_SPLINE_CHART:
        options = getAreaSplineChartOptions();
        break;
      case Renderer.STACKED_AREA_CHART:
        options = getStackedAreaChartOptions(theme, reversed, formatter);
        break;
      default:
        options = getStackedColumnChartOptions(theme, reversed, formatter, comparative);
    }

    const xAxisLabelsStyle = {
      ...theme.typography.caption,
      color: theme.palette.text.primary,
    };
    delete xAxisLabelsStyle.lineHeight;

    options.xAxis = {
      ...options.xAxis,
      categories,
      labels: {
        style: xAxisLabelsStyle,
      },
    };
    options.yAxis = {
      ...options.yAxis,
      gridLineColor: theme.palette.mode === ThemeModes.DARK ? theme.palette.general.divider : "#e6e6e6",
      reversed,
      custom: {
        allowNegativeLog: true,
      },
      type: getAxisType(!!logScale),
      title: {
        enabled: false,
      },
      labels: {
        style: {
          ...theme.typography.caption,
          color: theme.palette.text.primary,
        },
        formatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
          return formatter(this.value, true, comparative);
        },
      },
    };

    options.legend = {
      ...options.legend,
      itemStyle: {
        ...theme.typography.caption,
        color: theme.palette.text.primary,
      },
      navigation: {
        enabled: true,
        activeColor: theme.palette.action.active,
        inactiveColor: theme.palette.action.disabled,
        style: {
          ...theme.typography.caption,
          color: theme.palette.text.primary,
        },
      },
      reversed: true,
    };

    options.series = series;
    options.plotOptions = {
      ...options.plotOptions,
      series: {
        events: {
          legendItemClick(this: Highcharts.Series, e: any) {
            if (shiftKeyPressed.current) {
              e.preventDefault();
              handleFilterAll(e, this.chart.series);
              this.chart.redraw();
              return;
            }
            // Allow for activating filtered out series
            const index = options.series.findIndex((s) => s.name === e.target.name);
            options.series[index].visible = !options.series[index].visible;
          },
        },
        // https://www.highcharts.com/forum/viewtopic.php?t=40295
        // when series is greater than ~99 flickering will occur because of inactive state.
        states: {
          inactive: {
            enabled: series.length < 100,
          },
        },
      },
    };
    options.chart = {
      ...options.chart,
      resetZoomButton: {
        position: "left",
      },
      backgroundColor: theme.palette.background.paper,
    };

    options.colors = colors;
    options.tooltip = {
      pointFormatter() {
        return `${this.series.name}: <b>${formatter(this.y, false, comparative)}</b><br/>`;
      },
    };
    options.exporting = {
      enabled: true,
      allowHTML: false,
      filename: title,
      scale: 1,
      sourceWidth: 1920,
      sourceHeight: `${(9 / 16) * 100}%`,
      buttons: {
        contextButton: {
          menuItems: exportMenuItems,
        },
      },
      menuItemDefinitions: {
        // Custom definition
        logScale: {
          onclick: () => {
            if (changeAxisScale) {
              changeAxisScale();
            }
          },
          text: logScale ? highchartsText.LINEAR_SCALE : highchartsText.LOG_SCALE,
        },
      },
      chartOptions: {
        yAxis: [
          {
            gridLineColor: theme.palette.mode === ThemeModes.DARK ? theme.palette.general.divider : "#e6e6e6",
            reversed,
            title: {
              enabled: false,
            },
            labels: {
              style: {
                ...theme.typography.caption,
                color: theme.palette.text.primary,
                fontSize: "16px",
              },
              formatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
                return formatter(this.value, true, comparative);
              },
            },
          },
        ],
        xAxis: [
          {
            categories,
            labels: {
              style: {
                ...theme.typography.caption,
                color: theme.palette.text.primary,
                fontSize: "16px",
              },
            },
          },
        ],
        legend: {
          layout: "vertical",
          verticalAlign: "top",
          align: "right",
          alignColumns: false,
          maxHeight: undefined,
          itemMarginTop: 0,
          width: "30%",
          navigation: {
            enabled: false,
          },
          itemStyle: {
            ...theme.typography.caption,
            color: theme.palette.text.primary,
            fontSize: "16px",
            textOverflow: "ellipsis",
          },
        },
      },
    };

    if (isWidget) {
      const cols = data?.getCols();
      options = getWidgetChartOptions(theme, options, enableWidgetLegend, cols);
    }
    if (hideExportingMenu) {
      options.exporting = { enabled: false };
    }
    return options;
  }, [
    title,
    type,
    formatter,
    theme,
    reversed,
    categories,
    series,
    colors,
    data,
    isWidget,
    shiftKeyPressed,
    handleFilterAll,
    exportMenuItems,
    logScale,
    comparative,
    changeAxisScale,
    hideExportingMenu,
    enableWidgetLegend,
  ]);

  // shift keydown handler, useKeyPress hook state not working with High charts
  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === "Shift") {
        shiftKeyPressed.current = true;
      }
    };
    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, []);

  const allowRender = useMemo(() => !(isWidget && !forceRender), [forceRender, isWidget]);
  const newTypesMapping: Record<string, EChartType> = {
    [Renderer.STACKED_COLUMN_CHART]: "stacked-column",
    [Renderer.LINE_CHART]: "line",
    [Renderer.STACKED_AREA_CHART]: "stacked-area",
    [Renderer.COLUMN_CHART]: "column",
    [Renderer.BAR_CHART]: "bar",
    [Renderer.STACKED_BAR_CHART]: "stacked-bar",
    [Renderer.SPLINE_CHART]: "line-spline",
    [Renderer.AREA_SPLINE_CHART]: "area-spline",
    [Renderer.AREA_CHART]: "area",
  };

  const formatterWithComparative = useCallback(
    (value: string | number, short?: boolean) => formatter(value, short, comparative),
    [formatter, comparative]
  );

  const categoryFormatter = useCallback(
    (value: string) => {
      if (isWidget) {
        return getWidgetFormatter(data?.getCols())(value);
      } else {
        return value;
      }
    },
    [isWidget, data]
  );

  return (
    <>
      {Object.keys(newTypesMapping).includes(type) ? (
        <Box sx={{ height: "100%" }}>
          <ECharts
            ref={ref}
            logScale={logScale}
            widgetView={isWidget}
            type={newTypesMapping[type]}
            categories={categories}
            series={series}
            valueFormatter={formatterWithComparative}
            categoryFormatter={categoryFormatter}
            forecastMode={data?.forecastSettings?.mode}
            forecasts={data?.forecasts}
          />
        </Box>
      ) : (
        <HighchartsReact
          ref={ref}
          containerProps={{ style: { height: "100%" } }}
          allowChartUpdate={allowRender}
          highcharts={Highcharts}
          options={options}
        />
      )}
    </>
  );
};

export default memo(ChartsRenderer);
