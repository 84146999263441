import { type StripePaymentIntent, StripePaymentMethodType } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { type Invoice, type InvoiceStatus } from "./types";

// Check if payment methods types has one that is not immediate,
// i.e. a payment method type that requires some processing time in stripe (usually a few days).
const hasNonImmediatePaymentMethod = (paymentMethodTypes: StripePaymentMethodType[]) =>
  paymentMethodTypes.some((t) => t !== StripePaymentMethodType.Card);

// getPaymentsInformation returns pending payments information if the invoice was not in PAID status yet.
// it returns the list of pending payments that require processing time,
// a flag indicating if there are pending payments and the sum of the pending payments.
export const getPendingPaymentsInformation = (
  invoice: Invoice
): {
  pendingPayments: StripePaymentIntent[];
  isPending: boolean;
  pendingPaymentSum: number;
} => {
  let pendingPayments: StripePaymentIntent[] = [];
  let isPending = false;

  if (!invoice.PAID && invoice.stripePaymentIntents) {
    pendingPayments = pendingPayments.concat(
      invoice.stripePaymentIntents.filter((pi) => {
        if (pi.payment_method_types && hasNonImmediatePaymentMethod(pi.payment_method_types)) {
          return pi.status === "processing" || (pi.status === "succeeded" && pi.debit === invoice.DEBIT);
        }

        return pi.status === "succeeded" && pi.debit === invoice.DEBIT;
      })
    );

    isPending = invoice.stripePaymentIntents.some(
      (pi) =>
        pi.payment_method_types && hasNonImmediatePaymentMethod(pi.payment_method_types) && pi.status === "processing"
    );
  }

  const pendingPaymentSum = pendingPayments.reduce((sum, pi) => sum + pi.amount, 0) / 100;

  return {
    pendingPayments,
    isPending,
    pendingPaymentSum,
  };
};

export const getInvoiceStatus = (invoice: Invoice): { _STATUS: InvoiceStatus; _STATUS_ID: number } => {
  const { isPending, pendingPaymentSum } = getPendingPaymentsInformation(invoice);
  const _BALANCE = Math.round((invoice.DEBIT ?? 0 - pendingPaymentSum) * 100) / 100;
  const today = DateTime.utc().startOf("day");

  if (invoice.CANCELED) {
    return {
      _STATUS: "Canceled",
      _STATUS_ID: 8,
    };
  }

  if (invoice.isDraft) {
    return {
      _STATUS: "Proforma",
      _STATUS_ID: 7,
    };
  }
  if (isPending) {
    return {
      _STATUS: "Processing",
      _STATUS_ID: 6,
    };
  }
  if (invoice.PAID || _BALANCE <= 0) {
    return {
      _STATUS: "Paid",
      _STATUS_ID: 1,
    };
  }

  if (today > DateTime.fromJSDate(invoice.PAYDATE.toDate())) {
    if (_BALANCE < invoice.TOTPRICE) {
      return {
        _STATUS: "Past Due",
        _STATUS_ID: 4,
      };
    }
    return {
      _STATUS: "Past Due",
      _STATUS_ID: 5,
    };
  }

  if (_BALANCE < invoice.TOTPRICE) {
    return {
      _STATUS: "Partially Paid",
      _STATUS_ID: 2,
    };
  }

  return {
    _STATUS: "Open",
    _STATUS_ID: 3,
  };
};
