import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import en from "./en/resource";
import ja from "./ja/resource";
import { browserLanguage } from "./utils";

export const resources = {
  en,
  ja,
} as const;

let lng = localStorage.getItem("i18nLng");

if (!lng) {
  lng = browserLanguage === "ja" ? "ja" : "en";
}

i18n.use(initReactI18next).init({
  supportedLngs: Object.keys(resources),
  resources,
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: "en",
  lng,
});

export default i18n;
