import { collection, doc, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type AUTO_GENERATED_AWS_ACCOUNTS_DOC_KEY, type AutoGeneratedAwsAccountsDoc } from "./AutoGeneratedAwsAccounts";
import { type ProductEnum } from "./Collection";
import { type AccountManagersCompany, type CustomerModel } from "./Customer";
import { type Product } from "./Integration";
import { type NotificationsDescriptorDoc } from "./Notifications";
import { type PermissionModel } from "./Permission";
import { type RoleModel } from "./Role";
import { type UserNotification } from "./User";

type AccountRole = { name: string; value: string; vendors: string[] };

export type UserNotificationModel = {
  name: string;
  description: string;
  value: UserNotification;
  order: number;
  permissions?: Reference<PermissionModel>[];
  enabled?: boolean;
};

type UserNotificationDoc = {
  notifications: UserNotificationModel[];
};

export type AwsFeaturePermission = {
  name: string;
  permissions: string[];
  policies: string[] | null;
};

export type CloudConnectCategory = {
  allowPartialAccessLevel?: boolean;
  description: string;
  id: string;
  name: string;
  orgLevelOnlyPermissions?: string[];
  permissions: string[];
};

export type GcpLocation = {
  title: string;
  value: string;
};

type CloudConnectDatasetLocations = {
  gcp: GcpLocation[];
};

type CloudConnectDoc = {
  awsFeaturePermissions: AwsFeaturePermission[];
  categories: CloudConnectCategory[];
  datasetLocations: CloudConnectDatasetLocations;
};

type SlackAppWorkspace = {
  name: string;
  customer: Reference<CustomerModel>;
  botToken: string;
};

type AppModelSlackApp = {
  subCollections: {
    slackAppWorkspaces: SlackAppWorkspace;
  };
};

type AppModelMSTeamsApp = {
  subCollections: {
    channels: MSTeamsChannel;
  };
};

export type MSTeamsChannel = {
  conversationId: string; // the channel id
  channelName: string;

  teamName: string;
  teamId: string;

  tenantId: string;
  serviceUrl: string;

  botId: string;
  customerId?: string;
  addedAt?: number;
  removed?: boolean;
};

type AppUsersCsvColumns = {
  columns: Array<{
    column: string;
    field: string;
  }>;
  notificationCsvLabelsMap: {
    "Cloud Cost Anomalies": string;
    "Cloud Known Issues": string;
    "Cloud Quota Utilization": string;
    "Credits Utilization": string;
    "New Invoices": string;
    "Payment Due/Overdue": string;
  };
};

@collection("app")
export class AppModel {
  docs?: {
    version: AppModelDoc;
    "account-manager-roles": AccountManagerRoles;
    "cloud-connect": CloudConnectDoc;
    fixer: FixerDoc;
    "doit-employees": DoitEmployeesDoc;
    "my-customers": MyCustomersDoc;
    "partner-access": PartnerAccessDoc;
    support: SupportModelDoc;
    stripe: StripePaymentsDoc;
    "priority-v2": PriorityV2;
    algolia: AlgoliaConfig;
    "algolia-public": AlgoliaPublicConfig;
    "master-payer-accounts": MasterPayerAccountsDoc;
    [AUTO_GENERATED_AWS_ACCOUNTS_DOC_KEY]: AutoGeneratedAwsAccountsDoc;
    "sso-providers-texts": SsoProvidersTexts;
    userNotifications: UserNotificationDoc;
    notifications: NotificationsDescriptorDoc;
    countries: AppModelCountry;
    perks: PerksConfig;
    "slack-app": AppModelSlackApp;
    "msteams-app": AppModelMSTeamsApp;
    "users-csv-columns": AppUsersCsvColumns;
    "cloudhealth-pricebooks": AppModelCloudHealthPriceBook;
    ava: Ava;
    contracts: AppModelContracts;
  };
}

export type PerkPageConfig = {
  perksType: PerkPageSectionTitles[];
};

type PerkPageSectionTitles = {
  agenda: string;
  checklist: string;
  modules: string;
  id: string;
};

type SsoProvidersTexts = {
  [key in SsoTypes]: {
    [ssoProviderName: string]: SsoLabels;
  };
};

type SsoTypes = "saml" | "oidc";

type SsoLabels = {
  spEntityId: string;
  callbackUrl: string;
  idpLoginUrl: string;
};

export type PartnerData = {
  auth: {
    tenantId: string;
  };
  company: AccountManagersCompany;
  domains: string[];
  name: string;
  allowAuthProvider?: string;
};

@doc("partner-access")
class PartnerAccessDoc {
  partners!: PartnerData[];
}

export type DateStringFormat = `${number}-${number}-${number}`;

@subCollection("doersColumnsConfig")
export class MyCustomersDocDoersColumnsConfigModel {
  columns!: CustomersColumnId[];
}

@subCollection("my-customers")
export class MyCustomersDoc {
  subCollections?: {
    doersColumnsConfig: MyCustomersDocDoersColumnsConfigModel;
  };
}

export type FixerExchangeDailyRatesModel = { [dateFormatted: string]: Record<string, number> };

export type FixerExchangeDailyRatesDoc = {
  docs: { [year: string]: { [dateFormatted: string]: Record<string, number> } };
};

export type FixerExchangeYearlyRatesModel = { [year: string]: FixerExchangeDailyRatesModel };

@doc("fixer")
export class FixerDoc {
  subCollections?: {
    fixerExchangeRates: FixerExchangeDailyRatesDoc;
  };
}

@subCollection("doitRoles")
export class AppModelDoitEmployeesDocDoitRoles {
  roleName!: DoitRole;

  users!: string[];

  description!: string;
}

@subCollection("CustomersMetadata")
export class CustomersMetadata {
  lastUpdated!: Timestamp;

  needsUpdate!: boolean;

  progress?: number;
}

@doc("doit-employees")
class DoitEmployeesDoc {
  subCollections?: {
    doitRoles: AppModelDoitEmployeesDocDoitRoles;
  };
}

@doc("ava")
class Ava {
  subCollections?: {
    customersMetadata: CustomersMetadata;
  };
}

@collection("app")
export class AccountManagerRoles {
  roles!: AccountRole[];
}

@doc("version")
export class AppModelDoc {
  theme?: string;

  commitSha!: string;

  shortCommitSha!: string;

  branch!: string;

  showSnackbar?: boolean;

  timestamp!: Timestamp;

  forceRefresh?: boolean;
}

@collection("app")
export class AppModelCloudHealthPriceBook {
  operations!: {
    name: string;
  }[];

  products!: Product[];

  regions!: {
    name: string;
  }[];
}

export type ContractTypeOption = {
  label: string;
  value: string;
};

@doc("contracts")
export class AppModelContracts {
  options!: ContractTypeOption[];

  subCollections?: {
    acceleratorTypes: AcceleratorType;
  };
}

@doc("countries")
export class AppModelCountry {
  code!: Record<string, Country>;
}

export type Country = {
  name: string;
  region: string;
};

export type ChannelNotificationsProps = {
  customersNotifications: boolean;
  id: string;
  internalChannel?: string;
  name?: string;
  title?: string;
};

@collection("app")
export class AppModelSlack {
  channels!: ChannelNotificationsProps[];
}

export enum CustomersColumnsID {
  Checkbox = "",
  PrimaryDomain = "primaryDomain",
  ThisMonth = "thisMonth",
  Trend = "trend",
  AccountManagers = "accountManagers",
  FlexsaveAWS = "flexsaveAWS",
  BigqueryLens = "bigqueryLens",
  AwsRampPlanAttainment = "awsRampPlanAttainment",
  GcpRampPlanAttainment = "gcpRampPlanAttainment",
  AwsContracts = "awsContracts",
  GcpContracts = "gcpContracts",
  Assets = "assets",
  AwsRampPlanStatus = "awsRampPlanStatus",
  GcpRampPlanStatus = "gcpRampPlanStatus",
  GkeCostAllocation = "gkeCostAllocation",
  TrialStatus = "trialStatus",
  RevenueEstGCP = "revenueEstGCP",
}

export type CustomersColumnId = `${CustomersColumnsID}`;

export enum DoitRole {
  AwsAccountGenerator = "aws-account-generator-admin",
  BillingProfileAdmin = "billing-profile-admin",
  CustomerSettingsAdmin = "customer-settings-admin",
  Developers = "developers",
  FlexsaveAdmin = "flexsave-admin",
  FlexsaveSuperAdmin = "flexsave-super-admin",
  MarketplaceAdmin = "marketplace-admin",
  MasterPayerAccountOpsAdmin = "master-payer-account-ops-admin",
  Owners = "owners",
  PerksAdmin = "perks-admin",
  ContractAdmin = "contract-admin",
  ContractOwner = "contract-owner",
  FieldSalesManager = "field-sales-manager",
  NordiaPreview = "nordia-feature-preview",
  CAOwnershipAssigner = "ca-ownership-assigner",
  TeamCloudAnalytics = "team-cloud-analytics",
  TemplateLibraryAdmin = "cloud-analytics-template-library-admins",
  CustomerTieringAdmin = "customer-tiering-admin",
  Cloudflow = "cloudflow",
  PreviewAssets = "preview-assets",
}

type RoleNotification = {
  name: string;
  roleRef: Reference<RoleModel>;
  notifications: number[];
};

@collection("app")
export class AppRoleNotificationModel {
  roleNotifications!: RoleNotification[];
}

export type AppPartnerData = {
  name: string;
  auth: {
    tenantId: string;
  };
  domains: string[];
  company: "google_cloud_platform" | "amazon_web_services";
  allowAuthProvider?: string;
};

@collection("app")
export class AppPartnerAccessModel {
  partners!: AppPartnerData[];
}

export type SupportTier = "basic" | "business" | "enterprise" | "developer";
export type SupportModel = "partner-led" | "resold" | null;
export type Tenancy = "dedicated" | "shared";
export type MasterPayerAccountStatus = "pending" | "active" | "retired";
export type BillingProfileStatus = "active" | "inactive";
export type ServiceType = "solve" | "navigator" | "advantage";
export type ServiceTier = "standard" | "enhanced" | "premium" | "enterprise" | null;
export type Services = { type: ServiceType; tier: ServiceTier };

export type Support = {
  tier: SupportTier;
  model: SupportModel;
  includeMemberAccounts?: boolean;
  enterpriseTAMLocation?: string;
  supportStart?: Timestamp | null;
  awsCaseIdSupportTicketUrl?: string;
};

export type MasterPayerAccountsFeatures = Partial<{
  "no-root-access": boolean;
  "import-org": boolean;
  "edp-ppa": boolean;
  "no-import-org-reason"?: string;
  "org-billing-start-month"?: Timestamp;
  "nra-expected-mrr"?: number;
  "nra-link-to-materials"?: string;
}>;

export type MpaRevenue = {
  month: string;
  lastUpdated: Timestamp;
  ppa?: number;
  edp?: number;
  support?: number;
  total?: number;
};

@subCollection("mpaAccounts")
export class MasterPayerAccountsModel {
  accountNumber!: string;

  customerId!: string;

  domain?: string;

  name!: string;

  friendlyName!: string;

  rootEmail?: string;

  roleARN!: string;

  tenancyType!: Tenancy;

  flexSaveAllowed?: boolean;

  flexSaveRecalculationStartDate?: Timestamp;

  defaultAwsFlexSaveDiscountRate!: number;

  support!: Support;

  cur_bucket!: string;

  cur_path!: string;

  expectedOnboardingDate?: Timestamp | null;

  status?: MasterPayerAccountStatus; // TO BE MADE MANDATORY FIELDS AFTER CMP-6194

  retireDate?: Timestamp;

  features?: MasterPayerAccountsFeatures; // TO BE MADE MANDATORY FIELDS AFTER CMP-6194

  regions?: string[];

  lastUpdated!: Timestamp;

  timeCreated!: Timestamp;

  requestedBy?: string;

  onboardingTicketId?: string;

  onePasswordItemId?: string;

  requesterLocation?: string;

  ticketAssignee?: string;

  revenues?: MpaRevenue[];

  tags?: string[];

  existingCustomer?: boolean;

  existingCustomerTier?: string;

  services?: Services[];
}

@doc("master-payer-accounts")
class MasterPayerAccountsDoc {
  subCollections?: {
    mpaAccounts: MasterPayerAccountsModel;
  };
}

@subCollection("services")
export class SupportServiceModel {
  blacklisted!: boolean;

  last_update!: Timestamp;

  categories!: {
    name: string;
    id: string;
  }[];

  id!: string;

  name!: string;

  platform!: string;

  summary!: string;

  tags!: string[];

  url!: string;

  version!: number;
}

class GeckoStatisticsModel {
  docs!: {
    "top-services": SupportStatisticsModel;
  };
}

@doc("support")
export class SupportModelDoc {
  subCollections?: {
    services: SupportServiceModel;
    geckoStatistics: GeckoStatisticsModel;
  };

  abTestingCounter!: number;
}

export type PriorityStatus = {
  available?: boolean;
  scheduledDownTime?: Timestamp | null;
};

@doc("priority-v2")
export class PriorityV2 {
  companies!: Company[];

  status?: PriorityStatus;
}

@doc("stripe")
export class StripePaymentsDoc {
  disable!: {
    acss_debit: boolean;
    bacs_debit: boolean;
    card: boolean;
    sepa_debit: boolean;
    us_bank_account: boolean;
  };

  disable_stripe_payments!: boolean;
}

export type Company = {
  companyId?: string;
  companyName?: string;
  countries: string[];
  wireTransfer: {
    key: string;
    value: string;
  }[];
};

export type AlgoliaConfig = {
  appId: string;
  searchKey: string;
  devAppId: string;
  devSearchKey: string;
  restrictedIndices: string[];
};

export type AlgoliaPublicConfig = {
  appId: string;
  devAppId: string;
};

@subCollection("statistics")
export class SupportStatisticsModel {
  distribution_products_30d!: {
    label: string;
    value: number;
  }[];
}

export type PerksConfig = {
  perksType: PerkTypeLabel[];
};

export type PerkTypeLabel = {
  id: string;
  agenda: string;
  checklist: string;
  modules: string;
};

export type AcceleratorType = {
  id?: string;
  label: string;
  cloud: ProductEnum.GoogleCloud | ProductEnum.AmazonWebServices;
};
