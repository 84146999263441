import React, { useCallback, useMemo, useState } from "react";

import { SortableContext } from "@dnd-kit/sortable";
import { Box, Stack } from "@mui/material";

import { DotsLine } from "../../../../Components/Dashboard/DotsLine";
import { DropLine } from "./DropLine";
import { DroppableSpaceBetween } from "./DroppableSpaceBetween";
import { type HoverItem } from "./Grid/DraggableWidgetsGrid";
import { ResizingHandle } from "./ResizingHandle";
import { SortableWidget } from "./SortableWidget";
import { type GridRow, type WidgetItem, type WidgetWidth } from "./useWidgetItemsWithRows";
import { calculateWidthWithDelta, getWidth } from "./widgetUtils";

type Props = {
  rows: GridRow[];
  disableEditing: boolean;
  width: number;
  height: string | number;
  dragging: boolean;
  onResized: (rowIndex: number, width: [WidgetWidth, WidgetWidth]) => void;
  hoverItem?: HoverItem;
};

type ResizeContext = {
  rowIndex: number;
  delta: number;
};

const betweenRowsSpace = "20px";

export function Rows({ rows, disableEditing, height, width, hoverItem, dragging, onResized }: Props) {
  const isHovering = useCallback(
    (item: WidgetItem | undefined, position: HoverItem["position"]) => {
      if (!item) {
        return false;
      }
      return hoverItem?.id === item.name && hoverItem?.position === position;
    },
    [hoverItem]
  );

  const [resizing, setResizing] = useState<ResizeContext | false>(false);

  const itemsWithRowsInfo = useMemo(
    () =>
      rows.flatMap((row, rowIndex) => [
        {
          item: undefined,
          index: undefined,
          isFirst: false,
          isLast: false,
          nextItem: undefined,
          newRow: true,
          rowIndex,
          itemsInRow: 0,
        },
        ...row.items.map((item, index) => {
          const isFirst = index === 0;
          const isLast = index === row.items.length - 1;
          const nextItem = !isLast ? row.items[index + 1] : undefined;

          return {
            item,
            index,
            isFirst,
            isLast,
            nextItem,
            newRow: false,
            rowIndex,
            itemsInRow: row.items.length,
          };
        }),
      ]),
    [rows]
  );

  const ids = useMemo(() => rows.flatMap((rows) => rows.items.map((item) => item.name)), [rows]);

  const calcWidth = useCallback(
    (item: WidgetItem, rowIndex: number, itemsInRow: number, index: number) => {
      let result = getWidth(item.width) * width;
      if (resizing && resizing.rowIndex === rowIndex) {
        if (index === 0) {
          result = calculateWidthWithDelta(item, width, resizing.delta);
        } else if (index === 1) {
          result = calculateWidthWithDelta(item, width, -resizing.delta);
        }
      }

      let extra = 10;

      if (itemsInRow === 1) {
        extra = 0;
      }

      if (itemsInRow === 2 && index === 1) {
        extra = -10;
      }

      if (itemsInRow === 3) {
        if (index === 1) {
          extra = 0;
        } else if (index === 2) {
          extra = -10;
        }
      }

      return `${result + extra}px`;
    },
    [resizing, width]
  );

  const handleResizingEnd = useCallback(() => {
    if (!resizing) {
      return;
    }

    const items = rows[resizing.rowIndex].items;
    const widthWithDelta = calculateWidthWithDelta(items[0], width, resizing.delta);

    const allowedWidths = [0.333 * width, 0.5 * width, 0.666 * width];

    const getNearestAllowedWidth = (currentWidth: number) =>
      allowedWidths.reduce(
        (prev, curr) => (Math.abs(curr - currentWidth) < Math.abs(prev - currentWidth) ? curr : prev),
        allowedWidths[0]
      );

    // Adjust the width to the nearest allowed value
    const adjustedWidth = getNearestAllowedWidth(widthWithDelta);

    if (adjustedWidth === allowedWidths[0]) {
      onResized(resizing.rowIndex, [1, 2]);
    } else if (adjustedWidth === allowedWidths[1]) {
      onResized(resizing.rowIndex, [1.5, 1.5]);
    } else if (adjustedWidth === allowedWidths[2]) {
      onResized(resizing.rowIndex, [2, 1]);
    }

    setResizing(false);
  }, [onResized, resizing, rows, width]);

  return (
    <SortableContext items={ids}>
      {itemsWithRowsInfo.map(({ item, index, isFirst, isLast, nextItem, newRow, rowIndex, itemsInRow }) => {
        if (newRow) {
          return (
            <Stack key={`${rowIndex}_horizontal`} width="100%" height={betweenRowsSpace}>
              {resizing && resizing.rowIndex === rowIndex ? (
                <Box pt={0.5} width="100%">
                  <DotsLine />
                </Box>
              ) : (
                <DroppableSpaceBetween id={`${rowIndex}_horizontal`} />
              )}
            </Stack>
          );
        } else if (item && index !== undefined) {
          return (
            <Stack key={item.name} direction="row" width={calcWidth(item, rowIndex, itemsInRow, index)} height={height}>
              {isFirst && <Box minWidth="5px">{isHovering(item, "start") && <DropLine rightSpacing vertical />}</Box>}

              <Box width={`calc(100% - ${isLast ? "5px" : isFirst ? "25px" : "20px"})`}>
                <SortableWidget key={item.name} id={item.name} width="100%" height={height} />
              </Box>

              {!isLast && (
                <Box width={betweenRowsSpace} height="100%">
                  {dragging ? (
                    <DroppableSpaceBetween
                      disabled={disableEditing || !hoverItem}
                      hover={isHovering(item, "end") || isHovering(nextItem, "start")}
                      vertical
                      id={`${rowIndex}_vertical_${index}`}
                    />
                  ) : (
                    <ResizingHandle
                      disabled={disableEditing || itemsInRow !== 2}
                      onResizeStart={() => setResizing({ rowIndex, delta: 0 })}
                      onResizeEnd={handleResizingEnd}
                      onResize={(delta) => setResizing({ rowIndex, delta })}
                    />
                  )}
                </Box>
              )}

              {isLast && <Box minWidth="5px">{isHovering(item, "end") && <DropLine leftSpacing vertical />}</Box>}
            </Stack>
          );
        }
      })}
      <Stack key={`${rows.length}_horizontal`} width={"100%"} height={betweenRowsSpace}>
        <DroppableSpaceBetween id={`${rows.length}_horizontal`} />
      </Stack>
      <Box height={10} width="100%" />
    </SortableContext>
  );
}
