import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import { Alert, Stack } from "@mui/material";
import noop from "lodash/noop";

import { type RFNode } from "../../types";
import { SourceHandle, TargetHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  selected?: boolean;
  data: RFNode<CloudFlowNodeType.ACTION>;
};

// TODO set status from models
export const ActionNode = ({ id, data, selected }: Props) => (
  <>
    <NodeWrapper
      title={data.nodeData.name || ""}
      status={data.nodeData.status || "error"}
      onEdit={data.onEditNode}
      onDelete={data.onDeleteNode}
      isActive={!!selected}
      onClick={noop}
    >
      {!!data.nodeData.statusMessage && (
        <Stack my={-2} pl={5} pr={1}>
          <Alert severity="warning" sx={{ my: 1 }}>
            {data.nodeData.statusMessage}
          </Alert>
        </Stack>
      )}
    </NodeWrapper>
    <SourceHandle id={id} />
    <TargetHandle id={id} />
  </>
);
