import { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { Alert, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const languages = [
  { value: "en", label: "English (American)" },
  { value: "ja", label: "Japanese" },
];

export const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = useCallback(
    (e) => {
      i18n.changeLanguage(e.target.value);
      localStorage.setItem("i18nLng", e.target.value);
    },
    [i18n]
  );

  return (
    <FormControl fullWidth>
      <InputLabel>Language</InputLabel>
      <Select value={i18n.language} label="Language" onChange={handleChangeLanguage} size="small">
        {languages.map((lang) => (
          <MenuItem key={lang.value} value={lang.value}>
            {lang.label}
          </MenuItem>
        ))}
      </Select>
      {i18n.language === "ja" && (
        <Alert severity="info" sx={{ mt: 2 }}>
          Only navigation and the Sign in and Consulting and support pages are available in Japanese.
        </Alert>
      )}
    </FormControl>
  );
};
