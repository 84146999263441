import {
  createContext,
  type Dispatch,
  type MutableRefObject,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";

import {
  AnalyticsDataSource,
  type CurrencyCode,
  type CustomTimeRange,
  type DatahubMetrics,
  type ExtendedMetric,
  Feature,
  Metric,
  Renderer,
  type ReportConfig,
  type TimeInterval,
  type TimeSettingsConfig,
} from "@doitintl/cmp-models";

import { type MetricWSnap, type ReportCustomTimeRange } from "../../../types";
import { convertNullToArray } from "../../../utils/common";
import { defaultTimeSettingsConfig } from "../generateReport/utils";
import {
  type ReportConfigAction,
  reportConfigReducer,
  type ReportConfigStoreType,
} from "../reducers/ReportConfigReducer";
import { setDefaultConfig } from "../report/utils";
import { ExportOptions, sanitizeCustomTimeRange } from "../utilities";
import { getDefaultForecastSettings } from "./../report/config/ForecastSettings/utils";
import { useReportContext } from "./ReportContext";
import type ReportData from "../ReportData";

type ReportInitial = ReportConfig & {
  name: string;
  description: string;
  calcMetrics: MetricWSnap[];
  extendedMetrics: ExtendedMetric[];
  datahubMetrics: DatahubMetrics[];
  customTimeRange: CustomTimeRange | null;
  excludeSelectMetadataIds: Set<string>;
};

export const showValuesPersistKey = "analytics-show-values-checkbox";

const initConfigCalcMetric = (
  metric?: Metric,
  calcMetrics?: MetricWSnap[],
  calculatedMetricId?: string
): MetricWSnap | null => {
  if (metric === Metric.CALCULATED && calculatedMetricId) {
    return calcMetrics?.find((m) => m.snapshot.id === calculatedMetricId) ?? null;
  }

  return null;
};

const initExtendedMetric = (
  reportConfig: ReportConfig | undefined,
  extendedMetrics: ExtendedMetric[] | undefined,
  datahubMetrics: DatahubMetrics[] | undefined
) => {
  if (reportConfig?.metric === Metric.EXTENDED && !!reportConfig?.extendedMetric) {
    const combinedMetrics = [...(extendedMetrics ?? []), ...(datahubMetrics ?? [])];
    const foundMetric = combinedMetrics.find((m) => m.key === reportConfig.extendedMetric);
    return foundMetric?.key ?? "";
  }

  return "";
};

const initConfigRenderer = (reportConfig) => {
  // If there is no renderer on the report config
  // or renderer is one of the old export options (sheets/csv),
  // then default to stack column chart renderer
  if (!reportConfig?.renderer || reportConfig.renderer in ExportOptions) {
    return Renderer.STACKED_COLUMN_CHART;
  }

  return reportConfig.renderer;
};

const initialTimeRangeOption = (customTimeRange: ReportCustomTimeRange, timeSettings?: TimeSettingsConfig) => ({
  label: "",
  mode: timeSettings?.mode ?? defaultTimeSettingsConfig.mode,
  time: timeSettings?.unit ?? defaultTimeSettingsConfig.unit,
  amount: timeSettings?.amount ?? defaultTimeSettingsConfig.amount,
  range: { start: customTimeRange?.from ?? null, end: customTimeRange?.to ?? null },
  includeCurrent: timeSettings?.includeCurrent,
});

const getForecastSettings = (
  features: Feature[] | undefined,
  customTimeRange: ReportCustomTimeRange,
  timeSettings: TimeSettingsConfig | undefined,
  timeInterval: TimeInterval | undefined
) => {
  if (features?.includes(Feature.FORECAST) && !!timeInterval) {
    const timeRange = initialTimeRangeOption(customTimeRange, timeSettings);
    return getDefaultForecastSettings(timeRange, timeInterval, "totals");
  }
};

export const initialConfig = (report?: ReportInitial): ReportConfigStoreType => {
  const renderer = initConfigRenderer(report);
  const customTimeRange = report?.customTimeRange ? sanitizeCustomTimeRange(report.customTimeRange) : null;

  const localStorageValue = localStorage.getItem(showValuesPersistKey);
  const showValuesInFilterSearchResults = localStorageValue === null || localStorageValue === "true";

  const {
    metric,
    dataSource = AnalyticsDataSource.BILLING,
    timeInterval,
    rowOrder,
    colOrder,
    comparative,
    limitAggregation,
    currency,
    aggregator,
    count,
  } = report || {};

  return {
    name: report?.name ?? "",
    description: report?.description ?? "",
    calcMetrics: convertNullToArray(report?.calcMetrics),
    extendedMetrics: convertNullToArray(report?.extendedMetrics),
    datahubMetrics: convertNullToArray(report?.datahubMetrics),
    customTimeRange,
    excludeSelectMetadataIds: report?.excludeSelectMetadataIds ?? new Set(),
    showValuesInFilterSearchResults,
    // config from firestore
    calculatedMetric: initConfigCalcMetric(report?.metric, report?.calcMetrics, report?.calculatedMetric?.id),
    extendedMetric: initExtendedMetric(report, report?.extendedMetrics, report?.datahubMetrics),
    metricFilters: convertNullToArray(report?.metricFilters),
    excludePartialData: !!report?.excludePartialData,
    includeCredits: !!report?.includeCredits,
    includeSubtotals: !!report?.includeSubtotals,
    renderer,
    features: convertNullToArray(report?.features?.filter((feature) => feature !== Feature.FORECAST)),
    forecastSettings:
      report?.forecastSettings ??
      getForecastSettings(report?.features, customTimeRange, report?.timeSettings, timeInterval),
    logScale: !!report?.logScale,
    cols: convertNullToArray(report?.cols),
    filters: convertNullToArray(report?.filters),
    optional: convertNullToArray(report?.optional),
    rows: convertNullToArray(report?.rows),
    timeRangeOption: initialTimeRangeOption(customTimeRange, report?.timeSettings),
    ...setDefaultConfig({
      metric,
      renderer,
      timeInterval,
      rowOrder,
      colOrder,
      comparative,
      limitAggregation,
      currency,
      aggregator,
      count,
      dataSource,
    }),
  };
};

type ReportConfigContextType = {
  reportConfig: ReportConfigStoreType;
  dispatchReportConfig: Dispatch<ReportConfigAction>;
  reportData: MutableRefObject<ReportData | undefined>;
  reportDataWForecasts: MutableRefObject<ReportData | undefined>;
};

const ReportConfigContext = createContext({
  reportConfig: initialConfig(),
  dispatchReportConfig: (_: ReportConfigAction) => {},
} as ReportConfigContextType);

type Props = {
  calcMetrics: MetricWSnap[];
  extendedMetrics: ExtendedMetric[];
  datahubMetrics: DatahubMetrics[];
  currency?: CurrencyCode | null;
  excludeSelectMetadataIds: Set<string>;
  dataSource: AnalyticsDataSource;
};

export const ReportConfigContextProvider = ({ children, props }: { children?: ReactNode; props: Props }) => {
  const { report } = useReportContext();
  const config = report?.data.config as ReportConfig;

  const reportInitial: ReportInitial = useMemo(
    () => ({
      ...config,
      name: report?.data.name ?? "",
      description: report?.data.description ?? "",
      calcMetrics: props.calcMetrics,
      extendedMetrics: props.extendedMetrics,
      datahubMetrics: props.datahubMetrics,
      currency: props.currency,
      excludeSelectMetadataIds: props.excludeSelectMetadataIds,
      dataSource: props.dataSource ?? AnalyticsDataSource.BILLING,
    }),
    [
      config,
      props.calcMetrics,
      props.currency,
      props.dataSource,
      props.datahubMetrics,
      props.excludeSelectMetadataIds,
      props.extendedMetrics,
      report?.data.description,
      report?.data.name,
    ]
  );

  const [reportConfig, dispatchReportConfig] = useReducer(reportConfigReducer, initialConfig(reportInitial));
  const reportData = useRef<ReportData>();
  const reportDataWForecasts = useRef<ReportData>();
  const reportConfigProviderValue = useMemo(
    () => ({ reportConfig, dispatchReportConfig, reportData, reportDataWForecasts }),
    [reportConfig, dispatchReportConfig]
  );

  useEffect(() => {
    dispatchReportConfig({ payload: { extendedMetrics: props.extendedMetrics } });
  }, [props.extendedMetrics]);

  useEffect(() => {
    dispatchReportConfig({
      payload: {
        calculatedMetric: initConfigCalcMetric(
          reportInitial?.metric,
          reportInitial?.calcMetrics,
          reportInitial?.calculatedMetric?.id
        ),
      },
    });
  }, [reportInitial?.metric, reportInitial?.calcMetrics, reportInitial?.calculatedMetric?.id]);

  return <ReportConfigContext.Provider value={reportConfigProviderValue}>{children}</ReportConfigContext.Provider>;
};

export const useReportConfig = (): ReportConfigContextType => useContext(ReportConfigContext);
