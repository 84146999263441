import { Box, Divider, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useCustomerContext } from "../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../Context/TierProvider";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { type Insight } from "../Insights/types";
import { useInsightThreads } from "./api";
import { CreateThread } from "./CreateThread/CreateThread";
import InsightThreads from "./InsightThreads";

type Props = {
  insight: Insight;
};

export const Threads = ({ insight }: Props) => {
  const { customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(customer);

  const isEntitled = useIsFeatureEntitled("governance:threads") || isPresentationMode;

  const { threads, isFetching } = useInsightThreads(insight.key);

  if (isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={6}>
        <CircularProgress />
      </Box>
    );
  }

  const ThreadsContent = () => {
    if (!isEntitled || threads === null || threads === undefined || threads.length === 0) {
      // There's no thread in Firestore
      return <CreateThread isEntitled={!!isEntitled} insight={insight} isFirstThread />;
    }

    // We have both the thread from Firestore and its related Jira Issues
    return (
      <Box gap={2}>
        <Box maxHeight={500} overflow="auto">
          <InsightThreads insight={insight} />
        </Box>
        <CreateThread isEntitled={isEntitled} insight={insight} />
      </Box>
    );
  };

  const getHeaderContent = () => {
    if (threads?.length === 0) {
      return "Get started";
    }

    return `Linked threads ${threads && threads?.length > 0 ? `(${threads.length})` : ""}`;
  };

  return (
    <>
      <Typography variant="h4" color="inherit" sx={{ textDecoration: "none", fontWeight: 500 }} mb={1}>
        {getHeaderContent()}
      </Typography>
      <Box sx={{ mb: 2 }}>
        <ThreadsContent />
        <Divider />
      </Box>
    </>
  );
};
