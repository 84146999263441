import { memo } from "react";

import { areEqual, type ListChildComponentProps } from "react-window";
import { CardActionArea, Stack, Typography } from "@mui/material";

import { AlgoliaListSubHeaders } from "../../../../Components/AlgoliaSearch/consts";

export const _CloudflowAlgoliaResultList = (props: ListChildComponentProps) => {
  const { index: parentIndex, data, style } = props;
  if (data.collections.length === 0) {
    return null;
  }
  const { source, items } = data.collections[parentIndex];
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Stack key={parentIndex} style={style} gap={1} pb={2}>
      <Typography data-cy="search-section-header" sx={{ fontWeight: 500, fontSize: 16, color: "text.primary" }}>
        {AlgoliaListSubHeaders[items[0]._indexName] || "Recent searches"}
      </Typography>
      {items.map((item) => (
        <CardActionArea
          key={item.objectID}
          sx={(theme) => ({
            "&.Mui-focusVisible": {
              "& .MuiCard-root": {
                borderColor: theme.palette.primary.main,
                "& .MuiCardHeader-action": {
                  opacity: 1,
                },
              },
            },
            "& .MuiCardActionArea-focusHighlight": {
              background: "none",
            },
          })}
          {...data.autocomplete.getItemProps({ item, source })}
        >
          {source.templates.item({ item, state: data.autocompleteState })}
        </CardActionArea>
      ))}
    </Stack>
  );
};
export const CloudflowAlgoliaResultList = memo(_CloudflowAlgoliaResultList, areEqual);
