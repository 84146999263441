import { useRef, useState } from "react";

import { DraggableCore } from "react-draggable";
import { Box } from "@mui/material";

import { DropLine } from "./DropLine";
import { useHover } from "./hooks/useHover";

type Props = {
  disabled: boolean;
  onResizeStart: () => void;
  onResize: (delta: number) => void;
  onResizeEnd: () => void;
};

export function ResizingHandle({ disabled, onResize, onResizeStart, onResizeEnd }: Props) {
  const [x, setX] = useState(-1);

  const mouseOverRef = useRef<HTMLDivElement>(null);
  const mouseOver = useHover(mouseOverRef);
  const [resizing, setResizing] = useState(false);

  return (
    <DraggableCore
      handle=".handle"
      disabled={disabled}
      onStart={(_e, data) => {
        onResizeStart();
        setX(data.x);
        setResizing(true);
      }}
      onDrag={(_e, data) => {
        onResize(data.x - x);
      }}
      onStop={() => {
        onResizeEnd();
        setResizing(false);
      }}
    >
      <Box
        ref={mouseOverRef}
        className="handle"
        sx={
          disabled
            ? {}
            : {
                "&:hover": {
                  cursor: "ew-resize",
                },
              }
        }
        width={"100%"}
        height={"100%"}
      >
        {!disabled && (mouseOver || resizing) && <DropLine vertical leftSpacing />}
      </Box>
    </DraggableCore>
  );
}
