import { type FC } from "react";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Box, CircularProgress, IconButton, Tooltip, useTheme } from "@mui/material";
import { BaseEdge, type Edge, EdgeLabelRenderer, type EdgeProps, getBezierPath } from "@xyflow/react";

import { cloudflowTexts } from "../../../../assets/texts";

const CustomEdge: FC<EdgeProps<Edge<{ label: string; loading?: boolean }>>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const {
    palette: { primary, divider },
  } = useTheme();

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={{ stroke: divider }} />
      <EdgeLabelRenderer>
        <Box
          sx={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
        >
          <Tooltip title={cloudflowTexts.ADD_NEW_STEP} placement="right" arrow={true}>
            <IconButton
              sx={{
                p: 0,
                "&:hover": {
                  color: primary.main,
                  backgroundColor: primary.contrastText,
                },
                pointerEvents: "all",
                backgroundColor: primary.contrastText,
              }}
            >
              {data?.loading ? <CircularProgress size={24} /> : <ControlPointIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
