import { type SyntheticEvent } from "react";

import { Metadata } from "@doitintl/cmp-models";
import { Box, Paper } from "@mui/material";

import { DraggableItemHolder } from "../../../../Components/DragAndDrop/DraggableItemHolder";
import { type MetadataOption } from "../../../../types";
import { type ReportAddDimension, type ReportItemRemove } from "../../utilities";
import ReportItemContent from "./ReportItemContent";

type Props = {
  item: MetadataOption | undefined;
  id: string;
  description?: string;
  onItemRemove: ReportItemRemove;
  handleAddDimension: ReportAddDimension;
  isDraggable?: boolean;
  grabbing?: boolean;
  disabled?: boolean;
  tooltip?: string;
  openSplitMenu?: (event: SyntheticEvent, item?: MetadataOption) => void;
  splitsLen?: number;
  includeOrigin?: boolean;
  onIncludeOrigin?: (id: string, include: boolean) => void;
};

export const ReportItem = ({
  item,
  id,
  description,
  onItemRemove,
  handleAddDimension,
  isDraggable = false,
  grabbing,
  disabled,
  tooltip,
  openSplitMenu,
  splitsLen,
  includeOrigin,
  onIncludeOrigin,
}: Props) => {
  if (!item) {
    return <></>;
  }

  const height = "auto";
  const itemDisabled = disabled || item?._disabled;
  const itemFiltered = (item._filter && item._filter.length > 0) || !!item._regexp || item._metricFilter;
  const showSplitButton = item.data.type !== Metadata.ATTRIBUTION;

  if (!itemDisabled && isDraggable) {
    return (
      <Box py={0.5} data-cy={`report-item-${item.data.label}`}>
        <DraggableItemHolder key={id} id={id} grabbing={Boolean(grabbing)} height={height}>
          <ReportItemContent
            label={item.data.label}
            numOfValues={item._filter?.length ?? 0}
            description={description}
            onRemove={() => onItemRemove([item])}
            onClick={item.data.type !== Metadata.DATETIME ? handleAddDimension([item]) : undefined}
            disabled={itemDisabled}
            filtered={itemFiltered}
            tooltip={tooltip}
            showSplitButton={showSplitButton}
            openSplitMenu={(event) => openSplitMenu?.(event, item)}
            splitsLen={splitsLen}
            includeOrigin={includeOrigin}
            onIncludeOrigin={() => onIncludeOrigin?.(item.id, !includeOrigin)}
          />
        </DraggableItemHolder>
      </Box>
    );
  }

  return (
    <Box py={0.5} data-cy={`report-item-${item.data.label}`}>
      <Paper variant="outlined" sx={{ height, px: 1 }}>
        <ReportItemContent
          label={item.data.label}
          numOfValues={item._filter?.length ?? 0}
          description={description}
          onRemove={!itemDisabled ? () => onItemRemove([item]) : undefined}
          onClick={!itemDisabled && item.data.type !== Metadata.DATETIME ? handleAddDimension([item]) : undefined}
          disabled={itemDisabled}
          filtered={itemFiltered}
          tooltip={tooltip}
        />
      </Paper>
    </Box>
  );
};

export default ReportItem;
