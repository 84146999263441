import { type Dispatch, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Aggregator, CurrenciesMap, CurrencyCodes, Metric, TimeInterval, TimeSettingsMode } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Button,
  CircularProgress,
  Icon,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";

import { attributionGroupsText } from "../../../../assets/texts";
import { guidedExperienceText } from "../../../../assets/texts/CloudAnalytics";
import useFormatter from "../../../../Components/hooks/useFormatter";
import { useCloudAnalyticsContext } from "../../../../Context/AnalyticsContext";
import { type AttributionWRef } from "../../../../types";
import { maxFallbackLength } from "../../attributionGroups/AttributionGroupDragGroup";
import { useQueryRun } from "../../previewReport/useQueryRun";
import { getCols, getFilteredAttributionGroups, getRows } from "../../previewReport/utils";
import { type CostAllocationAction, type CostAllocationState } from "../costAllocationReducer";
import { CUSTOM_OPTION, NUM_OF_MONTHS, sendStepMixpanelEvent } from "../utils";
import Step3Row from "./Step3Row";
import type ReportData from "../../ReportData";

const STEP_NUMBER = 3;

type Props = {
  costAllocationState: CostAllocationState;
  dispatchCostAllocationState: Dispatch<CostAllocationAction>;
  origin: string;
};

const Step3 = ({ costAllocationState, dispatchCostAllocationState, origin }: Props) => {
  const { metadata: metadataSnapshots, fetchMetadata } = useCloudAnalyticsContext();
  const [unallocatedRowValues, setUnallocatedRowValues] = useState<number[]>(new Array(NUM_OF_MONTHS).fill(0));
  const [totalColumnValues, setTotalColumnValues] = useState<number[]>(new Array(NUM_OF_MONTHS).fill(0));
  const reportDataRef = useRef<ReportData | null>(null);
  const reportData = reportDataRef.current;
  const [queryRunning, setQueryRunning] = useState(false);
  const [runQuery, setRunQuery] = useState(false);
  const [, setReportError] = useState<string | null>(null);
  const [nullFallback, setNullFallback] = useState(costAllocationState.unallocatedName);
  const currency = CurrencyCodes[CurrencyCodes.USD];
  const formatter = useFormatter(Aggregator.TOTAL, currency, Metric.COST, undefined, undefined);
  const isInit = useRef(false);

  useEffect(() => {
    sendStepMixpanelEvent(STEP_NUMBER, "add-attributions", origin);
  }, [origin]);

  useEffect(() => {
    if (!isInit.current && metadataSnapshots.length) {
      isInit.current = true;
    }
  }, [metadataSnapshots.length]);

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  const filteredAttributions = useMemo(() => {
    if (costAllocationState.newAttributions.some((attr) => attr.hasUnsavedChanges)) {
      return [];
    }

    return costAllocationState.newAttributions.flatMap((extendedAttr) => {
      if (extendedAttr.data === null) {
        return [];
      }
      const attr: AttributionWRef = {
        ref: extendedAttr.ref,
        data: extendedAttr.data,
        transform: extendedAttr.transform,
      };
      return attr;
    });
  }, [costAllocationState.newAttributions]);

  const attributionGroupsForPreview = [
    {
      id: undefined,
      name: undefined,
      attributions: filteredAttributions.map((attr) => attr.ref.id),
      ...(nullFallback && { nullFallback }),
    },
  ];
  const filteredAttributionGroups = getFilteredAttributionGroups(attributionGroupsForPreview, filteredAttributions);

  useQueryRun({
    attributions: filteredAttributions,
    previewData: {
      attributionGroupsPayload: filteredAttributionGroups,
      attributionGroups: attributionGroupsForPreview,
      currency,
      timeInterval: TimeInterval.MONTH,
      timeRangeOptions: {
        mode: TimeSettingsMode.Last,
        time: TimeInterval.MONTH,
        amount: 3,
        includeCurrent: false,
      },
      scope: [],
      rows: getRows(filteredAttributions.length, filteredAttributionGroups),
      filters: [],
      cols: getCols(metadataSnapshots ?? [], TimeInterval.MONTH),
    },
    queryRunning,
    setQueryRunning,
    reportDataWForecasts: reportDataRef,
    runQuery,
    setRunQuery,
    setReportError,
    forecastMode: false,
  });

  useEffect(() => {
    // isInit check is needed on step back to not run the query 2 times
    if (isInit.current && filteredAttributions.length !== 0) {
      setRunQuery(true);
    }
  }, [filteredAttributions.length]);

  useEffect(() => {
    if (reportData) {
      const rowKeys = reportData.getRowKeys();
      const colKeys = reportData.getColKeys();
      const unallocatedRowKey = rowKeys.find((rowKey) => rowKey.includes(nullFallback || "Unallocated"));
      const unallocatedResults: number[] = new Array(NUM_OF_MONTHS).fill(0);
      const columnTotals: number[] = new Array(NUM_OF_MONTHS).fill(0);

      colKeys.forEach((colKey, idx) => {
        if (unallocatedRowKey) {
          const cellValueAggregator = reportData.getAggregator(unallocatedRowKey, colKey);
          unallocatedResults[idx] = cellValueAggregator.value();
        }
        const rowTotalAggregator = reportData.getAggregator([], colKey);
        columnTotals[idx] = rowTotalAggregator.value();
      });

      setUnallocatedRowValues(unallocatedResults);
      setTotalColumnValues(columnTotals);
    }
  }, [nullFallback, reportData]);

  const getPreviewResultFromReportData = useCallback(
    (attrName: string) => {
      if (reportData) {
        const rowKey = reportData.getRowKeys().find((rowKey) => rowKey.includes(attrName));
        if (rowKey) {
          return reportData.getColKeys().map((colKey) => {
            const cellValueAggregator = reportData.getAggregator(rowKey, colKey);
            return cellValueAggregator.value();
          });
        }
      }
    },
    [reportData]
  );

  const months = useMemo(
    () => Array.from({ length: NUM_OF_MONTHS }, (_, i) => DateTime.now().minus({ month: i + 1 }).monthShort).reverse(),
    []
  );

  useEffect(() => {
    let allEmpty = true;
    let someAttrHasUnsavedChanges = false;

    for (const attr of costAllocationState.newAttributions) {
      if (attr.data) {
        allEmpty = false;
      }
      if (attr.hasUnsavedChanges) {
        someAttrHasUnsavedChanges = true;
        break;
      }
    }

    dispatchCostAllocationState({
      type: "UPDATE_NEXT_BUTTON_DISABLED",
      payload: { nextButtonDisabled: { stepIdx: STEP_NUMBER - 1, disabled: allEmpty || someAttrHasUnsavedChanges } },
    });

    if (allEmpty) {
      setUnallocatedRowValues(new Array(NUM_OF_MONTHS).fill(0));
      setTotalColumnValues(new Array(NUM_OF_MONTHS).fill(0));
    }
  }, [costAllocationState.newAttributions, dispatchCostAllocationState]);

  const renderCostValue = (value: number, isTotal?: boolean) => {
    const variant = isTotal ? "subtitle2" : "body2";
    if (queryRunning) {
      return <CircularProgress size={16} />;
    }
    if (value !== 0) {
      return (
        <Typography variant={variant} color={isTotal ? "textSecondary" : "textPrimary"}>
          {formatter(value, true, undefined)}
        </Typography>
      );
    }
    return (
      <Typography variant={variant} color="textSecondary">
        {CurrenciesMap[currency]}
      </Typography>
    );
  };

  const handleNullFallbackChange = (event: { type: string; target: { value: string } }) => {
    dispatchCostAllocationState({ type: "SET_UNALLOCATED_NAME", payload: { unallocatedName: event.target.value } });
  };

  const handleNullFallbackBlur = (event: { type: string; target: { value: string } }) => {
    setNullFallback(event.target.value);
    setRunQuery(true);
  };

  return (
    <>
      <Typography mb={3}>
        {guidedExperienceText.step3.p1.part1}
        <Typography component="span" fontWeight={500}>
          {guidedExperienceText.step3.setUp}
        </Typography>
        {guidedExperienceText.step3.p1.part2}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={(theme) => ({ background: theme.palette.primary.hoverBackground })}>
              <TableCell>{costAllocationState.selectedAG.name}</TableCell>
              {months.map((month) => (
                <TableCell align="center" key={month}>
                  <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                    <Typography variant="body2" fontWeight="500">
                      {month}
                    </Typography>
                    <Tooltip title={guidedExperienceText.step3.monthTooltip} placement="top" arrow>
                      <InfoOutlinedIcon fontSize="small" color="action" width="16px" height="16px" />
                    </Tooltip>
                  </Stack>
                </TableCell>
              ))}
              <TableCell align="center" sx={{ pl: 0 }}>
                {guidedExperienceText.step3.rules}
              </TableCell>
              <TableCell sx={{ width: 96 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {costAllocationState.newAttributions.map((attr, idx) => (
              <Step3Row
                agName={
                  costAllocationState.selectedAG.displayName === CUSTOM_OPTION
                    ? ""
                    : costAllocationState.selectedAG.name.toLowerCase().slice(0, -1)
                }
                attribution={attr}
                currency={currency}
                dispatchCostAllocationState={dispatchCostAllocationState}
                formatter={formatter}
                metadataSnapshots={metadataSnapshots}
                nameFieldAutoFocus={idx === 0}
                key={attr.ref.id}
                previewResult={
                  getPreviewResultFromReportData(attr.data?.name ?? "") ?? new Array(NUM_OF_MONTHS).fill(0)
                }
                queryRunning={queryRunning}
              />
            ))}
            <TableRow>
              <TableCell colSpan={12}>
                <Button
                  variant="text"
                  onClick={() => dispatchCostAllocationState({ type: "ADD_EMPTY_ATTRIBUTION", payload: {} })}
                >
                  <AddIcon fontSize="small" />
                  <Typography variant="subtitle2" ml={1}>
                    {guidedExperienceText.step3.addMore}
                  </Typography>
                </Button>
              </TableCell>
            </TableRow>
            {/* Unallocated row */}
            <TableRow hover>
              <TableCell>
                <TextField
                  name="title"
                  variant="outlined"
                  placeholder={attributionGroupsText.UNALLOCATED}
                  value={costAllocationState.unallocatedName}
                  onChange={handleNullFallbackChange}
                  onBlur={handleNullFallbackBlur}
                  inputProps={{ maxLength: maxFallbackLength }}
                  fullWidth
                  sx={{ backgroundColor: "background.paper" }}
                />
              </TableCell>
              {unallocatedRowValues.map((val, idx) => (
                <TableCell key={`column-unallocated-${idx}`} align="center">
                  {renderCostValue(val)}
                </TableCell>
              ))}
              <TableCell />
              <TableCell size="small" align="right">
                <Tooltip title={attributionGroupsText.UNALLOCATED_TOOLTIP}>
                  <Icon component="div" sx={{ cursor: "pointer" }}>
                    <InfoOutlinedIcon fontSize="small" color="action" />
                  </Icon>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter sx={(theme) => ({ background: theme.palette.primary.hoverBackground })}>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">{guidedExperienceText.step3.totalCost}</Typography>
              </TableCell>
              {totalColumnValues.map((val, idx) => (
                <TableCell align="center" key={`column-total-${idx}`}>
                  {renderCostValue(val, true)}
                </TableCell>
              ))}
              <TableCell />
              <TableCell />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default Step3;
