import { type FC } from "react";

import { ModelType } from "@doitintl/cmp-models";
import { TextField } from "@mui/material";
import { type FieldInputProps } from "formik";

import { useFieldCommonProps } from "../useFieldCommonProps";
import { OptionalParamWrapper } from "./wrappers/OptionalParamWrapper";
import { ReferencedFieldWrapper } from "./wrappers/ReferencedField/ReferencedFieldWrapper";

export const NumberParam: FC<{
  fieldProps: FieldInputProps<number>;
  label: string;
  onRemove?: () => void;
}> = ({ fieldProps, label, onRemove }) => {
  const commonFieldProps = useFieldCommonProps(fieldProps, label, onRemove === undefined);

  const value = commonFieldProps.value ?? "";

  return (
    <OptionalParamWrapper onRemove={onRemove}>
      <ReferencedFieldWrapper commonFieldProps={commonFieldProps} modelType={ModelType.FLOAT}>
        <TextField type="number" fullWidth {...commonFieldProps} value={value} />
      </ReferencedFieldWrapper>
    </OptionalParamWrapper>
  );
};
