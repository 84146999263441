import { CloudFlowNodeType } from "@doitintl/cmp-models";
import { Button, Stack } from "@mui/material";
import noop from "lodash/noop";

import { cloudflowTexts } from "../../../../assets/texts";
import { type RFNode } from "../../types";
import { SourceHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: RFNode;
};

const buttons = [
  { label: cloudflowTexts.CUSTOM_SCHEDULE, nodeType: CloudFlowNodeType.TRIGGER },
  { label: cloudflowTexts.MANUALLY_START, nodeType: CloudFlowNodeType.MANUAL_TRIGGER },
  { label: cloudflowTexts.EXTERNAL_SYSTEM, nodeType: CloudFlowNodeType.ACTION },
];

export const StartStepConfigurator = ({ id, data }: Props) => (
  <NodeWrapper
    title={data.nodeData.name || ""}
    status={data.nodeData.status || "validated"}
    isActive={false}
    onClick={noop}
    disableHover={true}
  >
    <Stack gap={1} mb={-1} pl={1}>
      {buttons.map((button) => (
        <Button
          key={button.label}
          variant="outlined"
          onClick={() => data.onAddNode && data.onAddNode(button.nodeType, id, data.nodeData.display?.position)}
        >
          {button.label}
        </Button>
      ))}
    </Stack>
    <SourceHandle id={id} />
  </NodeWrapper>
);
