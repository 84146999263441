import { createContext, type Dispatch, type FC, type ReactNode, useContext, useEffect, useState } from "react";

import { ModelType, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { Form, Formik, useFormikContext } from "formik";
import noop from "lodash/noop";

import { BooleanParam } from "./parameters/BooleanParam";
import { ListParam } from "./parameters/ListParam";
import { NumberParam } from "./parameters/NumberField";
import { PlaceholderParam } from "./parameters/PlaceholderParam";
import { StringParam } from "./parameters/StringParam";
import { StructureParam } from "./parameters/StructureParam";
import { TimestampParam } from "./parameters/TimestampParam";
import { type generateApiActionParametersSchema, useApiActionParametersSchema } from "./useApiActionParametersSchema";

export const SchemaContext = createContext<ReturnType<typeof generateApiActionParametersSchema> | null>(null);

export const useApiActionParameterSchema = () => {
  const context = useContext(SchemaContext);
  if (!context) {
    throw new Error("useSchemaContext must be used within a SchemaProvider");
  }
  return context;
};

export const GenericForm: FC<{
  fieldPath: string;
  inputModel?: UnwrappedApiServiceModelDescriptor;
  label: string;
  onRemove?: () => void;
  placeholderTooltip?: string;
}> = ({ inputModel, fieldPath, onRemove, label, placeholderTooltip }) => {
  const formikProps = useFormikContext();

  const fieldProps = formikProps.getFieldProps(fieldPath);

  if (!inputModel) {
    return <PlaceholderParam label={label} required={onRemove === undefined} placeholderTooltip={placeholderTooltip} />;
  }
  if (fieldProps.value === undefined) {
    return null;
  }

  switch (inputModel.type) {
    case ModelType.STRING:
      return <StringParam inputModel={inputModel} fieldProps={fieldProps} label={label} onRemove={onRemove} />;

    case ModelType.TIMESTAMP:
      return <TimestampParam inputModel={inputModel} fieldProps={fieldProps} label={label} onRemove={onRemove} />;

    case ModelType.INTEGER:
    case ModelType.FLOAT:
      return <NumberParam fieldProps={fieldProps} label={label} onRemove={onRemove} />;

    case ModelType.BOOLEAN:
      return <BooleanParam fieldProps={fieldProps} label={label} onRemove={onRemove} />;

    case ModelType.LIST:
      return (
        <ListParam
          fieldPath={fieldPath}
          fieldProps={fieldProps}
          label={label}
          inputModel={inputModel}
          onRemove={onRemove}
        />
      );

    case ModelType.STRUCTURE:
      return (
        <StructureParam
          fieldPath={fieldPath}
          fieldProps={fieldProps}
          label={label}
          inputModel={inputModel}
          onRemove={onRemove}
        />
      );

    default:
      return null;
  }
};

const FormChangesListener: FC<{
  onValuesChange?: (parameters: unknown) => void;
  onValidityChange?: Dispatch<boolean>;
}> = ({ onValuesChange, onValidityChange }) => {
  const { dirty, isValid, isValidating, values } = useFormikContext();
  const [changedValues, setChangedValues] = useState(values);
  const [changedValidity, setChangedValidity] = useState(isValid);

  useEffect(() => {
    dirty && setChangedValues(values);
  }, [dirty, values]);

  useEffect(() => {
    !isValidating && setChangedValidity(isValid);
  }, [isValid, isValidating]);

  useEffect(() => onValuesChange?.(changedValues), [changedValues, onValuesChange]);
  useEffect(() => onValidityChange?.(changedValidity), [changedValidity, onValidityChange]);

  return null;
};

type ApiActionParametersFormProps = {
  inputModel: UnwrappedApiServiceModelDescriptor;
  values?: object;
  onValuesChange?: (parameters: unknown) => void;
  onValidityChange: Dispatch<boolean>;
  children?: ReactNode;
};

export const ApiActionParametersForm: FC<ApiActionParametersFormProps> = ({
  inputModel,
  values,
  onValuesChange,
  onValidityChange,
  children,
}) => {
  const validationSchema = useApiActionParametersSchema(inputModel);

  return (
    <SchemaContext.Provider value={validationSchema}>
      <Formik
        validateOnChange
        validateOnBlur
        validateOnMount
        initialValues={validationSchema.cast(values ?? {})}
        validationSchema={validationSchema}
        onSubmit={noop}
      >
        <Form>
          {children}
          <FormChangesListener onValuesChange={onValuesChange} onValidityChange={onValidityChange} />
        </Form>
      </Formik>
    </SchemaContext.Provider>
  );
};

export const GenericApiActionParametersForm: FC<ApiActionParametersFormProps> = (props) => (
  <ApiActionParametersForm {...props}>
    <GenericForm inputModel={props.inputModel} fieldPath="" label="" />
  </ApiActionParametersForm>
);
