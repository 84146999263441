import { type FC } from "react";

import { ModelType, type StringApiServiceModelDescriptor } from "@doitintl/cmp-models";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, MenuItem, TextField } from "@mui/material";
import { type FieldInputProps } from "formik";

import { useToggle } from "../../../../../utils/useToggle";
import { useFieldCommonProps } from "../useFieldCommonProps";
import { OptionalParamWrapper } from "./wrappers/OptionalParamWrapper";
import { ReferencedFieldWrapper } from "./wrappers/ReferencedField/ReferencedFieldWrapper";

export const StringParam: FC<{
  fieldProps: FieldInputProps<string>;
  inputModel: StringApiServiceModelDescriptor;
  label: string;
  onRemove?: () => void;
}> = ({ fieldProps, inputModel, label, onRemove }) => {
  const commonFieldProps = useFieldCommonProps(fieldProps, label, onRemove === undefined);

  const [isVisible, , , toggleVisibility] = useToggle(false);

  const endAdornment = inputModel.sensitive ? (
    <InputAdornment position="end">
      <IconButton aria-label="toggle value visibility" onClick={toggleVisibility} edge="end">
        {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    </InputAdornment>
  ) : undefined;

  return (
    <OptionalParamWrapper onRemove={onRemove}>
      <ReferencedFieldWrapper commonFieldProps={commonFieldProps} modelType={ModelType.STRING}>
        <TextField
          {...commonFieldProps}
          type={!inputModel.sensitive || isVisible ? "text" : "password"}
          fullWidth
          select={inputModel.enum !== undefined}
          multiline={inputModel.multiline}
          rows={4}
          maxRows={10}
          InputProps={{
            endAdornment,
          }}
        >
          {inputModel.enum?.map((itemValue, index) => (
            <MenuItem key={itemValue} value={itemValue}>
              {inputModel.enumDescriptions?.[index] ?? itemValue}
            </MenuItem>
          ))}
        </TextField>
      </ReferencedFieldWrapper>
    </OptionalParamWrapper>
  );
};
