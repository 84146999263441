import { type FC, useId } from "react";

import { ModelType } from "@doitintl/cmp-models";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { type FieldInputProps, useFormikContext } from "formik";

import { useFieldCommonProps } from "../useFieldCommonProps";
import { OptionalParamWrapper } from "./wrappers/OptionalParamWrapper";
import { ReferencedFieldWrapper } from "./wrappers/ReferencedField/ReferencedFieldWrapper";

export const BooleanParam: FC<{
  fieldProps: FieldInputProps<boolean>;
  label: string;
  onRemove?: () => void;
}> = ({ fieldProps, label, onRemove }) => {
  const commonFieldProps = useFieldCommonProps(fieldProps, label, onRemove === undefined);
  const formikProps = useFormikContext();
  const id = useId();

  return (
    <OptionalParamWrapper onRemove={onRemove}>
      <ReferencedFieldWrapper commonFieldProps={commonFieldProps} modelType={ModelType.BOOLEAN}>
        <FormControl fullWidth>
          <InputLabel id={id}>{commonFieldProps.label}</InputLabel>
          <Select
            {...commonFieldProps}
            value={commonFieldProps.value ? 1 : 0}
            onChange={({ target: { value } }) => formikProps.setFieldValue(fieldProps.name, value === 1)}
            labelId={id}
            size="small"
          >
            <MenuItem value={0}>false</MenuItem>
            <MenuItem value={1}>true</MenuItem>
          </Select>
        </FormControl>
      </ReferencedFieldWrapper>
    </OptionalParamWrapper>
  );
};
