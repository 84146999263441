import { useCallback, useMemo } from "react";

import { AnalyticsResourceType, CloudAnalyticsModel, type CloudAnalyticsModelMetricModel } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  type TransformMethod,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";

import { metricText } from "../../../assets/texts";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { type AttributionWRef, type MetricWSnap } from "../../../types";

const metricsTransform = (
  data: WithFirebaseModel<CloudAnalyticsModelMetricModel>,
  snapshot: DocumentSnapshotModel<CloudAnalyticsModelMetricModel>,
  attributions: AttributionWRef[] | null
): MetricWSnap | null => {
  if (attributions && data.type === AnalyticsResourceType.PRESET && data.variables) {
    const isMatch = data.variables.some((v) => attributions.some((a) => a.ref.id === v?.attribution?.id));
    if (!isMatch) {
      return null;
    }
  } else if (!data.variables || data.variables.length === 0) {
    return null;
  }

  if (data.name === metricText.UNTITLED_METRIC) {
    return null;
  }

  return {
    data,
    snapshot,
  };
};

export const useCustomMetrics = (): [MetricWSnap[], boolean] => {
  const { customer } = useCustomerContext();
  const { filteredAttributions } = useAttributionsContext();

  const isPresetMetricsEntitled = useIsFeatureEntitled("analytics:calculatedMetrics:presets", true);
  const isCustomMetricsEntitled = useIsFeatureEntitled("analytics:calculatedMetrics", true);

  const metricsCollectionRef = useMemo(
    () => getCollection(CloudAnalyticsModel).doc("metrics").collection("cloudAnalyticsMetrics"),
    []
  );

  const presetMetricsQuery = useMemo(() => {
    if (!isPresetMetricsEntitled) {
      return;
    }

    return metricsCollectionRef.where("type", "==", AnalyticsResourceType.PRESET).where("customer", "==", null);
  }, [isPresetMetricsEntitled, metricsCollectionRef]);

  const customMetricsQuery = useMemo(() => {
    if (!isCustomMetricsEntitled) {
      return;
    }

    return metricsCollectionRef.where("type", "==", AnalyticsResourceType.CUSTOM).where("customer", "==", customer.ref);
  }, [isCustomMetricsEntitled, metricsCollectionRef, customer.ref]);

  const transformPreset: TransformMethod<CloudAnalyticsModelMetricModel, MetricWSnap> = useCallback(
    (data, snapshot) => metricsTransform(data, snapshot, filteredAttributions),
    [filteredAttributions]
  );

  const [presetMetrics, loadingPresetMetrics] = useCollectionData(presetMetricsQuery, {
    transform: transformPreset,
  });

  const transformCustom: TransformMethod<CloudAnalyticsModelMetricModel, MetricWSnap> = useCallback(
    (data, snapshot) => metricsTransform(data, snapshot, null),
    []
  );

  const [customMetrics, loadingCustomMetrics] = useCollectionData(customMetricsQuery, {
    transform: transformCustom,
  });

  const metricsList = useMemo<MetricWSnap[]>(
    () =>
      [...(presetMetrics ?? []), ...(customMetrics ?? [])].sort(
        (a, b) => (b?.data.timeModified?.toDate().getTime() || 0) - (a?.data.timeModified?.toDate().getTime() || 0)
      ),
    [presetMetrics, customMetrics]
  );

  const isLoading = (loadingPresetMetrics && !!presetMetricsQuery) || (loadingCustomMetrics && !!customMetricsQuery);

  return [metricsList, isLoading];
};
