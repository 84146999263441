import { CloudFlowNodeType, type ReferencedNodeValue } from "@doitintl/cmp-models";

import { type NodeModelWithId } from "./types";

export function isReferencedNodeValue(value: unknown): value is ReferencedNodeValue {
  if (value === null || typeof value !== "object") {
    return false;
  }
  const keys = Object.keys(value);
  const expectedKeys = ["referencedNodeId", "referencedField"];

  return keys.length === expectedKeys.length && expectedKeys.every((expectedKey) => keys.includes(expectedKey));
}

export function isActionNode(nodeEntity: NodeModelWithId): nodeEntity is NodeModelWithId<CloudFlowNodeType.ACTION> {
  return nodeEntity.type === CloudFlowNodeType.ACTION;
}

export function isFilterNode(nodeEntity: NodeModelWithId): nodeEntity is NodeModelWithId<CloudFlowNodeType.FILTER> {
  return nodeEntity.type === CloudFlowNodeType.FILTER;
}

export function isConditionNode(
  nodeEntity: NodeModelWithId
): nodeEntity is NodeModelWithId<CloudFlowNodeType.CONDITION> {
  return nodeEntity.type === CloudFlowNodeType.CONDITION;
}
