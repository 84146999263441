import { useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router";
import { Link as InternalLink } from "react-router-dom";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import BarChartIcon from "@mui/icons-material/BarChart";
import LinkIcon from "@mui/icons-material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";

import { useAsyncCurrency } from "../../../Components/hooks/useCurrency";
import LoadingButton from "../../../Components/LoadingButton";
import { useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import { consoleErrorWithSentry } from "../../../utils";
import { useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";
import { handleBackClick } from "../../../utils/navigation";
import { useInsights } from "../../Insights/api";
import { useJiraIssue, useThreadId, useUserId } from "../api";
import { deleteThread } from "../firestore";
import { JiraStatusChip } from "../LinkedThread/JiraStatusChip";
import ThreadThreeDotsMenu from "../ThreeDotsMenu/ThreadThreeDotsMenu";
import { createImpactFormatter, formatDate, getImpactAndUnits } from "../utils";
import { ThreadDetailsField } from "./ThreadDetailsField";

type Parameters = {
  threadId: string;
};

export const ThreadDetails = () => {
  const theme = useTheme();

  const { isDoitEmployee } = useAuthContext();

  const [deleting, setDeleting] = useState(false);

  const history = useHistory();

  const { isMobile } = useFullScreen();
  const { customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(customer);

  const successSnackbar = useSuccessSnackbar();

  const { threadId } = useParams<Parameters>();

  useEffect(() => {
    mixpanel.track("threads.details.open", {
      thread: threadId,
    });
  }, [threadId]);

  const { insights, isFetching: isFetchingInsights } = useInsights();

  const { thread, isFetching: isFetchingThread } = useThreadId(threadId);

  const { user, isFetching: isFetchingUser } = useUserId(thread?.reporter?.id);

  const { jiraIssue, isErrored } = useJiraIssue(thread?.targetData.issueKey);

  const { asyncConvertCurrency, customerCurrencySymbol } = useAsyncCurrency();

  const matchingInsight = useMemo(
    () => (insights || []).find((insight) => insight.key === thread?.sourceData?.insightKey),
    [insights, thread?.sourceData?.insightKey]
  );

  const [dataConverted, setDataConverted] = useState<{ impact: number; impactUnits: string; isCurrency: boolean }>();

  useEffect(() => {
    getImpactAndUnits(matchingInsight, asyncConvertCurrency, customerCurrencySymbol).then(
      ({ value: impact, units: impactUnits, isCurrency }) => {
        setDataConverted({
          impact,
          impactUnits,
          isCurrency,
        });
      }
    );
  }, [matchingInsight, asyncConvertCurrency, customerCurrencySymbol]);

  if (isFetchingInsights || isFetchingThread || isFetchingUser || (!jiraIssue && !isErrored)) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={6}>
        <CircularProgress />
      </Box>
    );
  }

  let createdOnFieldValue = "n/a";

  if (jiraIssue?.createdDate) {
    createdOnFieldValue = formatDate(jiraIssue.createdDate);
    const createdByUser = user?.displayName || user?.email || "";

    if (createdByUser) {
      createdOnFieldValue += `, by ${createdByUser}`;
    }
  }

  const fields = [
    {
      label: "Priority",
      value: jiraIssue?.priority || "n/a",
    },
    {
      label: "Project",
      value: jiraIssue?.project || "n/a",
    },
    {
      label: "Issue type",
      value: jiraIssue?.issueType || "n/a",
    },
    {
      label: "Description",
      value: jiraIssue?.description || "n/a",
    },
    {
      label: "Assignee",
      value: jiraIssue?.assignee || "Unassigned",
    },
    {
      label: "Due date",
      value: formatDate(jiraIssue?.dueDate),
    },
    {
      label: "Created on",
      value: createdOnFieldValue,
    },
    {
      label: "Updated on",
      value:
        jiraIssue?.updatedDate && jiraIssue.updatedName
          ? `${formatDate(jiraIssue?.updatedDate)}, by ${jiraIssue?.updatedName}`
          : "n/a",
    },
  ];

  const handleDeleteThread = async () => {
    try {
      setDeleting(true);

      await deleteThread(threadId);

      successSnackbar("Thread deleted successfully!");

      setDeleting(false);

      history.goBack();
    } catch (e) {
      consoleErrorWithSentry(e);
      setDeleting(false);
    }
  };

  return (
    <Container maxWidth={isMobile ? undefined : "lg"} sx={{ px: isMobile ? 0 : undefined }}>
      <Grid container spacing={3}>
        <Grid container item xs={12} md={8}>
          <Card sx={{ border: "none" }}>
            <CardHeader
              sx={{
                ".MuiCardHeader-avatar": {
                  pt: 1,
                  alignSelf: "flex-start",
                },
                px: isMobile ? 0 : undefined,
              }}
              title={
                !isErrored && matchingInsight ? (
                  <Box>
                    <Typography variant="body2" color={theme.palette.text.secondary} sx={{ my: 2.5 }}>
                      Threads
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                      <Box>
                        <Typography variant="h1">{jiraIssue?.summary || ""}</Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            mt: 2,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <LinkIcon sx={{ pr: 1, verticalAlign: "middle" }} />
                          <Typography
                            component={InternalLink}
                            variant="body2"
                            sx={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                            to={`/customers/${customer.id}/insights/${matchingInsight?.providerId}/${thread?.sourceData.insightKey}`}
                          >
                            Insight: {matchingInsight?.title || ""}
                          </Typography>
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            mt: 2,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <BarChartIcon sx={{ pr: 1, verticalAlign: "middle" }} />
                          Impact:{" "}
                          {dataConverted &&
                            createImpactFormatter(
                              dataConverted.impactUnits,
                              dataConverted.isCurrency
                            )(dataConverted.impact)}
                        </Typography>
                        <Tooltip
                          title={isPresentationMode ? "Jira connection is not activated in Presentation mode." : ""}
                        >
                          <span>
                            <Button
                              disabled={isPresentationMode}
                              component={Link}
                              href={thread?.targetData?.issueURL || ""}
                              target="_blank"
                              rel="noopener"
                              variant="contained"
                              size="medium"
                              color="primary"
                              sx={{ mt: 4 }}
                            >
                              Manage in Jira
                              <OpenInNewIcon sx={{ ml: 1 }} />
                            </Button>
                          </span>
                        </Tooltip>
                      </Box>
                      <Box sx={{ mt: 0.5, mx: 2 }}>
                        <ThreadThreeDotsMenu
                          deleteOnly
                          threadId={thread?.id ?? ""}
                          targetUrl={thread?.targetData.issueURL ?? ""}
                        />
                      </Box>
                    </Stack>
                  </Box>
                ) : (
                  <Alert severity="error" sx={{ width: "fit-content" }}>
                    <Typography variant="body1">Error loading thread</Typography>
                    <Typography variant="body2">
                      Something went wrong and we could not load this thread. This could be because the issue has been
                      deleted in Jira. Would you like to delete this thread?
                    </Typography>
                    <Tooltip
                      title={
                        isPresentationMode
                          ? "Threads cannot be deleted in Presentation mode."
                          : isDoitEmployee
                            ? "DoiT employees cannot delete threads"
                            : ""
                      }
                    >
                      <span>
                        <LoadingButton
                          disabled={isDoitEmployee || isPresentationMode}
                          loading={deleting}
                          variant="outlined"
                          size="medium"
                          color="primary"
                          sx={{ mt: 2 }}
                          onClick={handleDeleteThread}
                          mixpanelEventId="threads.delete"
                        >
                          Delete thread
                        </LoadingButton>
                      </span>
                    </Tooltip>
                  </Alert>
                )
              }
              avatar={
                isMobile ? null : (
                  <IconButton
                    aria-label="Back"
                    onClick={handleBackClick(history, `/customers/${customer.id}/threads`)}
                    size="large"
                  >
                    <BackIcon color="primary" />
                  </IconButton>
                )
              }
            />
            {!isErrored && matchingInsight ? (
              <CardContent sx={{ pl: isMobile ? 0 : 10, mb: 5 }}>
                <Divider sx={{ mb: 4 }} />
                <Grid container spacing={1.5}>
                  {/* Status is a special case: */}
                  <Grid container item xs={12} md={3}>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      Status
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} md={9}>
                    <JiraStatusChip status={jiraIssue?.status || ""} statusCategory={jiraIssue?.statusCategory || ""} />
                  </Grid>
                  {/* All other fields: */}
                  {fields.map((item, index) => (
                    <ThreadDetailsField key={index} label={item.label} value={item.value} />
                  ))}
                </Grid>
              </CardContent>
            ) : null}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
