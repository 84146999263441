import { useCallback, useState } from "react";

import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { CLOUD_FLOW_CREATION_STATUS } from "@doitintl/cmp-models";
import { Box, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import noop from "lodash/noop";

import { cloudflowTexts } from "../../assets/texts";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { type CustomerPageId } from "../../Navigation/config/pages";
import { type CloudflowWSnap } from "../../types/Cloudflow";
import CloudflowBrowser from "./CloudflowBrowser/CloudflowBrowser";
import { useEditLink } from "./CloudflowBrowser/utils";
import { initialNodes } from "./CloudflowBuilder/hooks/consts";
import TemplatesRow from "./CloudflowTemplates/TemplatesRow";
import DeleteCloudFlowDialog from "./Dialog/DeleteCloudFlow";
import { useCloudflows, useCloudflowTemplates, useClouds, useCreateCloudflow, useDeleteCloudflow } from "./hooks";
import { type CloudflowTemplate } from "./types";

type DashboardProps = {
  pageId: CustomerPageId;
};

const Dashboard = ({ pageId }: DashboardProps) => {
  const history = useHistory();
  const { customer } = useCustomerContext();
  const {
    userModel: { email },
  } = useUserContext({ allowNull: false });
  const [deleteDialogId, setDeleteDialogId] = useState<string | null>(null);

  const { cloudflows, cloudflowsLoading } = useCloudflows();
  const { templates, templatesLoading } = useCloudflowTemplates();
  const location = useLocation();
  const [createCloudflow] = useCreateCloudflow();
  const [deleteCloudflow] = useDeleteCloudflow();

  const clouds = useClouds();

  const onViewRunHistory = useCallback(
    (row?: CloudflowWSnap) => {
      const pathname = `/customers/${customer.id}/cloudflow/history`;
      const state = row ? { name: row.data.name } : undefined;

      history.push({
        pathname,
        state,
      });
    },
    [customer.id, history]
  );

  const onEditLink = useEditLink();

  const onEdit = useCallback(
    (id: string) => {
      const link = onEditLink(id);
      history.push(link);
    },
    [history, onEditLink]
  );

  const onUnpublish = useCallback(noop, []);

  const onDelete = useCallback((id: string) => {
    setDeleteDialogId(id);
  }, []);

  const deleteCloudFlow = useCallback(
    async (id: string) => {
      await deleteCloudflow(customer.id, { id });
    },
    [customer.id, deleteCloudflow]
  );

  const handelCloseDeleteDialog = useCallback(() => {
    setDeleteDialogId(null);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!deleteDialogId) return;

    await deleteCloudFlow(deleteDialogId);
    handelCloseDeleteDialog();
  }, [deleteCloudFlow, handelCloseDeleteDialog, deleteDialogId]);

  const onNameClick = useCallback(
    (link) => {
      history.push(link);
    },
    [history]
  );

  const handleCreateCloudflow = useCallback(
    async (cloudflowName: string) => {
      const result = await createCloudflow(customer.id, {
        name: cloudflowName,
        status: CLOUD_FLOW_CREATION_STATUS.DRAFT,
        firstNodeId: initialNodes[0].id,
        nodes: initialNodes,
        collaborators: [{ email, role: "owner" }],
      });

      const cloudflowId = result?.data.id;

      if (cloudflowId) {
        history.push(`${location.pathname}/edit/${cloudflowId}`);
      }
    },
    [email, createCloudflow, customer.id, history, location.pathname]
  );

  const onCreateCloudflow = useCallback(async () => {
    await handleCreateCloudflow("New Cloudflow");
  }, [handleCreateCloudflow]);

  const onClickViewAll = useCallback(() => {
    history.push(`${location.pathname}/templates/`);
  }, [history, location.pathname]);

  const onTemplateClick = useCallback(
    (template: CloudflowTemplate) => {
      history.push(`${location.pathname}/templates/${template.id}`);
    },
    [history, location.pathname]
  );

  return (
    <>
      {pageId === "cloudflow" && (
        <Box mx={-2} mt={-1} bgcolor="background.paper">
          <Box px={2} mt={-4}>
            <Box mb={3} />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack gap={1}>
                  <Typography variant="h1">{cloudflowTexts.CLOUDFLOW}</Typography>
                  <Typography variant="body1" color="text.secondary">
                    {cloudflowTexts.DASHBOARD_SUBHEADER}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Box mb={3} />
            <TemplatesRow
              templatesLoading={templatesLoading}
              templates={templates}
              onTemplateClick={onTemplateClick}
              onClickViewAll={onClickViewAll}
              clouds={clouds}
            />
            <Box mb={3} />
            <CloudflowBrowser
              cloudflows={cloudflows}
              loading={cloudflowsLoading}
              actions={{ onCreateCloudflow, onViewRunHistory, onDelete, onEdit, onUnpublish, onNameClick }}
            />
            <DeleteCloudFlowDialog
              handleClose={handelCloseDeleteDialog}
              handleDelete={handleDelete}
              isDialogOpened={!!deleteDialogId}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Dashboard;
