import { type Dispatch, useCallback, useEffect, useMemo, useState } from "react";

import {
  CurrenciesMap,
  type CurrencyCodes,
  DashboardModel,
  type DashboardModelAttributionModel,
  Metadata,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";
import { CheckCircle, Close as CloseIcon, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

import { useApiContext } from "../../../../api/context";
import { guidedExperienceText } from "../../../../assets/texts/CloudAnalytics";
import { useAnalyticsDimensions } from "../../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions";
import { type AnalyticsMetadata } from "../../../../Components/hooks/cloudAnalytics/useAnalyticsMetadata";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type AttributionWRef } from "../../../../types";
import { consoleErrorWithSentry } from "../../../../utils";
import useAttributionCreateFormula from "../../attributions/AttributionCreate/useAttributionCreateFormula";
import useAttributionCreateSelector from "../../attributions/AttributionCreate/useAttributionCreateSelector";
import { createAttribution, updateAttribution } from "../../attributions/db";
import { useDeleteAttribution } from "../../attributions/DeleteAttributionValidation";
import { isValidFiltersAndFormula } from "../../attributions/utils";
import { formatFirestoreDate } from "../../utilities";
import { type CostAllocationAction, type ExtendedAttribution } from "../costAllocationReducer";
import { GUIDED_EXPERIENCE } from "../utils";

const getAttributionWithRef = async (
  docRef: ModelReference<DashboardModelAttributionModel>
): Promise<AttributionWRef | null> => {
  const snapshot = await docRef.get();
  const data = snapshot.asModelData();
  if (data) {
    return {
      data,
      ref: docRef,
      transform: {
        timeModified: formatFirestoreDate(data.timeModified),
      },
    };
  }
  return null;
};

type Props = {
  agName: string;
  attribution: ExtendedAttribution;
  currency: CurrencyCodes;
  dispatchCostAllocationState: Dispatch<CostAllocationAction>;
  formatter: (value: number, showSign?: boolean) => string;
  metadataSnapshots: AnalyticsMetadata;
  nameFieldAutoFocus: boolean;
  previewResult: number[];
  queryRunning: boolean;
};

const Step3Row = ({
  agName,
  attribution,
  currency,
  dispatchCostAllocationState,
  formatter,
  metadataSnapshots,
  nameFieldAutoFocus,
  previewResult,
  queryRunning,
}: Props) => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const { dimensions, handleDimensionLabelsChange } = useAnalyticsDimensions({
    metadataSnapshots,
  });
  const deleteAttribution = useDeleteAttribution(GUIDED_EXPERIENCE);

  const [open, setOpen] = useState(false);
  const [name, setName] = useState(attribution.data?.name ?? "");
  const [isAttributionCreating, setIsAttributionCreating] = useState(false);
  const isEmptyAttribution = !attribution.data;

  const filteredDimensions = useMemo(() => {
    const filtered = dimensions?.slice();
    filtered?.forEach((d) => {
      if (d.data.type === Metadata.DATETIME) {
        d._visible = false;
      } else if (d.data.type === Metadata.ATTRIBUTION) {
        const index = filtered.indexOf(d);
        if (index !== -1) {
          filtered.splice(index, 1);
        }
      }
    });
    return filtered;
  }, [dimensions]);

  const { AttributionCreateSelector, filters } = useAttributionCreateSelector({
    initialFilters: attribution.data?.filters ?? undefined,
    dimensions: filteredDimensions,
    handleDimensionLabelsChange,
    // excludeSelectMetadataIds,
    guidedExperienceMode: true,
  });

  const { AttributionCreateFormula, formulaState } = useAttributionCreateFormula({
    formula: attribution.data?.formula,
    filters,
    guidedExperienceMode: true,
  });

  useEffect(() => {
    const isNameChanged = name !== (attribution.data?.name ?? "");
    const isFiltersChanged = !isEqual(attribution.data?.filters ?? [], filters);
    const isFormulaChanged = formulaState.value.trim() !== (attribution.data?.formula?.trim() ?? "");
    const hasUnsavedChanges = isNameChanged || isFiltersChanged || isFormulaChanged;

    if (hasUnsavedChanges !== attribution.hasUnsavedChanges) {
      dispatchCostAllocationState({
        type: "UPDATE_ATTRIBUTION",
        payload: {
          attrId: attribution.ref.id,
          attrHasUnsavedChanges: hasUnsavedChanges,
        },
      });
    }
  }, [attribution, dispatchCostAllocationState, filters, formulaState.value, name]);

  const hasValidFiltersAndFormula = useMemo(
    () => isValidFiltersAndFormula(filters, formulaState.valid),
    [filters, formulaState.valid]
  );

  const isValidAttribution = useMemo(() => !!(name && hasValidFiltersAndFormula), [name, hasValidFiltersAndFormula]);

  const handleSave = useCallback(async () => {
    if (!isValidAttribution || !attribution.hasUnsavedChanges) {
      return;
    }

    try {
      let docRef: ModelReference<DashboardModelAttributionModel>;

      if (isEmptyAttribution) {
        setIsAttributionCreating(true);

        const fields = {
          draft: true,
          expireBy: new Date(Date.now() + 24 * 60 * 60 * 1000),
          filters,
          formula: formulaState.value,
          name: name.trim(),
        };

        await createAttribution(api, customer.id, fields, attribution.ref.id, { from: GUIDED_EXPERIENCE });

        docRef = getCollection(DashboardModel)
          .doc("google-cloud-reports")
          .collection("attributions")
          .doc(attribution.ref.id);
      } else {
        const fields = {
          filters,
          formula: formulaState.value,
          name: name.trim(),
        };
        await updateAttribution(api, customer.id, attribution.ref.id, fields, { from: GUIDED_EXPERIENCE });
        docRef = attribution.ref;
      }

      const newAttribution = await getAttributionWithRef(docRef);

      dispatchCostAllocationState({
        type: "UPDATE_ATTRIBUTION",
        payload: { newAttribution, attrId: newAttribution?.ref?.id, attrHasUnsavedChanges: false },
      });
    } catch (error: any) {
      consoleErrorWithSentry(error);
    }
    setIsAttributionCreating(false);
  }, [
    api,
    attribution.hasUnsavedChanges,
    attribution.ref,
    customer,
    dispatchCostAllocationState,
    filters,
    formulaState.value,
    isEmptyAttribution,
    isValidAttribution,
    name,
  ]);

  const handleSaveDebounced = useMemo(() => debounce(handleSave, 1000), [handleSave]);

  useEffect(() => {
    handleSaveDebounced();
    return () => {
      handleSaveDebounced.cancel();
    };
  }, [handleSaveDebounced]);

  const toggleCollapse = () => {
    setOpen((prev) => !prev);
  };

  const removeRow = useCallback(async () => {
    dispatchCostAllocationState({ type: "DELETE_ATTRIBUTION", payload: { attrId: attribution.ref.id } });

    if (!isEmptyAttribution) {
      await deleteAttribution({
        ids: [attribution.ref.id],
        onResponsePopulated: () => {},
        onResponseEmpty: () => {},
        onError: () => {},
      });
    }
  }, [attribution.ref.id, deleteAttribution, dispatchCostAllocationState, isEmptyAttribution]);

  return (
    <>
      <TableRow hover sx={{ "& .MuiTableCell-root": { borderBottom: "none" } }}>
        <TableCell>
          <TextField
            autoFocus={nameFieldAutoFocus}
            placeholder={`${guidedExperienceText.step3.enter}${agName ? ` ${agName} ` : " "}${guidedExperienceText.step3.nameHere}`}
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            sx={{ backgroundColor: "background.paper" }}
          />
        </TableCell>
        {previewResult.map((res, idx) => (
          <TableCell key={`row-${idx}-${res}`} align="center">
            {queryRunning && !isEmptyAttribution ? (
              <CircularProgress size={16} />
            ) : isEmptyAttribution ? (
              <Typography variant="body2" color="textSecondary">
                {CurrenciesMap[currency]}
              </Typography>
            ) : (
              formatter(res || 0, true)
            )}
          </TableCell>
        ))}
        <TableCell align="center">
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Button variant="text" onClick={toggleCollapse}>
              {guidedExperienceText.step3.setUp}
            </Button>
            <Box sx={{ width: 16, height: 16, ml: 0.5 }}>
              {isAttributionCreating ? (
                <CircularProgress size={16} />
              ) : !isEmptyAttribution && isValidAttribution ? (
                <CheckCircle sx={{ color: "#2E7D32", width: 16, height: 16 }} />
              ) : null}
            </Box>
          </Box>
        </TableCell>
        <TableCell size="small" sx={{ p: 1 }}>
          <IconButton onClick={toggleCollapse}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
          <IconButton onClick={removeRow}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ py: open ? 3 : 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto">
            <Box sx={{ margin: 1 }}>
              <Typography variant="body1" gutterBottom component="div">
                {guidedExperienceText.step3.rowDescription}
              </Typography>
              {AttributionCreateSelector}
              {AttributionCreateFormula}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Step3Row;
