import { type FC } from "react";

export type HighlightResult = {
  value: string;
  matchLevel: "none" | "partial" | "full";
  fullyHighlighted?: boolean;
  matchedWords: string[];
};

export type CategorizedSkills = {
  platform: HighlightResult[];
  category: HighlightResult[];
  service: HighlightResult[];
  activity: HighlightResult[];
  "pro-serv": HighlightResult[];
};

export const HighlightedText: FC<{ text: string; highlightedValue?: string }> = ({ text, highlightedValue }) => {
  if (!highlightedValue) {
    return <>{text}</>;
  }
  return <span dangerouslySetInnerHTML={{ __html: highlightedValue }} />;
};

export type SkillCategory = {
  type: "platform" | "category" | "service" | "activity" | "pro-serv" | "other";
  name: string;
};

export const categorizeSkill = (skill: string): SkillCategory => {
  const parts = skill.split("/");
  if (parts.length > 1) {
    const platform = parts[0].toUpperCase();
    switch (parts[1]) {
      case "platform":
        return { type: "platform", name: platform };
      case "category": {
        const categoryName = parts.slice(2).join(" ").replace(/-/g, " ");
        return { type: "category", name: `${platform} ${categoryName}` };
      }
      case "service": {
        const serviceName = parts.slice(2).join("-").replace(/-/g, " ");
        return { type: "service", name: `${platform}-${serviceName}` };
      }
      case "activity": {
        const activityName = parts.slice(2).join(" ").replace(/-/g, " ");
        return { type: "activity", name: `${platform} ${activityName}` };
      }
      case "pro-serv": {
        const proServName = parts.slice(2).join("-").replace(/-/g, " ");
        return { type: "pro-serv", name: `${platform}-${proServName}` };
      }
    }
  }
  return { type: "other", name: skill };
};

export const categorizeSkills = (skills: HighlightResult[]): CategorizedSkills =>
  skills.reduce(
    (acc: CategorizedSkills, skill) => {
      const skillValue = skill.value.replace(/<\/?mark>/g, "");
      const category = categorizeSkill(skillValue);
      if (category.type !== "other") {
        acc[category.type].push(skill);
      }
      return acc;
    },
    { platform: [], category: [], service: [], activity: [], "pro-serv": [] }
  );
