import { useCallback, useMemo, useRef } from "react";

import { WidgetState } from "@doitintl/cmp-models";

import { useApiContext } from "../../api/context";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useDashboardsContext } from "../../Context/DashboardContext";
import { refreshWidget } from "../../Pages/CloudAnalytics/handlers/updateWidgets";
import { ErrorCode } from "../../Pages/CloudAnalytics/utilities";
import { type ReportWSnap } from "../../types";
import { consoleErrorWithSentry } from "../../utils";
import { getWidgetId } from "../../utils/widgets";
import { useWidgetData } from "../Dashboard/Analytics/hooks";

export const CACHE_DASHBOARD = "Reports cache";

type Props = {
  report: ReportWSnap;
  isTable: boolean;
  handleReportError: (code: ErrorCode) => void;
};

const useReportCache = ({ report, isTable, handleReportError }: Props) => {
  const { dashboards, addWidgetToDashboard, createDashboard } = useDashboardsContext();
  const { customer } = useCustomerContext();
  const api = useApiContext();
  const cacheDashboard = useMemo(() => dashboards.find((d) => d.name === CACHE_DASHBOARD), [dashboards]);
  const isDashboardCreated = useRef(false);

  const reportWidget = useMemo(
    () => ({
      width: isTable ? 3 : 1,
      name: `cloudReports::${customer.id}_${report.snapshot.id}`,
      state: WidgetState.Success,
    }),
    [customer.id, isTable, report.snapshot.id]
  );

  const currentReportWidget = useMemo(
    () => cacheDashboard?.widgets.find((w) => w.name === reportWidget.name),
    [cacheDashboard, reportWidget.name]
  );

  const createCacheDashboard = useCallback(async () => {
    try {
      return await createDashboard({
        name: CACHE_DASHBOARD,
        isPublic: true,
        hidden: true,
        widgets: [reportWidget],
      });
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  }, [createDashboard, reportWidget]);

  const addCacheWidgetToDashboard = useCallback(async () => {
    if (cacheDashboard) {
      try {
        if (!currentReportWidget) {
          await addWidgetToDashboard(cacheDashboard.id, reportWidget.name, { width: reportWidget.width });
        }
        await refreshWidget(api, customer.id, report.snapshot.id);
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    }
  }, [
    cacheDashboard,
    currentReportWidget,
    api,
    customer.id,
    report.snapshot.id,
    addWidgetToDashboard,
    reportWidget.name,
    reportWidget.width,
  ]);

  const isReportCached = useMemo(
    () =>
      cacheDashboard?.widgets.find((w) => {
        const [, reportId] = w.name.split("_");
        return reportId === report.snapshot.id;
      }),
    [cacheDashboard, report.snapshot.id]
  );

  const cacheReport = useCallback(async () => {
    try {
      if (cacheDashboard) {
        await addCacheWidgetToDashboard();
      } else if (!isDashboardCreated.current) {
        const id = await createCacheDashboard();
        if (id) {
          isDashboardCreated.current = true;
        }
        await refreshWidget(api, customer.id, report.snapshot.id);
      }
    } catch (error: any) {
      if (error.response?.status === 507) {
        handleReportError(ErrorCode.EXCEEDED_FIRESTORE_LIMIT);
      }
      consoleErrorWithSentry(error);
    }
  }, [
    addCacheWidgetToDashboard,
    api,
    cacheDashboard,
    createCacheDashboard,
    customer.id,
    handleReportError,
    report.snapshot.id,
  ]);

  const widgetId = () => {
    if (isReportCached) {
      return getWidgetId(isReportCached);
    }
    return "";
  };
  const id = widgetId();
  const { data } = useWidgetData(id);

  const rawReport = useMemo(() => data?.rawReport, [data?.rawReport]);

  return {
    cacheReport,
    rawCacheReport: rawReport,
    reportCacheUpdatedAt: data?.updateTime,
    currentReportWidget,
  };
};

export default useReportCache;
