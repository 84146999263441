import { type EntitlementKey } from "@doitintl/cmp-models";

type TemplateFunction = (displayName: string) => string;

type PaymentRequirement = "free" | "fourFree" | "paid";

export const acceleratorEntitlementKeys: EntitlementKey[] = [
  "services:accelerator:included",
  "services:accelerator:included:4",
  "services:accelerator:paid",
] as const;
export type AcceleratorEntitlementKey = (typeof acceleratorEntitlementKeys)[number];

export const workshopEntitlementKeys: EntitlementKey[] = [
  "services:workshop:included",
  "services:workshop:paid",
] as const;
export type WorkshopEntitlementKey = (typeof workshopEntitlementKeys)[number];

export const professionalServicesEntitlementKeys: EntitlementKey[] = [
  ...acceleratorEntitlementKeys,
  ...workshopEntitlementKeys,
  "services:training",
] as const;
export type ProfessionalServicesEntitlementKey = (typeof professionalServicesEntitlementKeys)[number];

export const helperTemplates: Record<PaymentRequirement, TemplateFunction> = {
  free: (displayName: string): string =>
    `Included with the Cloud ${displayName.includes("Solve") ? "" : "Solve "}${displayName} plan`,
  fourFree: (displayName: string): string =>
    `Four Accelerators included with the Cloud ${displayName.startsWith("Solve") ? "" : "Solve "}${displayName} plan`,
  paid: (displayName: string): string =>
    `Available on-demand for a fee with the Cloud ${displayName.startsWith("Solve") ? "" : "Solve "}${displayName} plan`,
};

/**
 * Get the helper text for the Professional Service offering detailing whether the user must pay.
 * @param entitlement the entitlement key representing the entitlement being used to authorize this particular professional service.
 * @param tierDisplayName the customer's tier name
 */
export const getHelperText = (
  entitlement?: ProfessionalServicesEntitlementKey,
  tierDisplayName?: string
): string | undefined => {
  if (!tierDisplayName || !entitlement) {
    return undefined;
  }

  const heritage: boolean = tierDisplayName.includes("Heritage");
  const basic: boolean = tierDisplayName.includes("Basic");
  if (heritage) {
    tierDisplayName = tierDisplayName.replace(/\s?Heritage\s?/, " ").trim();
  }
  if (basic) {
    tierDisplayName = tierDisplayName.replace(/\s?Basic\s?/, " ").trim();
  }

  let output: string;

  switch (entitlement) {
    case "services:training":
      output = helperTemplates.free(tierDisplayName);
      break;
    case "services:accelerator:included":
      output = helperTemplates.free(tierDisplayName);
      break;
    case "services:accelerator:included:4":
      output = helperTemplates.fourFree(tierDisplayName);
      break;
    case "services:accelerator:paid":
      output = helperTemplates.paid(tierDisplayName);
      break;
    case "services:workshop:included":
      output = helperTemplates.free(tierDisplayName);
      break;
    case "services:workshop:paid":
      output = helperTemplates.paid(tierDisplayName);
      break;
    default:
      return undefined;
  }

  if (heritage || basic) {
    output = output.replace("the", "your");
  }

  return output;
};
