import { useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useCustomerAccountManager, usePerkView, useRegisterInterest } from "../hooks";
import { PerkContent } from "./PerkContent";
import { PerkHeader } from "./PerkHeader";
import { PerkStickyHeader } from "./PerkStickyHeader";
import { RegisterInterestDialog } from "./RegisterInterestDialog";
import { RequestProfessionalServiceDialog } from "./RequestProfessionalServiceDialog";

const PerkView = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const { customer } = useCustomerContext();
  const { sectionTitles, disabledPerk, requestTicket, perkLoading, perk } = usePerkView();
  const pathname = useLocation().pathname?.replace(/\/+$/, "");
  const accountManager = useCustomerAccountManager();

  const [stickyHeaderActive, setStickyHeaderActive] = useState(false);
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false);
  const [requestServiceDialogOpen, setRequestServiceDialogOpen] = useState(false);

  const backTo = pathname.substring(0, pathname.lastIndexOf("/"));

  const fullHeaderHeight = useMemo<number>(() => (isXs ? 292 : 244), [isXs]);

  useEffect(() => {
    const resizeHeaderOnScroll = () => {
      setStickyHeaderActive(document.documentElement.scrollTop > fullHeaderHeight);
    };
    window.addEventListener("scroll", resizeHeaderOnScroll);
    return () => {
      window.removeEventListener("scroll", resizeHeaderOnScroll);
    };
  }, [fullHeaderHeight, setStickyHeaderActive]);

  const { registerInterest, registerInterestProServ, processing } = useRegisterInterest(
    setRequestServiceDialogOpen,
    setRegisterDialogOpen
  );

  return (
    <>
      {(perkLoading || processing) && !perk && (
        <Box display="flex" justifyContent="center" alignItems="center" height="40vh" width="100%">
          <SyncLoader size={10} color={theme.palette.primary.main} loading />
        </Box>
      )}
      {perk && sectionTitles && (
        <Box>
          {registerDialogOpen && <RegisterInterestDialog onClose={() => setRegisterDialogOpen(false)} />}
          {requestServiceDialogOpen && (
            <RequestProfessionalServiceDialog
              accountManagerName={accountManager?.name}
              loading={perkLoading}
              onConfirm={registerInterestProServ}
              onClose={() => {
                setRequestServiceDialogOpen(false);
              }}
              perkType={perk.fields.type}
            />
          )}
          <PerkHeader
            backTo={backTo}
            disabledPerk={disabledPerk}
            registerInterest={registerInterest}
            perk={perk}
            ticket={requestTicket}
            sectionTitles={sectionTitles}
          />
          {stickyHeaderActive && (
            <PerkStickyHeader
              backTo={backTo}
              customerId={customer.id}
              disabledPerk={disabledPerk}
              registerInterest={registerInterest}
              perk={perk}
              sectionTitles={sectionTitles}
            />
          )}
          <PerkContent
            perk={perk}
            sectionTitles={sectionTitles}
            registerInterest={registerInterest}
            stickyHeaderActive={stickyHeaderActive}
          />
        </Box>
      )}
    </>
  );
};

export default PerkView;
