import "./FlexsaveCalculator.css";
import "./fonts/matter-bold.ttf";
import "./fonts/matter-regular.ttf";

import { useEffect, useState } from "react";

import { useCountUp } from "react-countup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Grid, InputBase, MenuItem, Select, TextField, ThemeProvider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import isEmail from "is-email";

import mixpanel from "../../utils/mixpanel";
import { CloudProviders, useChiliPiper } from "../hooks/useChiliPiper";
import LoadingButton from "../LoadingButton";
import { styles, theme } from "./FlexsaveCalculatorStyles";

const importantCurrencies: { label: string; sign: string }[] = [
  {
    label: "USD",
    sign: "$",
  },
  {
    label: "GBP",
    sign: "£",
  },
  {
    label: "EUR",
    sign: "€",
  },
];

const otherCurrencies: { label: string; sign: string }[] = [
  {
    label: "ILS",
    sign: "₪",
  },
  {
    label: "AUD",
    sign: "A$",
  },
  {
    label: "CAD",
    sign: "C$",
  },
  {
    label: "DKK",
    sign: "kr",
  },
  {
    label: "NOK",
    sign: "kr",
  },
  {
    label: "SEK",
    sign: "kr",
  },
  {
    label: "BRL",
    sign: "R$",
  },
  {
    label: "SGD",
    sign: "S$",
  },
  {
    label: "MXN",
    sign: "MX$",
  },
  {
    label: "CHF",
    sign: "Fr.",
  },
  {
    label: "MYR",
    sign: "RM",
  },
  {
    label: "TWD",
    sign: "NT$",
  },
  {
    label: "EGP",
    sign: "E£",
  },
  {
    label: "ZAR",
    sign: "R",
  },
  {
    label: "JPY",
    sign: "¥",
  },
  {
    label: "IDR",
    sign: "Rp",
  },
  {
    label: "AED",
    sign: "د.إ",
  },
  {
    label: "THB",
    sign: "฿",
  },
  {
    label: "COP",
    sign: "COL$",
  },
].sort((a, b) => a.label.localeCompare(b.label));

const currencies = [...importantCurrencies, ...otherCurrencies];

const calculateSavings = (monthlySpend: number): number => {
  const annualCloudGrowth = 1.1;
  return Math.round(12 * monthlySpend * 0.21 * annualCloudGrowth);
};

const FlexsaveCalculator = () => {
  const [currency, setCurrency] = useState(importantCurrencies[0]);
  const [monthlySpend, setMonthlySpend] = useState("");
  const [isMonthlySpendFocus, setIsMonthlySpendFocus] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const submitChiliPiper = useChiliPiper();

  const { countUp, update } = useCountUp({
    start: 0,
    end: 0,
    separator: ",",
    duration: 1,
    decimals: 0,
  });

  useEffect(() => {
    mixpanel.identify();
    mixpanel.track("web.flexsave.calc.open");
  }, []);

  useEffect(() => {
    update(calculateSavings(+monthlySpend));
  }, [monthlySpend, update]);

  const onCloudSpendSet = () => {
    setIsMonthlySpendFocus(false);
    mixpanel?.track("web.flexsave.calc.cloud-spend.set", {
      "web.flexsave.calc.spend": +monthlySpend,
    });
  };

  const onBookDemo = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    mixpanel?.track("web.flexsave.calc.cta.start", {
      "web.flexsave.calc.cta": { type: "book a demo" },
      "web.flexsave.calc.email": email,
    });

    let data;

    setIsLoading(false);
    submitChiliPiper(
      {
        email,
        platform: CloudProviders.AMAZON_WEB_SERVICES,
        estimate: parseInt(countUp.toString(), 10),
        ...(data
          ? {
              firstname: data.firstName,
              lastname: data.lastName,
              companyName: data.enrichment?.employment.name,
              companyDomain: data.enrichment?.employment.domain,
              country: data.enrichment?.geo.country,
            }
          : {
              firstname: email.split("@")[0],
            }),
      },
      () =>
        mixpanel?.track("web.flexsave.calc.cta.complete", {
          "web.flexsave.calc.email": email,
        })
    );
  };

  const onEmailSet = () => {
    if (isEmail(email)) {
      mixpanel?.track("web.flexsave.calc.email-address.set", {
        "web.flexsave.calc.email": email,
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="column" spacing={3}>
        <Grid item container direction="row" spacing={{ xs: 3, md: 10 }} sx={{ alignItems: "center" }}>
          <Grid item container spacing={3} direction="column" xs={12} md={5}>
            <Grid item>
              <Typography sx={styles.emptyStateInputLabel}>
                Enter your monthly spend below, and see how much other companies like yours have saved
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.inputLabel}>Monthly spend</Typography>
              <Box sx={styles.monthlySpendControl(isMonthlySpendFocus)}>
                <Select
                  variant="standard"
                  disableUnderline={true}
                  id="currency-selector"
                  value={currency.label}
                  onChange={(e) => setCurrency(currencies.find((c) => c.label === e.target.value) ?? currencies[0])}
                  inputProps={{ renderValue: () => currency.sign }}
                  IconComponent={KeyboardArrowDownIcon}
                  sx={styles.currencySelector}
                >
                  {currencies.map((x, i) => (
                    <MenuItem key={i} value={x.label} style={styles.currencyOption}>
                      {x.sign} {x.label}
                    </MenuItem>
                  ))}
                </Select>
                <InputBase
                  value={monthlySpend}
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  onChange={(event) =>
                    setMonthlySpend(+event.target.value === 0 ? "" : (+event.target.value).toString())
                  }
                  sx={styles.monthlySpendInput}
                  onFocus={() => setIsMonthlySpendFocus(true)}
                  onBlur={() => onCloudSpendSet()}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} md={7}>
            <Box sx={styles.savingsBox}>
              <Stack
                paddingY={{ xs: "20px" }}
                paddingX={{ xs: "50px" }}
                minHeight={{ xs: "150px" }}
                alignItems="center"
              >
                {monthlySpend ? (
                  <>
                    <Typography sx={styles.savingsResult}>
                      {currency.sign}
                      {monthlySpend ? countUp : ""}
                    </Typography>
                    <Typography sx={styles.savingsSubtitle}>Annual savings achieved by companies like yours</Typography>
                  </>
                ) : (
                  <Typography sx={styles.savingsSubtitleEmptyState}>
                    See how much other companies like yours have saved
                  </Typography>
                )}
              </Stack>
              <form style={{ width: "100%" }}>
                <Box sx={styles.bookDemoForm}>
                  <Typography sx={{ ...styles.inputLabel, fontWeight: "600" }} display="inline">
                    Learn more about Flexsave:
                  </Typography>

                  <Grid container sx={{ marginTop: "10px" }} gap={1} alignItems="center">
                    <Grid item sm xs={12}>
                      <TextField
                        placeholder="Email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        onBlur={onEmailSet}
                        sx={styles.emailInput}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <LoadingButton
                        type="submit"
                        variant="outlined"
                        sx={styles.demoButton}
                        disabled={!isEmail(email) || isLoading}
                        onClick={onBookDemo}
                        loading={isLoading}
                        mixpanelEventId="flexsave-calculator.book-demo"
                      >
                        Book a demo
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default FlexsaveCalculator;
